import { Colors } from './constants';

export default function getGoalStatusColor(status?: string) {
  switch (status) {
    case 'amber':
      return Colors.yellow;
    case 'green':
      return Colors.green;
    case 'red':
      return Colors.red;
    default:
      return Colors.gray;
  }
}

/**
 * @generated SignedSource<<6dbdd205a4492af04828180f55bdbb4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PulseGroupStatus = "Completed" | "Expired" | "Open";
export type pulseQuery$variables = {
  pulseGroupEntityId: string;
};
export type pulseQueryVariables = pulseQuery$variables;
export type pulseQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly preferredName: string | null;
      readonly submittedPulsesCount: number;
    };
    readonly findPulseGroup: {
      readonly id: string;
      readonly entityId: string;
      readonly updatedAt: DateString;
      readonly status: PulseGroupStatus;
      readonly createdAt: DateString;
      readonly remainingPulses: ReadonlyArray<{
        readonly id: string;
        readonly entityId: string;
        readonly question: {
          readonly __typename: string;
          readonly currentRevision: {
            readonly id: string;
          } | null;
          readonly body: string;
          readonly hasComment: boolean;
        };
      }>;
      readonly totalPulsesCount: number;
      readonly remainingPulsesCount: number;
      readonly skippedPulsesCount: number;
    } | null;
  } | null;
};
export type pulseQueryResponse = pulseQuery$data;
export type pulseQuery = {
  variables: pulseQueryVariables;
  response: pulseQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pulseGroupEntityId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preferredName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "submittedPulsesCount",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "pulseGroupEntityId",
    "variableName": "pulseGroupEntityId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasComment",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalPulsesCount",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remainingPulsesCount",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "skippedPulsesCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pulseQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "PulseGroup",
            "kind": "LinkedField",
            "name": "findPulseGroup",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Pulse",
                "kind": "LinkedField",
                "name": "remainingPulses",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "question",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": "currentRevision",
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "currentRevisionV2",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pulseQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "PulseGroup",
            "kind": "LinkedField",
            "name": "findPulseGroup",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Pulse",
                "kind": "LinkedField",
                "name": "remainingPulses",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "question",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": "currentRevision",
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "currentRevisionV2",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd79c01e9cbd2f8169c5a3ad9167f8dd",
    "id": null,
    "metadata": {},
    "name": "pulseQuery",
    "operationKind": "query",
    "text": "query pulseQuery(\n  $pulseGroupEntityId: String!\n) {\n  viewer {\n    user {\n      preferredName\n      submittedPulsesCount\n      id\n    }\n    findPulseGroup(pulseGroupEntityId: $pulseGroupEntityId) {\n      id\n      entityId\n      updatedAt\n      status\n      createdAt\n      remainingPulses {\n        id\n        entityId\n        question {\n          __typename\n          currentRevision: currentRevisionV2 {\n            __typename\n            id\n          }\n          body\n          hasComment\n          id\n        }\n      }\n      totalPulsesCount\n      remainingPulsesCount\n      skippedPulsesCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "43c6f6c1f6885af98796817f7093c7ed";

export default node;

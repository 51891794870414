import { useEffect, useContext, createContext } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

const AUTH_TOKEN = 'LATTICE_TK_KEY';

export async function findAccessToken(): Promise<string | null> {
  const accessToken = await AsyncStorage.getItem(AUTH_TOKEN);

  return accessToken ?? null;
}

export async function persistAccessToken(accessToken: string): Promise<void> {
  await AsyncStorage.setItem(AUTH_TOKEN, accessToken);
}

export const AuthenticationContext = createContext({
  isLoggedIn: false,
  setIsLoggedIn: (_loggedIn: boolean) => {},
});

export const AuthenticationContextProvider = AuthenticationContext.Provider;

export default function useAuthentication() {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthenticationContext);

  const logOut = async () => {
    await AsyncStorage.removeItem(AUTH_TOKEN);

    setIsLoggedIn(false);
  };

  const setAccessToken = async (t: string) => {
    await persistAccessToken(t);

    setIsLoggedIn(true);
  };

  const handleAuthentication = async () => {
    const accessToken = await findAccessToken();

    if (accessToken) {
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    handleAuthentication();
  }, []);

  return {
    isLoggedIn,
    setAccessToken,
    logOut,
  };
}

import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import { ActivityIndicator, Keyboard, TouchableOpacity, View } from 'react-native';
import { ListItem } from '@rneui/themed';
import Autocomplete from 'react-native-autocomplete-input';
import { debounce } from 'lodash';
import styled, { css } from '@emotion/native';

import { styles as TIStyles } from './TextInput';
import Avatar from './Avatar';
import StaticContainer from './StaticContainer';
import { isWeb, Colors, fontTypes } from '../utils/constants';
import { getInitials } from '../utils/fns';
import { AuthedEnv } from '../utils/createQueryRenderer';
import HandleNetworkError from './HandleNetworkError';
import { GraphqlError } from '../utils/Environment';
import type { FindUserInputQueryResponse } from 'jakiro-types/FindUserInputQuery.graphql';

type ContainerState = {
  query: string | null;
  searchTerm: string | null;
};

type ProductEnabled = { isEnabled: boolean | null };

type Products = { feedback: ProductEnabled | null };

type Person = {
  id: string;
  avatarUrl: string;
  name: string;
  title: string;
  products: Products | null;
};

type ContainerProps = {
  placeholder?: string;
  disabled?: boolean;
  inputContainerStyle?: any;
  onPersonAdd: (k: Person) => void;
  isOptionDisabled?: (option: Person | null) => string | null;
};

type FindUserInputState = {
  isFocused: boolean;
};

type FindUserInputProps = {
  query: string | null;
  loading: boolean;
  onSearch: (k: string) => void;
} & ContainerProps &
  FindUserInputQueryResponse;

class FindUserInput extends React.Component<FindUserInputProps, FindUserInputState> {
  state = {
    isFocused: false,
  };

  toggleFocus = () => {
    this.setState(prevState => ({ isFocused: !prevState.isFocused }));
  };

  render() {
    const {
      query,
      viewer,
      loading,
      onPersonAdd,
      placeholder = '',
      inputContainerStyle = {},
      isOptionDisabled,
      disabled,
    } = this.props;
    const { isFocused } = this.state;
    const users = viewer?.searchUsers || [];

    if (disabled) return null;

    return (
      <View
        style={css`
          position: relative;
        `}
      >
        <Autocomplete
          onFocus={this.toggleFocus}
          onBlur={this.toggleFocus}
          inputContainerStyle={[
            TIStyles.input(isFocused, Colors.blue, Colors.lightgray),
            inputContainerStyle,
          ]}
          style={css`
            padding: 12px 16px;
            background-color: white;
          `}
          hideResults={!query}
          placeholder={placeholder}
          data={users.filter(o => o && !['inactive', 'destroyed'].includes(o.status))}
          value={query}
          onChangeText={this.props.onSearch}
          flatListProps={{
            keyExtractor: item => item.name,
            style: css`
              position: relative;
              border-right: 1px solid rgba(0, 0, 0, 0.12);
              border-left: 1px solid rgba(0, 0, 0, 0.12);
            `,
            renderItem: ({ item }) => {
              const disabledMessage = isOptionDisabled ? isOptionDisabled(item) : null;
              const containerStyle = disabled ? { backgroundColor: Colors.disabled_gray } : {};
              return (
                <ListItem
                  key={item.name}
                  bottomDivider
                  Component={TouchableOpacity}
                  onPress={() => {
                    onPersonAdd && onPersonAdd(item);
                    this.props.onSearch('');
                    Keyboard.dismiss();
                  }}
                  containerStyle={containerStyle}
                  disabled={Boolean(disabledMessage)}
                >
                  <View
                    style={css`
                      margin-right: ${isWeb ? '16px' : '0px'};
                    `}
                  >
                    <Avatar small avatarUrl={item.avatarUrl} title={getInitials(item.name)} />
                  </View>
                  <ListItem.Content>
                    <ListItem.Title>{item.name}</ListItem.Title>
                    <ListItem.Subtitle style={fontTypes.bodySecondary}>
                      {item.title !== 'n/a' ? item.title : null}
                    </ListItem.Subtitle>
                    <ListItem.Title style={fontTypes.bodySecondary}>
                      {disabledMessage || null}
                    </ListItem.Title>
                  </ListItem.Content>
                </ListItem>
              );
            },
          }}
        />

        <LoadingPositioner>
          {loading && <ActivityIndicator animating={loading} size="small" />}
        </LoadingPositioner>
      </View>
    );
  }
}

export default class Container extends React.Component<ContainerProps, ContainerState> {
  state = {
    query: '',
    searchTerm: '',
  };

  onSearch = (query: string | null) => {
    this.setState({ query }, () => this.setSearch(query));
  };

  setSearch = debounce((query: string | null) => {
    this.setState({ searchTerm: query });
  }, 250);

  render() {
    return (
      <QueryRenderer
        environment={AuthedEnv}
        variables={{ query: this.state.searchTerm, hasQuery: this.state.searchTerm !== '' }}
        query={graphql`
          query FindUserInputQuery($query: String!, $hasQuery: Boolean!) {
            viewer {
              searchUsers(query: $query) @include(if: $hasQuery) {
                id
                entityId
                name
                email
                avatarUrl
                title
                status
                products {
                  feedback {
                    isEnabled
                  }
                }
              }
              user {
                preferredName
              }
            }
          }
        `}
        render={({ error, props }) => {
          if (error) {
            return <HandleNetworkError error={error as GraphqlError} />;
          }

          if (props) {
            return (
              <StaticContainer shouldUpdate>
                <FindUserInput
                  {...this.state}
                  {...this.props}
                  {...(props as FindUserInputQueryResponse)}
                  onSearch={this.onSearch}
                  loading={false}
                />
              </StaticContainer>
            );
          }

          return (
            <StaticContainer>
              <FindUserInput
                {...this.state}
                {...this.props}
                onSearch={this.onSearch}
                loading
                viewer={null}
              />
            </StaticContainer>
          );
        }}
      />
    );
  }
}

const LoadingPositioner = styled.View`
  position: absolute;
  top: 10px;
  z-index: 20;
  right: 10px;
`;

import * as React from 'react';
import { TouchableOpacity, Animated } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';

import { Colors } from '../utils/constants';

type Props = {
  onPress: (() => void) | null;
  direction: 'LEFT' | 'RIGHT';
  style?: any;
};

export default class ChevBtn extends React.Component<Props> {
  opacity = new Animated.Value(this.props.onPress === null ? 0.25 : 1);

  componentDidUpdate(prevProps: Props) {
    if (typeof prevProps.onPress === 'function' && this.props.onPress === null) {
      Animated.spring(this.opacity, { toValue: 0.25, useNativeDriver: true }).start();
    }

    if (prevProps.onPress === null && typeof this.props.onPress === 'function') {
      Animated.spring(this.opacity, { toValue: 1, useNativeDriver: true }).start();
    }
  }

  render() {
    const { direction, onPress, style } = this.props;

    return (
      <Animated.View style={{ opacity: this.opacity }}>
        <TouchableOpacity
          activeOpacity={onPress ? 0.5 : 1}
          onPress={onPress || (() => {})}
          style={{ ...styles.chev, ...style }}
        >
          <Ionicons
            color={Colors.icongray}
            name={direction === 'LEFT' ? 'ios-arrow-back' : 'ios-arrow-forward'}
            size={24}
          />
        </TouchableOpacity>
      </Animated.View>
    );
  }
}

const styles = {
  chev: {
    minWidth: 60,
    backgroundColor: Colors.lightestgray,
  },
} as const;

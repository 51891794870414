import { useState, useEffect } from 'react';
import { View } from 'react-native';
import { css } from '@emotion/native';
import { Text } from '@rneui/themed';
import { RouteProp } from '@react-navigation/native';
import Ionicons from '@expo/vector-icons/Ionicons';
import { Buffer } from 'buffer';

import MultilineTextInput from '../../components/MultilineTextInput';
import Container from '../../components/Container';
import withHeader from '../../hocs/withHeader';
import withKeyboardAware from '../../hocs/withKeyboardAware';
import { connectDropdownAlert, Alert } from '../../hocs/withDropdownAlert';
import { Colors, isIOS } from '../../utils/constants';
import { getLength } from '../../utils/fns';
import saveViewerPrivateOneOnOneNote from '../../mutations/saveViewerPrivateOneOnOneNote';
import { RootStackParamList } from '../../components/Navigation/types';

type Props = {
  isKeyboardOpen: boolean;
  keyboardHeight: number;
  dropdownAlert: Alert;
  route: RouteProp<RootStackParamList, 'Private Notes'>;
};

function SecretNoteView(props: Props) {
  const { meetingStatus, isOneOnOnesDisabled, meetingId, viewerPrivateNote } = props.route.params;

  const [value, setValue] = useState<string>(viewerPrivateNote ?? '');

  const { isKeyboardOpen, keyboardHeight, dropdownAlert } = props;

  const isDone = meetingStatus === 'DONE';

  let input;

  useEffect(() => {
    const timer = setTimeout(() => {
      input.focus();
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container
      flex={1}
      justify="flex-start"
      style={css`
        padding: 20px;
        background-color: ${Colors.lighteryellow};
        height: 100%;
      `}
    >
      <Container
        flex={1}
        justify="flex-start"
        align="flex-start"
        style={css`
          height: 100%;
          width: 100%;
        `}
      >
        <MultilineTextInput
          getInputRef={i => {
            input = i;
          }}
          onChange={newValue => {
            setValue(newValue);
          }}
          value={value}
          placeholder="Write a private note"
          onBlur={() => {
            if (value === viewerPrivateNote) return;

            saveViewerPrivateOneOnOneNote.commit(
              {
                // TODO: Find a better way of doing this
                meetingId: Buffer.from(`OneOnOneMeeting:${meetingId}`).toString('base64'),
                privateNote: value,
              },
              ({ saveViewerPrivateOneOnOneNote }) => {
                if (getLength(saveViewerPrivateOneOnOneNote.errors)) {
                  dropdownAlert({
                    type: 'error',
                    title: 'Whoops, something is not right.',
                    body: 'There was an error saving this private note, please try again.',
                  });
                }
              },
              () => {
                dropdownAlert({
                  type: 'error',
                  title: 'Whoops, something is not right.',
                  body: 'There was an error saving this private note, please try again.',
                });
              },
            );
          }}
          blurOnSubmit={false}
          returnKeyType={null}
          editable={!isDone && !isOneOnOnesDisabled}
          inputStyle={fullSizeStart}
          containerStyle={fullSizeStart}
          inputContainerStyle={fullSizeStart}
        />
      </Container>
      {isKeyboardOpen && (
        <View
          style={{
            marginTop: 0,
            alignItems: 'center',
            width: '100%',
            flexDirection: 'column',
            marginBottom: isIOS ? keyboardHeight : 0,
          }}
        >
          <Ionicons name="md-eye-off" size={16} color={Colors.darkgray} />
          <Text style={{ marginLeft: 10, color: Colors.darkgray }}>Only you can see this</Text>
        </View>
      )}
    </Container>
  );
}

const fullSizeStart = css`
  color: black;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
`;

export default connectDropdownAlert(
  withHeader({ title: 'Private Notes', mode: 'BACK' })(withKeyboardAware(SecretNoteView)),
);

import { noop } from 'lodash';

type Props = {
  title: string;
  description: string;
  onCancel?: any;
  onConfirm: any;
};

const Confirm = ({ title, description, onCancel = noop, onConfirm }: Props) => {
  const res = window.confirm(`${title}\n${description}`);

  if (res) {
    onConfirm();
  } else {
    onCancel();
  }
};

export default Confirm;

/**
 * @generated SignedSource<<bf3691bd44c87bc061ef3b7f1a7e74f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BaseReviewGrouptypeEnumType = "manager" | "peer" | "report" | "self";
export type ReviewCycleSelectQuery$variables = {
  reviewCycleEntityId: string;
};
export type ReviewCycleSelectQueryVariables = ReviewCycleSelectQuery$variables;
export type ReviewCycleSelectQuery$data = {
  readonly viewer: {
    readonly findReviewCycle: {
      readonly entityId: string;
      readonly isProjectBased: boolean;
      readonly name: string;
      readonly scorableRevieweesWithouManagerTemplate: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly entityId: string;
            readonly user: {
              readonly name: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      readonly viewerReviewGroupsToRespond: ReadonlyArray<{
        readonly type: BaseReviewGrouptypeEnumType;
        readonly reviewRequests: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly entityId: string;
              readonly submittedAt: DateString | null;
              readonly isRejected: boolean;
              readonly reviewee: {
                readonly user: {
                  readonly name: string | null;
                } | null;
                readonly closedAt: DateString | null;
              };
              readonly reviewGroup: {
                readonly type: BaseReviewGrouptypeEnumType;
              };
            };
          } | null> | null;
        } | null;
      }>;
    } | null;
  } | null;
};
export type ReviewCycleSelectQueryResponse = ReviewCycleSelectQuery$data;
export type ReviewCycleSelectQuery = {
  variables: ReviewCycleSelectQueryVariables;
  response: ReviewCycleSelectQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reviewCycleEntityId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "reviewCycleId",
    "variableName": "reviewCycleEntityId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isProjectBased",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 250
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "submittedAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRejected",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closedAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v12/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReviewCycleSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ReviewCycle",
            "kind": "LinkedField",
            "name": "findReviewCycle",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": "scorableRevieweesWithouManagerTemplate",
                "args": (v5/*: any*/),
                "concreteType": "reviewCycleDirectReportsWithoutManagerTemplateConnection",
                "kind": "LinkedField",
                "name": "viewerDirectReportsWithoutManagerTemplate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "reviewCycleDirectReportsWithoutManagerTemplateEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Reviewee",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "viewerDirectReportsWithoutManagerTemplate(first:100)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReviewGroup",
                "kind": "LinkedField",
                "name": "viewerReviewGroupsToRespond",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": "reviewRequests",
                    "args": (v8/*: any*/),
                    "concreteType": "ViewerReviewGroupReviewRequestsConnection",
                    "kind": "LinkedField",
                    "name": "viewerReviewRequests",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ViewerReviewGroupReviewRequestsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReviewRequest",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Reviewee",
                                "kind": "LinkedField",
                                "name": "reviewee",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v11/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReviewGroup",
                                "kind": "LinkedField",
                                "name": "reviewGroup",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "viewerReviewRequests(first:250)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReviewCycleSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ReviewCycle",
            "kind": "LinkedField",
            "name": "findReviewCycle",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": "scorableRevieweesWithouManagerTemplate",
                "args": (v5/*: any*/),
                "concreteType": "reviewCycleDirectReportsWithoutManagerTemplateConnection",
                "kind": "LinkedField",
                "name": "viewerDirectReportsWithoutManagerTemplate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "reviewCycleDirectReportsWithoutManagerTemplateEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Reviewee",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v13/*: any*/),
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "viewerDirectReportsWithoutManagerTemplate(first:100)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReviewGroup",
                "kind": "LinkedField",
                "name": "viewerReviewGroupsToRespond",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": "reviewRequests",
                    "args": (v8/*: any*/),
                    "concreteType": "ViewerReviewGroupReviewRequestsConnection",
                    "kind": "LinkedField",
                    "name": "viewerReviewRequests",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ViewerReviewGroupReviewRequestsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReviewRequest",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Reviewee",
                                "kind": "LinkedField",
                                "name": "reviewee",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReviewGroup",
                                "kind": "LinkedField",
                                "name": "reviewGroup",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  (v12/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "viewerReviewRequests(first:250)"
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f4c8b147ed3085857cb3387879ee4391",
    "id": null,
    "metadata": {},
    "name": "ReviewCycleSelectQuery",
    "operationKind": "query",
    "text": "query ReviewCycleSelectQuery(\n  $reviewCycleEntityId: String!\n) {\n  viewer {\n    findReviewCycle(reviewCycleId: $reviewCycleEntityId) {\n      entityId\n      isProjectBased\n      name\n      scorableRevieweesWithouManagerTemplate: viewerDirectReportsWithoutManagerTemplate(first: 100) {\n        edges {\n          node {\n            entityId\n            user {\n              name\n              id\n            }\n            id\n          }\n        }\n      }\n      viewerReviewGroupsToRespond {\n        type\n        reviewRequests: viewerReviewRequests(first: 250) {\n          edges {\n            node {\n              entityId\n              submittedAt\n              isRejected\n              reviewee {\n                user {\n                  name\n                  id\n                }\n                closedAt\n                id\n              }\n              reviewGroup {\n                type\n                id\n              }\n              id\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c17a542eb8c4a76705d82e3972aa7cd3";

export default node;

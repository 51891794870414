import * as React from 'react';
import {
  Animated,
  Switch,
  View,
  ScrollView,
  SafeAreaView,
  Text,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native';
import { graphql } from 'react-relay';
import { css } from '@emotion/native';
import * as Svg from 'react-native-svg';
import { FormattedDate, FormattedTime } from 'react-intl-native';
import { isEmpty } from 'lodash';
import type { StackNavigationProp } from '@react-navigation/stack';
import type { RouteProp } from '@react-navigation/native';

import * as CreateCheckin from '../../mutations/createCheckin';
import * as EditCheckin from '../../mutations/editCheckin';
import * as PublishCheckin from '../../mutations/publishCheckin';
import { connectDropdownAlert, Alert } from '../../hocs/withDropdownAlert';
import { fontTypes } from '../../utils/constants';

import type { RootStackParamList } from '../../components/Navigation/types';
import Container from '../../components/Container';
import Header from '../../components/Header';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import createQueryRenderer from '../../utils/createQueryRenderer';

import { getFullWeekDates } from '../../utils/fns';

import type { updatesQueryResponse } from 'jakiro-types/updatesQuery.graphql';

const ONE_YEAR = 1000 * 60 * 60 * 24 * 365;

type Props = {
  navigation: StackNavigationProp<RootStackParamList, 'Update'>;
  route: RouteProp<RootStackParamList, 'Update'>;
  loading: boolean;
  viewer: updatesQueryResponse['viewer'];
  dropdownAlert: Alert;
};

function CreateUpdate({ navigation, route, loading, viewer, dropdownAlert }: Props) {
  const weekId = route.params.weekId;

  const { weekStartDate, weekEndDate } = getFullWeekDates(weekId);

  const periodEnd = jsDateFromDateString(weekEndDate);
  const periodStart = jsDateFromDateString(weekStartDate);
  const now = new Date();
  // @ts-expect-error
  const year = now - periodStart > ONE_YEAR ? 'numeric' : undefined;

  const questions = viewer?.managerCheckinRelationship?.questions;
  const isCheckinCreated = viewer?.managerCheckinRelationship?.checkinByWeekId !== null;
  const showCheckinSentiment =
    viewer?.company?.checkinsSentimentScoresEnabled ||
    viewer?.managerCheckinRelationship?.checkinByWeekId?.sentimentResponse;

  const [isPrivate, setIsPrivate] = React.useState(true);
  const [isDrafting, setIsDrafting] = React.useState(false);
  const [isPublishing, setIsPublishing] = React.useState(false);
  const [isCheckinPublished, setIsCheckinPublished] = React.useState(false);
  const [sentimentScore, setSentimentScore] = React.useState(null);
  const [publishedAt, setPublishedAt] = React.useState(null);
  const [questionMap, setQuestionMap] = React.useState({});
  const [isCheckinsActive, setIsCheckinsActive] = React.useState(false);
  const [isPublicCheckinsEnabled, setIsPublicCheckinsEnabled] = React.useState(false);

  const setQuestionResponse = (id: string, body: string) => {
    setQuestionMap({
      ...questionMap,
      [id]: {
        ...questionMap[id],
        body,
      },
    });
  };

  React.useEffect(() => {
    const answersList = viewer?.managerCheckinRelationship?.checkinByWeekId?.answersList;
    const sentimentRating =
      viewer?.managerCheckinRelationship?.checkinByWeekId?.sentimentResponse?.rating;
    const isPublished = viewer?.managerCheckinRelationship?.checkinByWeekId?.published || false;
    const isPublic = viewer?.managerCheckinRelationship?.checkinByWeekId?.public;
    const hasPublishedAt = viewer?.managerCheckinRelationship?.checkinByWeekId?.publishedAt;
    const updatesProductEnabled = viewer?.user?.products?.updates?.isEnabled;
    const checkinsActive =
      viewer?.managerCheckinRelationship?.isCheckinsActive && updatesProductEnabled;
    const publicCheckinsEnabled = viewer?.company?.checkinsPublicCheckinsEnabled;

    if (checkinsActive) {
      setIsCheckinsActive(true);
    }

    if (publicCheckinsEnabled) {
      setIsPublicCheckinsEnabled(true);
    }

    if (hasPublishedAt) {
      setPublishedAt(hasPublishedAt);
    }

    if (isPublic) {
      setIsPrivate(false);
    }

    if (isPublished) {
      setIsCheckinPublished(true);
    }

    if (sentimentRating != null) {
      setSentimentScore(sentimentRating);
    }

    if (answersList && answersList.length) {
      const questionsAnswered = answersList.reduce(
        (acc, { id: answerId, question: { id }, body }) => ({
          ...acc,
          [id]: { id: answerId, body },
        }),
        {},
      );

      setQuestionMap(questionsAnswered);
    }
  }, [viewer]);

  const submitUpdate = async (publish = false) => {
    if (!questions) return;

    if (publish) {
      setIsPublishing(true);
    } else {
      setIsDrafting(true);
    }

    const answers = questions.map(question => {
      if (questionMap[question.id]) {
        return {
          questionId: question.id,
          ...questionMap[question.id],
        };
      } else {
        return {
          questionId: question.id,
          body: '',
        };
      }
    });

    const data = {
      weekId: route.params.weekId,
      public: !isPrivate && isPublicCheckinsEnabled,
      sentimentScore,
      answers,
    };

    let checkinId = viewer?.managerCheckinRelationship?.checkinByWeekId?.id;

    if (!isCheckinCreated) {
      const relationshipId = viewer?.managerCheckinRelationship?.id;

      // we should never hit the below conditional
      if (!relationshipId) return;

      return await CreateCheckin.commit(
        { data, relationshipId },
        async res => {
          if (publish) {
            await publishCheckin(res.createCheckin.checkin.id);
          } else {
            setIsDrafting(false);
          }
        },
        err => {
          setIsDrafting(false);
          setIsPublishing(false);

          dropdownAlert({
            type: 'error',
            title: 'Whoops!',
            body: err[0] || 'Something is not right, please try again',
          });
        },
      );
    }

    const { weekId, ...rest } = data;

    if (!checkinId) return;

    await EditCheckin.commit(
      { data: rest, checkinId },
      () => {
        if (!publish) {
          setIsDrafting(false);
          dropdownAlert({
            type: 'success',
            title: 'Saved!',
            body: 'Your draft has been saved',
          });
        }
      },
      () => {
        setIsDrafting(false);
        dropdownAlert({
          type: 'error',
          title: 'Whoops!',
          body: 'Something went wrong, please try again',
        });
      },
    );

    if (publish) {
      await publishCheckin(checkinId);
    }
  };

  async function publishCheckin(checkinId: string) {
    if (!checkinId) return;

    await PublishCheckin.commit(
      { checkinId },
      () => {
        dropdownAlert({
          type: 'success',
          title: 'Published update!',
          body: 'Your update has been published',
        });

        setPublishedAt(new Date());
        setIsPublishing(false);
        setIsCheckinPublished(true);
      },
      () => {
        setIsPublishing(false);
        dropdownAlert({
          type: 'error',
          title: 'Whoops!',
          body: 'Something went wrong, please try again',
        });
      },
    );
  }

  const noUpdateContent = sentimentScore === null && isEmpty(questionMap);

  const getVisibilityMessage = () => {
    if (isPrivate) return 'Only your manager will see this update';
    if (isPublicCheckinsEnabled) return 'This will be shared with the entire company';
    return 'Your company has disabled public updates. When saving edits to this update, it will become private (only visible by you and your manager)';
  };

  if (loading || !questions || questions.length === 0)
    return (
      <Container flex={1}>
        <ActivityIndicator />
      </Container>
    );

  return (
    <React.Fragment>
      <Header
        mode="BACK"
        handleOptionPress={() => {
          navigation.navigate('Updates');
        }}
        title={
          <Text>
            <FormattedDate day="numeric" month="short" year={year} value={periodStart} />
            {' - '}
            <FormattedDate day="numeric" month="short" year={year} value={periodEnd} />
          </Text>
        }
      />

      <SafeAreaView
        style={css`
          flex: 1;
          background-color: white;
        `}
      >
        <ScrollView
          style={css`
            flex: 1;
          `}
          keyboardDismissMode="on-drag"
        >
          <View
            style={css`
              width: 100%;
              padding-left: 16px;
              padding-right: 16px;
              display: flex;
              flex-direction: column;
              max-width: 700px;
              margin: 0 auto;
            `}
          >
            <VerticalSpacer />

            {questions.map((question, index) => {
              return (
                <View
                  key={index}
                  style={css`
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    margin-bottom: 32px;
                  `}
                >
                  <Text style={fontTypes.title}>
                    {index + 1}. {question.body}
                  </Text>

                  <TextInput
                    placeholder={isCheckinsActive ? 'Add an answer...' : 'No response given'}
                    multiline
                    onChange={value => setQuestionResponse(question.id, value)}
                    value={questionMap[question.id] ? questionMap[question.id].body : ''}
                    inputStyle={{ width: '100%', height: 120 }}
                    style={{ paddingHorizontal: 0, marginTop: 16 }}
                    returnKeyType={null}
                    inputContainerStyle={
                      isCheckinsActive
                        ? {}
                        : {
                            borderWidth: 0,
                            borderBottomWidth: 0,
                          }
                    }
                    editable={isCheckinsActive}
                  />
                </View>
              );
            })}

            {showCheckinSentiment && (
              <View
                pointerEvents={isCheckinsActive ? 'auto' : 'none'}
                style={css`
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  margin-bottom: 32px;
                  width: 100%;
                `}
              >
                <Text style={[fontTypes.title, { marginBottom: 16 }]}>
                  How are you feeling this week?
                </Text>

                <Container
                  direction="row"
                  justify="space-between"
                  style={css`
                    width: 100%;
                  `}
                >
                  {sentimentResponses.map(({ component, label }, index) => (
                    <SentimentResponse
                      key={index}
                      component={component}
                      label={label}
                      selected={sentimentScore}
                      value={index + 1}
                      setSelected={setSentimentScore}
                    />
                  ))}
                </Container>
              </View>
            )}

            <Container
              justify="space-between"
              direction="row"
              style={css`
                margin-bottom: 32px;
              `}
            >
              <Container direction="column" align="flex-start">
                <Text style={[fontTypes.title, { marginBottom: 8 }]}>
                  {isPrivate || !isPublicCheckinsEnabled ? 'Private' : 'Public'} update
                </Text>
                <Text>{getVisibilityMessage()}</Text>
              </Container>

              {isCheckinsActive && isPublicCheckinsEnabled && (
                <Switch
                  value={isPrivate}
                  onValueChange={next => setIsPrivate(next)}
                  disabled={!isCheckinsActive}
                />
              )}
            </Container>

            {!isCheckinPublished && (
              <Button
                title="Save draft"
                onPress={() => submitUpdate(false)}
                disabled={isDrafting || noUpdateContent}
                loading={isDrafting}
                type="outline"
                style={css`
                  margin-bottom: 16px;
                  width: 100%;
                `}
              />
            )}

            {isCheckinPublished && (
              <Text
                style={css`
                  margin-bottom: 8px;
                  ${fontTypes.bodySecondary};
                `}
              >
                Your update was shared on <Timestamp value={publishedAt} />
              </Text>
            )}

            {isCheckinsActive && (
              <Button
                title={`${isCheckinPublished ? 'Edit' : 'Publish'} update`}
                onPress={() => submitUpdate(true)}
                disabled={isPublishing || noUpdateContent || !isCheckinsActive}
                loading={isPublishing}
                style={css`
                  width: 100%;
                `}
              />
            )}

            <VerticalSpacer />
          </View>
        </ScrollView>
      </SafeAreaView>
    </React.Fragment>
  );
}

const spring = {
  friction: 3,
  tension: 40,
};

function SentimentResponse({ label, component, selected, setSelected, value }) {
  const isSelected = selected === value;

  const emojiScale = new Animated.Value(1);
  const textTranslateY = new Animated.Value(0);

  React.useEffect(() => {
    if (isSelected) {
      Animated.spring(emojiScale, { ...spring, toValue: 1.5, useNativeDriver: true }).start();
      Animated.spring(textTranslateY, { ...spring, toValue: 10, useNativeDriver: true }).start();
    } else {
      Animated.spring(emojiScale, { toValue: 1, useNativeDriver: true }).start();
      Animated.spring(textTranslateY, { toValue: 0, useNativeDriver: true }).start();
    }
  }, [isSelected]);

  function setSelectedValue() {
    if (isSelected) {
      return setSelected(null);
    }

    setSelected(value);
  }

  const emojiStyle = {
    transform: [{ scale: emojiScale }],
  };

  const textStyle = {
    transform: [{ translateY: textTranslateY }],
  };

  return (
    <TouchableOpacity onPress={setSelectedValue}>
      <Container
        align="center"
        direction="column"
        style={css`
          width: 100%;
          padding: 16px 12px;
          overflow: visible;
        `}
      >
        <Animated.View style={emojiStyle}>
          <View
            style={css`
              font-size: 24px;
              margin-bottom: 8px;
            `}
          >
            {component}
          </View>
        </Animated.View>

        <Animated.View style={textStyle}>
          <Text>{label}</Text>
        </Animated.View>
      </Container>
    </TouchableOpacity>
  );
}

const StrongDisagree = () => (
  <Svg.Svg width={32} height={32} fill="none">
    <Svg.Circle cx={16} cy={16} r={16} fill="#ffc873" />
    <Svg.Path
      fill="#222D38"
      fillRule="evenodd"
      d="M10.426 26.019a1.455 1.455 0 0 0 2.017.403l.06-.04a6.303 6.303 0 0 1 6.993 0l.06.04a1.455 1.455 0 0 0 1.614-2.42l-.06-.04a9.212 9.212 0 0 0-10.22 0l-.06.04a1.455 1.455 0 0 0-.404 2.017z"
      clipRule="evenodd"
    />
    <Svg.Path
      fill="#222D38"
      d="M8.017 18.91a1.454 1.454 0 0 1 1.987-.533l.84.485a1.455 1.455 0 0 1-1.455 2.519l-.84-.485a1.454 1.454 0 0 1-.532-1.987zm12.606 1.938a1.455 1.455 0 0 1 .533-1.986l.84-.485a1.455 1.455 0 0 1 1.454 2.519l-.84.485a1.455 1.455 0 0 1-1.987-.533z"
    />
  </Svg.Svg>
);

const Disagree = () => (
  <Svg.Svg width={32} height={32} fill="none">
    <Svg.Circle cx={16} cy={16} r={16} fill="#ffc873" />
    <Svg.Path
      fill="#222D38"
      fillRule="evenodd"
      d="M10.426 23.11a1.455 1.455 0 0 0 2.017.403l.06-.04a6.303 6.303 0 0 1 6.993 0l.06.04a1.455 1.455 0 0 0 1.614-2.42l-.06-.04a9.212 9.212 0 0 0-10.22 0l-.06.04a1.455 1.455 0 0 0-.404 2.017z"
      clipRule="evenodd"
    />
    <Svg.Path
      fill="#222D38"
      d="M7.758 16.485a1.94 1.94 0 1 1 3.878 0 1.94 1.94 0 0 1-3.878 0zm12.606 0a1.94 1.94 0 1 1 3.878 0 1.94 1.94 0 0 1-3.878 0z"
    />
  </Svg.Svg>
);

const Meh = () => (
  <Svg.Svg width={32} height={32} fill="none">
    <Svg.Circle cx={16} cy={16} r={16} fill="#ffc873" />
    <Svg.Path
      fill="#222D38"
      d="M7.758 12.606a1.94 1.94 0 1 1 3.878 0v.97a1.94 1.94 0 0 1-3.878 0v-.97zm12.606 0a1.94 1.94 0 0 1 3.878 0v.97a1.94 1.94 0 0 1-3.878 0v-.97zM9.697 21.818c0-.803.651-1.454 1.455-1.454h9.697a1.455 1.455 0 0 1 0 2.909h-9.697a1.455 1.455 0 0 1-1.455-1.455z"
    />
  </Svg.Svg>
);

const Agree = () => (
  <Svg.Svg width={32} height={32} fill="none">
    <Svg.Circle cx={16} cy={16} r={16} fill="#ffc873" />
    <Svg.Path
      fill="#222D38"
      fillRule="evenodd"
      d="M10.426 18.587a1.455 1.455 0 0 1 2.017-.403l.06.04a6.303 6.303 0 0 0 6.993 0l.06-.04a1.455 1.455 0 0 1 1.614 2.42l-.06.04a9.212 9.212 0 0 1-10.22 0l-.06-.04a1.455 1.455 0 0 1-.404-2.017z"
      clipRule="evenodd"
    />
    <Svg.Path
      fill="#222D38"
      d="M7.758 11.636a1.94 1.94 0 1 1 3.878 0v.97a1.94 1.94 0 0 1-3.878 0v-.97zm12.606 0a1.94 1.94 0 1 1 3.878 0v.97a1.94 1.94 0 0 1-3.878 0v-.97z"
    />
  </Svg.Svg>
);

const StrongAgree = () => (
  <Svg.Svg width="32px" height="32px" fill="none">
    <Svg.Circle cx="16" cy="16" r="16" fill="#ffc873" />
    <Svg.Path
      fill="#222D38"
      d="M7.758 9.697a1.94 1.94 0 1 1 3.878 0v.97a1.94 1.94 0 0 1-3.878 0v-.97zM20.364 9.697a1.94 1.94 0 0 1 3.878 0v.97a1.94 1.94 0 0 1-3.878 0v-.97z"
    />
    <Svg.Path
      fill="#222D38"
      fillRule="evenodd"
      d="M11.636 14.546a.97.97 0 0 0-.97.97 5.333 5.333 0 0 0 10.667 0 .97.97 0 0 0-.97-.97h-8.727z"
      clipRule="evenodd"
    />
  </Svg.Svg>
);

const sentimentResponses = [
  {
    component: <StrongDisagree />,
    label: 'Awful',
  },
  {
    component: <Disagree />,
    label: 'Poor',
  },
  {
    component: <Meh />,
    label: 'Neutral',
  },
  {
    component: <Agree />,
    label: 'Good',
  },

  {
    component: <StrongAgree />,
    label: 'Great',
  },
];

const VerticalSpacer = () => {
  return (
    <View
      style={css`
        height: 32px;
      `}
    />
  );
};

const Timestamp = ({ value: dateString }) => {
  if (!dateString) return null;

  const date = new Date(dateString);

  let dateProps = { month: 'long', day: 'numeric' };

  if (date.getFullYear() !== new Date().getFullYear()) {
    // Type '{ year: string; month: string; day: string; }' is not assignable to type '{ month: string; day: string; }'.
    // @ts-expect-error
    dateProps = { ...dateProps, year: 'numeric' };
  }

  return (
    <Text>
      <FormattedDate value={date} {...dateProps} />
      {' @ '}
      <FormattedTime value={date} />
    </Text>
  );
};

export function jsDateFromDateString(dateString: string): Date {
  const [year, month, date] = dateString.split('-').map(Number);
  // Months in JS are 0 indexed
  return new Date(year, month - 1, date);
}

export default createQueryRenderer(connectDropdownAlert(CreateUpdate), CreateUpdate, {
  query: graphql`
    query updatesQuery($weekId: String!, $includeDraft: Boolean!) {
      viewer {
        managerCheckinRelationship {
          id
          dueDOW
          isCheckinsActive
          questions: questionsList {
            id
            body
          }
          checkinByWeekId(weekId: $weekId, includeDraft: $includeDraft) {
            id
            published
            publishedAt
            public
            weekId
            sentimentResponse {
              rating
            }
            answersList {
              id
              question {
                id
              }
              body
            }
          }
        }
        user {
          products {
            updates {
              isEnabled
            }
          }
        }
        company {
          checkinsSentimentScoresEnabled
          checkinsPublicCheckinsEnabled
        }
      }
    }
  `,
  queriesParams: ({ route }) => ({
    weekId: route.params.weekId,
    includeDraft: true,
  }),
  getLoadingProp: true,
});

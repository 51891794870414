/**
 * @generated SignedSource<<7781cc39a8d7d100d9cf31502b6b0552>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type feedbackQuery$variables = {};
export type feedbackQueryVariables = feedbackQuery$variables;
export type feedbackQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly preferredName: string | null;
    };
    readonly company: {
      readonly feedbackDeliverablePublicEnabled: boolean;
      readonly feedbackDeliverablePrivateEnabled: boolean;
      readonly feedbackDeliverableManagerOnlyEnabled: boolean;
      readonly feedbackDeliverableManagerAndPrivateEnabled: boolean;
      readonly valuesEnabled: boolean | null;
      readonly values: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      } | null> | null;
    };
  } | null;
};
export type feedbackQueryResponse = feedbackQuery$data;
export type feedbackQuery = {
  variables: feedbackQueryVariables;
  response: feedbackQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preferredName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feedbackDeliverablePublicEnabled",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feedbackDeliverablePrivateEnabled",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feedbackDeliverableManagerOnlyEnabled",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feedbackDeliverableManagerAndPrivateEnabled",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valuesEnabled",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "CompanyValue",
  "kind": "LinkedField",
  "name": "values",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "feedbackQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "feedbackQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4cbd65f5411bca03f11d6015e3e8a6d6",
    "id": null,
    "metadata": {},
    "name": "feedbackQuery",
    "operationKind": "query",
    "text": "query feedbackQuery {\n  viewer {\n    user {\n      preferredName\n      id\n    }\n    company {\n      feedbackDeliverablePublicEnabled\n      feedbackDeliverablePrivateEnabled\n      feedbackDeliverableManagerOnlyEnabled\n      feedbackDeliverableManagerAndPrivateEnabled\n      valuesEnabled\n      values {\n        id\n        name\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "763403c173a7cee17d817443133f76a7";

export default node;

/**
 * @generated SignedSource<<3e6f619d1b4a0e35b0f84bd57c6832ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReviewCycleQuestionResponse_reviewRequestQuestion$data = {
  readonly id: string;
  readonly isPrivate: boolean;
  readonly reviewResponse: {
    readonly content: any | null;
  } | null;
  readonly goal: {
    readonly " $fragmentSpreads": FragmentRefs<"GoalCard_goal">;
  } | null;
  readonly questionRevision: {
    readonly entityId: string;
    readonly body: string;
    readonly question: {
      readonly __typename: string;
      readonly entityId: string;
      readonly isOptional?: boolean;
      readonly shouldHideNumbers?: boolean;
      readonly maximumSelection?: number | null;
      readonly isRatingOptional?: boolean;
      readonly isBodyOptional?: boolean;
      readonly isMultipleSelectOptional?: boolean;
      readonly isMultipleChoiceOptional?: boolean;
    };
    readonly scale?: ReadonlyArray<string>;
    readonly choices?: ReadonlyArray<string>;
  };
  readonly " $fragmentType": "ReviewCycleQuestionResponse_reviewRequestQuestion";
};
export type ReviewCycleQuestionResponse_reviewRequestQuestion = ReviewCycleQuestionResponse_reviewRequestQuestion$data;
export type ReviewCycleQuestionResponse_reviewRequestQuestion$key = {
  readonly " $data"?: ReviewCycleQuestionResponse_reviewRequestQuestion$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewCycleQuestionResponse_reviewRequestQuestion">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "scale",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "choices",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shouldHideNumbers",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maximumSelection",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isBodyOptional",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewCycleQuestionResponse_reviewRequestQuestion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPrivate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModernReviewResponse",
      "kind": "LinkedField",
      "name": "reviewResponse",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Goal",
      "kind": "LinkedField",
      "name": "goal",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GoalCard_goal"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "questionRevision",
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "questionRevisionV2",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "RatingQuestionRevision",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "MultipleSelectQuestionRevision",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "MultipleChoiceQuestionRevision",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "RatingAndBodyQuestionRevision",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "MultipleSelectAndBodyQuestionRevision",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "MultipleChoiceAndBodyQuestionRevision",
          "abstractKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "question",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isOptional",
                  "storageKey": null
                }
              ],
              "type": "ReviewCycleQuestionInterface",
              "abstractKey": "__isReviewCycleQuestionInterface"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v3/*: any*/)
              ],
              "type": "RatingQuestion",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v4/*: any*/)
              ],
              "type": "MultipleSelectQuestion",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isRatingOptional",
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "type": "RatingAndBodyQuestion",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isMultipleSelectOptional",
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "type": "MultipleSelectAndBodyQuestion",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isMultipleChoiceOptional",
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "type": "MultipleChoiceAndBodyQuestion",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ModernReviewRequestQuestion",
  "abstractKey": null
};
})();

(node as any).hash = "e65db2b8c3549282d4e21b69021b68ed";

export default node;

/**
 * @generated SignedSource<<9da09ddf93ab3ab35b5ae81a1ef732b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BaseGoalstatusEnumType = "amber" | "green" | "red";
import { FragmentRefs } from "relay-runtime";
export type GoalHeader_goal$data = {
  readonly id: string;
  readonly entityId: string;
  readonly name: string;
  readonly private: boolean;
  readonly priority: number | null;
  readonly status: BaseGoalstatusEnumType | null;
  readonly " $fragmentSpreads": FragmentRefs<"GoalProgress_goal">;
  readonly " $fragmentType": "GoalHeader_goal";
};
export type GoalHeader_goal = GoalHeader_goal$data;
export type GoalHeader_goal$key = {
  readonly " $data"?: GoalHeader_goal$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoalHeader_goal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalHeader_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "private",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalProgress_goal"
    }
  ],
  "type": "Goal",
  "abstractKey": null
};

(node as any).hash = "dd0fb0dc86c4aa0148226b6625af45ea";

export default node;

import * as React from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import { Spring } from 'react-spring/renderprops';
import { css } from '@emotion/native';

import { Colors, fontTypes } from '../../utils/constants';
import Header from '../Header';

type Props = {
  isLoading: boolean;
  name: string | null;
  isConnected: boolean;
  children: any;
};

export default function ReviewResponseShell({
  isLoading,
  isConnected = true,
  name,
  children,
}: Props) {
  return (
    <View
      style={css`
        background-color: white;
        width: 100%;
        flex: 1;
      `}
    >
      <View
        style={css`
          width: 100%;
          position: relative;
          z-index: 10;
        `}
      >
        <Header
          title={name}
          mode="BACK"
          rightComponent={
            <Spring
              from={{
                opacity: 0,
                scale: 0.5,
              }}
              to={{
                opacity: isLoading ? 1 : 0,
                scale: isLoading ? 1 : 0.85,
              }}
            >
              {({ opacity, scale }) => (
                <View
                  style={[
                    css`
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: center;
                    `,
                    { opacity, transform: [{ scale }] },
                  ]}
                >
                  <ActivityIndicator />

                  <Text
                    style={css`
                      margin-left: 8px;
                    `}
                  >
                    Saving
                  </Text>
                </View>
              )}
            </Spring>
          }
          containerStyle={css`
            width: 100%;
          `}
        />
        <Spring
          from={{
            opacity: 0,
            translateY: -40,
          }}
          to={{
            opacity: !isConnected ? 1 : 0,
            translateY: !isConnected ? 10 : -40,
          }}
        >
          {({ opacity, translateY }) => (
            <View
              style={[
                css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  background-color: ${Colors.lightred};
                  position: absolute;
                  width: 100%;
                  bottom: -20px;
                  border-top: 1px solid red;
                  padding: 8px;
                `,
                { opacity, transform: [{ translateY }] },
              ]}
            >
              <Text style={(fontTypes.body, { color: Colors.darkred })}>
                Lost connection, please reconnect to sync.
              </Text>
            </View>
          )}
        </Spring>
      </View>

      {children}
    </View>
  );
}

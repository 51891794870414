/**
 * @generated SignedSource<<edf59470b5ef415ec849fe2ffc50397f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AcceptRejectedPeerReviewRequestInput = {
  clientMutationId?: string | null;
  reviewRequestId: string;
};
export type acceptRejectedPeerReviewRequestMutation$variables = {
  input: AcceptRejectedPeerReviewRequestInput;
};
export type acceptRejectedPeerReviewRequestMutationVariables = acceptRejectedPeerReviewRequestMutation$variables;
export type acceptRejectedPeerReviewRequestMutation$data = {
  readonly acceptRejectedPeerReviewRequest: {
    readonly reviewRequest: {
      readonly isRejected: boolean;
    } | null;
    readonly errors: ReadonlyArray<string | null> | null;
  } | null;
};
export type acceptRejectedPeerReviewRequestMutationResponse = acceptRejectedPeerReviewRequestMutation$data;
export type acceptRejectedPeerReviewRequestMutation = {
  variables: acceptRejectedPeerReviewRequestMutationVariables;
  response: acceptRejectedPeerReviewRequestMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRejected",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "acceptRejectedPeerReviewRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcceptRejectedPeerReviewRequestPayload",
        "kind": "LinkedField",
        "name": "acceptRejectedPeerReviewRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReviewRequest",
            "kind": "LinkedField",
            "name": "reviewRequest",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "acceptRejectedPeerReviewRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcceptRejectedPeerReviewRequestPayload",
        "kind": "LinkedField",
        "name": "acceptRejectedPeerReviewRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReviewRequest",
            "kind": "LinkedField",
            "name": "reviewRequest",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b382a4d6e978496a804f80d23d69d579",
    "id": null,
    "metadata": {},
    "name": "acceptRejectedPeerReviewRequestMutation",
    "operationKind": "mutation",
    "text": "mutation acceptRejectedPeerReviewRequestMutation(\n  $input: AcceptRejectedPeerReviewRequestInput!\n) {\n  acceptRejectedPeerReviewRequest(input: $input) {\n    reviewRequest {\n      isRejected\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "f81458f480d21559be0e59418b861a4a";

export default node;

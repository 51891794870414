/**
 * @generated SignedSource<<ec24c9ca212e24017eb6d8d074d0736b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GoalProfileInformationTab_information$data = {
  readonly ownersList: ReadonlyArray<{
    readonly name: string | null;
    readonly avatarUrl: string | null;
    readonly preferredName: string | null;
  }>;
  readonly createdAt: DateString;
  readonly dueDate: DateString;
  readonly isDepartmentGoal: boolean;
  readonly companyGoal: boolean;
  readonly tags: ReadonlyArray<{
    readonly entityId: string;
    readonly name: string | null;
  }>;
  readonly " $fragmentType": "GoalProfileInformationTab_information";
};
export type GoalProfileInformationTab_information = GoalProfileInformationTab_information$data;
export type GoalProfileInformationTab_information$key = {
  readonly " $data"?: GoalProfileInformationTab_information$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoalProfileInformationTab_information">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalProfileInformationTab_information",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "ownersList",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatarUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "preferredName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDepartmentGoal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyGoal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityId",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
})();

(node as any).hash = "78cf7050697188df7f3e40c17c399d4e";

export default node;

import { graphql } from 'react-relay';

import commitMutation from './commitMutation';

import type { AcceptRejectedPeerReviewRequestInput } from 'jakiro-types/acceptRejectedPeerReviewRequestMutation.graphql';

const mutation = graphql`
  mutation acceptRejectedPeerReviewRequestMutation($input: AcceptRejectedPeerReviewRequestInput!) {
    acceptRejectedPeerReviewRequest(input: $input) {
      reviewRequest {
        isRejected
      }
      errors
    }
  }
`;

export function commit(args: AcceptRejectedPeerReviewRequestInput, onCompleted: any, onError: any) {
  const variables = {
    input: {
      ...args,
    },
  };

  return commitMutation({
    mutationName: 'acceptRejectedPeerReviewRequest',
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

import * as React from 'react';
import { View } from 'react-native';

type Align = 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
type Justify = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
type Direction = 'row' | 'column';

type Props = {
  children: React.ReactNode;
  justify?: Justify;
  align?: Align;
  direction?: Direction;
  flex?: number;
  style?: any;
  testID?: string;
};

const Container = ({
  children,
  style,
  flex,
  direction = 'column',
  align = 'center',
  justify = 'center',
  testID,
}: Props) => (
  <View testID={testID} style={[styles.view(justify, align, direction, flex), style]}>
    {children}
  </View>
);

const getFlex = (flex: number | null): any => (flex ? { flex } : {});

type StyleType = {
  justifyContent: Justify;
  alignItems: Align;
  flexDirection: Direction;
  flex: number | null;
};

const styles = {
  view: (justify: Justify, align: Align, direction: Direction, flex: number | null) =>
    ({
      ...getFlex(flex),
      justifyContent: justify,
      alignItems: align,
      flexDirection: direction,
    } as StyleType),
} as const;

export default Container;

/**
 * @generated SignedSource<<bf601f0bfe4c28ea2a7f98c63f08b953>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateNextRecurringOneOnOneMeetingInput = {
  checkinRelationshipEntityId: string;
  clientMutationId?: string | null;
};
export type createNextRecurringOneOnOneMeetingMutation$variables = {
  input: CreateNextRecurringOneOnOneMeetingInput;
};
export type createNextRecurringOneOnOneMeetingMutationVariables = createNextRecurringOneOnOneMeetingMutation$variables;
export type createNextRecurringOneOnOneMeetingMutation$data = {
  readonly createNextRecurringOneOnOneMeeting: {
    readonly meeting: {
      readonly entityId: string;
    } | null;
    readonly errors: ReadonlyArray<string | null> | null;
  } | null;
};
export type createNextRecurringOneOnOneMeetingMutationResponse = createNextRecurringOneOnOneMeetingMutation$data;
export type createNextRecurringOneOnOneMeetingMutation = {
  variables: createNextRecurringOneOnOneMeetingMutationVariables;
  response: createNextRecurringOneOnOneMeetingMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createNextRecurringOneOnOneMeetingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNextRecurringOneOnOneMeetingPayload",
        "kind": "LinkedField",
        "name": "createNextRecurringOneOnOneMeeting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OneOnOneMeeting",
            "kind": "LinkedField",
            "name": "meeting",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createNextRecurringOneOnOneMeetingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNextRecurringOneOnOneMeetingPayload",
        "kind": "LinkedField",
        "name": "createNextRecurringOneOnOneMeeting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OneOnOneMeeting",
            "kind": "LinkedField",
            "name": "meeting",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d1b39bdbcc7bf4350f85c35cc0c55c7f",
    "id": null,
    "metadata": {},
    "name": "createNextRecurringOneOnOneMeetingMutation",
    "operationKind": "mutation",
    "text": "mutation createNextRecurringOneOnOneMeetingMutation(\n  $input: CreateNextRecurringOneOnOneMeetingInput!\n) {\n  createNextRecurringOneOnOneMeeting(input: $input) {\n    meeting {\n      entityId\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa3c4f5f9838a0305600df87da34c565";

export default node;

import { commitMutation, graphql } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';
import { listRecordRemoveUpdater } from '../utils/mutationHelpers';
import type { destroyTalkingPointMutationVariables } from 'jakiro-types/destroyTalkingPointMutation.graphql';

const mutation = graphql`
  mutation destroyTalkingPointMutation($input: DestroyTalkingPointInput!) {
    destroyTalkingPoint(input: $input) {
      destroyedTalkingPointId
      errors
    }
  }
`;

type Fn = (val: any) => void;

function commit(
  vars: destroyTalkingPointMutationVariables['input'],
  onCompleted: Fn,
  onError: Fn,
  parentId: string,
) {
  const variables = {
    input: {
      ...vars,
    },
  };

  const sharedUpdater = store => {
    listRecordRemoveUpdater({
      parentId,
      parentFieldName: 'talkingPoints',
      itemId: vars.talkingPointId,
      store,
    });
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    updater: sharedUpdater,
    optimisticUpdater: sharedUpdater,
    onCompleted,
    onError,
  });
}

export default { commit };

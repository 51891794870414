import { useEffect, useRef, useState } from 'react';
import { SafeAreaView, StyleProp, Text, TouchableOpacity, View, ViewStyle } from 'react-native';

import { Colors, fontTypes } from '../../utils/constants';

interface OptionSetType {
  options: OptionSetOptionType[];
}

export interface OptionSetOptionType {
  body: string;
  entityId: string;
  id: string;
}

interface Props {
  onMultipleChoiceSelect: (option: OptionSetOptionType) => void;
  optionSet: OptionSetType;
  selectedOption: OptionSetOptionType | null;
}

const RadioButton = ({ checked, style }: { checked: boolean; style?: StyleProp<ViewStyle> }) => {
  return (
    <View
      style={[
        {
          alignItems: 'center',
          backgroundColor: checked ? Colors.blue : 'transparent',
          borderColor: checked ? Colors.blue : Colors.gray,
          borderRadius: 8,
          borderWidth: 1,
          height: 12,
          justifyContent: 'center',
          width: 12,
        },
        style,
      ]}
    >
      {checked ? (
        <View
          style={{
            backgroundColor: Colors.lighterblue,
            borderRadius: 6,
            height: 4,
            width: 4,
          }}
        />
      ) : null}
    </View>
  );
};

// FIXME: Setting option height with useEffect + useRef does not work properly
// Option currently expands to accommodate longer option text but does not apply to the other options
export function MultipleChoiceSelect({ onMultipleChoiceSelect, optionSet, selectedOption }: Props) {
  const [height, setHeight] = useState(undefined);
  const optionRef = useRef(null);

  useEffect(() => {
    if (optionRef.current) {
      // If an option's height exceeds 51px, that indicates the text has wrapped to a second line
      // "If text extends to 2 lines on any option, all options should grow to match height"
      if (optionRef.current.clientHeight > 51) {
        setHeight(60);
      }
      return;
    }
  }, [optionRef]);

  return (
    <SafeAreaView style={{ width: '100%' }}>
      {optionSet.options.map(option => {
        const isSelected = option.entityId === selectedOption?.entityId;

        return (
          <View
            key={option.id}
            style={{
              marginBottom: 8,
            }}
          >
            <TouchableOpacity
              onPress={() => onMultipleChoiceSelect(option)}
              ref={optionRef}
              style={{
                alignItems: 'center',
                backgroundColor: isSelected ? Colors.lighterblue : Colors.lightestgray,
                borderColor: isSelected ? Colors.blue : 'transparent',
                borderRadius: 4,
                borderWidth: 1,
                flexDirection: 'row',
                height, // FIXME
                minWidth: 343,
                paddingHorizontal: 8,
                paddingVertical: 12,
                width: '100%',
              }}
            >
              <RadioButton checked={isSelected} style={{ marginRight: 8 }} />
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  height: '100%',
                  width: '90%',
                }}
              >
                <Text
                  style={{
                    fontFamily: isSelected
                      ? fontTypes.bodyTitle.fontFamily
                      : fontTypes.bodyTitleRegular.fontFamily,
                    color: isSelected
                      ? fontTypes.bodyTitle.color
                      : fontTypes.bodyTitleRegular.color,
                    lineHeight: 20,
                    fontSize: 17,
                  }}
                >
                  {option.body}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        );
      })}
    </SafeAreaView>
  );
}

import { commitMutation, graphql } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';
import type { createNativeDeviceTokenMutationVariables } from 'jakiro-types/createNativeDeviceTokenMutation.graphql';

const mutation = graphql`
  mutation createNativeDeviceTokenMutation($input: CreateNativeDeviceTokenInput!) {
    createNativeDeviceToken(input: $input) {
      nativeDeviceToken {
        deviceToken
        deviceOs
        deviceUniqueId
      }
    }
  }
`;

function commit(
  vars: createNativeDeviceTokenMutationVariables['input'],
  onCompleted: any,
  onError: any,
) {
  const variables = {
    input: {
      ...vars,
    },
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

export default { commit };

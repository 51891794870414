import * as React from 'react';
import { Text } from '@rneui/themed';
import { camelCase } from 'lodash';

import Container from './Container';
import { Colors, fontTypes } from '../utils/constants';

type Props = {
  label: string;
  value?: string;
  children?: React.ReactNode;
  containerStyle?: any;
};

const LabeledValue = ({ label, value, children, containerStyle = {} }: Props) => {
  return (
    <Container
      testID={`LabeledValue_${camelCase(label)}`}
      style={containerStyle}
      align="flex-start"
    >
      <Text style={{ ...fontTypes.body, color: Colors.darkergray, paddingBottom: 10 }}>
        {label}
      </Text>
      {value && (
        <Text testID={`LabeledValue_${camelCase(label)}-value`} style={fontTypes.bodyTitleRegular}>
          {value}
        </Text>
      )}
      {children}
    </Container>
  );
};

export default LabeledValue;

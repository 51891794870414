/**
 * @generated SignedSource<<64e7c813c1ec14cc579047211cd28b9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GoalProfileTimelineTab_activities$data = {
  readonly activities: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdAt: DateString;
        readonly " $fragmentSpreads": FragmentRefs<"Activity_activity">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "GoalProfileTimelineTab_activities";
};
export type GoalProfileTimelineTab_activities = GoalProfileTimelineTab_activities$data;
export type GoalProfileTimelineTab_activities$key = {
  readonly " $data"?: GoalProfileTimelineTab_activities$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoalProfileTimelineTab_activities">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalProfileTimelineTab_activities",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "includeChildren",
          "value": false
        }
      ],
      "concreteType": "goalActivitiesConnection",
      "kind": "LinkedField",
      "name": "activities",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "goalActivitiesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Activity",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Activity_activity"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "activities(includeChildren:false)"
    }
  ],
  "type": "Goal",
  "abstractKey": null
};

(node as any).hash = "a0550634a19cec571bbd95dda849944f";

export default node;

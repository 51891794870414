import { css } from '@emotion/native';
import { Text } from 'react-native';
import { createFragmentContainer, graphql } from 'react-relay';

import TimelineItem from '../../../../components/TimelineItem';
import getGoalStatusColor from '../../../../utils/getGoalStatusColor';

import type { GoalCreated_goal } from 'jakiro-types/GoalCreated_goal.graphql';

type Props = {
  goal: GoalCreated_goal;
};

const GoalCreated = ({ goal }: Props) => {
  if (!goal.creator) return null;
  return (
    <TimelineItem date={new Date(goal.createdAt)} user={goal.creator} color={getGoalStatusColor()}>
      <Text
        style={css`
          padding-bottom: 10px;
          padding-top: 10px;
        `}
      >
        Goal set! 🙌
      </Text>
    </TimelineItem>
  );
};

const FragmentContainer = createFragmentContainer(GoalCreated, {
  goal: graphql`
    fragment GoalCreated_goal on Goal {
      entityId
      createdAt
      creator {
        name
        avatarUrl
        ...TimelineItem_user
      }
    }
  `,
});

export default FragmentContainer;

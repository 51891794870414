/**
 * @generated SignedSource<<6b99ffab3097282cd23095744bc0604b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type updatesQuery$variables = {
  weekId: string;
  includeDraft: boolean;
};
export type updatesQueryVariables = updatesQuery$variables;
export type updatesQuery$data = {
  readonly viewer: {
    readonly managerCheckinRelationship: {
      readonly id: string;
      readonly dueDOW: number;
      readonly isCheckinsActive: boolean;
      readonly questions: ReadonlyArray<{
        readonly id: string;
        readonly body: string;
      } | null>;
      readonly checkinByWeekId: {
        readonly id: string;
        readonly published: boolean;
        readonly publishedAt: DateString | null;
        readonly public: boolean;
        readonly weekId: string;
        readonly sentimentResponse: {
          readonly rating: number;
        } | null;
        readonly answersList: ReadonlyArray<{
          readonly id: string;
          readonly question: {
            readonly id: string;
          } | null;
          readonly body: string | null;
        } | null> | null;
      } | null;
    } | null;
    readonly user: {
      readonly products: {
        readonly updates: {
          readonly isEnabled: boolean;
        };
      };
    };
    readonly company: {
      readonly checkinsSentimentScoresEnabled: boolean | null;
      readonly checkinsPublicCheckinsEnabled: boolean | null;
    };
  } | null;
};
export type updatesQueryResponse = updatesQuery$data;
export type updatesQuery = {
  variables: updatesQueryVariables;
  response: updatesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeDraft"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "weekId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDOW",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCheckinsActive",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v6 = {
  "alias": "questions",
  "args": null,
  "concreteType": "CheckinQuestion",
  "kind": "LinkedField",
  "name": "questionsList",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "includeDraft",
    "variableName": "includeDraft"
  },
  {
    "kind": "Variable",
    "name": "weekId",
    "variableName": "weekId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "public",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weekId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rating",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "CheckinAnswer",
  "kind": "LinkedField",
  "name": "answersList",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckinQuestion",
      "kind": "LinkedField",
      "name": "question",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProducts",
  "kind": "LinkedField",
  "name": "products",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProductSettings",
      "kind": "LinkedField",
      "name": "updates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "checkinsSentimentScoresEnabled",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "checkinsPublicCheckinsEnabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updatesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckinRelationship",
            "kind": "LinkedField",
            "name": "managerCheckinRelationship",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "Checkin",
                "kind": "LinkedField",
                "name": "checkinByWeekId",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "sentimentResponse",
                    "kind": "LinkedField",
                    "name": "sentimentResponse",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updatesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckinRelationship",
            "kind": "LinkedField",
            "name": "managerCheckinRelationship",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "Checkin",
                "kind": "LinkedField",
                "name": "checkinByWeekId",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "sentimentResponse",
                    "kind": "LinkedField",
                    "name": "sentimentResponse",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fa8dea04a2c548b3763a4e4cbc9bc8ba",
    "id": null,
    "metadata": {},
    "name": "updatesQuery",
    "operationKind": "query",
    "text": "query updatesQuery(\n  $weekId: String!\n  $includeDraft: Boolean!\n) {\n  viewer {\n    managerCheckinRelationship {\n      id\n      dueDOW\n      isCheckinsActive\n      questions: questionsList {\n        id\n        body\n      }\n      checkinByWeekId(weekId: $weekId, includeDraft: $includeDraft) {\n        id\n        published\n        publishedAt\n        public\n        weekId\n        sentimentResponse {\n          rating\n          id\n        }\n        answersList {\n          id\n          question {\n            id\n          }\n          body\n        }\n      }\n    }\n    user {\n      products {\n        updates {\n          isEnabled\n        }\n      }\n      id\n    }\n    company {\n      checkinsSentimentScoresEnabled\n      checkinsPublicCheckinsEnabled\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b401ea1778d09d441bb6ea32d0a75f70";

export default node;

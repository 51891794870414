type Source = 'goal' | 'metric';

type ObjectiveProgress = {
  type: 'qualitative' | 'quantitative';
  amountType: 'digit' | 'dollar' | 'percent';
  goalAmount: number | null;
  progressAmount: number | null;
  startingAmount: number | null;
  computedProgressAmount: number | null;
};

type ObjectiveProgressPercentage = {
  startLabel: string;
  progressLabel: string;
  goalLabel: string;
  progressPercentage: number;
  overAchieved: boolean;
  formerGoalRelativePercentage: number;
};

const parseObjectiveProgress = (
  source: Source,
  objective: ObjectiveProgress,
): ObjectiveProgressPercentage | null => {
  const isNumeric = objective.type === 'quantitative';

  if (source === 'goal' && !isNumeric) {
    return null;
  }

  let startLabel = '',
    progressLabel = '',
    goalLabel = '';

  let progressPercentage = 0;
  let formerGoalRelativePercentage = 0;

  const amountType = source === 'goal' ? objective.amountType : objective.type;

  progressPercentage = forNumberGoal(
    Number(objective.startingAmount),
    Number(objective.progressAmount),
    Number(objective.goalAmount),
  );

  if (progressPercentage > 1) {
    formerGoalRelativePercentage = forNumberGoal(
      Number(objective.startingAmount),
      Number(objective.goalAmount),
      Number(objective.progressAmount),
    );
  }

  if (amountType === 'percent') {
    startLabel = objective.startingAmount
      ? `Start: ${Number(objective.startingAmount).toFixed(2)}%`
      : 'Start: 0%';
    goalLabel = objective.goalAmount
      ? `Goal: ${Number(objective.goalAmount).toFixed(2)}%`
      : 'Goal: 100%';
    progressLabel = `Current: ${(objective.computedProgressAmount || 0) * 100}%`;
  }

  if (amountType === 'digit') {
    startLabel = objective.startingAmount ? `Start: ${objective.startingAmount}` : 'Start: 0';
    goalLabel = objective.goalAmount ? `Goal: ${objective.goalAmount}` : 'Goal: 100';
    progressLabel = `Current: ${objective.progressAmount || 0} (${
      (objective.computedProgressAmount || 0) * 100
    }%)`;
  }

  if (amountType === 'dollar') {
    startLabel = objective.startingAmount ? `Start: $${objective.startingAmount}` : 'Start: $0';
    goalLabel = objective.goalAmount ? `Goal: $${objective.goalAmount}` : 'Goal: $100';
    progressLabel = `Current: $${objective.progressAmount || 0} (${
      (objective.computedProgressAmount || 0) * 100
    }%)`;
  }

  return {
    startLabel,
    progressLabel,
    goalLabel,
    progressPercentage,
    overAchieved: progressPercentage > 1,
    formerGoalRelativePercentage,
  };
};

const forNumberGoal = (
  startingAmount: number,
  progressAmount: number,
  goalAmount: number,
): number => progressAmount / (goalAmount - startingAmount);

export default parseObjectiveProgress;
export type { ObjectiveProgressPercentage };

import { createFragmentContainer, graphql } from 'react-relay';

import ObjectiveProgress from './ObjectiveProgress';

// import type { GoalProgress_goal } from 'jakiro-types/GoalProgress_goal.graphql';

type Props = {
  goal: any;
  progressBackgroundStyle?: {
    backgroundColor: string;
  };
  progressFillStyle?: {
    backgroundColor: string;
  };
  showProgressLabel?: boolean;
  startAndGoalStyle?: any;
};

const GoalProgress = ({
  goal,
  showProgressLabel = false,
  progressBackgroundStyle,
  progressFillStyle,
  startAndGoalStyle,
}: Props) => {
  return (
    goal && (
      <ObjectiveProgress
        source="goal"
        objective={goal}
        progressBackgroundStyle={progressBackgroundStyle}
        progressFillStyle={progressFillStyle}
        showProgressLabel={showProgressLabel}
        startAndGoalStyle={startAndGoalStyle}
      />
    )
  );
};

const FragmentContainer = createFragmentContainer(GoalProgress, {
  goal: graphql`
    fragment GoalProgress_goal on Goal {
      id
      amountType: computedAmountType
      goalAmount: computedGoalAmount
      progressAmount
      startingAmount: computedStartingAmount
      type: computedType
      computedProgressAmount
    }
  `,
});

export default FragmentContainer;

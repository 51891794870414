import { Text, View } from 'react-native';
import { css } from '@emotion/native';

import { createFragmentContainer, graphql } from 'react-relay';
import type { CompetencyCard_competency } from 'jakiro-types/CompetencyCard_competency.graphql';

import { Colors, fontTypes } from '../../utils/constants';

type Props = {
  competency: CompetencyCard_competency;
};

const CompetencyCard = ({ competency }: Props) => {
  const competencyDescription = competency.description;

  return (
    <View
      style={css`
        background-color: ${Colors.lightestgray};
        margin-bottom: 16px;
        padding: 16px 32px;
      `}
    >
      <View
        style={css`
          width: 100%;
        `}
      >
        <Text
          style={[
            fontTypes.title,
            css`
              text-align: center;
            `,
          ]}
        >
          {competency.name}
        </Text>
      </View>
      {competencyDescription && (
        <View
          style={css`
            width: 100%;
            margin-top: 16px;
          `}
        >
          <Text style={[fontTypes.bodySecondary]}>{competencyDescription}</Text>
        </View>
      )}
    </View>
  );
};

export default createFragmentContainer(CompetencyCard, {
  competency: graphql`
    fragment CompetencyCard_competency on Competency {
      name
      description
    }
  `,
});

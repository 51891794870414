import { StyleSheet, Text } from 'react-native';

import Container from './Container';

import { Colors, fontTypes } from '../utils/constants';

type Props = {
  title: string;
  body: string;
};

const EmptyState = ({ title, body }: Props) => {
  return (
    <Container flex={1} justify="flex-start" align="center" style={styles.container}>
      <Text style={styles.emptyStateHeader}>{title}</Text>
      <Text style={styles.emptyStateBody}>{body}</Text>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.lightestgray,
    paddingTop: 100,
  },
  emptyStateHeader: {
    ...fontTypes.title,
    marginBottom: 8,
  },
  emptyStateBody: {
    ...fontTypes.subHeaderBodyTitleSecondary,
  },
});

export default EmptyState;

/**
 * @generated SignedSource<<7971ad8b1206d66e68392d2a74cc05b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ComputedUserstatusEnumType = "active" | "created" | "destroyed" | "inactive" | "invited";
export type FindUserInputQuery$variables = {
  query: string;
  hasQuery: boolean;
};
export type FindUserInputQueryVariables = FindUserInputQuery$variables;
export type FindUserInputQuery$data = {
  readonly viewer: {
    readonly searchUsers?: ReadonlyArray<{
      readonly id: string;
      readonly entityId: string;
      readonly name: string | null;
      readonly email: string | null;
      readonly avatarUrl: string | null;
      readonly title: string | null;
      readonly status: ComputedUserstatusEnumType;
      readonly products: {
        readonly feedback: {
          readonly isEnabled: boolean;
        };
      };
    } | null> | null;
    readonly user: {
      readonly preferredName: string | null;
    };
  } | null;
};
export type FindUserInputQueryResponse = FindUserInputQuery$data;
export type FindUserInputQuery = {
  variables: FindUserInputQueryVariables;
  response: FindUserInputQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasQuery"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "condition": "hasQuery",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        }
      ],
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "searchUsers",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatarUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProducts",
          "kind": "LinkedField",
          "name": "products",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserProductSettings",
              "kind": "LinkedField",
              "name": "feedback",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isEnabled",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preferredName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FindUserInputQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FindUserInputQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e6c81b9387b871bf6e373ca8f83dc59",
    "id": null,
    "metadata": {},
    "name": "FindUserInputQuery",
    "operationKind": "query",
    "text": "query FindUserInputQuery(\n  $query: String!\n  $hasQuery: Boolean!\n) {\n  viewer {\n    searchUsers(query: $query) @include(if: $hasQuery) {\n      id\n      entityId\n      name\n      email\n      avatarUrl\n      title\n      status\n      products {\n        feedback {\n          isEnabled\n        }\n      }\n    }\n    user {\n      preferredName\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a21d51f1a4c14494d100f9150d2ce7cf";

export default node;

import * as React from 'react';
import { graphql } from 'react-relay';
import { css } from '@emotion/native';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { debounce, isEmpty } from 'lodash';
import type { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import moment from 'moment';
import BottomSheet from 'reanimated-bottom-sheet';
import { MarkdownView } from 'react-native-markdown-view';
import Ionicons from '@expo/vector-icons/Ionicons';
import Feather from '@expo/vector-icons/Feather';

import { fontTypes, isWeb, Colors } from '../../utils/constants';
import createQueryRenderer from '../../utils/createQueryRenderer';
import { formatDate } from '../../utils/fns';

import useHasNetwork from '../../hooks/useHasNetwork';

import { connectDropdownAlert, Alert } from '../../hocs/withDropdownAlert';

import * as AddReviewResponse from '../../mutations/addReviewResponse';
import * as SubmitReviewRequest from '../../mutations/submitReviewRequest';
import * as RejectPeerReviewRequest from '../../mutations/rejectPeerReviewRequest';
import * as AcceptRejectedPeerReviewRequest from '../../mutations/acceptRejectedPeerReviewRequest';

import type { RootStackParamList } from '../../components/Navigation/types';
import Button from '../../components/Button';
import CompetencyCard from '../../components/CompetencyCard';
import GoalCard from '../../components/GoalCard';
import Markdown from '../../components/Markdown';
import CalloutBanner from '../../components/CalloutBanner';
import ReviewCycleQuestionResponse from '../../components/reviews/ReviewCycleQuestionResponse';
import ReviewCycleIntro from '../../components/reviews/ReviewCycleIntro';
import ReviewResponseShell from '../../components/reviews/ReviewResponseShell';
import Loading from '../../components/Loading';
/* eslint-disable import/no-unresolved */
// TS/Eslint don't understand that react-native is inferring these file types
// by which platform we're on .native.js .web.js .ios.js .android.js
// @ts-expect-error
import Confirm from '../../components/Confirm';
/* eslint-enable */

import {
  isInvalid,
  reviewCycleResponseReducer,
  initialReviewCycleState,
  SET_QUESTION_RESPONSES,
  SET_QUESTION_RESPONSE,
} from './reviewCycleResponseFormReducer';

import type { ReviewCycleResponseFormQueryResponse } from 'jakiro-types/ReviewCycleResponseFormQuery.graphql';

type Props = {
  dropdownAlert: Alert;
  navigation: StackNavigationProp<RootStackParamList, 'Review Response'>;
  route: RouteProp<RootStackParamList, 'Review Response'>;
} & ReviewCycleResponseFormQueryResponse;

function ReviewCycleResponseForm({ viewer, dropdownAlert, navigation, route }: Props) {
  const reviewCycle = viewer?.findReviewCycle;
  const reviewRequest = viewer?.findReviewRequest;
  if (!reviewRequest || !reviewCycle) return null;

  const name = reviewCycle.name;
  const reviewRequestId = reviewRequest.id;
  const allowPeersToRejectPeerReviewRequests = reviewCycle.allowPeersToRejectPeerReviewRequests;
  const isRejectablePeerReview =
    allowPeersToRejectPeerReviewRequests && reviewRequest.reviewGroup.type === 'peer';
  const user = reviewRequest.reviewee.user;
  const questions = reviewRequest.reviewRequestQuestions || [];
  const instructions = reviewRequest.reviewGroup.reviewTemplate?.instructions;

  if (!user) return null;

  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isAcceptingOrDeclining, setIsAcceptingOrDeclining] = React.useState(false);
  const [focusedRatingQuestionRevision, setFocusedRatingQuestionRevision] = React.useState(null);

  const bottomSheetRef = React.useRef(null);
  const [state, dispatch] = React.useReducer(reviewCycleResponseReducer, initialReviewCycleState);
  const hasNetwork = useHasNetwork();

  React.useEffect(() => {
    const questionResponses = questions.reduce((acc, mrrq) => {
      const question = mrrq?.questionRevision.question;
      const content: any = mrrq?.reviewResponse?.content || {};

      return {
        ...acc,
        [mrrq.id]: {
          isInvalid: isInvalid(content, question),
          ...content,
        },
      };
    }, {});

    dispatch({
      type: SET_QUESTION_RESPONSES,
      payload: {
        responses: {
          ...questionResponses,
        },
      },
    });
  }, [questions]);

  React.useEffect(() => {
    if (hasNetwork) {
      Object.keys(state.responses).map(async id => {
        const item = await AsyncStorage.getItem(id);
        try {
          let res = JSON.parse(item);

          if (res) {
            // This is to be backwards compatible w/ an old version of the payload.
            // Feel free to remove once enough time has passed and enough users are off the old version.
            const payload =
              res.responses != null
                ? {
                    reviewRequestQuestion: res.responses[0].reviewRequestQuestion,
                    isRejected: false,
                    content: res.responses[0].content,
                  }
                : res;

            setIsLoading(true);
            commitQuestionResponse({ reviewRequestQuestionId: id, payload });
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      });
    }
  }, [hasNetwork]);

  const debouncedCommit = React.useRef(debounce(commitQuestionResponse, 750));

  function saveResponse({ reviewRequestQuestionId, value }) {
    const payload = {
      reviewRequestQuestion: { id: reviewRequestQuestionId },
      content: value,
      // We don't currently support skipping questions on mobile
      isRejected: false,
    };

    const question = questions.find(q => q.id === reviewRequestQuestionId);

    if (!question) {
      dropdownAlert({
        type: 'error',
        title: 'Something went wrong',
        body: `Please try again.`,
      });

      return;
    }

    dispatch({
      type: SET_QUESTION_RESPONSE,
      payload: {
        response: {
          ...value,
          isInvalid: isInvalid(value, question.questionRevision.question),
        },
        reviewRequestQuestionId,
      },
    });

    if (!hasNetwork) {
      AsyncStorage.setItem(reviewRequestQuestionId, JSON.stringify(payload));
      return;
    }

    debouncedCommit.current({ payload, reviewRequestQuestionId });
  }

  function commitQuestionResponse({ payload, reviewRequestQuestionId }) {
    if (hasNetwork) {
      setIsLoading(true);

      AddReviewResponse.commit(
        payload,
        () => {
          AsyncStorage.removeItem(reviewRequestQuestionId);

          setIsLoading(false);
        },
        () => {
          dropdownAlert({
            type: 'error',
            title: 'Something went wrong',
            body: `Please try again.`,
          });

          setIsLoading(false);
        },
      );
    }
  }

  function submitReviewRequest() {
    setIsSubmitting(true);

    const payload = {
      reviewRequestId,
    };

    SubmitReviewRequest.commit(
      payload,
      () => {
        setIsSubmitting(false);
        navigation.goBack();
      },
      () => {
        dropdownAlert({
          type: 'error',
          title: 'Something went wrong',
          body: `Please try again.`,
        });

        setIsSubmitting(false);
      },
    );
  }

  const hasInvalidResponses = () => {
    return Object.keys(state.responses).some(id => {
      const item = state.responses[id];

      return item.isInvalid;
    });
  };

  function initializeDeclinePeerReview() {
    Confirm({
      title: 'Decline writing this peer review?',
      description: `Declining this Peer Review will remove it from your list of pending reviews and notify ${
        user?.name || 'the reviewee'
      }'s manager.`,
      onConfirm: submitDeclinePeerReview,
    });
  }

  function submitDeclinePeerReview() {
    setIsAcceptingOrDeclining(true);

    const args = { reviewRequestId };

    RejectPeerReviewRequest.commit(
      args,
      () => {
        setIsAcceptingOrDeclining(false);
        navigation.goBack();
      },
      () => {
        dropdownAlert({
          type: 'error',
          title: 'Something went wrong',
          body: `Please try again.`,
        });

        setIsAcceptingOrDeclining(false);
      },
    );
  }

  function acceptPeerReview() {
    setIsAcceptingOrDeclining(true);

    const args = { reviewRequestId };

    AcceptRejectedPeerReviewRequest.commit(
      args,
      () => {
        setIsAcceptingOrDeclining(false);
      },
      () => {
        dropdownAlert({
          type: 'error',
          title: 'Something went wrong',
          body: `Please try again.`,
        });

        setIsAcceptingOrDeclining(false);
      },
    );
  }

  const showNoQuestionBanner = !questions.length;

  if (showNoQuestionBanner) {
    return (
      <ReviewResponseShell
        name={user && user.name ? user.name : name}
        isLoading={isLoading}
        isConnected={hasNetwork}
      >
        <CalloutBanner type="error">There are no questions to answer</CalloutBanner>
      </ReviewResponseShell>
    );
  }

  if (isEmpty(state.responses)) {
    return <Loading />;
  }

  const submitDisabled =
    isSubmitting ||
    reviewRequest.isSubmitted ||
    reviewRequest.isRejected ||
    reviewRequest.isClosed ||
    reviewRequest.lockedForSubmission ||
    hasInvalidResponses() ||
    !hasNetwork;

  return (
    <ReviewResponseShell
      name={user && user.name ? user.name : name}
      isLoading={isLoading}
      isConnected={hasNetwork}
    >
      {focusedRatingQuestionRevision && !isWeb && (
        <View
          style={[
            {
              ...StyleSheet.absoluteFillObject,
              backgroundColor: '#000',
              zIndex: 15,
              opacity: 0.1,
            },
          ]}
        />
      )}

      <BottomSheet
        ref={bottomSheetRef}
        snapPoints={[0, 300, 600]}
        onCloseEnd={() => {
          setFocusedRatingQuestionRevision(null);
        }}
        renderContent={() => {
          const shouldHideNumbers = focusedRatingQuestionRevision?.question?.shouldHideNumbers;
          const scale = focusedRatingQuestionRevision?.scale || [];

          if (focusedRatingQuestionRevision === null) return null;

          return (
            <View
              style={css`
                min-height: 570px;
                background-color: white;
                padding-top: 16px;
                padding-left: 24px;
                padding-right: 24px;
              `}
            >
              <Text style={[fontTypes.title, { marginBottom: 24, fontSize: 22, lineHeight: 28 }]}>
                Definitions:
              </Text>

              {Array.isArray(scale) &&
                scale.length > 0 &&
                scale.map((k, i) => {
                  return (
                    <View
                      style={css`
                        display: flex;
                        flex-direction: ${shouldHideNumbers ? 'column' : 'row'};
                        padding-bottom: 24px;
                      `}
                      key={i}
                    >
                      <Text
                        style={[
                          fontTypes.bodyTitle,
                          css`
                            padding-right: 16px;
                            font-weight: 600;
                          `,
                        ]}
                      >
                        {shouldHideNumbers ? k : `${i + 1}:`}
                      </Text>
                      {!shouldHideNumbers && <Text style={fontTypes.body}>{k}</Text>}
                    </View>
                  );
                })}
            </View>
          );
        }}
        renderHeader={() => (
          <View
            style={css`
              background-color: white;
              padding-top: 10px;
              border-top-left-radius: 20px;
              border-top-right-radius: 20px;
            `}
          >
            <View
              style={css`
                align-items: center;
              `}
            >
              <View
                style={css`
                  width: 40px;
                  height: 8px;
                  border-radius: 16px;
                  background-color: #00000040;
                  margin-bottom: 10px;
                `}
              />
            </View>
          </View>
        )}
      />

      <KeyboardAwareScrollView
        enableAutomaticScroll
        enableResetScrollToCoords={false}
        style={css`
          padding: 16px 0;
          background-color: '#2c2c2f';
        `}
      >
        <View
          style={css`
            max-width: 700px;
            width: 100%;
            margin: 0 auto;
          `}
        >
          <ReviewCycleIntro reviewRequest={reviewRequest} />

          {!reviewRequest.isRejected && (
            <React.Fragment>
              {Boolean(instructions) && (
                <MarkdownView style={[fontTypes.body, { marginHorizontal: 16, marginBottom: 16 }]}>
                  {instructions.trim().replace(/<br>/g, '\n')}
                </MarkdownView>
              )}

              {questions.length > 0 &&
                questions.map((q, index) => {
                  const description = q.description;
                  const maximumSelection = q?.questionRevision?.question?.maximumSelection;

                  const hasCustomMaximumSelection = maximumSelection != null;
                  const isRating =
                    q.questionRevision.question.__typename === 'RatingQuestion' ||
                    q.questionRevision.question.__typename === 'RatingAndBodyQuestion';

                  return (
                    <View
                      key={q.id}
                      style={css`
                        margin-left: 16px;
                        margin-right: 16px;
                        margin-bottom: 32px;
                        padding: 4px;
                        border-radius: 4px;
                      `}
                    >
                      <View
                        style={css`
                          display: flex;
                          flex-direction: row;
                          align-items: flex-start;
                          justify-content: flex-start;
                          margin-right: 8px;
                          margin-bottom: ${description || q.competency || q.goal || isRating
                            ? '8px'
                            : '16px'};
                        `}
                      >
                        <View
                          style={css`
                            display: flex;
                            align-items: center;
                            flex-direction: row;
                          `}
                        >
                          <Text style={fontTypes.bodyTitle}>{index + 1}. </Text>

                          {q.isPrivate && (
                            <Ionicons
                              size={15}
                              color={Colors.darkergray}
                              name="lock-closed"
                              containerStyle={css`
                                margin-right: 8px;
                                margin-left: 4px;
                              `}
                            />
                          )}
                        </View>

                        <Text style={[fontTypes.bodyTitle, { flexWrap: 'wrap', flex: 1 }]}>
                          {q.questionRevision.body}{' '}
                          {q.questionRevision.question.isOptional === false && (
                            <Text style={{ color: 'red' }}>*</Text>
                          )}
                        </Text>
                      </View>

                      {Boolean(description) && (
                        <View
                          style={[
                            // TODO investigate whether or not this style cascades
                            // @ts-expect-error
                            fontTypes.bodySecondary,
                            { marginBottom: hasCustomMaximumSelection || isRating ? 8 : 16 },
                          ]}
                        >
                          <Markdown>{description}</Markdown>
                        </View>
                      )}

                      {isRating && (
                        <View
                          style={css`
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            padding-bottom: ${q.competency || q.goal ? '8px' : '16px'};
                          `}
                        >
                          <Text style={fontTypes.bodySecondary}>Choose one below</Text>

                          {!q.questionRevision.question?.shouldHideNumbers && (
                            <TouchableOpacity
                              onPress={() => {
                                setFocusedRatingQuestionRevision(q.questionRevision);

                                if (bottomSheetRef.current) {
                                  bottomSheetRef.current.snapTo(1);
                                }
                              }}
                            >
                              <Feather
                                color={Colors.icongray}
                                name="info"
                                containerStyle={css`
                                  margin-left: 8px;
                                `}
                              />
                            </TouchableOpacity>
                          )}
                        </View>
                      )}

                      {q.competency != null && <CompetencyCard competency={q.competency} />}

                      {q.goal != null && (
                        <GoalCard
                          goal={q.goal}
                          cardStyle={css`
                            width: 100%;
                            margin: 0;
                            margin-bottom: 16px;
                          `}
                          onPress={(goalEntityId: string) => {
                            const goalIds = route.params?.goalIds || [];

                            navigation.navigate('Goal', {
                              goalIds: [...goalIds, goalEntityId],
                            });
                          }}
                        />
                      )}

                      {Boolean(hasCustomMaximumSelection) && (
                        <Text
                          style={[
                            fontTypes.bodySecondary,
                            { color: Colors.darkestgray, marginBottom: 16 },
                          ]}
                        >
                          Select up to {maximumSelection}
                        </Text>
                      )}

                      <ReviewCycleQuestionResponse
                        saveResponse={saveResponse}
                        disabled={
                          reviewRequest.isSubmitted ||
                          reviewRequest.isRejected ||
                          reviewRequest.isClosed
                        }
                        reviewRequestQuestion={q}
                        state={state}
                      />
                    </View>
                  );
                })}

              <View
                style={css`
                  padding-bottom: ${isRejectablePeerReview ? '16px' : '48px'};
                  padding-left: 16px;
                  padding-right: 16px;
                  width: 100%;
                `}
              >
                {reviewRequest.isSubmitted && (
                  <Text style={[fontTypes.bodySecondary, { marginBottom: 8 }]}>
                    Submitted {formatDate(moment(reviewRequest.submittedAt))}
                  </Text>
                )}

                {reviewRequest.isClosed && (
                  <Text style={[fontTypes.bodySecondary, { marginBottom: 8 }]}>
                    Automatically Submitted {formatDate(moment(reviewRequest.closedAt))}
                  </Text>
                )}

                {hasInvalidResponses() && (
                  <Text style={[fontTypes.error, { marginBottom: 8 }]}>
                    Please respond to all required questions
                  </Text>
                )}

                {!reviewRequest.isSubmitted && !hasNetwork && (
                  <Text style={[fontTypes.error, { marginBottom: 8 }]}>
                    Please reconnect to the internet before submitting.
                  </Text>
                )}

                <Button
                  title={
                    user.viewerIsUser
                      ? 'Submit your self evaluation'
                      : `Submit ${
                          user && user.preferredName ? `${user.preferredName}'s` : ''
                        } Review`
                  }
                  onPress={submitReviewRequest}
                  disabled={submitDisabled}
                  loading={isSubmitting}
                  buttonStyle={css`
                    height: 50px;
                    background-color: ${Colors.iosBlue};
                  `}
                  containerStyle={css`
                    width: 100%;
                  `}
                  style={css`
                    width: 100%;
                  `}
                />
              </View>
            </React.Fragment>
          )}

          {isRejectablePeerReview && (
            <View
              style={css`
                margin-bottom: 32px;
                padding-left: 16px;
                padding-right: 16px;
                width: 100%;
              `}
            >
              {reviewRequest.isRejected ? (
                <Button
                  title="Re-accept this Peer Review"
                  onPress={acceptPeerReview}
                  disabled={isAcceptingOrDeclining || !reviewRequest.isRejected}
                  loading={isAcceptingOrDeclining}
                  buttonStyle={css`
                    height: 50px;
                  `}
                  containerStyle={css`
                    width: 100%;
                    margin-top: 16px;
                    margin-bottom: 24px;
                  `}
                  style={css`
                    width: 100%;
                  `}
                />
              ) : (
                <Button
                  title="Decline writing this Peer Review"
                  onPress={initializeDeclinePeerReview}
                  disabled={isAcceptingOrDeclining || reviewRequest.isRejected}
                  loading={isAcceptingOrDeclining}
                  buttonStyle={css`
                    height: 50px;
                    background-color: white;
                  `}
                  titleStyle={css`
                    color: ${Colors.red};
                  `}
                  containerStyle={css`
                    width: 100%;
                    margin-top: 16px;
                    margin-bottom: 24px;
                  `}
                  style={css`
                    width: 100%;
                  `}
                />
              )}
            </View>
          )}
        </View>
      </KeyboardAwareScrollView>
    </ReviewResponseShell>
  );
}

export default createQueryRenderer(
  connectDropdownAlert(ReviewCycleResponseForm),
  ReviewCycleResponseForm,
  {
    query: graphql`
      query ReviewCycleResponseFormQuery(
        $reviewRequestEntityId: String!
        $reviewCycleEntityId: String!
      ) {
        viewer {
          findReviewCycle(reviewCycleId: $reviewCycleEntityId) {
            id
            name
            allowPeersToRejectPeerReviewRequests
          }
          findReviewRequest(reviewRequestId: $reviewRequestEntityId) {
            id
            lockedForSubmission
            isRejected
            isSubmitted
            isClosed
            submittedAt
            closedAt
            reviewGroup {
              type
              reviewTemplate {
                instructions
              }
            }
            reviewee {
              user {
                name
                preferredName
                avatarUrl
                title
                viewerIsUser
              }
            }
            reviewRequestQuestions {
              id
              isPrivate
              description
              reviewResponse {
                content
              }
              competency {
                ...CompetencyCard_competency
              }
              goal {
                ...GoalCard_goal
              }
              questionRevision: questionRevisionV2 {
                entityId
                body
                ... on RatingQuestionRevision {
                  scale
                }
                ... on RatingAndBodyQuestionRevision {
                  scale
                }
                question {
                  __typename
                  entityId
                  ... on ReviewCycleQuestionInterface {
                    isOptional
                  }
                  ... on RatingQuestion {
                    shouldHideNumbers
                  }
                  ... on MultipleSelectQuestion {
                    maximumSelection
                  }
                  ... on RatingAndBodyQuestion {
                    shouldHideNumbers
                  }
                  ... on MultipleSelectAndBodyQuestion {
                    maximumSelection
                  }
                }
              }
              ...ReviewCycleQuestionResponse_reviewRequestQuestion
            }
            ...ReviewCycleIntro_reviewRequest
          }
        }
      }
    `,
    queriesParams: ({ route }) => ({
      reviewRequestEntityId: route.params.reviewRequestEntityId,
      reviewCycleEntityId: route.params.reviewCycleEntityId,
    }),
  },
);

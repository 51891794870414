import { createFragmentContainer, graphql } from 'react-relay';

import GoalCreated from './GoalCreated';
import GoalUpdate from './GoalUpdate';

import type { Activity_activity } from 'jakiro-types/Activity_activity.graphql';

type Props = {
  activity: Activity_activity;
};

const Activity = ({ activity }: Props) => {
  if (!activity || !activity.source) {
    return null;
  }

  switch (activity.sourceableType) {
    case 'goalCreated':
      return <GoalCreated goal={activity.source} />;
    case 'update':
      return <GoalUpdate update={activity.source} />;
    default:
      return null;
  }
};

const FragmentContainer = createFragmentContainer(Activity, {
  activity: graphql`
    fragment Activity_activity on Activity {
      entityId
      createdAt
      sourceableType
      source {
        ... on ActivitySource {
          ... on Goal {
            ...GoalCreated_goal
          }
          ... on GoalUpdate {
            ...GoalUpdate_update
          }
        }
      }
    }
  `,
});

export default FragmentContainer;

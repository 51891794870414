import { graphql } from 'react-relay';

import commitMutation from './commitMutation';

const mutation = graphql`
  mutation rejectPeerReviewRequestMutation($input: RejectPeerReviewRequestInput!) {
    rejectPeerReviewRequest(input: $input) {
      rejectedReviewRequest {
        isRejected
      }
      errors
    }
  }
`;

type Args = {
  reviewRequestId: string;
};

export function commit(args: Args, onCompleted: any, onError: any) {
  const variables = {
    input: {
      ...args,
    },
  };

  return commitMutation({
    mutationName: 'rejectPeerReviewRequest',
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

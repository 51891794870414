import { commitMutation, graphql } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';
import type { SaveViewerPrivateOneOnOneNoteInput } from 'jakiro-types/saveViewerPrivateOneOnOneNoteMutation.graphql';

const mutation = graphql`
  mutation saveViewerPrivateOneOnOneNoteMutation($input: SaveViewerPrivateOneOnOneNoteInput!) {
    saveViewerPrivateOneOnOneNote(input: $input) {
      meeting {
        viewerPrivateNote
        id
      }
      errors
      clientMutationId
    }
  }
`;

type Fn = (val: any) => void;

function commit(vars: SaveViewerPrivateOneOnOneNoteInput, onCompleted: Fn, onError: Fn) {
  const variables = {
    input: {
      ...vars,
    },
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

export default { commit };

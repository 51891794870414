// This polyfill is needed to use IntlProvider on android devices
import 'intl';
import 'intl/locale-data/jsonp/en';

import { useEffect, useState, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { css } from '@emotion/native';
import * as Font from 'expo-font';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { View, StatusBar, LogBox } from 'react-native';
import DropdownAlert from 'react-native-dropdownalert';
import * as Sentry from 'sentry-expo';
import { parse } from 'query-string';
import { graphql, fetchQuery_DEPRECATED } from 'react-relay';
import { createClient, AnalyticsProvider } from '@segment/analytics-react-native';

import ErrorBoundary from '../components/ErrorBoundary';
/* eslint-disable import/no-unresolved */
// TS/Eslint don't understand that react-native is inferring these file types
// by which platform we're on .native.js .web.js .ios.js .android.js
// @ts-expect-error
import Navigation from '../components/Navigation';
/* eslint-enable import/no-unresolved */
import type { GetCompanyInfoQueryResponse } from '../utils/GetCompanyInfo';
import { COMPANY_SLUG, Colors, isDevelopment, isWeb } from '../utils/constants';
import { provideDropdownAlert } from '../hocs/withDropdownAlert';
import withForceUpdate from '../hocs/withForceUpdate';
import { CompanyContextProvider } from '../hooks/useCompany';
import {
  findAccessToken,
  persistAccessToken,
  AuthenticationContextProvider,
} from '../hooks/useAuthentication';
import { captureException } from '../utils/fns';
import environment from '../utils/Environment';
import { getConfig } from '../utils/getConfig';

import type { nativeQuery } from 'jakiro-types/nativeQuery.graphql';

if (!isDevelopment) {
  Sentry.init({
    dsn: getConfig('SENTRY'),
    enableInExpoDevelopment: false,
  });
}

const segmentClient = createClient({
  writeKey: getConfig('SEGMENT_KEY'),
  flushAt: 0,
  flushInterval: 0,
  trackAppLifecycleEvents: true,
});

const query = graphql`
  query nativeQuery {
    viewer {
      company {
        slug
        name
        entityId
        emailDomain
        ssoLoginUrl
        forceSSOLogin
        logoFileName
        allowSelfRegistration
        allowPhoneNumberAuth
      }
    }
  }
`;

async function GetCompanyInfo() {
  const data = await fetchQuery_DEPRECATED<nativeQuery>(environment(), query, {});

  try {
    const blob = JSON.stringify(data.viewer.company);
    await AsyncStorage.setItem(COMPANY_SLUG, blob);
  } catch (e) {
    captureException(e);
  }
}

type Props = {
  setDropdown: (ref: any) => void;
};

function App(props: Props) {
  const [company, setCompany] = useState<GetCompanyInfoQueryResponse | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState<boolean>(false);

  const companyContextValue = useMemo(
    () => ({
      company,
      setCompany,
    }),
    [company],
  );

  const authenticationContextValue = useMemo(
    () => ({
      isLoggedIn,
      setIsLoggedIn,
    }),
    [isLoggedIn],
  );

  useEffect(() => {
    const loadFontsAndCompany = async () => {
      LogBox && LogBox.ignoreLogs(['Animated: `useNativeDriver`']);

      await Font.loadAsync({
        'SFProDisplay-Bold': require('../assets/fonts/SFProDisplay-Bold.ttf'),
        'SFProDisplay-Regular': require('../assets/fonts/SFProDisplay-Regular.ttf'),

        'SFProDisplay-SemiBold': require('../assets/fonts/SFProDisplay-SemiBold.ttf'),
        'SFProDisplay-Semibold': require('../assets/fonts/SFProDisplay-SemiBold.ttf'),

        'SanFranciscoText-Regular': require('../assets/fonts/SanFranciscoText-Regular.ttf'),
        'SanFranciscoText-Bold': require('../assets/fonts/SanFranciscoText-Bold.ttf'),
      });

      setFontsLoaded(true);

      const company = await AsyncStorage.getItem(COMPANY_SLUG);

      if (company) {
        setCompany(JSON.parse(company));
      }

      if (!isWeb) {
        const accessToken = await findAccessToken();

        if (accessToken) {
          setIsLoggedIn(true);
        }

        return;
      }

      const hash = parse(window.location.hash);

      let token = hash.access_token as string;

      if (token) {
        await persistAccessToken(token);
        // Clear hash from URL if we have it
        history.replaceState(null, '', ' ');
        // Attempt at persisting company info that usually comes from our login experience
        await GetCompanyInfo();
      } else {
        const accessToken = await findAccessToken();

        if (accessToken) {
          setIsLoggedIn(true);
        }
      }
    };

    loadFontsAndCompany();
  }, []);

  if (!fontsLoaded) return null;

  return (
    <ErrorBoundary>
      <AnalyticsProvider client={segmentClient}>
        <IntlProvider locale="en">
          <View
            style={css`
              flex: 1;
            `}
          >
            <StatusBar barStyle="dark-content" />
            <CompanyContextProvider value={companyContextValue}>
              <AuthenticationContextProvider value={authenticationContextValue}>
                <Navigation isLoggedIn={isLoggedIn} />
              </AuthenticationContextProvider>
            </CompanyContextProvider>
            <DropdownAlert
              errorColor={Colors.dropDown}
              ref={ref => {
                props.setDropdown(ref);
              }}
            />
          </View>
        </IntlProvider>
      </AnalyticsProvider>
    </ErrorBoundary>
  );
}

const hasDropDown = provideDropdownAlert(App);
const hasForceUpdate = withForceUpdate(hasDropDown);

export default hasForceUpdate;

import { createContext, useContext } from 'react';
import type { GetCompanyInfoQueryResponse } from '../utils/GetCompanyInfo';

export const CompanyContext = createContext({
  company: null,
  setCompany: (_c: GetCompanyInfoQueryResponse) => {},
});

export const CompanyContextProvider = CompanyContext.Provider;

export function useCompany() {
  return useContext(CompanyContext);
}

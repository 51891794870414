import { commitMutation, graphql } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';
import type { completeTalkingPointMutationVariables } from 'jakiro-types/completeTalkingPointMutation.graphql';

const mutation = graphql`
  mutation completeTalkingPointMutation($input: CompleteTalkingPointInput!) {
    completeTalkingPoint(input: $input) {
      talkingPoint {
        id
        body
        entityId
        creator {
          avatarUrl
          name
          viewerIsUser
        }
        completedAt
      }
    }
  }
`;

type Fn = (val: any) => void;

function commit(
  vars: completeTalkingPointMutationVariables['input'],
  onCompleted: Fn,
  onError: Fn,
) {
  const variables = {
    input: {
      ...vars,
    },
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

export default { commit };

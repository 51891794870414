/**
 * @generated SignedSource<<daa86eab78299ea5a16cfb43a6502b2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetencyCard_competency$data = {
  readonly name: string;
  readonly description: string | null;
  readonly " $fragmentType": "CompetencyCard_competency";
};
export type CompetencyCard_competency = CompetencyCard_competency$data;
export type CompetencyCard_competency$key = {
  readonly " $data"?: CompetencyCard_competency$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompetencyCard_competency">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompetencyCard_competency",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "Competency",
  "abstractKey": null
};

(node as any).hash = "434fc33c2bd9e899dddd1b80487cd6b8";

export default node;

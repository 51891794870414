import { ActivityIndicator } from 'react-native';

import Container from './Container';
import { Colors } from '../utils/constants';

type Props = {
  size?: number | 'small' | 'large' | undefined;
  color?: string;
};

const Loading = ({ size = 'large', color = Colors.darkergray }: Props) => (
  <Container flex={1}>
    <ActivityIndicator size={size} color={color} />
  </Container>
);

export default Loading;

import { Text } from 'react-native';
import Markdown from 'react-native-markdown-display';
import styled, { css } from '@emotion/native';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment';
import { truncate } from 'lodash';

import type { FeedbackCard_feedback } from 'jakiro-types/FeedbackCard_feedback.graphql';
import { UserChipFragment } from './UserChip';
import Container from './Container';
import Badge from './Badge';
import { fontTypes, Colors } from '../utils/constants';

type Props = {
  feedback: FeedbackCard_feedback;
};

function FeedbackCard({ feedback }: Props) {
  const { body, targets = [], author, createdAt, companyValues, competency } = feedback;

  if (!body) return null;

  const competencyText = competency?.name;
  return (
    <Card>
      <Container
        direction="row"
        justify="space-between"
        style={css`
          padding-bottom: ${competencyText ? '0' : '4'}px;
        `}
      >
        <Container direction="row">
          <Text style={[fontTypes.body_semi_bold, { fontWeight: '600', marginRight: 8 }]}>
            FROM
          </Text>

          <UserChipFragment user={author} xsmall />
        </Container>

        <Text style={fontTypes.bodySecondary}>{moment(createdAt).format('MMM DD, YYYY')}</Text>
      </Container>
      {competencyText && (
        <Container
          align="flex-start"
          flex={1}
          style={css`
            margin-bottom: 14px;
          `}
        >
          <Text style={{ color: Colors.darkergray }}>
            on{' '}
            <Text style={[fontTypes.body_semi_bold, { fontWeight: '600' }]}>
              {truncate(competencyText, { length: 28 })}
            </Text>
          </Text>
        </Container>
      )}

      {/** @ts-expect-error TODO investigate whether or not this style cascades */}
      <Markdown style={fontTypes.body}>{body.trim()}</Markdown>

      {Array.isArray(targets) && targets.length > 1 && (
        <Text style={[fontTypes.bodySecondary, { paddingTop: 8 }]}>
          Also shared with {targets.length - 1} other{targets.length - 1 > 1 ? 's' : ''}
        </Text>
      )}

      {Array.isArray(companyValues) && companyValues.length > 0 && (
        <ValueContainer>
          {companyValues.map((value, index) => {
            const name = value?.name || '';

            if (!name) return null;

            return (
              <Badge variant="info" key={index} style={{ marginRight: 8, marginBottom: 4 }}>
                {name}
              </Badge>
            );
          })}
        </ValueContainer>
      )}
    </Card>
  );
}

const Card = styled.View`
  background-color: white;
  padding: 16px;
  box-shadow: 0px 1px 3px rgba(34, 45, 56, 0.16);
  border-radius: 4px;
  margin-bottom: 16px;
`;

const ValueContainer = styled.View`
  padding-top: 16px;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  display: flex;
`;

export default createFragmentContainer(FeedbackCard, {
  feedback: graphql`
    fragment FeedbackCard_feedback on Feedback {
      body
      createdAt
      companyValues {
        name
      }
      targets {
        id
      }
      author {
        avatarUrl
        name
        preferredName
        ...UserChip_user
      }
      competency {
        name
      }
    }
  `,
});

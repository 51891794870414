import { Image } from 'react-native';

import { Text } from '@rneui/themed';
import * as Updates from 'expo-updates';

import { fontTypes, Colors } from '../utils/constants';
import Button from './Button';
import Container from './Container';
import { GraphqlError } from '../utils/Environment';
import useAuthentication from '../hooks/useAuthentication';

type Props = {
  error?: GraphqlError;
};

const HandleNetworkError = ({ error }: Props) => {
  const { logOut } = useAuthentication();

  if (error && error.statusCode === 401) {
    // React Navigation will automatically route us to the login screen
    void logOut();
    return null;
  }

  return (
    <Container flex={1} justify="center" align="center" style={{ backgroundColor: Colors.white }}>
      <Image
        style={{ width: 240, height: 144 }}
        source={require('../assets/images/no-result.png')}
      />

      <Text
        style={[
          fontTypes.informative_text_semibold,
          { textAlign: 'center', fontWeight: 'bold', maxWidth: 295 },
        ]}
      >
        Looks like something went wrong
      </Text>

      <Text style={[fontTypes.informative_text, { textAlign: 'center', maxWidth: 295 }]}>
        Please try reloading the app. If that doesn't work, please contact support@lattice.com
      </Text>

      <Button
        title="Reload App"
        onPress={() => Updates.reloadAsync()}
        type="clear"
        containerStyle={{
          marginTop: 8,
        }}
      />
    </Container>
  );
};

export default HandleNetworkError;

import * as React from 'react';
import { StyleSheet, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Text } from '@rneui/themed';

import type { GetCompanyInfoQueryResponse } from '../../utils/GetCompanyInfo';
import type { StackNavigationProp } from '@react-navigation/stack';
import Container from '../../components/Container';
import Button, { ButtonStyles } from '../../components/Button';
import {
  APPLICATION_MAX_WIDTH_INT,
  ScreenWidth,
  Colors,
  fontTypes,
  isWeb,
} from '../../utils/constants';
import type { RootStackParamList } from '../../components/Navigation/types';

type Props = {
  resetSlug: () => void;
  errorMsg: string | undefined;
  handleGoogleAuth: () => void;
  handleSSO: () => void;
} & GetCompanyInfoQueryResponse;

const LoginOptions = ({ company, handleSSO, errorMsg, resetSlug, handleGoogleAuth }: Props) => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList, 'Welcome'>>();

  return (
    <Container flex={1} justify="flex-end">
      {!company?.forceSSOLogin && (
        <React.Fragment>
          <Container style={{ height: 50 }}>
            <Text style={styles.error}>{errorMsg || ''}</Text>
          </Container>
          <Button
            testID="LoginOptions_button-google"
            buttonStyle={styles.google}
            titleStyle={styles.googleTitle}
            title="Log in with Google"
            onPress={handleGoogleAuth}
            icon={
              <Image
                style={styles.gImage}
                source={require('../../assets/images/google_logo.png')}
              />
            }
          />
          <Button
            testID="LoginOptions_button-email"
            titleStyle={styles.emailTitle}
            buttonStyle={styles.email}
            title="Log in with email"
            onPress={() => navigation.navigate('Login', { company })}
          />
          {company?.allowPhoneNumberAuth && !isWeb && (
            <Button
              titleStyle={styles.emailTitle}
              buttonStyle={styles.email}
              title="Log in with phone"
              onPress={() => navigation.navigate('Phone Login', { company })}
            />
          )}
        </React.Fragment>
      )}
      {company?.ssoLoginUrl && (
        <Button
          testID="LoginOptions_button-sso"
          titleStyle={styles.ssoTitle}
          buttonStyle={styles.sso}
          onPress={handleSSO}
          title="User Single Sign On (SSO)"
        />
      )}
      <Button
        testID="LoginOptions_button-notSlug"
        onPress={resetSlug}
        titleStyle={styles.notCompany}
        buttonStyle={[styles.sso, { marginBottom: 0 }]}
        title={`Not ${company?.name || ''}?`}
      />
    </Container>
  );
};

const BtnStyles = {
  width: ScreenWidth - 60,
  maxWidth: APPLICATION_MAX_WIDTH_INT,
  height: 55,
};

const styles = StyleSheet.create({
  googleTitle: {
    ...fontTypes.bodyTitle,
    color: 'black',
    fontFamily: 'SFProDisplay-SemiBold',
    marginLeft: 8,
  },
  emailTitle: {
    ...fontTypes.bodyTitle,
    color: 'white',
    fontFamily: 'SFProDisplay-SemiBold',
  },
  ssoTitle: {
    ...fontTypes.informative_text,
    color: 'white',
    fontFamily: 'SFProDisplay-SemiBold',
  },
  notCompany: {
    ...fontTypes.informative_text,
    color: 'white',
    fontFamily: 'SFProDisplay-SemiBold',
  },
  error: {
    ...fontTypes.error,
    marginTop: 10,
    fontSize: 15,
  },
  gImage: { width: 20, height: 20 },
  google: {
    ...ButtonStyles.rounded,
    ...BtnStyles,
    backgroundColor: Colors.white,
    marginBottom: 25,
  },
  email: {
    ...ButtonStyles.rounded,
    ...BtnStyles,
    backgroundColor: 'rgba(250, 250, 250, 0.30)',
    marginBottom: 25,
  },
  sso: {
    marginBottom: 50,
    ...ButtonStyles.rounded,
    ...BtnStyles,
    backgroundColor: 'transparent',
  },
});

export default LoginOptions;

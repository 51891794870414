import { Dimensions, View } from 'react-native';
import { css } from '@emotion/native';
import { createFragmentContainer, graphql } from 'react-relay';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';

import GoalCard from '../../../components/GoalCard';
import type { RootStackParamList } from '../../../components/Navigation/types';
import { isMaxWidth, APPLICATION_MAX_WIDTH_STRING } from '../../../utils/constants';

import type { GoalProfileKeyResultsTab_metricsList } from 'jakiro-types/GoalProfileKeyResultsTab_metricsList.graphql';

type Props = {
  metricsList: GoalProfileKeyResultsTab_metricsList;
};

const GoalProfileKeyResultsTab = ({ metricsList: { childGoals } }: Props) => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList, 'Goal'>>();
  const route = useRoute<RouteProp<RootStackParamList, 'Goal'>>();

  const hasChildGoals = childGoals ? !!childGoals.length : false;

  return (
    <View
      testID="GoalProfileKeyResultsTab"
      style={css`
        flex: 1;
        width: 100%;
      `}
    >
      {(childGoals || []).map((item, index) => {
        if (!item) return null;

        if (hasChildGoals) {
          return (
            <View
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
              }}
              key={index}
            >
              <GoalCard
                cardStyle={{
                  width: Dimensions.get('window').width * 0.85,
                  maxWidth: isMaxWidth ? APPLICATION_MAX_WIDTH_STRING : 320,
                }}
                goal={item}
                onPress={(goalEntityId: string) => {
                  const goalIds = route.params?.goalIds || [];
                  navigation.navigate('Goal', {
                    goalIds: Array.isArray(goalIds)
                      ? [...goalIds, goalEntityId]
                      : [goalIds, goalEntityId],
                  });
                }}
              />
            </View>
          );
        }

        return null;
      })}
    </View>
  );
};

export default createFragmentContainer(GoalProfileKeyResultsTab, {
  metricsList: graphql`
    fragment GoalProfileKeyResultsTab_metricsList on Goal {
      childGoals: childrenList {
        ...GoalCard_goal
      }
    }
  `,
});

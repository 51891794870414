import * as React from 'react';
import { graphql, createRefetchContainer } from 'react-relay';
import { Pressable, Keyboard } from 'react-native';
import moment from 'moment';
import { isEmpty } from 'lodash';
import type { RouteProp } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';

import * as CreateNextRecurringOneOnOneMeetingMutation from '../../mutations/createNextRecurringOneOnOneMeeting';
import createQueryRenderer from '../../utils/createQueryRenderer';
import { connectDropdownAlert } from '../../hocs/withDropdownAlert';
import OneOnOneContent from './components/OneOnOneContent';
import ActiveGoalsTab from '../../components/ActiveGoals';
import BottomOverlayTab from '../../components/BottomOverlayTab';
import PastOneOnOnesTab from './components/PastOneOnOnesTab';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import type { RootStackParamList } from '../../components/Navigation/types';

import type { OneOnOne_checkinRelationship } from 'jakiro-types/OneOnOne_checkinRelationship.graphql';

type Props = {
  checkinRelationship: OneOnOne_checkinRelationship;
  relay: any;
  isLoading: boolean;
  navigation: StackNavigationProp<RootStackParamList, 'One On One'>;
  route: RouteProp<RootStackParamList, 'One On One'>;
};

function OneOnOne({ checkinRelationship, relay, isLoading, navigation, route }: Props) {
  const [hiddenBottomOverlay, setHiddenBottomOverlay] = React.useState(true);
  const [hasRetriedHash, setHasRetriedHash] = React.useState({});

  React.useEffect(() => {
    if (!checkinRelationship) return;

    const nextRecurringMeeting = checkinRelationship?.nextRecurringOneOnOneMeeting;

    if (
      nextRecurringMeeting &&
      nextRecurringMeeting.__typename === 'NextScheduledOneOnOneMeeting' &&
      !hasRetriedHash[nextRecurringMeeting.id]
    ) {
      setHasRetriedHash(hasRetriedHash => ({
        ...hasRetriedHash,
        [nextRecurringMeeting.id]: true,
      }));

      CreateNextRecurringOneOnOneMeetingMutation.commit(
        {
          checkinRelationshipEntityId: checkinRelationship.entityId,
        },
        ({ createNextRecurringOneOnOneMeeting }) => {
          if (!createNextRecurringOneOnOneMeeting) return;

          const { meeting } = createNextRecurringOneOnOneMeeting;

          if (meeting) {
            relay.retry();
          }
        },
      );
    }
  }, [checkinRelationship, hasRetriedHash, relay]);

  const setOneOnOne = React.useCallback(
    id => {
      const currentParams = route.params;

      navigation.setParams({
        ...currentParams,
        meetingId: id,
      });
    },
    [navigation],
  );

  const onOptionPress = React.useCallback(() => {
    toggleBottomOverlay();
  }, []);

  const toggleBottomOverlay = React.useCallback(() => {
    setHiddenBottomOverlay(hiddenBottomOverlay => !hiddenBottomOverlay);
  }, []);

  if (isLoading || isEmpty(checkinRelationship)) {
    return <Loading />;
  }

  const hasMeetingId = Boolean(route.params.meetingId);

  const manager = checkinRelationship.manager;
  const report = checkinRelationship.report;

  const viewerIsReport = checkinRelationship.report?.viewerIsUser;

  const oneOnOne =
    checkinRelationship.findOneOnOneMeeting || checkinRelationship.nextRecurringOneOnOneMeeting;

  const scheduledAt = oneOnOne?.scheduledAt;
  const nextOneOnOneScheduledAt = checkinRelationship.nextRecurringOneOnOneMeeting?.scheduledAt;

  const userId = viewerIsReport ? manager.entityId : report.entityId;

  const viewerIsManager = checkinRelationship.viewerIsManager || false;
  const otherUser = viewerIsManager ? checkinRelationship.report : checkinRelationship.manager;

  const idWrap = id => () => setOneOnOne(id);

  const bottomOverlayTabs = [
    {
      key: 'active-goals',
      title: 'Active goals',
      component: (
        <ActiveGoalsTab userId={userId} viewerIsUser={false} toggleModal={toggleBottomOverlay} />
      ),
    },
    {
      key: 'past-1on1s',
      title: 'Past 1:1s',
      component: (
        <PastOneOnOnesTab userId={userId} viewerIsUser={false} toggleModal={toggleBottomOverlay} />
      ),
    },
  ];

  const lastCreatedOneOnOne = checkinRelationship.oneOnOneMeetings?.edges[0]?.node.entityId;

  // Handle next 1:1 action
  let handleNext = null;
  // @ts-expect-error
  if (oneOnOne?.nextOneOnOneMeeting?.entityId) {
    // @ts-expect-error
    handleNext = idWrap(oneOnOne?.nextOneOnOneMeeting?.entityId);
    // @ts-expect-error
  } else if (oneOnOne?.nextOneOnOneMeeting) {
    // if we have a meetingId and our NextOneOnOne exsists.
    // note that sometimes Google events stop syncing an
    // and the nextOneOnOne won't exsist in which case
    // we don't want the user to go to the create page
    handleNext = hasMeetingId && nextOneOnOneScheduledAt ? () => setOneOnOne(null) : null;
  }
  return (
    <>
      <Pressable
        onPress={() => {
          Keyboard.dismiss();
        }}
      >
        <Header
          mode="BACK"
          title={scheduledAt ? moment(scheduledAt).format('LL') : 'Next 1:1'}
          containerStyle={{ width: '100%' }}
          handleOptionPress={onOptionPress}
        />
      </Pressable>

      <OneOnOneContent
        userId={route.params.userId}
        checkinRelationship={checkinRelationship}
        handleNext={handleNext}
        handleBack={
          // @ts-expect-error
          oneOnOne.previousOneOnOneMeeting?.entityId
            ? // @ts-expect-error
              idWrap(oneOnOne.previousOneOnOneMeeting.entityId)
            : lastCreatedOneOnOne && hasMeetingId === false
            ? // if we don't have a meeting ID, we go to the last one on one
              // with a meeting id. From there we use previousOneOnOnes to navigate
              // back
              idWrap(lastCreatedOneOnOne)
            : null
        }
        oneOnOne={oneOnOne}
        isRefetching={false}
        onRefetch={() => relay.refetch()}
        viewerIsManager={viewerIsManager}
        otherUser={otherUser}
        setOneOnOne={setOneOnOne}
      />

      <BottomOverlayTab
        tabs={bottomOverlayTabs}
        toggle={toggleBottomOverlay}
        isVisible={!hiddenBottomOverlay}
      />
    </>
  );
}

const OneOnOneRefetchContainer = createRefetchContainer(
  OneOnOne,
  {
    checkinRelationship: graphql`
      fragment OneOnOne_checkinRelationship on CheckinRelationship
      @argumentDefinitions(meetingId: { type: "String!" }, hasNoMeetingId: { type: "Boolean!" }) {
        viewerIsManager
        id
        entityId
        isOneOnOneActive
        report {
          preferredName
          viewerIsUser
          entityId
        }
        manager {
          preferredName
          viewerIsUser
          entityId
        }
        oneOnOneMeetings(first: 1, pastOnly: true) {
          edges {
            node {
              entityId
              scheduledAt
            }
          }
        }
        findOneOnOneMeeting(meetingId: $meetingId) @skip(if: $hasNoMeetingId) {
          scheduledAt
          entityId
          previousOneOnOneMeeting {
            ... on OneOnOneMeeting {
              entityId
            }
          }
          nextOneOnOneMeeting {
            ... on OneOnOneMeeting {
              entityId
            }
          }
          ...OneOnOneContent_oneOnOne
        }
        nextRecurringOneOnOneMeeting {
          __typename
          id
          scheduledAt
          ... on OneOnOneMeeting {
            entityId
            ...OneOnOneContent_oneOnOne
          }
        }
        ...OneOnOneContent_checkinRelationship
      }
    `,
  },
  graphql`
    query OneOnOneRefetchQuery($meetingId: String!, $userId: String!, $hasNoMeetingId: Boolean!) {
      user(entityId: $userId) {
        viewerUserRelationship {
          ...OneOnOne_checkinRelationship
            @arguments(meetingId: $meetingId, hasNoMeetingId: $hasNoMeetingId)
        }
      }
    }
  `,
);

export default connectDropdownAlert(
  createQueryRenderer(OneOnOneRefetchContainer, OneOnOne, {
    query: graphql`
      query OneOnOneQuery($meetingId: String!, $userId: String!, $hasNoMeetingId: Boolean!) {
        user(entityId: $userId) {
          viewerUserRelationship {
            ...OneOnOne_checkinRelationship
              @arguments(meetingId: $meetingId, hasNoMeetingId: $hasNoMeetingId)
          }
        }
      }
    `,
    getLoadingProp: true,
    nullablePropName: 'checkinRelationship',
    fragmentPropResolver: props => {
      return {
        checkinRelationship: props.user?.viewerUserRelationship,
      };
    },
    queriesParams: ({ route }) => {
      const hasMeetingId = Boolean(route.params.meetingId);

      return {
        meetingId: route.params.meetingId || '',
        userId: route.params.userId,
        hasNoMeetingId: !hasMeetingId,
      };
    },
  }),
);

import { SafeAreaView } from 'react-native';
import { Button } from '@rneui/themed';
import { css } from '@emotion/native';

import { Colors } from '../../utils/constants';

function OpinionBar({
  selectScore,
  selectedScore,
  isOpinionLoading,
  blueSelectedOpinionButton,
}: {
  selectScore: (k: string) => void;
  selectedScore: string;
  isOpinionLoading?: boolean;
  blueSelectedOpinionButton?: boolean;
}) {
  return (
    <SafeAreaView
      style={css`
        width: 100%;
      `}
    >
      {OPINIONS.map(({ value, label }) => (
        <OpinionButton
          key={value}
          onPress={() => {
            selectScore(value);
          }}
          selected={value === selectedScore}
          loading={isOpinionLoading && value === selectedScore}
          blueSelectedOpinionButton={blueSelectedOpinionButton}
        >
          {label}
        </OpinionButton>
      ))}
    </SafeAreaView>
  );
}

export default OpinionBar;

type OpinionButtonProps = {
  selected?: boolean;
  primary?: boolean;
  children: string;
  onPress: any;
  blueSelectedOpinionButton?: boolean;
  loading: boolean;
};

function OpinionButton({
  children,
  selected,
  blueSelectedOpinionButton,
  primary,
  ...rest
}: OpinionButtonProps) {
  return (
    <Button
      type="clear"
      title={children}
      loadingProps={{
        color: blueSelectedOpinionButton && selected ? Colors.blue : primary ? 'white' : 'black',
        style: css`
          width: 100%;
        `,
      }}
      containerStyle={css`
        width: 100%;
        margin-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
      `}
      buttonStyle={css`
        background-color: ${blueSelectedOpinionButton && selected
          ? Colors.lighterblue
          : primary
          ? Colors.iosBlue
          : selected
          ? '#FBDFA6'
          : Colors.lightestgray};
        border-color: ${blueSelectedOpinionButton && selected ? Colors.blue : 'transparent'};
        border-width: 1px;
        width: 100%;
        height: 50px;
      `}
      titleProps={{
        style: css`
          font-size: 17px;
          line-height: 25px;
          font-family: ${blueSelectedOpinionButton && selected
            ? 'SFProDisplay-SemiBold'
            : 'SFProDisplay-Regular'};
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: ${blueSelectedOpinionButton && selected
            ? Colors.darkestgray
            : primary
            ? 'white'
            : Colors.darkergray};
        `,
      }}
      disabledStyle={css`
        background-color: rgba(0, 0, 0, 0.05);
        opacity: 0.8;
      `}
      {...rest}
    />
  );
}

export const OPINIONS = [
  { value: 'StronglyAgree', label: 'Strongly Agree', surveyValue: 4 },
  { value: 'Agree', label: 'Agree', surveyValue: 3 },
  { value: 'Neutral', label: 'Neutral', surveyValue: 2 },
  { value: 'Disagree', label: 'Disagree', surveyValue: 1 },
  { value: 'StronglyDisagree', label: 'Strongly Disagree', surveyValue: 0 },
];

/**
 * @generated SignedSource<<a7f4d0f9884443755dd6a51877de6804>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserIntro_user$data = {
  readonly avatarUrl: string | null;
  readonly title: string | null;
  readonly name: string | null;
  readonly " $fragmentType": "UserIntro_user";
};
export type UserIntro_user = UserIntro_user$data;
export type UserIntro_user$key = {
  readonly " $data"?: UserIntro_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserIntro_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserIntro_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatarUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "4ef17b50d94be4efae26dce10d301c78";

export default node;

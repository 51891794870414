import * as React from 'react';
import { graphql } from 'react-relay';
import { css } from '@emotion/native';
import { ScrollView, ActivityIndicator, View, Text } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { noop } from 'lodash';
import { useAnalytics } from '@segment/analytics-react-native';

import Header from '../../components/Header';
import Button, { ButtonStyles } from '../../components/Button';
import Avatar from '../../components/Avatar';
import { connectDropdownAlert, Alert } from '../../hocs/withDropdownAlert';

import { fontTypes, Colors } from '../../utils/constants';
import createQueryRenderer from '../../utils/createQueryRenderer';

import type { faceMatchQueryResponse } from 'jakiro-types/faceMatchQuery.graphql';

type Props = {
  retry: () => void;
  dropdownAlert: Alert;
} & faceMatchQueryResponse;

function FaceMatch({ viewer, dropdownAlert, randomFaceGame, retry }: Props) {
  const answer = randomFaceGame.answer;
  const [names, setNames] = React.useState([]);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const { track } = useAnalytics();

  React.useEffect(() => {
    const { choices } = randomFaceGame;
    if (answer) {
      const next = choices.map(c => c.name);
      next.splice(Math.round(Math.random() * choices.length), 0, answer.name);
      setNames(next);
    }
  }, [answer]);

  const submit = name => {
    if (name !== answer.name) {
      track('Jakiro.FaceMatch', {
        userId: viewer?.userId,
        properties: {
          success: false,
        },
      });
      dropdownAlert({
        type: 'error',
        title: "Whoops, that's not right",
        body: '',
      });
      return;
    }

    if (name === answer.name) {
      track('Jakiro.FaceMatch', {
        userId: viewer?.userId,
        properties: {
          success: true,
        },
      });
      dropdownAlert({
        type: 'success',
        title: 'Got it!',
        body: '',
      });
      setShowSuccess(true);
    }
  };

  const goToNextPerson = () => {
    retry();
    setShowSuccess(false);
  };

  return (
    <LinearGradient
      colors={['#9AF4FA', '#0F63B8']}
      start={{ x: 0.05, y: 0.05 }}
      end={{ x: 0.95, y: 0.95 }}
      style={css`
        display: flex;
        flex: 1;
        flex-direction: column;
      `}
    >
      <Header
        title=""
        mode="BACK"
        backgroundColor="transparent"
        buttonColor="white"
        bottomComponent
        containerStyle={css`
          width: 100%;
        `}
      />

      <ScrollView
        style={css`
          flex: 1;
          padding: 16px;
          padding-top: 32px;
          padding-bottom: 72px;
        `}
      >
        <View
          style={css`
            height: 100%;
            width: 100%;
            flex: 1;
            flex-shrink: 0;
            flex-basis: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: white;
            padding: 32px 16px;
            border-radius: 16px;

            shadow-radius: 10px;
            shadow-color: #000;
            shadow-opacity: 0.2;
          `}
        >
          <View
            style={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 100%;
              margin-bottom: 16px;
            `}
          >
            <Avatar
              renderPlaceholderContent={<ActivityIndicator />}
              xlarge
              avatarUrl={answer.avatarUrl}
            />

            <View
              style={[
                css`
                  text-align: center;
                  width: 100%;
                  display: flex;
                  align-items: center;
                `,
                { opacity: showSuccess ? 1 : 0 },
              ]}
            >
              <Text
                style={[
                  fontTypes.header,
                  css`
                    margin-top: 16px;
                    margin-bottom: 8px;
                    font-style: italic;
                    color: ${Colors.darkergray};
                  `,
                ]}
              >
                {answer.name}
              </Text>
              <Text
                style={[
                  fontTypes.title,
                  css`
                    margin-bottom: 8px;
                    font-style: italic;
                    color: ${Colors.darkgray};
                  `,
                ]}
              >
                {answer.title}
              </Text>
              <Text style={(fontTypes.title, { fontStyle: 'italic', color: Colors.darkgray })}>
                {answer.department?.name}
              </Text>
            </View>
          </View>

          {names.map(name => {
            if (!name) return null;
            return (
              <RoundButton
                key={name}
                onPress={() => submit(name)}
                title={name}
                disabled={showSuccess}
              />
            );
          })}

          <RoundButton
            disabled={false}
            onPress={showSuccess ? goToNextPerson : noop}
            title="Next person"
            containerStyle={[
              css`
                margin-top: 32px;
              `,
              { opacity: showSuccess ? 1 : 0 },
            ]}
            buttonStyle={css`
              background-color: ${Colors.lightgray};
            `}
            titleStyle={css`
              color: ${Colors.darkergray};
            `}
          />
        </View>
      </ScrollView>
    </LinearGradient>
  );
}

type RoundButtonProps = {
  onPress: () => void;
  title: string;
  buttonStyle?: any;
  titleStyle?: any;
  containerStyle?: any;
  disabled: boolean;
};

function RoundButton({
  onPress,
  title,
  containerStyle,
  buttonStyle,
  titleStyle,
  disabled,
}: RoundButtonProps) {
  return (
    <Button
      disabled={disabled}
      containerStyle={[
        css`
          margin-bottom: 16px;
          width: 100%;
        `,
        containerStyle,
      ]}
      disabledStyle={css`
        background-color: ${Colors.lightestgray};
      `}
      buttonStyle={[
        css`
          ${ButtonStyles.rounded}
          background-color: ${Colors.lightestgray};
          height: 40px;
        `,
        buttonStyle,
      ]}
      titleStyle={[
        css`
          color: ${Colors.darkergray};
          font-weight: 600;
        `,
        titleStyle,
      ]}
      title={title || ''}
      key="type"
      onPress={onPress}
    />
  );
}

export default createQueryRenderer(connectDropdownAlert(FaceMatch), FaceMatch, {
  query: graphql`
    query faceMatchQuery {
      viewer {
        userId
      }
      randomFaceGame {
        answer {
          name
          title
          department {
            name
          }
          avatarUrl
        }
        choices {
          name
        }
      }
    }
  `,
});

/**
 * @generated SignedSource<<1ae9e2abf162148c1b37be61f3f38376>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type faceMatchQuery$variables = {};
export type faceMatchQueryVariables = faceMatchQuery$variables;
export type faceMatchQuery$data = {
  readonly viewer: {
    readonly userId: string;
  } | null;
  readonly randomFaceGame: {
    readonly answer: {
      readonly name: string | null;
      readonly title: string | null;
      readonly department: {
        readonly name: string | null;
      } | null;
      readonly avatarUrl: string | null;
    };
    readonly choices: ReadonlyArray<{
      readonly name: string | null;
    }>;
  };
};
export type faceMatchQueryResponse = faceMatchQuery$data;
export type faceMatchQuery = {
  variables: faceMatchQueryVariables;
  response: faceMatchQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = [
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v1/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "faceMatchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FaceGame",
        "kind": "LinkedField",
        "name": "randomFaceGame",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "answer",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Department",
                "kind": "LinkedField",
                "name": "department",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "choices",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "faceMatchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FaceGame",
        "kind": "LinkedField",
        "name": "randomFaceGame",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "answer",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Department",
                "kind": "LinkedField",
                "name": "department",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "choices",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d3bc9b99545312fe7a7241f590d1197e",
    "id": null,
    "metadata": {},
    "name": "faceMatchQuery",
    "operationKind": "query",
    "text": "query faceMatchQuery {\n  viewer {\n    userId\n    id\n  }\n  randomFaceGame {\n    answer {\n      name\n      title\n      department {\n        name\n        id\n      }\n      avatarUrl\n      id\n    }\n    choices {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a6d5d4340ef10fbd4b6cfa8a0d5cc894";

export default node;

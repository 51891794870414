import { useMemo } from 'react';
import { graphql } from 'react-relay';
import styled, { css } from '@emotion/native';
import { TouchableOpacity, Text, View } from 'react-native';
import type { StackNavigationProp } from '@react-navigation/stack';
import * as Linking from 'expo-linking';
import sortBy from 'lodash/sortBy';
import noop from 'lodash/noop';
import { ListItem } from '@rneui/themed';
import moment from 'moment';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import { formatDate, cleanConnection } from '../../utils/fns';
import { ActiveGoalsTab } from '../../components/ActiveGoals';
import NextMeetingCard from '../../components/NextMeetingCard';
import Header from '../../components/Header';
import UserProfile from '../../components/UserProfile';
import type { RootStackParamList } from '../../components/Navigation/types';
import { ListItemChevron } from '../../components/ListItemChevron';
import {
  isWeb,
  Colors,
  fontTypes,
  APPLICATION_MAX_WIDTH_STRING,
  ListItemStyle,
} from '../../utils/constants';
import createQueryRenderer from '../../utils/createQueryRenderer';
import FeedbackCard from '../../components/FeedbackCard';

import type { DirectoryProfileQueryResponse } from 'jakiro-types/DirectoryProfileQuery.graphql';

type Props = DirectoryProfileQueryResponse & {
  navigation: StackNavigationProp<RootStackParamList, 'Profile'>;
};

const DirectoryProfile = ({ viewer, navigation }: Props) => {
  const user = viewer?.user;

  const hasEmployeePercentile = viewer.features.employeePercentile;

  const activeGoalsConnection = viewer?.user?.activeGoals;
  const userHasGoals = (activeGoalsConnection?.edges || []).length > 1;

  const feedbackEnabled = viewer?.user?.products.feedback.isEnabled;
  const feedbackConnection = viewer?.user?.viewerVisibleFeedback;
  const feedback = cleanConnection(feedbackConnection);

  const isOneOnOneActive = viewer?.user?.viewerUserRelationship?.isOneOnOneActive || false;

  const report = viewer?.user?.viewerUserRelationship?.report;
  const manager = viewer?.user?.viewerUserRelationship?.manager;

  const viewerIsReport = viewer?.user?.viewerUserRelationship?.report?.viewerIsUser;

  const target = viewerIsReport ? manager : report;

  const hasNextOneOnOneScheduled = user?.viewerUserRelationship?.nextRecurringOneOnOneMeeting;

  let scheduledAt;
  let meetingId;

  if (hasNextOneOnOneScheduled !== null) {
    scheduledAt = user?.viewerUserRelationship?.nextRecurringOneOnOneMeeting?.scheduledAt;
    meetingId = user?.viewerUserRelationship?.nextRecurringOneOnOneMeeting?.entityId || null;
  } else {
    const oneOnOneFromConnection = user?.viewerUserRelationship?.oneOnOneMeetings?.edges[0]?.node;
    scheduledAt = oneOnOneFromConnection?.scheduledAt;
    meetingId = oneOnOneFromConnection?.entityId;
  }

  const customFields = useMemo(
    () => sortBy(cleanConnection(user?.viewerVisibleCustomProfileFields), ['fieldName']),
    [user?.viewerVisibleCustomProfileFields],
  );

  const hasManager = user?.manager?.email;
  const hasDepartment = user?.department?.name;
  const hasStartDate = user?.startDate;
  const hasCustomFields =
    customFields && customFields.length > 0 && customFields.some(k => k.displayedValue);

  if (!user) return null;

  return (
    <PageContainer>
      <Header title="Profile" mode="BACK" />

      <Container>
        <Inner>
          <Intro>
            <UserProfile
              title={user.name}
              subtitle={user.title}
              avatarUrl={user.avatarUrl}
              onPressAvatar={() => {}}
            />
          </Intro>

          <Section>
            <Headline
              style={css`
                padding-top: 32px;
                padding-bottom: 16px;
              `}
            >
              Contact information
            </Headline>

            <ListItem
              style={ListItemStyle}
              Component={TouchableOpacity}
              onPress={canExternalLink('PHONE', user.phoneNumber || '--')}
              bottomDivider
              topDivider
            >
              <MaterialIcons
                name="phone"
                style={{
                  color: Colors.darkergray,
                  marginRight: isWeb ? 16 : 0,
                }}
                size={24}
              />
              <ListItem.Content>
                <ListItem.Title>{user.phoneNumber || '--'}</ListItem.Title>
              </ListItem.Content>
            </ListItem>

            {!viewer?.features?.hideEmailFromPublicProfile && (
              <ListItem
                style={ListItemStyle}
                Component={TouchableOpacity}
                onPress={canExternalLink('EMAIL', user.email || '--')}
                bottomDivider
              >
                <MaterialIcons
                  name="email"
                  style={{
                    color: Colors.darkergray,
                    marginRight: isWeb ? 16 : 0,
                  }}
                  size={24}
                />
                <ListItem.Content>
                  <ListItem.Title>{user.email || '--'}</ListItem.Title>
                </ListItem.Content>
              </ListItem>
            )}
          </Section>

          {(hasCustomFields || hasStartDate || hasDepartment || hasManager) && (
            <Section>
              <Headline
                style={css`
                  padding-bottom: 16px;
                `}
              >
                About
              </Headline>

              {hasManager && (
                <ListItem
                  style={ListItemStyle}
                  onPress={() => {
                    navigation.push('Profile', {
                      userEntityId: user.manager?.entityId,
                    });
                  }}
                  Component={TouchableOpacity}
                  topDivider
                  bottomDivider
                >
                  <ListItem.Content>
                    <ListItem.Title style={aboutTitleStyle}>Manager</ListItem.Title>
                    <ListItem.Subtitle>{user.manager?.name || '--'}</ListItem.Subtitle>
                  </ListItem.Content>
                  <ListItemChevron />
                </ListItem>
              )}

              {hasDepartment && (
                <ListItem
                  style={ListItemStyle}
                  onPress={() => {
                    navigation.push('Directory Department Employees', {
                      departmentEntityId: user?.department?.entityId,
                    });
                  }}
                  Component={TouchableOpacity}
                  bottomDivider
                  topDivider={!hasManager}
                >
                  <ListItem.Content>
                    <ListItem.Title style={aboutTitleStyle}>Department</ListItem.Title>
                    <ListItem.Subtitle>{user?.department?.name || '--'}</ListItem.Subtitle>
                  </ListItem.Content>
                  <ListItemChevron />
                </ListItem>
              )}

              {hasStartDate && (
                <ListItem
                  style={ListItemStyle}
                  bottomDivider
                  topDivider={!hasManager && !hasDepartment}
                >
                  <ListItem.Content>
                    <ListItem.Title style={aboutTitleStyle}>Start date</ListItem.Title>
                    <ListItem.Subtitle>{user.startDate || '--'}</ListItem.Subtitle>
                  </ListItem.Content>
                </ListItem>
              )}

              {hasEmployeePercentile && user.startedAfterPercentage != null && (
                <ListItem style={ListItemStyle} bottomDivider>
                  <ListItem.Content>
                    <ListItem.Title style={aboutTitleStyle}>Percentile</ListItem.Title>
                    <ListItem.Subtitle>{`${user.startedAfterPercentage}%`}</ListItem.Subtitle>
                  </ListItem.Content>
                </ListItem>
              )}

              {customFields.map(d => {
                if (!d.displayedValue) return null;
                const isHidden = d.privacyLevel !== 'Everyone';
                const isRelationship = d.__typename === 'RelationshipCustomProfileField';

                return (
                  <ListItem
                    key={d.fieldName}
                    style={ListItemStyle}
                    Component={TouchableOpacity}
                    onPress={
                      isRelationship
                        ? () => {
                            navigation.push('Profile', {
                              userEntityId: d.choiceUser?.entityId,
                            });
                          }
                        : noop
                    }
                    bottomDivider
                  >
                    <ListItem.Content>
                      <ListItem.Title style={aboutTitleStyle}>{d.fieldName}</ListItem.Title>
                      <ListItem.Subtitle>{d.displayedValue || '--'}</ListItem.Subtitle>
                    </ListItem.Content>
                    {isHidden && <MaterialIcons name="lock" color={Colors.darkergray} size={24} />}
                    {isRelationship && <ListItemChevron />}
                  </ListItem>
                );
              })}
            </Section>
          )}

          {isOneOnOneActive && target && (
            <Section>
              <Headline
                style={css`
                  padding-bottom: 16px;
                `}
              >
                Upcoming
              </Headline>

              <View
                style={css`
                  padding-left: 16px;
                  padding-right: 16px;
                `}
              >
                <NextMeetingCard
                  user={target}
                  onPress={() => {
                    navigation.push('One On One', {
                      meetingId,
                      userId: target.entityId,
                    });
                  }}
                  date={formatDate(moment(scheduledAt))}
                />
              </View>
            </Section>
          )}

          {userHasGoals && (
            <Section>
              <Headline>Their active goals</Headline>

              <ActiveGoalsTab
                viewer={viewer}
                cardStyle={css`
                  margin-bottom: 16px;
                  margin-top: 0px;
                  margin-right: 0px;
                  margin-left: 16px;
                `}
              />
            </Section>
          )}

          {feedbackEnabled && feedback.length > 0 && (
            <Section
              style={css`
                margin-bottom: 16px;
              `}
            >
              <Headline>Feedback they’ve received</Headline>

              <View
                style={css`
                  margin: 16px;
                `}
              >
                {feedback.map(k => (
                  <FeedbackCard viewer={viewer} feedback={k} key={k.id} />
                ))}
              </View>
            </Section>
          )}
        </Inner>
      </Container>
    </PageContainer>
  );
};

const PageContainer = styled.View`
  background-color: ${Colors.faint};
  flex: 1;
`;

const Intro = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
`;

const Container = styled.ScrollView`
  padding: 32px 0;
  width: 100%;
`;

const Inner = styled.View`
  flex: 1;
  width: 100%;
  max-width: ${APPLICATION_MAX_WIDTH_STRING};
  margin: 0 auto;
`;

const Section = styled.View`
  margin-bottom: 32px;
`;

const Headline = styled(Text)`
  ${fontTypes.title}
  padding-left: 16px;
`;

const aboutTitleStyle = {
  marginBottom: 4,
  textTransform: 'uppercase',
  ...fontTypes.body_semi_bold,
} as const;

export default createQueryRenderer(DirectoryProfile, DirectoryProfile, {
  query: graphql`
    query DirectoryProfileQuery($userEntityId: String!) {
      viewer {
        features
        user: findUser(userId: $userEntityId) {
          viewerIsUser
          startedAfterPercentage
          entityId
          name
          title
          avatarUrl
          startDate
          phoneNumber
          timezone
          realTimezone
          email
          department {
            name
            entityId
          }
          manager {
            entityId
            name
            email
          }
          products {
            feedback {
              isEnabled
            }
          }
          viewerVisibleCustomProfileFields(first: 30) {
            edges {
              node {
                __typename
                fieldName
                displayedValue
                privacyLevel
                ... on RelationshipCustomProfileField {
                  choiceUser {
                    entityId
                    email
                    name
                  }
                }
              }
            }
          }
          viewerUserRelationship {
            isOneOnOneActive
            ... on CheckinRelationship {
              oneOnOneMeetings(first: 1) {
                edges {
                  node {
                    scheduledAt
                    entityId
                  }
                }
              }
              nextRecurringOneOnOneMeeting {
                id
                scheduledAt
                ... on OneOnOneMeeting {
                  entityId
                }
              }
            }
            report {
              entityId
              viewerIsUser
              ...NextMeetingCard_user
            }
            manager {
              entityId
              viewerIsUser
              ...NextMeetingCard_user
            }
          }
          viewerVisibleFeedback(first: 20) {
            pageInfo {
              hasNextPage
            }
            edges {
              node {
                id
                ...FeedbackCard_feedback
              }
            }
          }
          activeGoals: ownedGoals(state: ACTIVE, last: 100, orderBy: Priority) {
            edges {
              node {
                ...GoalCard_goal
              }
            }
          }
        }
      }
    }
  `,
  queriesParams: ({ route }) => ({
    userEntityId: route.params.userEntityId,
  }),
});

const LinkTypes = {
  PHONE: 'tel:',
  EMAIL: 'mailto:',
};

const canExternalLink = (label: string, value: string): any => {
  if (value === '--' || !LinkTypes[label]) return;

  const prefix = LinkTypes[label];
  const url = prefix + value;

  return async () => {
    const supported = await Linking.canOpenURL(url);

    if (!supported) return;

    Linking.openURL(url);
  };
};

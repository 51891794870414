import * as React from 'react';
import { ScrollView, Keyboard, Image, View, TouchableOpacity } from 'react-native';
import { Text } from '@rneui/themed';
// import CodeInput from 'react-native-confirmation-code-field';
import { css } from '@emotion/native';
import type { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import { Colors, fontTypes } from '../../utils/constants';
import Container from '../../components/Container';
import Button, { ButtonStyles } from '../../components/Button';
import Header from '../../components/Header';
import { PhoneConfirmationCodeLogin } from '../../utils/Login';
import useTimeout from '../../hooks/useTimeout';
import { RootStackParamList } from '../../components/Navigation/types';

type Props = {
  navigation: StackNavigationProp<RootStackParamList, 'Phone Login Confirmation'>;
  route: RouteProp<RootStackParamList, 'Phone Login Confirmation'>;
};

export default function LoginPhoneConfirmation(props: Props) {
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  // const [verificationCode, setVerificationCode] = React.useState(false);
  const [revealResendCode, setRevealResendCode] = React.useState(false);

  const { navigation, route } = props;

  useTimeout(() => {
    setRevealResendCode(true);
  }, 30 * 1000);

  const submit = code => {
    Keyboard.dismiss();

    setIsLoading(true);

    PhoneConfirmationCodeLogin(
      {
        requestId: route.params.requestId,
        userEntityId: route.params.userEntityId,
        userPhoneDetailEntityId: route.params.userPhoneDetailEntityId,
        verificationCode: code,
      },
      (errors: string[]) => {
        setIsLoading(false);
        setErrorMsg(errors[0]);
      },
      () => {
        setIsLoading(false);
        navigation.navigate('Home');
      },
    );
  };

  return (
    <Container flex={1}>
      <Header
        title=""
        mode="BACK"
        bottomComponent
        containerStyle={css`
          width: 100%;
        `}
      />

      <ScrollView
        contentContainerStyle={css`
          flex: 1;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
        `}
        style={css`
          width: 100%;
          padding-top: 60px;
        `}
      >
        <Container
          flex={1}
          justify="flex-start"
          style={css`
            max-width: 300px;
            width: 100%;
          `}
        >
          <Image
            source={require('../../assets/images/latticeLogo.png')}
            resizeMode="contain"
            style={css`
              width: 55px;
              height: 31px;
              margin-bottom: 15px;
            `}
          />
          {errorMsg ? (
            <Text
              style={css`
                ${fontTypes.bodyTitle}
                ${fontTypes.error}
              margin-bottom: 30px;
              `}
            >
              {errorMsg}
            </Text>
          ) : (
            <Text
              style={css`
                ${fontTypes.bodyTitle}
                margin-bottom: 30px;
              `}
            >
              Enter the 6 digit confirmation code
            </Text>
          )}

          <View
            style={css`
              width: 300px;
            `}
          >
            {/* <CodeInput
              codeLength={6}
              activeColor={Colors.iosBlue}
              inactiveColor="rgba(0,0,0,0.1)"
              inputPosition="center"
              keyboardType="numeric"
              size={50}
              onFulfill={verificationCode => {
                setVerificationCode(verificationCode);
                submit(verificationCode);
              }}
              autoFocus={true}
            /> */}
          </View>

          <Button
            title="Confirm"
            onPress={submit}
            // disabled={!verificationCode}
            disabledStyle={css`
              background-color: ${Colors.gray};
            `}
            loading={isLoading}
            loadingProps={{ size: 'large', color: 'rgba(111, 202, 186, 1)' }}
            containerStyle={css`
              margin: 30px 0 0;
              width: 100%;
            `}
            buttonStyle={css`
              ${ButtonStyles.rounded}
              background-color: ${Colors.iosBlue};
              height: 50px;
            `}
            titleStyle={css`
              color: ${Colors.white};
              font-weight: 600;
            `}
          />

          <View
            style={css`
              margin-top: 32px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            `}
          >
            {revealResendCode ? (
              <TouchableOpacity
                onPress={() => {
                  navigation.goBack();
                  route.params.setRequestId(route.params.requestId);
                }}
              >
                <Text
                  style={css`
                    text-decoration: underline;
                  `}
                >
                  Resend code
                </Text>
              </TouchableOpacity>
            ) : (
              <Text>After 30 seconds you can resend another code.</Text>
            )}
          </View>
        </Container>
      </ScrollView>
    </Container>
  );
}

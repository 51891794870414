import { Button as RNEButton } from '@rneui/themed';
import { fontTypes, Colors } from '../utils/constants';

const Button = RNEButton;

const styles = {
  fullWidth: {
    width: '100%',
    elevation: 0,
  },
  default: {
    backgroundColor: Colors.brightblue,
    elevation: 0,
  },
  rounded: {
    borderRadius: 50,
    paddingHorizontal: 10,
    elevation: 0,
  },
  clear: {
    elevation: 0,
    ...fontTypes.bodyTitle,
  },
} as const;

export { Button as default, styles as ButtonStyles };

import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { ListItem } from '@rneui/themed';
import { css } from '@emotion/native';
import type { RouteProp } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import type { RootStackParamList } from '../../components/Navigation/types';
import Container from '../../components/Container';
import {
  isWeb,
  fontTypes,
  Colors,
  APPLICATION_MAX_WIDTH_INT,
  ListItemStyle,
} from '../../utils/constants';
import Header from '../../components/Header';

export const privacyOptions = [
  {
    value: 'public',
    leftIcon: 'public',
    title: 'Public',
    description: 'Everyone will be able to see this feedback',
    type: 'feedbackDeliverablePublicEnabled',
  },
  {
    value: 'private',
    leftIcon: 'lock',
    title: 'Private',
    description: 'Only the recipient will see this feedback',
    type: 'feedbackDeliverablePrivateEnabled',
  },
  {
    value: 'privateAndManager',
    leftIcon: 'lock',
    title: 'Private + manager',
    description: 'Only the recipient and their manager will see this feedback',
    type: 'feedbackDeliverableManagerAndPrivateEnabled',
  },
  {
    value: 'managerOnly',
    leftIcon: 'lock',
    title: 'Manager only',
    description: 'Only the recipient’s manager will see this feedback',
    type: 'feedbackDeliverableManagerOnlyEnabled',
  },
] as const;

type Props = {
  navigation: StackNavigationProp<RootStackParamList, 'Feedback Visibility'>;
  route: RouteProp<RootStackParamList, 'Feedback Visibility'>;
};

export default function FeedbackVisbility(props: Props) {
  const { navigation, route } = props;

  const enabledStates = route.params;

  return (
    <Container
      flex={1}
      style={css`
        background-color: ${Colors.faint};
      `}
    >
      <Header mode="BACK" title="Feedback visibility" containerStyle={{ width: '100%' }} />

      <View style={styles.wrapper}>
        {privacyOptions.map((option, index) => {
          if (enabledStates[option.type] === false) return null;

          return (
            <ListItem
              style={ListItemStyle}
              topDivider={index === 0}
              bottomDivider
              key={index}
              onPress={() => {
                route.params.updateVisibility(index);
                navigation.navigate('Give Feedback');
              }}
              Component={TouchableOpacity}
            >
              <MaterialIcons
                color={Colors.darkergray}
                name={option.leftIcon}
                style={{
                  marginRight: isWeb ? 16 : 0,
                }}
                size={24}
              />
              <ListItem.Content>
                <ListItem.Title>{option.title}</ListItem.Title>
                <ListItem.Subtitle style={fontTypes.bodySecondary}>
                  {option.description}
                </ListItem.Subtitle>
              </ListItem.Content>
            </ListItem>
          );
        })}
      </View>
    </Container>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    width: '100%',
    maxWidth: APPLICATION_MAX_WIDTH_INT,
    paddingTop: 24,
  },
});

import * as React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { noop } from 'lodash';
import { Avatar, ListItem, Text } from '@rneui/themed';
import { useNavigation } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';
import { css } from '@emotion/native';
import { MarkdownView } from 'react-native-markdown-view';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import { isWeb, Colors, fontTypes, isIOS } from '../../../utils/constants';
import { getLength, getInitials } from '../../../utils/fns';
import TalkingPointInput from './TalkingPointInput';
import PrivateNoteLink from './PrivateNoteLink';
import { connectDropdownAlert, Alert } from '../../../hocs/withDropdownAlert';
import Badge from '../../../components/Badge';
import Markdown from '../../../components/Markdown';
import { RootStackParamList } from '../../../components/Navigation/types';

import addTalkingPoint from '../../../mutations/addTalkingPointToNextOneOnOneMeeting';
import editTalkingPoint from '../../../mutations/editTalkingPointToNextOneOnOneMeeting';
import destroyTalkingPoint from '../../../mutations/destroyTalkingPoint';
import destroyOneOnOneTalkingPointComment from '../../../mutations/destroyOneOnOneTalkingPointComment';
import completeTalkingPoint from '../../../mutations/completeTalkingPoint';
import unCompleteTalkingPoint from '../../../mutations/unCompleteTalkingPoint';
/* eslint-disable import/no-unresolved */
// TS/Eslint don't understand that react-native is inferring these file types
// by which platform we're on .native.js .web.js .ios.js .android.js
// @ts-expect-error
import Confirm from '../../../components/Confirm';
/* eslint-enable */

import type { OneOnOneContent_oneOnOne } from 'jakiro-types/OneOnOneContent_oneOnOne.graphql';

export type Status = 'IS_LOADING' | 'NEW' | 'ONGOING' | 'DONE';

type Props = {
  userId: string;
  meetingId: string;
  status: Status;
  talkingPoints: OneOnOneContent_oneOnOne['talkingPoints'];
  checkinRelationshipId: string;
  oneOnOneId: string;
  nextOneOnOneId?: string | undefined;
  privateNote?: string | undefined;
  scrollViewRef?: any;
  dropdownAlert: Alert;
  setOneOnOne: (k: string | undefined) => void;
  otherParticipantNotes: {
    otherUser: { preferredName: string | undefined };
    note: string | undefined;
  };
  viewerNotes: {
    note: string | undefined;
  };
  isCancelled: string | undefined;
  isOneOnOnesDisabled: boolean;
};

const empyPoint = {
  body: '',
  completedAt: null,
  id: null,
};

function TalkingPoints(props: Props) {
  const {
    userId,
    meetingId,
    checkinRelationshipId,
    oneOnOneId,
    nextOneOnOneId = null,
    viewerNotes,
    status = 'NEW',
    talkingPoints,
    otherParticipantNotes,
    isCancelled,
    isOneOnOnesDisabled,
    privateNote,
    scrollViewRef,
    dropdownAlert,
    setOneOnOne,
  } = props;
  const isDone = status === 'DONE';

  const [editingPoint, setEditingPoint] = React.useState(empyPoint);
  const [newNote, setNewNote] = React.useState(viewerNotes.note);
  const [toDelete, setToDelete] = React.useState(null);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList, 'One On One'>>();

  let emptyInputPoint;
  const inputs = {};

  const onSuccess =
    (payloadName: string, focusOnEmptyInput: boolean, oneOnOneId?: string | undefined) =>
    (payload: any) => {
      if (getLength(payload[payloadName].errors)) {
        return onFailure();
      }
      // if oneOnOneId is null, we need to refetch as we just created a new one on one!
      if (oneOnOneId === null && payloadName === 'addTalkingPointToNextOneOnOneMeeting') {
        const createdId = payload[payloadName]?.talkingPoint?.meeting?.entityId;

        // we're intentionally not early returning here!
        // If we were to early return, our state wouldn't be fully reset
        // and after we've set the next one on one, we'd come in with a
        // duplicate talking point
        if (createdId) {
          setOneOnOne(createdId);
        }
      }

      setToDelete(null);

      setEditingPoint(empyPoint);

      focusOnEmptyInput && emptyInputPoint && emptyInputPoint.focus();
    };

  const onFailure = () => {
    setToDelete(null);

    setEditingPoint(empyPoint);

    dropdownAlert({
      type: 'error',
      title: 'Whoops, something is not right.',
      body: 'There was an error with this talking point, please try again. If the problem persist, contact customer support.',
    });
  };

  const deletePoint = (id?: string) => {
    destroyTalkingPoint.commit(
      {
        talkingPointId: (typeof id === 'string' && id) || toDelete || '',
      },
      onSuccess('destroyTalkingPoint', false),
      onFailure,
      oneOnOneId,
    );
  };

  const sanitizeNote = (note: string | undefined) => {
    if (note === null) return '';

    return String(note).replace(/<br>/g, '\n');
  };

  const handlePointChange = (value: string, pointId: string | undefined) => {
    const hasLength = !!getLength(value);

    setEditingPoint({
      id: pointId,
      body: value,
      completedAt: null,
    });

    if (!hasLength && pointId) {
      deletePoint(pointId || '');
    }
  };

  const handleCheckBox = (talkingPointId: string, isChecked: boolean) => {
    if (!talkingPointId) return;

    const noop = () => {};

    !isChecked
      ? completeTalkingPoint.commit({ talkingPointId }, noop, onFailure)
      : unCompleteTalkingPoint.commit({ talkingPointId }, noop, onFailure);
  };

  const onSubmit = (focusOnEmptyInput: boolean) => {
    if (editingPoint.id) {
      // TODO: if not body then detroy
      if (!editingPoint.body.length || !editingPoint.id) return;

      editTalkingPoint.commit(
        {
          body: editingPoint.body.trim(),
          talkingPointId: editingPoint.id,
        },
        onSuccess('editTalkingPointToNextOneOnOneMeeting', false),
        onFailure,
      );
    } else {
      if (!editingPoint.body.length || !editingPoint.body.trim().length) return;

      const vars = {
        body: editingPoint.body.trim(),
        checkinRelationshipId,
        meetingId: nextOneOnOneId === oneOnOneId ? undefined : oneOnOneId,
        /* Weird right?
        Why are you comparing these ids?
        There are somes bugs with Relay with this architecture.
        First, for the OneOnOneContent to be resuable on other situations.
        It must be able to update the store solely depending on
        a field name and a parentId.

        When we receive a new 1:1 it doesn't have a 1:1 id, it
        has a next 1:1 id. If we use the next 1:1 id, at first it's fine
        but once the 1:1 starts by adding a new Talking Point it won't
        be added to the current 1:1 being seen on the view, it would be added to
        the next 1:1 which is not the current.

        The next 1:1 is auto generated once the lastest 1:1 is started.
        */
      };

      addTalkingPoint.commit(
        vars,
        onSuccess('addTalkingPointToNextOneOnOneMeeting', focusOnEmptyInput, oneOnOneId),
        onFailure,
        oneOnOneId,
      );
    }
  };

  return (
    <View style={styles.view}>
      <View style={{ padding: 20 }}>
        <View
          style={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 15px;
          `}
        >
          <Text style={[fontTypes.title, { marginRight: 8 }]}>Talking points</Text>
          {isCancelled && <Badge variant="warning">Cancelled</Badge>}
        </View>
        {talkingPoints.map((point, index) => {
          const comments = point.comments ?? [];

          return (
            <View
              key={point.id}
              style={css`
                margin-bottom: 24px;
              `}
            >
              <TalkingPointInput
                getInputRef={e => {
                  if (index != null) {
                    inputs[index] = e;
                  }
                }}
                blurOnSubmit
                updatePosition={() => {
                  isIOS && scrollViewRef && scrollViewRef.update();
                }}
                status={status}
                user={point.creator || undefined}
                toggleCheckBox={handleCheckBox}
                id={point.id}
                onSubmit={onSubmit}
                onChange={handlePointChange}
                value={editingPoint.id === point.id ? editingPoint.body : point.body || ''}
                completed={!!point.completedAt}
                onDelete={(id: string) => {
                  Confirm({
                    title: 'Remove talking point',
                    description: 'Are you sure you want to delete this talking point?',
                    onConfirm: () => deletePoint(id),
                  });
                }}
                isDisabled={isDone || !point.creator.viewerIsUser}
                isOneOnOnesDisabled={isOneOnOnesDisabled}
              />

              <View
                style={css`
                  margin-left: ${isWeb ? '32px' : '40px'};
                `}
              >
                {comments.length > 0 &&
                  comments.map(t => (
                    <ListItem
                      containerStyle={css`
                        padding-left: 0;
                        padding-right: 0;
                        padding-bottom: 8px;
                        align-items: flex-start;
                      `}
                      Component={View}
                      key={t.id}
                    >
                      <Avatar
                        source={{ uri: t.user.avatarUrl }}
                        title={getInitials(t.user.name)}
                        size={20}
                        containerStyle={{ marginTop: 2 }}
                      />
                      <ListItem.Content>
                        <ListItem.Title>
                          <View style={{ marginLeft: isWeb ? 8 : 0 }}>
                            <Markdown
                              // onPress has issues passing the event to it's parent so we'll need to capture
                              // the onPress event here. It's a strange since we are doing it in the ListItem title
                              // but this makes it work. If you can fix Markdown's onPress to bubble up events feel
                              // free to fix this.
                              onPress={() => {
                                if (t.user.viewerIsUser && !isOneOnOnesDisabled) {
                                  navigation.navigate('Talking Point Comment', {
                                    userId,
                                    meetingId,
                                    talkingPointCommentId: t.id,
                                    talkingPointBody: point?.body,
                                    talkingPointId: point?.id,
                                    comment: t.body,
                                  });
                                }
                              }}
                            >
                              {t.body}
                            </Markdown>
                          </View>
                        </ListItem.Title>
                      </ListItem.Content>
                      {t.user.viewerIsUser && (
                        <MaterialIcons
                          name="close"
                          color={Colors.icongray}
                          size={20}
                          onPress={() => {
                            const talkingPointId = point?.id;
                            const talkingPointCommentId = t.id;

                            if (!talkingPointId) return null;

                            Confirm({
                              title: 'Remove comment',
                              description: 'Are you sure you want to delete this comment?',
                              onConfirm: () => {
                                destroyOneOnOneTalkingPointComment.commit(
                                  {
                                    talkingPointId,
                                    talkingPointCommentId,
                                  },
                                  noop,
                                  () => {
                                    dropdownAlert({
                                      type: 'error',
                                      title: 'Whoops, something is not right.',
                                      body: 'There was an error with this comment, please try again. If the problem persist, contact customer support.',
                                    });
                                  },
                                );
                              },
                            });
                          }}
                        />
                      )}
                    </ListItem>
                  ))}

                {!isOneOnOnesDisabled && (
                  <TouchableOpacity
                    style={css`
                      margin-top: 8px;
                      margin-bottom: 16px;
                    `}
                    onPress={() => {
                      navigation.navigate('Talking Point Comment', {
                        userId,
                        meetingId,
                        talkingPointBody: point?.body,
                        talkingPointId: point?.id,
                        comment: '',
                      });
                    }}
                  >
                    <Text style={[fontTypes.bodySecondary, { color: Colors.blue }]}>
                      + Add comment
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          );
        })}
        {status !== 'DONE' && !isOneOnOnesDisabled && (
          <View
            style={css`
              margin-bottom: 24px;
            `}
          >
            <TalkingPointInput
              id=""
              key="new-one"
              blurOnSubmit={false}
              updatePosition={() => {
                isIOS && scrollViewRef && scrollViewRef.update();
              }}
              getInputRef={i => {
                emptyInputPoint = i;
              }}
              status={status}
              toggleCheckBox={handleCheckBox}
              onSubmit={onSubmit}
              onChange={handlePointChange}
              value={editingPoint.id ? '' : editingPoint.body}
              completed={false}
              onDelete={() => null}
              isDisabled={isDone}
              isOneOnOnesDisabled={isOneOnOnesDisabled}
            />
          </View>
        )}
        {isOneOnOnesDisabled && !talkingPoints.length && (
          <Text style={[styles.secondaryFont, { marginHorizontal: 0 }]}>No talking points</Text>
        )}
      </View>
      {oneOnOneId !== null && (
        <React.Fragment>
          {getLength(otherParticipantNotes.note) > 0 && (
            <View style={styles.container}>
              <Text
                style={{
                  ...fontTypes.subHeaderBodyTitle,
                  marginBottom: 16,
                  fontFamily: 'SFProDisplay-SemiBold',
                }}
              >
                {otherParticipantNotes.otherUser.preferredName}&apos;s shared notes
              </Text>
              <MarkdownView style={[fontTypes.body, { marginHorizontal: 0, marginBottom: 16 }]}>
                {sanitizeNote(otherParticipantNotes.note)}
              </MarkdownView>
            </View>
          )}
          <TouchableOpacity
            onPress={() => {
              if (isDone === false) {
                navigation.navigate('Shared Notes', {
                  userId,
                  meetingId,
                  note: newNote,
                  meetingStatus: status,
                  optimisticSharedNoteUpdate: (n: string) => {
                    setNewNote(n);
                  },
                });
              }
            }}
            activeOpacity={1}
            style={styles.container}
            disabled={isOneOnOnesDisabled}
          >
            <Text
              style={{
                ...fontTypes.subHeaderBodyTitle,
                marginBottom: 16,
                fontFamily: 'SFProDisplay-SemiBold',
              }}
            >
              Your shared notes
            </Text>
            {getLength(newNote) > 0 || !isOneOnOnesDisabled ? (
              <MarkdownView style={[fontTypes.body, { marginHorizontal: 0, marginBottom: 16 }]}>
                {sanitizeNote(newNote) || 'Write a shared note'}
              </MarkdownView>
            ) : (
              <Text style={[styles.secondaryFont, { marginHorizontal: 0 }]}>No shared note</Text>
            )}
          </TouchableOpacity>
        </React.Fragment>
      )}

      {oneOnOneId !== null && (privateNote || !isOneOnOnesDisabled) && (
        <PrivateNoteLink
          onPress={() => {
            navigation.navigate('Private Notes', {
              userId,
              meetingId,
              viewerPrivateNote: privateNote,
              meetingStatus: status,
              isOneOnOnesDisabled,
            });
          }}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  view: { flex: 1, width: '100%', backgroundColor: 'transparent' },
  container: {
    position: 'relative',
    borderTopWidth: 0.5,
    borderTopColor: Colors.lightgray,
    padding: 20,
  },
  secondaryFont: {
    fontSize: 15,
    color: Colors.darkergray,
    fontStyle: 'italic',
  },
});

export default connectDropdownAlert(TalkingPoints);

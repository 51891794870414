import { commitMutation, graphql } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';

const mutation = graphql`
  mutation addReviewResponseMutation($input: AddReviewResponseInput!) {
    addReviewResponse(input: $input) {
      reviewResponse {
        content
      }
      reviewRequestQuestion {
        id
      }
      errors
    }
  }
`;

type Args = {
  reviewRequestQuestion: {
    id: string;
  };
  // See 'ReviewResponseContent' type
  content: {
    body?: string;
    multipleSelect?: string[];
    rating?: string;
  };
  isRejected: boolean;
};

export function commit(args: Args, onCompleted: any, onError: any) {
  const variables = {
    input: {
      reviewRequestQuestionId: args.reviewRequestQuestion.id,
      content: args.content,
      isRejected: args.isRejected,
    },
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

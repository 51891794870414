import { StyleSheet, View } from 'react-native';

import Tag from '../../../components/Tag';

type Props = {
  tags: {
    name: string;
  }[];
};

const TagsList = ({ tags }: Props) => {
  return (
    <View style={styles.wrapperStyle}>
      {tags.map((tag, i) => (
        <View key={i} style={styles.itemWrapper}>
          <Tag label={tag.name} />
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapperStyle: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
  },
  itemWrapper: {
    marginRight: 10,
  },
});

export default TagsList;

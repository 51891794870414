import * as React from 'react';
import { css } from '@emotion/native';
import cookie from 'cookie-cutter';

import document from 'global/document';

import Button from '../../components/Button';
import Container from '../../components/Container';
import { isWeb, fontTypes, Colors } from '../../utils/constants';
import { requestDesktopView, PREFERS_MOBILE_COOKIE } from '../../utils/requestDesktopView';
import Navigation from './Navigation';

const Cookie = cookie(document);

export default function WebContextContainer({ isLoggedIn }) {
  React.useEffect(() => {
    const prefersMobileOption = Cookie.get(PREFERS_MOBILE_COOKIE);

    if (prefersMobileOption === 'never') requestDesktopView();
  }, []);

  return (
    <>
      <Navigation isLoggedIn={isLoggedIn} />

      {isWeb && (
        <Container
          style={css`
            width: 100%;
            border-top: 1px solid ${Colors.border};
          `}
        >
          <Button
            title="VIEW DESKTOP WEBSITE"
            titleStyle={css`
              ${fontTypes.body_semi_bold};
              font-size: 13px;
            `}
            buttonStyle={css`
              background-color: transparent;
            `}
            onPress={requestDesktopView}
          />
        </Container>
      )}
    </>
  );
}

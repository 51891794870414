import { graphql, commitMutation } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';

import type { createCheckinMutationVariables } from 'jakiro-types/createCheckinMutation.graphql';

const mutation = graphql`
  mutation createCheckinMutation($input: CreateCheckinInput!) {
    createCheckin(input: $input) {
      checkin {
        id
      }
      user {
        id
      }
      errors
    }
  }
`;

export function commit(
  args: createCheckinMutationVariables['input'],
  onCompleted: any,
  onError: any,
) {
  const variables = {
    input: {
      ...args,
    },
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

import { TouchableOpacity } from 'react-native';
import { Text, ListItem } from '@rneui/themed';
import Ionicons from '@expo/vector-icons/Ionicons';

import { Colors, fontTypes } from '../../../utils/constants';
import Container from '../../../components/Container';
import { ListItemChevron } from '../../../components/ListItemChevron';

const SecretText = () => (
  <Container justify="flex-start" direction="row">
    <Text
      style={{
        ...fontTypes.bodyTitleRegular,
        color: Colors.darkergray,
        marginRight: 10,
      }}
    >
      Private note
    </Text>
    <Ionicons size={15} color={Colors.darkergray} name="lock-closed" />
  </Container>
);

type Props = {
  onPress: (p: any) => void;
};

const PrivateNoteLink = ({ onPress }: Props) => (
  <ListItem
    onPress={onPress}
    containerStyle={{ backgroundColor: Colors.lighteryellow, height: 60 }}
    Component={TouchableOpacity}
  >
    <ListItem.Content>
      <ListItem.Title>
        <SecretText />
      </ListItem.Title>
    </ListItem.Content>
    <ListItemChevron color={Colors.icongray} />
  </ListItem>
);

export default PrivateNoteLink;

import * as React from 'react';
import styled from '@emotion/native';

import { fontTypes } from '../../utils/constants';
import getColor from '../../utils/getSelectedColor';
import type { Tabs } from '.';

type Props = {
  handlePress: (l: Tabs) => void;
  isSelected: boolean;
  label: Tabs;
  icon: React.ReactNode;
};

const TabButton = ({ isSelected, handlePress, icon, label }: Props) => (
  <Tab onPress={() => handlePress(label)}>
    {icon}

    <Label style={{ color: getColor(isSelected) }}>{label}</Label>
  </Tab>
);

const Tab = styled.TouchableOpacity`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Label = styled.Text`
  ${fontTypes.body_semi_bold};
  font-size: 13px;
`;

export default TabButton;

import { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { graphql, createRefetchContainer } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';
import { css } from '@emotion/native';
import { FormattedDate } from 'react-intl-native';
import { ListItem } from '@rneui/themed';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import type { RootStackParamList } from '../../components/Navigation/types';
import LoadMore from '../../components/LoadMore';
import EmptyState from '../../components/EmptyState';
import { getLength, cleanConnection, getFullWeekDates } from '../../utils/fns';
import { Colors, fontTypes } from '../../utils/constants';
import { jsDateFromDateString } from './';

import type { PastUpdatesList_user } from 'jakiro-types/PastUpdatesList_user.graphql';

type Props = {
  user: PastUpdatesList_user;
  relay: any;
};

const ONE_YEAR = 1000 * 60 * 60 * 24 * 365;

function PastUpdatesList(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList, 'Updates'>>();

  const { user, relay } = props;
  const checkinsConnection = user.checkins;
  const checkins = cleanConnection(checkinsConnection);

  if (!checkins) {
    return <EmptyState title="No past updates" body="You have no past updates." />;
  }

  return (
    <>
      {checkins.length > 0 ? (
        checkins.map((node, index) => {
          if (!node.weekId) return null;

          const { weekStartDate, weekEndDate } = getFullWeekDates(node.weekId);

          const periodEnd = jsDateFromDateString(weekEndDate);
          const periodStart = jsDateFromDateString(weekStartDate);

          const now = new Date();
          // @ts-expect-error
          const year = now - periodStart > ONE_YEAR ? 'numeric' : undefined;

          return (
            <ListItem
              key={index}
              Component={TouchableOpacity}
              bottomDivider
              onPress={() => navigation.navigate('Update', { weekId: node.weekId })}
            >
              <ListItem.Content>
                <ListItem.Title
                  style={css`
                    ${fontTypes.bodyTitleRegular};
                    color: ${Colors.darkestgray};
                  `}
                >
                  <Text>
                    <FormattedDate day="numeric" month="short" year={year} value={periodStart} />
                    {' - '}
                    <FormattedDate year={year} day="numeric" month="short" value={periodEnd} />
                  </Text>
                </ListItem.Title>
              </ListItem.Content>

              <Text>
                <MaterialIcons color={Colors.icongray} name="chevron-right" size={24} />
              </Text>
            </ListItem>
          );
        })
      ) : (
        <View
          style={css`
            padding-top: 60px;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Text>No previous updates</Text>
        </View>
      )}

      {checkinsConnection.pageInfo.hasNextPage && (
        <LoadMore
          isLoading={isLoading}
          handleLoadMore={() => {
            setIsLoading(true);

            const count = getLength(checkinsConnection.edges);

            const refetchVariables = () => ({
              first: count + 10,
            });

            relay.refetch(refetchVariables, null, () => {
              setIsLoading(false);
            });
          }}
        />
      )}
    </>
  );
}

export default createRefetchContainer(
  PastUpdatesList,
  {
    user: graphql`
      fragment PastUpdatesList_user on User @argumentDefinitions(first: { type: "Int!" }) {
        checkins(first: $first) {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              weekId
            }
          }
        }
      }
    `,
  },
  graphql`
    query PastUpdatesListRefetchQuery($first: Int!) {
      viewer {
        user {
          ...PastUpdatesList_user @arguments(first: $first)
        }
      }
    }
  `,
);

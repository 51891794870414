import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  Linking,
  Share,
  StyleSheet,
  View,
  SectionList,
  Text,
  ScrollView,
  TouchableOpacity,
  Switch,
} from 'react-native';
import { ListItem } from '@rneui/themed';
import { graphql } from 'react-relay';
import * as Notifications from 'expo-notifications';
import { css } from '@emotion/native';
import type { StackNavigationProp } from '@react-navigation/stack';

import { ListItemChevron } from '../../components/ListItemChevron';
import UserProfile from '../../components/UserProfile';
import Header from '../../components/Header';
/* eslint-disable import/no-unresolved */
// TS/Eslint don't understand that react-native is inferring these file types
// by which platform we're on .native.js .web.js .ios.js .android.js
// @ts-expect-error
import Confirm from '../../components/Confirm';
/* eslint-enable */
import { connectDropdownAlert, Alert } from '../../hocs/withDropdownAlert';
import {
  fontTypes,
  Colors,
  isIOS,
  isWeb,
  OS,
  HAS_ASKED_FOR_NOTIFICATION_PERMISSION,
  APPLICATION_MAX_WIDTH_INT,
  ListItemStyle,
} from '../../utils/constants';
import { pify } from '../../utils/fns';
import createQueryRenderer from '../../utils/createQueryRenderer';
import { requestDesktopView } from '../../utils/requestDesktopView';
import { getInstallationId } from '../../utils/getInstallationId';

import type { settingsQueryResponse } from 'jakiro-types/settingsQuery.graphql';

import createNativeDeviceToken from '../../mutations/createNativeDeviceToken';
import toggleDeviceNotifications from '../../mutations/toggleNativeDeviceNotifications';
import * as LogoutMutation from '../../mutations/logout';
import type { RootStackParamList } from '../../components/Navigation/types';
import useAuthentication from '../../hooks/useAuthentication';

// allow these mutations to resolve promises
const createNativeToken = pify(createNativeDeviceToken.commit);
const toggleNotifications = pify(toggleDeviceNotifications.commit);

const sendUsFeedback = () => {
  const email = 'support@lattice.com';
  const subject = `Lattice ${isIOS ? 'iOS' : 'Android'} feedback`;

  Linking.openURL(`mailto:${email}?subject=${subject}`);
};

const shareWithTeam = () => {
  const title = 'Share Lattice with your team!';
  const message = `Get the Lattice mobile app at: https://lattice.com/mobile`;

  const options = isIOS ? { subject: title } : { dialogTitle: title };

  Share.share({ title, message }, options);
};

type Props = {
  dropdownAlert: Alert;
  navigation: StackNavigationProp<RootStackParamList, 'Admin'>;
} & settingsQueryResponse;

const allowlist = [
  'ryan@lattice.com',
  'jack.hanford@lattice.com',
  'eric@lattice.com',
  'richard@piedpiper.com',
  'jack@mailinator.com',
  'bill@lattice.com',
];

function Settings(props: Props) {
  const { viewer, dropdownAlert, navigation } = props;

  const [deviceId, setDeviceId] = useState(null);

  const [notificationsEnabled, setNotificationsEnabled] = useState(
    (viewer?.user.nativeDeviceTokens || []).some(
      ({ notificationsEnabled, deviceUniqueId }) =>
        deviceUniqueId === deviceId && notificationsEnabled,
    ),
  );
  const { logOut } = useAuthentication();

  useEffect(() => {
    const findDeviceId = async () => {
      const deviceUniqueId = await getInstallationId();
      setDeviceId(deviceUniqueId);
    };
    findDeviceId();
  }, []);

  if (!viewer) return null;
  const { user } = viewer;
  if (!user) return null;

  const slug = viewer.company.slug;
  const isLatticeEmployee = slug === 'latticesandbox' || slug === 'lattice' || slug === 'piedpiper';
  const isAllowListed = allowlist.includes(user.email);

  const sections = [
    {
      title: 'Settings',
      data: [
        isWeb && {
          key: 'view-desktop-website',
          title: 'View desktop website',
          onPress: requestDesktopView,
          chevron: { color: Colors.darkgray },
          topDivider: true,
          bottomDivider: false,
          danger: false,
        },
        {
          key: 'feedback',
          title: 'Send us feedback',
          onPress: sendUsFeedback,
          chevron: { color: Colors.darkgray },
          topDivider: isWeb === false,
          danger: false,
        },
        !isWeb && {
          key: 'push-notifications',
          danger: false,
          component: () => (
            <ListItem
              style={ListItemStyle}
              containerStyle={{ ...styles.listItem, paddingVertical: 8 }}
            >
              <ListItem.Content>
                <ListItem.Title>Push notifications</ListItem.Title>
              </ListItem.Content>
              <Switch
                style={{ marginLeft: 'auto' }}
                value={notificationsEnabled}
                onValueChange={async (value: boolean) => {
                  setNotificationsEnabled(value);

                  const devices = user.nativeDeviceTokens || [];

                  // the bottom conditional should rarely be invoked. The case we're account for is when
                  // user A downloads the app, signs in, agress to push notifications, logs out and
                  // user B signs in on the device, navigates to settings and toggles them.
                  if (!devices.length) {
                    const { status: existingStatus } = await Notifications.getPermissionsAsync();

                    let finalStatus = existingStatus;

                    // only ask if permissions have not already been determined, because
                    // iOS won't necessarily prompt the user a second time.
                    if (existingStatus !== 'granted') {
                      // Android remote notification permissions are granted during the app
                      // install, so this will only ask on iOS
                      const { status } = await Notifications.requestPermissionsAsync();
                      finalStatus = status;
                    }

                    // if the user rejects the prompt .. let's return early?
                    if (finalStatus !== 'granted') return;

                    let { data: token } = await Notifications.getExpoPushTokenAsync();

                    try {
                      await createNativeToken({
                        deviceToken: token,
                        deviceUniqueId: deviceId,
                        deviceOs: OS(),
                      });
                    } catch (err) {
                      dropdownAlert({
                        type: 'error',
                        title: 'Whoops, something is not right.',
                        body: 'There was an error toggling push notifications, please try again.',
                      });
                    }
                  }

                  try {
                    await toggleNotifications({
                      notificationsEnabled: value,
                      deviceUniqueId: deviceId,
                    });
                  } catch (err) {
                    dropdownAlert({
                      type: 'error',
                      title: 'Whoops, something is not right.',
                      body: 'There was an error toggling push notifications, please try again.',
                    });
                    // if we couldnt perform the mutation, set the <Switch /> back to the the value before this fn was called
                    setNotificationsEnabled(!value);
                  }
                }}
              />
            </ListItem>
          ),
        },
      ].filter(Boolean),
    },
    {
      title: 'Other',
      data: [
        !isWeb && {
          key: 'share',
          title: 'Share the app with team',
          onPress: shareWithTeam,
          chevron: { color: Colors.darkgray },
          topDivider: true,
        },
        isLatticeEmployee && isAllowListed
          ? {
              key: 'admin',
              title: 'Admin',
              topDivider: isWeb,
              danger: false,
              chevron: { color: Colors.darkgray },
              onPress: () =>
                navigation.push('Admin', {
                  email: user.email,
                  userId: user.entityId,
                }),
            }
          : null,
        {
          topDivider: isWeb && !(isLatticeEmployee && isAllowListed),
          key: 'logout',
          title: 'Log out',
          danger: true,
          onPress: () => {
            Confirm({
              title: 'Logging out',
              description: 'Are you sure you want to log out of your Lattice account?',
              onConfirm: async () => {
                await LogoutMutation.commit();
                await AsyncStorage.removeItem(HAS_ASKED_FOR_NOTIFICATION_PERMISSION);
                await logOut();
              },
            });
          },
        },
      ].filter(Boolean),
    },
  ];

  return (
    <View
      style={css`
        flex: 1;
      `}
    >
      <Header title="Settings" mode="CLOSE" />
      <ScrollView
        style={css`
          background-color: ${Colors.faint};
          display: flex;
          flex: 1;
          width: 100%;
        `}
      >
        <View style={styles.wrapper}>
          <View style={styles.profileWrapper}>
            <UserProfile
              onPressAvatar={() => {}}
              avatarUrl={user.avatarUrl}
              title={user.name}
              subtitle={user.email}
            />
          </View>
          <View style={styles.sectionListWrapper}>
            <SectionList
              // @ts-expect-error
              sections={sections}
              renderSectionHeader={({ section: { title } }) => (
                <Text style={styles.sectionHeader}>{title}</Text>
              )}
              renderItem={({ item }) => {
                // hopefully only temporarily needing support this, react-native-elements@beta#5
                if (item.component) {
                  return item.component();
                }

                return (
                  <ListItem
                    style={ListItemStyle}
                    Component={TouchableOpacity}
                    topDivider={item.topDivider || false}
                    containerStyle={styles.listItem}
                    onPress={item.onPress ? () => item.onPress() : null}
                  >
                    <ListItem.Content>
                      <ListItem.Title style={item?.danger && styles.dangerTitle}>
                        {item.title}
                      </ListItem.Title>
                    </ListItem.Content>
                    {item.chevron && <ListItemChevron color={item.chevron.color} />}
                  </ListItem>
                );
              }}
              bounces={false}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    maxWidth: APPLICATION_MAX_WIDTH_INT,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  profileWrapper: {
    marginTop: 20,
    paddingTop: 16,
    paddingLeft: 20,
  },
  sectionListWrapper: {
    flex: 1,
  },
  sectionHeader: {
    ...fontTypes.title,
    fontWeight: 'bold',
    marginTop: 32,
    marginBottom: 10,
    paddingLeft: 20,
  },
  listItem: {
    paddingVertical: 16,
    paddingRight: 20,
    paddingLeft: 20,
    borderBottomWidth: 1,
    borderBottomColor: Colors.border,
  },
  dangerTitle: {
    color: Colors.red,
  },
});

const hasDropDown = connectDropdownAlert(Settings);

export default createQueryRenderer(hasDropDown, Settings, {
  query: graphql`
    query settingsQuery {
      viewer {
        company {
          slug
          features
        }
        user {
          entityId
          email
          avatarUrl
          name
          nativeDeviceTokens {
            notificationsEnabled
            deviceUniqueId
          }
        }
      }
    }
  `,
});

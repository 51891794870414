import { StyleSheet } from 'react-native';
import { createFragmentContainer, graphql } from 'react-relay';

import Container from '../../Container';
import QuantitativeIcon from './QuantitativeIcon';
import QualitativeIcon from './QualitativeIcon';

import type { GoalBadge_goal } from 'jakiro-types/GoalBadge_goal.graphql';

type Props = {
  backgroundColor: string;
  accessibleColor: string;
  goal: GoalBadge_goal;
};

const GoalBadge = ({ backgroundColor, accessibleColor, goal }: Props) => {
  return (
    <Container align="flex-end" style={[styles.badgeContainer, { backgroundColor }]}>
      {goal.type === 'qualitative' ? (
        <QualitativeIcon color={accessibleColor} state={goal.state} status={goal.status} />
      ) : (
        <QuantitativeIcon color={accessibleColor} value={goal.computedProgressAmount} />
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  badgeContainer: {
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 40,
    height: 40,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 8,
    paddingLeft: 27,
  },
});

export default createFragmentContainer(GoalBadge, {
  goal: graphql`
    fragment GoalBadge_goal on Goal {
      computedProgressAmount
      entityId
      id
      state
      status
      type: computedType
    }
  `,
});

import * as React from 'react';
import { graphql } from 'react-relay';
import { ScrollView } from 'react-native';

import createQueryRenderer from '../../utils/createQueryRenderer';
import PastOneOnOneList from './PastOneOnOneList';
import Header from '../../components/Header';
import type { pastOneOnOnesQueryResponse } from 'jakiro-types/pastOneOnOnesQuery.graphql';

type Props = pastOneOnOnesQueryResponse;

const PastOneOnOnes = ({ viewer }: Props) => {
  if (!viewer || !viewer.managerCheckinRelationship) return null;
  const userId = viewer.user.entityId;

  return (
    <React.Fragment>
      <Header mode="BACK" title="Previous 1:1s" />

      <ScrollView style={{ flex: 1 }}>
        <PastOneOnOneList
          checkinRelationship={viewer.managerCheckinRelationship}
          userId={userId}
          viewerIsUser
        />
      </ScrollView>
    </React.Fragment>
  );
};

export default createQueryRenderer(PastOneOnOnes, PastOneOnOnes, {
  query: graphql`
    query pastOneOnOnesQuery($first: Int!) {
      viewer {
        user {
          entityId
        }
        managerCheckinRelationship {
          ...PastOneOnOneList_checkinRelationship @arguments(first: $first)
        }
      }
    }
  `,
  queriesParams: () => ({ first: 20 }),
});

/**
 * @generated SignedSource<<e9a10ed0778445021d881f3edeafd82d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type adminQuery$variables = {};
export type adminQueryVariables = adminQuery$variables;
export type adminQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly nativeDeviceTokens: ReadonlyArray<{
        readonly deviceUniqueId: string;
        readonly deviceToken: string;
      }>;
    };
  } | null;
};
export type adminQueryResponse = adminQuery$data;
export type adminQuery = {
  variables: adminQueryVariables;
  response: adminQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceUniqueId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceToken",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "adminQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NativeDeviceToken",
                "kind": "LinkedField",
                "name": "nativeDeviceTokens",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "adminQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NativeDeviceToken",
                "kind": "LinkedField",
                "name": "nativeDeviceTokens",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "156dbd0d3fdc432df9ed4f30dabd83fe",
    "id": null,
    "metadata": {},
    "name": "adminQuery",
    "operationKind": "query",
    "text": "query adminQuery {\n  viewer {\n    user {\n      nativeDeviceTokens {\n        deviceUniqueId\n        deviceToken\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "33eb240edd6497eba6fbaa96e65b7a36";

export default node;

/**
 * @generated SignedSource<<89215c4c766aca6698c0aea6a9e9749a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GoalProfileKeyResultsTab_metricsList$data = {
  readonly childGoals: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"GoalCard_goal">;
  } | null> | null;
  readonly " $fragmentType": "GoalProfileKeyResultsTab_metricsList";
};
export type GoalProfileKeyResultsTab_metricsList = GoalProfileKeyResultsTab_metricsList$data;
export type GoalProfileKeyResultsTab_metricsList$key = {
  readonly " $data"?: GoalProfileKeyResultsTab_metricsList$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoalProfileKeyResultsTab_metricsList">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalProfileKeyResultsTab_metricsList",
  "selections": [
    {
      "alias": "childGoals",
      "args": null,
      "concreteType": "Goal",
      "kind": "LinkedField",
      "name": "childrenList",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GoalCard_goal"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Goal",
  "abstractKey": null
};

(node as any).hash = "b33d8e07d31ec78f862a9725ae516bee";

export default node;

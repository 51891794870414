import { graphql, commitMutation } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';
import type { SubmitFeedbackInput } from 'jakiro-types/submitFeedbackMutation.graphql';

const mutation = graphql`
  mutation submitFeedbackMutation($input: SubmitFeedbackInput!) {
    submitFeedback(input: $input) {
      viewer {
        user {
          viewerVisibleFeedback(first: 20, feedbackDirection: GIVEN) {
            edges {
              node {
                id
                ... on FeedbackInterface {
                  __typename
                  body
                  createdAt
                  author {
                    avatarUrl
                    name
                    preferredName
                  }
                }
              }
            }
          }
        }
      }
      submittedFeedback {
        targets {
          receivedViewerVisibleFeedback: viewerVisibleFeedback(
            first: 20
            feedbackDirection: RECEIVED
          ) {
            edges {
              node {
                id
                ... on FeedbackInterface {
                  __typename
                  body
                  createdAt
                  author {
                    avatarUrl
                    name
                    preferredName
                  }
                }
              }
            }
          }
          givenToUserByViewerVisibleFeedback: viewerVisibleFeedback(
            first: 20
            feedbackDirection: GIVEN_TO_USER_BY_VIEWER
          ) {
            edges {
              node {
                id
                ... on FeedbackInterface {
                  __typename
                  body
                  createdAt
                  author {
                    avatarUrl
                    name
                    preferredName
                  }
                }
              }
            }
          }
        }
      }
      errors
    }
  }
`;

function commit(args: SubmitFeedbackInput, onCompleted: any, onError: any) {
  const variables = {
    input: {
      targetUserIds: args.targetUserIds,
      body: args.body,
      companyValueIds: args.companyValueIds,
      deliverableType: args.deliverableType,
      via: 'mobile',
    },
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

export default { commit };

/**
 * @generated SignedSource<<f9c90a4a53defc6c7c914903669399c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DirectoryDepartments_viewer$data = {
  readonly company: {
    readonly departmentsConnection: {
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly entityId: string;
          readonly name: string | null;
          readonly members: {
            readonly total: number | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  };
  readonly " $fragmentType": "DirectoryDepartments_viewer";
};
export type DirectoryDepartments_viewer = DirectoryDepartments_viewer$data;
export type DirectoryDepartments_viewer$key = {
  readonly " $data"?: DirectoryDepartments_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DirectoryDepartments_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 15,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": [
        "ACTIVE"
      ],
      "kind": "LocalArgument",
      "name": "status"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DirectoryDepartments_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "after",
              "variableName": "after"
            },
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "first"
            }
          ],
          "concreteType": "companyDepartmentsConnection",
          "kind": "LinkedField",
          "name": "departmentsConnection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "companyDepartmentsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Department",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "entityId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 1
                        },
                        {
                          "kind": "Variable",
                          "name": "status",
                          "variableName": "status"
                        }
                      ],
                      "concreteType": "departmentMembersConnection",
                      "kind": "LinkedField",
                      "name": "members",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "total",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "c0483207eec02317b5bf75b1ff728d30";

export default node;

import * as React from 'react';

const DropdownAlertContext = React.createContext({});

export type AlertMessage = {
  type: 'info' | 'warn' | 'error' | 'success';
  title: string;
  body: string;
};
export type Alert = (alert: AlertMessage) => void;

export const provideDropdownAlert = (Component: React.ComponentType<any>) =>
  class extends React.Component<any, {}> {
    dropdownAlert: any;

    alert = ({ type, title, body }: AlertMessage) => {
      this.dropdownAlert && this.dropdownAlert.alertWithType(type, title, body);
    };

    setDropdown = (ref: any) => (this.dropdownAlert = ref);

    render() {
      return (
        <DropdownAlertContext.Provider
          value={{
            dropdownAlert: this.alert,
          }}
        >
          <Component {...{ setDropdown: this.setDropdown, ...this.props }} />
        </DropdownAlertContext.Provider>
      );
    }
  };

export const connectDropdownAlert = (Component: React.ComponentType<any>) => (props: any) =>
  (
    <DropdownAlertContext.Consumer>
      {values => {
        return <Component {...values} {...props} />;
      }}
    </DropdownAlertContext.Consumer>
  );

/**
 * @generated SignedSource<<54fa1e1605e6278e88446fbf50aa9181>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleNativeDeviceNotificationsInput = {
  clientMutationId?: string | null;
  deviceUniqueId?: string | null;
  notificationsEnabled: boolean;
};
export type toggleNativeDeviceNotificationsMutation$variables = {
  input: ToggleNativeDeviceNotificationsInput;
};
export type toggleNativeDeviceNotificationsMutationVariables = toggleNativeDeviceNotificationsMutation$variables;
export type toggleNativeDeviceNotificationsMutation$data = {
  readonly toggleNativeDeviceNotifications: {
    readonly notificationsEnabled: boolean | null;
  } | null;
};
export type toggleNativeDeviceNotificationsMutationResponse = toggleNativeDeviceNotificationsMutation$data;
export type toggleNativeDeviceNotificationsMutation = {
  variables: toggleNativeDeviceNotificationsMutationVariables;
  response: toggleNativeDeviceNotificationsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleNativeDeviceNotificationsPayload",
    "kind": "LinkedField",
    "name": "toggleNativeDeviceNotifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "notificationsEnabled",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleNativeDeviceNotificationsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "toggleNativeDeviceNotificationsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ae0f68c9d8a985389c2fa6b2886dbd75",
    "id": null,
    "metadata": {},
    "name": "toggleNativeDeviceNotificationsMutation",
    "operationKind": "mutation",
    "text": "mutation toggleNativeDeviceNotificationsMutation(\n  $input: ToggleNativeDeviceNotificationsInput!\n) {\n  toggleNativeDeviceNotifications(input: $input) {\n    notificationsEnabled\n  }\n}\n"
  }
};
})();

(node as any).hash = "620b7834258bd7af4f9b5e42774c1b94";

export default node;

import { graphql } from 'react-relay';

import commitMutation from './commitMutation';

import type { saveOneOnOneTalkingPointCommentMutationVariables } from 'jakiro-types/saveOneOnOneTalkingPointCommentMutation.graphql';

const mutationName = 'saveOneOnOneTalkingPointComment';
const mutation = graphql`
  mutation saveOneOnOneTalkingPointCommentMutation($input: SaveOneOnOneTalkingPointCommentInput!) {
    saveOneOnOneTalkingPointComment(input: $input) {
      talkingPoint {
        id
        comments {
          id
          createdAt
          body
          user {
            viewerIsUser
            avatarUrl
            name
          }
        }
      }
      errors
    }
  }
`;

type Args = {
  talkingPointId: string;
  talkingPointCommentId: string | null;
  body: string;
};

function commit(args: Args, onCompleted: any, onError: any) {
  const variables: saveOneOnOneTalkingPointCommentMutationVariables = {
    input: {
      ...args,
    },
  };

  return commitMutation({
    mutationName,
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

export default { commit };

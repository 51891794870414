/**
 * @generated SignedSource<<19617f0152372afcdf3cdf8a29ac9e01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BaseGoalUpdatestatusEnumType = "amber" | "green" | "red";
export type BaseGoalamountTypeEnumType = "digit" | "dollar" | "percent";
export type BaseGoaltypeEnumType = "qualitative" | "quantitative";
import { FragmentRefs } from "relay-runtime";
export type GoalUpdate_update$data = {
  readonly entityId: string;
  readonly createdAt: DateString;
  readonly creator: {
    readonly name: string | null;
    readonly avatarUrl: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"TimelineItem_user">;
  } | null;
  readonly increase: number | null;
  readonly status: BaseGoalUpdatestatusEnumType;
  readonly comment: string | null;
  readonly goal: {
    readonly entityId: string;
    readonly goalAmount: number | null;
    readonly amountType: BaseGoalamountTypeEnumType | null;
    readonly type: BaseGoaltypeEnumType;
  } | null;
  readonly " $fragmentType": "GoalUpdate_update";
};
export type GoalUpdate_update = GoalUpdate_update$data;
export type GoalUpdate_update$key = {
  readonly " $data"?: GoalUpdate_update$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoalUpdate_update">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalUpdate_update",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatarUrl",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TimelineItem_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "increase",
      "storageKey": null
    },
    {
      "alias": "status",
      "args": null,
      "kind": "ScalarField",
      "name": "updateStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Goal",
      "kind": "LinkedField",
      "name": "goal",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "goalAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amountType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GoalUpdate",
  "abstractKey": null
};
})();

(node as any).hash = "bd294e541ba6bacec79699fcdc8e9f94";

export default node;

import { css } from '@emotion/native';
import { Image, Text } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';

import { fontTypes, Colors } from '../../utils/constants';

import Container from '../../components/Container';

import { Button } from '../../components/surveys/Button';

export function Welcome({
  name,
  setCurrentPage,
  surveyDetails,
}: {
  name: string;
  setCurrentPage: () => void;
  surveyDetails?: string | null;
}) {
  return (
    <Container style={{ marginBottom: 32 }}>
      <Image
        source={require('../../assets/images/survey-preview.png')}
        style={{ marginBottom: 40, height: 220, minWidth: 220, width: '100%' }}
      />

      <Text style={fontTypes.sub_header}>Welcome!</Text>

      <Text
        style={[
          fontTypes.header,
          {
            marginTop: 16,
            marginBottom: 32,
            color: Colors.darkgray,
            textAlign: 'center',
          },
        ]}
      >
        {name}
      </Text>

      {surveyDetails ? (
        <Button onPress={setCurrentPage} variant="secondary">
          Next
        </Button>
      ) : (
        <Button onPress={setCurrentPage} variant="primary">
          Start this survey
        </Button>
      )}

      <Container
        direction="row"
        style={css`
          margin-top: 32px;
        `}
      >
        <Ionicons name="md-eye-off" size={16} color={Colors.darkgray} />
        <Text style={[fontTypes.bodySecondary, { marginLeft: 8 }]}>Your answers are anonymous</Text>
      </Container>
    </Container>
  );
}

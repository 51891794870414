/**
 * @generated SignedSource<<691c0eead200045a338e0099fff90a28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PastOneOnOnesList_checkinRelationship$data = {
  readonly oneOnOneMeetings: {
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly scheduledAt: DateString;
        readonly " $fragmentSpreads": FragmentRefs<"PastOneOnOneListItem_oneOnOneMeeting">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "PastOneOnOnesList_checkinRelationship";
};
export type PastOneOnOnesList_checkinRelationship = PastOneOnOnesList_checkinRelationship$data;
export type PastOneOnOnesList_checkinRelationship$key = {
  readonly " $data"?: PastOneOnOnesList_checkinRelationship$data;
  readonly " $fragmentSpreads": FragmentRefs<"PastOneOnOnesList_checkinRelationship">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PastOneOnOnesList_checkinRelationship",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "concreteType": "checkinRelationshipOneOnOneMeetingsConnection",
      "kind": "LinkedField",
      "name": "oneOnOneMeetings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "checkinRelationshipOneOnOneMeetingsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OneOnOneMeeting",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "scheduledAt",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PastOneOnOneListItem_oneOnOneMeeting"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CheckinRelationship",
  "abstractKey": null
};

(node as any).hash = "eacf8dd8580b7fa4ef7ed112a4e5bca4";

export default node;

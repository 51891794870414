/**
 * @generated SignedSource<<0e21d02055ebfa64ea01eaec18540546>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Activity_activity$data = {
  readonly entityId: string;
  readonly createdAt: DateString;
  readonly sourceableType: string | null;
  readonly source: {
    readonly " $fragmentSpreads": FragmentRefs<"GoalCreated_goal" | "GoalUpdate_update">;
  } | null;
  readonly " $fragmentType": "Activity_activity";
};
export type Activity_activity = Activity_activity$data;
export type Activity_activity$key = {
  readonly " $data"?: Activity_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"Activity_activity">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Activity_activity",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceableType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "source",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GoalCreated_goal"
            }
          ],
          "type": "Goal",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GoalUpdate_update"
            }
          ],
          "type": "GoalUpdate",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": null
};

(node as any).hash = "4b15ca314195c6563d70c16008bdd776";

export default node;

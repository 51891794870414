import * as React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import { Text } from 'react-native';
import { css } from '@emotion/native';
import moment from 'moment';

import { Colors, fontTypes } from '../../utils/constants';
import { formatDate } from '../../utils/fns';
import CalloutBanner from '../CalloutBanner';

import type { ReviewCycleIntro_reviewRequest } from 'jakiro-types/ReviewCycleIntro_reviewRequest.graphql';
import UserIntro from './UserIntro';

type Props = {
  reviewRequest: ReviewCycleIntro_reviewRequest;
};

function ReviewCycleIntro({ reviewRequest }: Props) {
  const { reviewee, reviewGroup } = reviewRequest;
  const { reviewCycle, viewerIsPostReviewGiver, postReviewGiver } = reviewee;

  if (!reviewee || !reviewCycle) return null;

  const { allowDraftResponsesDuringPeerSelection } = reviewCycle;

  const isReviewRequestLocked = reviewRequest.lockedForSubmission;
  const isSelfReview = reviewGroup.type === 'self';

  const shouldShowBannerToSelfReviewer =
    allowDraftResponsesDuringPeerSelection && isReviewRequestLocked;

  if (isSelfReview && !shouldShowBannerToSelfReviewer) return null;

  const revieweeUser = reviewee.user;

  if (!revieweeUser) return null;

  const message = generateBannerMessage({
    reviewRequest,
    revieweeUser,
    reviewGroup,
    reviewCycle,
    viewerIsPostReviewGiver,
    postReviewGiver,
  });

  if (!message) return null;

  return (
    <React.Fragment>
      <UserIntro user={revieweeUser} />

      <CalloutBanner
        containerStyle={css`
          margin-top: 16px;
        `}
        title={isReviewRequestLocked ? generateBannerTitle({ reviewRequest, reviewCycle }) : null}
      >
        <Text style={[fontTypes.bodySecondary, { color: Colors.darkyellow }]}>{message}</Text>
      </CalloutBanner>
    </React.Fragment>
  );
}

function generateBannerTitle({ reviewRequest, reviewCycle }) {
  if (!reviewRequest.lockedForSubmission) return '';

  if (reviewCycle.allowDraftResponsesDuringPeerSelection && !reviewCycle.launchEmailSentAt) {
    return 'Peer selection in progress';
  }

  return 'Reviews in progress';
}

function generateBannerMessage({
  reviewRequest,
  revieweeUser,
  reviewGroup,
  reviewCycle,
  viewerIsPostReviewGiver,
  postReviewGiver,
}) {
  const isManagerReview = reviewGroup.type === 'manager';
  const isPeerReview = reviewGroup.type === 'peer';
  const isUpwardReview = reviewGroup.type === 'report';
  const revieweePreferredName = revieweeUser.preferredName || 'the reviewee';
  const postReviewGiverPreferredName =
    postReviewGiver && postReviewGiver.preferredName
      ? postReviewGiver.preferredName
      : "The reviewee's manager";
  const draftReviewDuringPeerSelectionText = `Start drafting your thoughts, but please note that you will not be able to submit your review until peer selection ends.`;
  const managerReviewersGoLastText = `Feel free to start drafting your thoughts, but to give you a chance to read everyone else's responses, you will not be able to submit your review until all other reviewers have submitted their review of ${revieweePreferredName}.`;

  if (reviewRequest.isRejected) {
    return `You declined writing this Peer Review on ${formatDate(
      moment(reviewRequest.rejectedAt),
      false,
    )}`;
  }

  if (reviewRequest.lockedForSubmission) {
    // When we allow draft reviews during peer selection,
    // show the message about drafting to manager reviewers while in
    // peer selection, then show them the manager reviewers go last message.
    if (reviewCycle.allowDraftResponsesDuringPeerSelection) {
      if (reviewCycle.launchEmailSentAt && isManagerReview) {
        return managerReviewersGoLastText;
      }

      // This will be shown to self, manager, and report groups
      return draftReviewDuringPeerSelectionText;
    }

    return managerReviewersGoLastText;
  }

  if (isPeerReview) {
    // Let flow handle making sure all cases are handled
    switch (reviewCycle.deliverableTypePeerReviews) {
      case 'all': {
        if (reviewRequest.hasOnlyPrivateQuestions) {
          return `Your review will only be shared with ${revieweePreferredName}'s manager.`;
        }

        let message = `${revieweePreferredName} will see your review with your name attached`;
        if (reviewRequest.hasPrivateQuestions) {
          message += ', except for answers to private questions';
        }
        message += '.';
        return message;
      }
      case 'anonymous': {
        let message = `${revieweePreferredName} will see your review`;
        if (reviewRequest.hasPrivateQuestions) {
          message += ', except for answers to private questions';
        }
        message += ', and your name will not be shown.';
        return message;
      }
      case 'managers': {
        return `Your review will only be shared with ${revieweePreferredName}'s manager.`;
      }
      case 'anonymous_managers': {
        return `Your review will only be shared with ${revieweePreferredName}'s manager, and your name will not be shown.`;
      }
    }
  }

  if (isUpwardReview) {
    // Let flow handle making sure all cases are handled
    switch (reviewCycle.deliverableTypeUpwardReviews) {
      case 'all': {
        if (reviewRequest.hasOnlyPrivateQuestions) {
          return `Your review will only be shared with ${revieweePreferredName}'s manager.`;
        }

        let message = `${revieweePreferredName} will see your review with your name attached`;
        if (reviewRequest.hasPrivateQuestions) {
          message += ', except for answers to private questions';
        }
        message += '.';
        return message;
      }
      case 'anonymous': {
        let message = `${revieweePreferredName} will see your review`;
        if (reviewRequest.hasPrivateQuestions) {
          message += ' except for answers to private questions';
        }
        message += ', and your name will not be shown.';
        return message;
      }
      case 'managers': {
        return `Your review will only be shared with ${revieweePreferredName}'s manager.`;
      }
      case 'anonymous_managers': {
        return `Your review will only be shared with ${revieweePreferredName}'s manager, and your name will not be shown.`;
      }
    }
  }

  if (isManagerReview) {
    // Let flow handle making sure all cases are handled
    switch (reviewCycle.deliverableTypeManagerReviews) {
      case 'both': {
        return `Your review${
          reviewRequest.hasPrivateQuestions ? ', except for private questions,' : ''
        } ${
          viewerIsPostReviewGiver ? ' and final summary' : ''
        } will be shared with ${revieweePreferredName}.`;
      }
      case 'only_review': {
        return `Your review${
          reviewRequest.hasPrivateQuestions ? ', except for private questions,' : ''
        } will be shared with ${revieweePreferredName}.`;
      }
      case 'only_summary': {
        if (viewerIsPostReviewGiver) {
          return `Your final summary will be shared with ${revieweePreferredName}.`;
        } else {
          return `${postReviewGiverPreferredName}'s final summary will be shared with ${revieweePreferredName}.`;
        }
      }
    }
  }
}

export default createFragmentContainer(ReviewCycleIntro, {
  reviewRequest: graphql`
    fragment ReviewCycleIntro_reviewRequest on ReviewRequest {
      id
      hasPrivateQuestions
      hasOnlyPrivateQuestions
      lockedForSubmission
      reviewGroup {
        type
      }
      isRejected
      rejectedAt
      reviewee {
        viewerIsPostReviewGiver
        reviewCycle {
          allowDraftResponsesDuringPeerSelection
          launchEmailSentAt
          deliverableTypeManagerReviews
          deliverableTypeUpwardReviews
          deliverableTypePeerReviews
        }
        user {
          preferredName
          ...UserIntro_user
        }
        postReviewGiver {
          preferredName
        }
      }
    }
  `,
});

import * as React from 'react';
import { css } from '@emotion/native';

import Container from '../Container';

export function PageWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Container
      flex={1}
      style={css`
        width: 100%;
      `}
    >
      <Container
        flex={1}
        style={css`
          max-width: 100%;
          width: 600px;
          position: relative;
        `}
      >
        {children}
      </Container>
    </Container>
  );
}

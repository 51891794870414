import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Linking from 'expo-linking';
import cookie from 'cookie-cutter';
import document from 'global/document';

import { COMPANY_SLUG, isDevelopment } from './constants';

const Cookie = cookie(document);

export type PreferMobileCookieOptionsType = 'never' | 'always';
export type PreferMobileCookieType = 'PREFERS_MOBILE';

export const PREFERS_MOBILE_COOKIE: PreferMobileCookieType = 'PREFERS_MOBILE';
export const PREFERS_MOBILE_OPTION: PreferMobileCookieOptionsType = 'never';

export async function requestDesktopView() {
  Cookie.set(PREFERS_MOBILE_COOKIE, PREFERS_MOBILE_OPTION, {
    path: '/',
    domain: '.latticehq.com',
  });

  let company;

  try {
    const companyBlob = await AsyncStorage.getItem(COMPANY_SLUG);
    company = JSON.parse(companyBlob);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }

  let prefix = '';
  if (company?.slug) {
    prefix = `${company?.slug || ''}.`;
  }

  Linking.openURL(isDevelopment ? 'http://localhost:5000' : `https://${prefix}latticehq.com`);
}

import { StyleSheet, Text } from 'react-native';
import { createFragmentContainer, graphql } from 'react-relay';

import Container from './Container';
import Avatar from './Avatar';

import { fontTypes } from '../utils/constants';
import { getInitials } from '../utils/fns';
import type { UserChip_user } from 'jakiro-types/UserChip_user.graphql';

type Props = {
  user: UserChip_user;
  xsmall?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  xlarge?: boolean;
};

const UserChip = ({ user, ...rest }: Props) => {
  return (
    <Container direction="row">
      <Avatar {...rest} avatarUrl={user.avatarUrl} title={getInitials(user.name)} />
      <Text style={styles.title}>{user.preferredName || user.name}</Text>
    </Container>
  );
};

export const UserChipFragment = createFragmentContainer(UserChip, {
  user: graphql`
    fragment UserChip_user on User {
      preferredName
      name
      avatarUrl
    }
  `,
});

const styles = StyleSheet.create({
  title: {
    ...fontTypes.bodyTitle,
    padding: 4,
  },
});

export default UserChip;

import * as React from 'react';
import { last, uniqBy } from 'lodash';
import { ActivityIndicator, FlatList, View } from 'react-native';
import type { StackNavigationProp } from '@react-navigation/stack';
import { SearchBar } from '@rneui/themed';
import { graphql, createRefetchContainer } from 'react-relay';
import { css } from '@emotion/native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import type { RootStackParamList } from '../../components/Navigation/types';
import { Colors, isAndroid } from '../../utils/constants';
import createQueryRenderer from '../../utils/createQueryRenderer';
import { cleanConnection } from '../../utils/fns';
import Header from '../../components/Header';
import Person from './PersonCell';

import type { DirectoryAllEmployees_viewer } from 'jakiro-types/DirectoryAllEmployees_viewer.graphql';

type Props = {
  viewer: DirectoryAllEmployees_viewer;
  relay: any;
  navigation: StackNavigationProp<RootStackParamList, 'Directory Employees'>;
};

const PER_PAGE = 30;

function DirectoryAllEmployees(props: Props) {
  const [query, setQuery] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [people, setPeople] = React.useState([]);

  const { viewer, navigation } = props;

  const hasNextPage = viewer.company.users?.pageInfo.hasNextPage;
  const lastCursor = last(viewer.company.users?.edges)?.cursor;

  React.useEffect(() => {
    if (!viewer.company.users && !viewer.searchUsers) return;

    const users = (query ? viewer.searchUsers : cleanConnection(viewer.company.users)) || [];

    if (users.length) {
      setPeople(people => uniqBy([...people, ...users], 'entityId'));
    }
  }, [viewer, query]);

  const onSearch = term => {
    if (term === query) return;

    setQuery(term);
    setLoading(true);
    setPeople([]);

    const refetchVariables = fragmentVariables => ({
      ...fragmentVariables,
      query: term,
      hasQuery: term.length > 0,
      after: '',
    });

    props.relay.refetch(refetchVariables, null, () => {
      setLoading(false);
    });
  };

  const onLoadMore = () => {
    if (!lastCursor) return;

    props.relay.refetch(variables => ({
      ...variables,
      after: lastCursor || '',
      first: PER_PAGE,
    }));
  };

  return (
    <React.Fragment>
      <Header
        mode="BACK"
        handleLeftIconPress={() => navigation.navigate('Directory')}
        title="All employees"
        bottomComponent
      />

      <SearchBar
        searchIcon={<MaterialIcons color={Colors.icongray} size={25} name="search" />}
        cancelIcon={<MaterialIcons color={Colors.icongray} size={25} name="arrow-back" />}
        clearIcon={<MaterialIcons color={Colors.icongray} size={25} name="clear" />}
        onChangeText={onSearch}
        onClear={() => {
          if (query) {
            onSearch('');
          }
        }}
        value={query}
        placeholder="Search"
        platform={isAndroid ? 'android' : 'ios'}
        showLoading={isLoading}
        containerStyle={{
          backgroundColor: 'white',
          borderBottomWidth: 1,
          borderColor: Colors.border,
        }}
      />

      <FlatList
        style={css`
          height: 100%;
          width: 100%;
          flex-direction: column;
          flex: 1;
          display: flex;
        `}
        keyExtractor={item => item.entityId}
        data={people}
        numColumns={3}
        renderItem={({ item }) => <Person user={item} key={item.entityId} />}
        onEndReached={onLoadMore}
        onEndReachedThreshold={0.25}
        initialNumToRender={30}
        ListFooterComponent={() => {
          if (!hasNextPage) return null;

          return (
            <View
              style={css`
                padding-top: 16px;
                padding-bottom: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <ActivityIndicator />
            </View>
          );
        }}
      />
    </React.Fragment>
  );
}

const DirectoryRefetch = createRefetchContainer(
  DirectoryAllEmployees,
  {
    viewer: graphql`
      fragment DirectoryAllEmployees_viewer on Viewer
      @argumentDefinitions(
        query: { type: "String!", defaultValue: "" }
        hasQuery: { type: "Boolean!", defaultValue: false }
        first: { type: "Int!", defaultValue: 30 }
        after: { type: "String!", defaultValue: "" }
        status: { type: "UserStatusEnum!", defaultValue: ACTIVE }
      ) {
        company {
          users(orderBy: LAST_NAME, query: $query, first: $first, after: $after, status: $status)
            @skip(if: $hasQuery) {
            pageInfo {
              hasNextPage
            }
            edges {
              cursor
              node {
                entityId
                ...PersonCell_user
              }
            }
          }
        }
        searchUsers(query: $query) @include(if: $hasQuery) {
          entityId
          ...PersonCell_user
        }
      }
    `,
  },
  graphql`
    query DirectoryAllEmployeesRefetchQuery(
      $query: String!
      $hasQuery: Boolean!
      $first: Int!
      $after: String!
      $status: UserStatusEnum!
    ) {
      viewer {
        ...DirectoryAllEmployees_viewer
          @arguments(
            query: $query
            hasQuery: $hasQuery
            first: $first
            after: $after
            status: $status
          )
      }
    }
  `,
);

export default createQueryRenderer(DirectoryRefetch, DirectoryAllEmployees, {
  query: graphql`
    query DirectoryAllEmployeesQuery(
      $query: String!
      $hasQuery: Boolean!
      $first: Int!
      $status: UserStatusEnum!
    ) {
      viewer {
        ...DirectoryAllEmployees_viewer
          @arguments(query: $query, hasQuery: $hasQuery, first: $first, status: $status)
      }
    }
  `,
  variables: {
    hasQuery: false,
    query: '',
    status: 'ACTIVE',
    first: PER_PAGE,
  },
});

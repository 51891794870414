import { Environment, Network, RecordSource, Store, QueryResponseCache } from 'relay-runtime';

import { findAccessToken } from '../hooks/useAuthentication';
import { getConfig } from './getConfig';

const cache = new QueryResponseCache({ size: 250, ttl: 60 * 5 * 1000 });

export class GraphqlError extends Error {
  statusCode: number;

  constructor(message, statusCode) {
    super(message);

    this.statusCode = statusCode;
  }
}

const environment = (endpoint: string = getConfig('GRAPHQL_ENDPOINT')) => {
  const store = new Store(new RecordSource());

  const fetchQuery = async (operation, variables) => {
    const token = await findAccessToken();
    const queryID = operation.name;

    try {
      const resp = await fetch(endpoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: operation.text,
          variables,
        }),
      });

      // this block is important, otherwise if we have an Error object as 'resp'
      // the JSON.parse will fail and our error handler will assume its a JSON.parse
      // error, not an API level error!
      if (!resp.ok) {
        throw resp.status;
      }

      const data = await resp.json();

      if (data.errors) {
        throw data.errors;
      }

      if (operation.operationKind !== 'mutation') {
        cache.set(queryID, variables, data);
      }

      return data;
    } catch (err) {
      const cachedData = cache.get(queryID, variables);

      // try to show user data if we have any in the cache
      // we skip this if block if the user isn't authorized!
      if (err !== 401 && cachedData !== null) {
        return cachedData;
      }

      throw new GraphqlError('Bad status code', err);
    }
  };

  const network = Network.create(fetchQuery);

  return new Environment({
    network,
    store,
  });
};

export default environment;

import * as React from 'react';
import { Easing, Animated, TouchableOpacity } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import { css } from '@emotion/native';

import { Colors } from '../../utils/constants';

type Props = {
  onPress: () => void;
  isShown: boolean;
};

export default class FloatingDownArrow extends React.Component<Props> {
  bounceValue = new Animated.Value(0);
  revealValue = new Animated.Value(1);

  componentDidMount() {
    this.animate();
  }

  animate = () => {
    this.bounceValue.setValue(0);
    Animated.timing(this.bounceValue, {
      toValue: 1,
      duration: 2000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => this.animate());
  };

  show = () => {
    Animated.spring(this.revealValue, {
      toValue: 0,
      useNativeDriver: true,
    }).start();
  };

  hide = () => {
    Animated.spring(this.revealValue, {
      toValue: 1,
      useNativeDriver: true,
    }).start();
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isShown === false && this.props.isShown === true) {
      this.show();
    } else if (prevProps.isShown === true && this.props.isShown === false) {
      this.hide();
    }
  }

  render() {
    const translateY = this.bounceValue.interpolate({
      inputRange: [0, 0.5, 1],
      outputRange: [0, 10, 0],
    });

    const ty = this.revealValue.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 80],
    });

    return (
      <Animated.View
        style={[
          css`
            margin-bottom: 16px;
            position: absolute;
            bottom: 24px;
            width: 100%;
            text-align: center;
          `,
          { transform: [{ translateY: ty }] },
        ]}
      >
        <TouchableOpacity onPress={this.props.onPress}>
          <Animated.View style={[{ transform: [{ translateY }] }]}>
            <Ionicons
              color={Colors.icongray}
              name="ios-arrow-down"
              size={24}
              style={{ textAlign: 'center' }}
            />
          </Animated.View>
        </TouchableOpacity>
      </Animated.View>
    );
  }
}

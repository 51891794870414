import { Text } from '@rneui/themed';

import Icon from '../../Icon';

type Props = {
  color: string;
  state: ('ended' | 'draft' | 'inactive' | 'active' | '%future added value') | null;
  status: ('green' | 'amber' | 'red' | '%future added value') | null;
};

function QualitativeIcon({ color, state, status }: Props) {
  if (state === 'ended') {
    return <Icon color={color} name="goal_complete" />;
  } else if (state === 'draft') {
    return <Icon color={color} name="edit" />;
  } else if (state === 'inactive') {
    return <Icon color={color} name="goal_incomplete" />;
  } else if (!status) {
    return <Text style={{ color }}>-</Text>;
  } else if (status === 'green') {
    return <Icon color={color} name="on_track" />;
  } else if (status === 'amber') {
    return <Icon color={color} name="progressing" />;
  } else if (status === 'red') {
    return <Icon color={color} name="off_track" />;
  }

  return null;
}

export default QualitativeIcon;

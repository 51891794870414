import { commitMutation, graphql } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';
import type { toggleNativeDeviceNotificationsMutationVariables } from 'jakiro-types/toggleNativeDeviceNotificationsMutation.graphql';

const mutation = graphql`
  mutation toggleNativeDeviceNotificationsMutation($input: ToggleNativeDeviceNotificationsInput!) {
    toggleNativeDeviceNotifications(input: $input) {
      notificationsEnabled
    }
  }
`;

function commit(
  vars: Pick<toggleNativeDeviceNotificationsMutationVariables, 'input'>,
  onCompleted: any,
  onError: any,
) {
  const variables = {
    input: {
      ...vars,
    },
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

export default { commit };

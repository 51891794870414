import { graphql } from 'react-relay';

import commitMutation from './commitMutation';

import type { skipPulseResponseMutationVariables } from 'jakiro-types/skipPulseResponseMutation.graphql';

const mutation = graphql`
  mutation skipPulseResponseMutation($input: SkipPulseInput!) {
    skipPulse(input: $input) {
      user {
        tasksList {
          __typename
          ... on RespondToPulseTask {
            id
            pulseGroup {
              entityId
            }
          }
        }
      }
      pulse {
        id
        skippedAt
        respondedFrom
        question {
          id
          body
        }
        pulseGroup {
          remainingPulses {
            id
            question {
              currentRevision: currentRevisionV2 {
                id
              }
              body
              hasComment
            }
          }
          totalPulsesCount
          remainingPulsesCount
          skippedPulsesCount
        }
      }
      errors
    }
  }
`;

export type PulseRespondedFromType = 'web' | 'email' | 'slack' | 'mobile';

type Args = {
  pulseId: string;
  respondedFrom: PulseRespondedFromType;
};

export function commit(args: Args, onCompleted: any, onError: any) {
  const variables: skipPulseResponseMutationVariables = {
    input: {
      ...args,
    },
  };

  return commitMutation({
    mutationName: 'skipPulse',
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

import * as React from 'react';
import { css } from '@emotion/native';
import { ButtonGroup } from '@rneui/themed';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';

import type { StateType } from '../../pages/surveys/helpers';

import { fontTypes, Colors } from '../../utils/constants';

import OpinionBar from '../engagement/OpinionBar';
import { AddComment } from '../engagement/Buttons';
import { MultipleChoiceSelect, OptionSetOptionType } from '../surveys/MultipleChoiceSelect';
import type { RootStackParamList } from '../../components/Navigation/types';

const ENPS = 'ENPSAndCommentQuestion';

const getResponseType = question => {
  const { __typename, hasComment } = question;

  if (__typename === 'OpinionQuestion') {
    if (hasComment) {
      return 'OpinionAndComment';
    } else {
      return 'Opinion';
    }
  } else if (__typename === 'CommentQuestion') {
    return 'Comment';
  } else if (__typename === ENPS) {
    return ENPS;
  } else if (__typename === 'EngagementMultipleChoiceQuestion') {
    return 'MultipleChoice';
  }

  throw new Error('Invalid survey question response type');
};

export function SurveyableQuestion({
  surveyEntityId,
  commentBody,
  selectENPS,
  selectMultipleChoice,
  selectScore,
  setCommentFocused,
  state,
  surveyableQuestion,
}: {
  surveyEntityId: string;
  commentBody: string;
  selectENPS: (id: string, eNPSChoice: string) => Promise<any>;
  selectMultipleChoice: (id: string, option: OptionSetOptionType) => Promise<any>;
  selectScore: (id: string, opinion: string) => Promise<any>;
  setCommentFocused: (id: string, comment: string) => void;
  state: StateType;
  surveyableQuestion: any;
}) {
  const navigation =
    useNavigation<StackNavigationProp<RootStackParamList, 'Engagement Survey Comment'>>();

  const responseType = getResponseType(surveyableQuestion.question);
  const setComment = comment => setCommentFocused(surveyableQuestion.id, comment);
  const selectedIndex =
    state[surveyableQuestion.id].eNPSChoice === null
      ? null
      : Number(state[surveyableQuestion.id].eNPSChoice);

  switch (responseType) {
    case ENPS:
      return (
        <React.Fragment>
          <View
            style={css`
              display: flex;
              align-items: center;
              flex-direction: row;
              width: 100%;
              justify-content: space-between;
              padding-left: 16px;
              padding-right: 16px;
            `}
          >
            <Text style={fontTypes.bodySecondary}>Not at all likely</Text>
            <Text style={fontTypes.bodySecondary}>Extremely likely</Text>
          </View>

          <View
            style={css`
              width: 100%;
              max-width: 100%;
              margin-left: 16px;
              margin-right: 16px;
            `}
          >
            <ButtonGroup
              buttons={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
              buttonStyle={css`
                height: 50px;
              `}
              containerStyle={css`
                height: 50px;
              `}
              selectedIndex={selectedIndex}
              onPress={score => selectENPS(surveyableQuestion.id, String(score))}
            />
          </View>
          <AddComment
            comment={commentBody}
            title="Add an anonymous comment..."
            onPress={() => {
              navigation.push('Engagement Survey Comment', {
                setComment,
                comment: commentBody,
                surveyEntityId,
              });
            }}
          />
        </React.Fragment>
      );
    case 'OpinionAndComment':
      return (
        <React.Fragment>
          <OpinionBar
            selectedScore={state[surveyableQuestion.id].opinion}
            selectScore={opinion => selectScore(surveyableQuestion.id, opinion)}
            isOpinionLoading={state[surveyableQuestion.id].loading}
            blueSelectedOpinionButton
          />

          <AddComment
            comment={commentBody}
            title="Add an anonymous comment..."
            onPress={() => {
              navigation.push('Engagement Survey Comment', {
                setComment,
                comment: commentBody,
                surveyEntityId,
              });
            }}
          />
        </React.Fragment>
      );
    case 'Opinion':
      return (
        <OpinionBar
          selectedScore={state[surveyableQuestion.id].opinion}
          selectScore={opinion => selectScore(surveyableQuestion.id, opinion)}
          isOpinionLoading={state[surveyableQuestion.id].loading}
          blueSelectedOpinionButton
        />
      );
    case 'MultipleChoice':
      return (
        <View style={{ height: '55%', paddingHorizontal: 16, width: '100%' }}>
          <ScrollView>
            <MultipleChoiceSelect
              onMultipleChoiceSelect={option => selectMultipleChoice(surveyableQuestion.id, option)}
              optionSet={surveyableQuestion.question.questionOptionSet}
              selectedOption={state[surveyableQuestion.id].option}
            />
          </ScrollView>
        </View>
      );
    case 'Comment':
      return (
        <TouchableOpacity
          onPress={() =>
            navigation.navigate('Engagement Survey Comment', {
              setComment,
              comment: commentBody,
              surveyEntityId,
            })
          }
          style={css`
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
          `}
        >
          <View
            style={css`
              padding: 8px;
              width: 100%;
              border-radius: 4px;
              border: 1px solid ${Colors.border};
              min-height: 120px;
            `}
          >
            <Text>{commentBody !== '' ? commentBody : 'Add an anonymous comment...'}</Text>
          </View>
        </TouchableOpacity>
      );
    default:
      throw Error('Invalid surveyable question!');
  }
}

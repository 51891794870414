import * as React from 'react';
import { graphql } from 'react-relay';
import { css } from '@emotion/native';
import { noop, isUndefined } from 'lodash';
import { ScrollView, SafeAreaView, Text, View } from 'react-native';
import type { StackNavigationProp } from '@react-navigation/stack';

import * as SaveMultipleChoiceSurveyQuestionResponse from '../../mutations/SaveMultipleChoiceSurveyQuestionResponse';
import * as SaveSurveyQuestionResponse from '../../mutations/saveSurveyQuestionResponse';
import * as SubmitSurveyResponses from '../../mutations/submitSurveyResponses';

import type { RootStackParamList } from '../../components/Navigation/types';
import { Button } from '../../components/surveys/Button';
import Container from '../../components/Container';
import FloatingDownArrow from '../../components/engagement/FloatingDownArrow';
import Header from '../../components/Header';
import { PageWrapper } from '../../components/surveys/PageWrapper';
import { ProgressBar } from '../../components/surveys/ProgressBar';
import { SurveyableQuestion } from '../../components/surveys/SurveyableQuestion';
import { SurveyDetailsPanel } from '../../components/surveys/SurveyDetailsPanel';
import Swiper from '../../components/Swiper';

/* eslint-disable import/no-unresolved */
// TS/Eslint don't understand that react-native is inferring these file types
// by which platform we're on .native.js .web.js .ios.js .android.js
// @ts-expect-error
import Confirm from '../../components/Confirm';
/* eslint-enable */
import { fontTypes, Colors } from '../../utils/constants';
import createQueryRenderer from '../../utils/createQueryRenderer';
import useDebounce from '../../hooks/useDebounce';
import { connectDropdownAlert, Alert } from '../../hocs/withDropdownAlert';
import { OptionSetOptionType } from '../../components/surveys/MultipleChoiceSelect';
import { Welcome } from './Welcome';
import { questionsReducer, initialQuestionState, SET_QUESTIONS, SET_QUESTION } from './helpers';

import { OPINIONS } from '../../components/engagement/OpinionBar';

import type { surveysQueryResponse } from 'jakiro-types/surveysQuery.graphql';

type Props = {
  isLoading: boolean;
  dropdownAlert: Alert;
  navigation: StackNavigationProp<RootStackParamList, 'Engagement Survey'>;
} & surveysQueryResponse;

function Surveys(props: Props) {
  const [state, dispatch] = React.useReducer(questionsReducer, initialQuestionState);

  const questions = props.viewer?.findSurvey?.questions || [];
  const name = props.viewer?.findSurvey?.name || '';
  const surveyId = props.viewer?.findSurvey?.id || '';
  const surveyEntityId = props.viewer?.findSurvey?.entityId || '';
  const surveyDetails = props.viewer?.findSurvey?.details;
  const { dropdownAlert, navigation } = props;

  // Pagination
  const [currentPage, setPage] = React.useState(0);
  const introPageCount = surveyDetails ? 2 : 1; // If survey details exist, we will have 2 intro pages including the welcome page
  const lastPage = questions.length + introPageCount;
  const scrollRef = React.useRef(null);

  const setCurrentPage = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(currentPage);
      setPage(currentPage => Math.min(currentPage + 1, lastPage));
    }
  };

  const [comment, setComment] = React.useState(0);
  const [focusedQuestionId, setFocusedQuestionId] = React.useState(null);

  const commentDebounced = useDebounce(comment, 500);

  React.useEffect(() => {
    if (!focusedQuestionId) return;

    dispatch({
      type: SET_QUESTION,
      payload: {
        id: focusedQuestionId,
        comment: commentDebounced,
      },
    });

    const mutationPayload = {
      ...state[focusedQuestionId],
      // we store our comments as enums, but need to transform them to indexes
      comment: commentDebounced,
    };

    // strip out both opinion and eNPSChoice as we may not want to send either
    // or we might want to send only 1
    const { loading, id: droppedId, opinion, eNPSChoice, option, ...rest } = mutationPayload;

    const hasOpinion = OPINIONS.find(o => o.value === opinion)?.surveyValue;
    const hasENPSAnswer = eNPSChoice !== null && typeof eNPSChoice !== 'undefined';

    if (hasOpinion) {
      rest.opinion = hasOpinion;
    } else if (hasENPSAnswer) {
      rest.eNPSChoice = eNPSChoice;
    }

    setFocusedQuestionId(null);

    SaveSurveyQuestionResponse.commit(rest, noop, () => {
      dropdownAlert({
        type: 'error',
        title: 'Something went wrong',
        body: `Please try again.`,
      });
    });
  }, [commentDebounced]);

  React.useEffect(() => {
    const payload = {};

    questions.forEach(item => {
      const questionRevisionId = item?.question?.currentRevision?.id;
      const surveyParticipantQuestionId = item?.id;

      if (!surveyParticipantQuestionId) return;

      const response: any = item?.response?.response;

      const opinion = response?.opinion ?? null;
      const eNPSChoice = response?.eNPSChoice ?? null;
      const comment = response?.comment ?? null;
      const option = item?.response?.multipleChoiceResponse ?? null;

      payload[surveyParticipantQuestionId] = {
        surveyParticipantQuestionId,
        questionRevisionId,
        opinion:
          !isUndefined(opinion) && opinion !== null
            ? OPINIONS.find(o => o.surveyValue === opinion)?.value
            : null,
        comment,
        eNPSChoice,
        option,
        loading: false,
      };
    });

    dispatch({ type: SET_QUESTIONS, payload });
  }, [questions]);

  const selectScore = async (id: string, opinion: string) => {
    if (!state[id]) return;

    let isOpinionAlreadySet = false;

    if (state[id].opinion === opinion) {
      isOpinionAlreadySet = true;
      dispatch({ type: SET_QUESTION, payload: { id, loading: true, opinion: null } });
    } else {
      dispatch({ type: SET_QUESTION, payload: { id, loading: true, opinion } });
    }

    const nextOpinionToSend = isOpinionAlreadySet
      ? null
      : OPINIONS.find(o => o.value === opinion)?.surveyValue;
    const mutationPayload = {
      ...state[id],
      opinion: nextOpinionToSend,
    };

    // don't send eNPSChoice or option on opinion questions
    const { loading, id: droppedId, eNPSChoice, option, ...rest } = mutationPayload;

    await SaveSurveyQuestionResponse.commit(
      rest,
      () => {
        dispatch({
          type: SET_QUESTION,
          payload: { id, loading: false, opinion: isOpinionAlreadySet ? null : opinion },
        });
      },
      () => {
        dispatch({
          type: SET_QUESTION,
          payload: { id, loading: false, opinion: isOpinionAlreadySet ? null : opinion },
        });
      },
    );
  };

  const selectENPS = async (id: string, eNPSChoice: string) => {
    if (!state[id]) return;

    let isENPSSet = false;

    if (state[id].eNPSChoice === eNPSChoice) {
      isENPSSet = true;
      dispatch({ type: SET_QUESTION, payload: { id, loading: true, eNPSChoice: null } });
    } else {
      dispatch({ type: SET_QUESTION, payload: { id, loading: true, eNPSChoice } });
    }

    const nextScore = isENPSSet ? null : eNPSChoice;

    const mutationPayload = {
      ...state[id],
      eNPSChoice: nextScore,
    };

    // don't send opinion or option on eNPS responses
    const { loading, id: droppedId, opinion, option, ...rest } = mutationPayload;

    await SaveSurveyQuestionResponse.commit(
      rest,
      () => {
        dispatch({
          type: SET_QUESTION,
          payload: { id, loading: false, eNPSChoice: nextScore },
        });
      },
      () => {
        dispatch({
          type: SET_QUESTION,
          payload: { id, loading: false, eNPSChoice: nextScore },
        });
      },
    );
  };

  const selectMultipleChoice = async (id: string, option: OptionSetOptionType) => {
    if (!state[id]) return;

    let isOptionAlreadySet = false;

    if (state[id].option === option) {
      isOptionAlreadySet = true;
      dispatch({ type: SET_QUESTION, payload: { id, loading: true, option: null } });
    } else {
      dispatch({ type: SET_QUESTION, payload: { id, loading: true, option } });
    }

    const nextOptionToSend = isOptionAlreadySet ? null : option;
    const mutationPayload = {
      questionRevisionId: state[id].questionRevisionId,
      surveyParticipantQuestionId: state[id].surveyParticipantQuestionId,
      multipleChoiceSelectionEntityId: nextOptionToSend ? nextOptionToSend.entityId : null,
    };

    await SaveMultipleChoiceSurveyQuestionResponse.commit(
      mutationPayload,
      () => {
        dispatch({
          type: SET_QUESTION,
          payload: {
            id,
            loading: false,
            option: isOptionAlreadySet ? null : option,
          },
        });
      },
      () => {
        dispatch({
          type: SET_QUESTION,
          payload: {
            id,
            loading: false,
            option: isOptionAlreadySet ? null : option,
          },
        });
      },
    );
  };

  const totalQuestions = Object.keys(state).length;
  const answeredQuestions = Object.keys(state).reduce((acc, question) => {
    if (!state[question]) return acc;

    const hasEnpsAnswer =
      state[question].eNPSChoice !== null && typeof state[question].eNPSChoice !== 'undefined';

    const hasAnswer =
      state[question].option || state[question].comment || state[question].opinion || hasEnpsAnswer
        ? 1
        : 0;

    return acc + hasAnswer;
  }, 0);

  const progress = (answeredQuestions / totalQuestions) * 100;

  const onSubmitPress = () => {
    if (answeredQuestions !== totalQuestions) {
      Confirm({
        title: 'Submit your survey answers?',
        description: `It looks like you've skipped ${
          totalQuestions - answeredQuestions
        } questions. By submitting, your anonymized survey answers will be locked and sent to your company Admin. Make sure you've taken one last look!`,
        onConfirm: submitSurvey,
      });
    } else {
      submitSurvey();
    }
  };

  const submitSurvey = () => {
    SubmitSurveyResponses.commit(
      { surveyId },
      () => {
        dropdownAlert({
          type: 'success',
          title: 'Survey submitted',
          body: `You've successfully submitted your responses for ${name} survey.`,
        });
        navigation.goBack();
      },
      () => {
        dropdownAlert({
          type: 'error',
          title: 'Something went wrong',
          body: `Please try again.`,
        });
      },
    );
  };

  const setCommentFocused = (id, comment) => {
    setComment(comment);

    if (id !== focusedQuestionId) {
      setFocusedQuestionId(id);
    }
  };

  const pages = [
    <PageWrapper key="welcome">
      <Welcome name={name} setCurrentPage={setCurrentPage} surveyDetails={surveyDetails} />
    </PageWrapper>,
    ...questions.map((item, index) => {
      if (!item || !state[item.id]) return null;

      const isLastQuestion = index === questions.length - 1;
      const commentBody = state[item.id]?.comment || '';

      return (
        <PageWrapper key={item.question.body}>
          <Container
            flex={1}
            direction="column"
            style={css`
              width: 100%;
            `}
          >
            <Container
              flex={1}
              style={css`
                max-width: 600px;
                height: 100%;
                width: 100%;
              `}
            >
              <Container
                style={css`
                  position: relative;
                  margin-bottom: 24px;
                `}
              >
                <Text style={fontTypes.subHeaderBodyTitleSecondary}>
                  {index + 1} / {questions.length}
                </Text>

                <Text
                  style={[
                    fontTypes.title,
                    {
                      textAlign: 'center',
                      paddingHorizontal: 16,
                      paddingTop: 8,
                      paddingBottom: 16,
                    },
                  ]}
                >
                  {item.question.body}
                </Text>
              </Container>

              <SurveyableQuestion
                surveyEntityId={surveyEntityId}
                selectENPS={selectENPS}
                selectMultipleChoice={selectMultipleChoice}
                setCommentFocused={setCommentFocused}
                selectScore={selectScore}
                commentBody={commentBody}
                surveyableQuestion={item}
                state={state}
              />
            </Container>

            {isLastQuestion && (
              <SafeAreaView
                style={css`
                  margin: 20px 0;
                  width: 100%;
                `}
              >
                <Button onPress={onSubmitPress} variant="primary">
                  Submit survey answers
                </Button>
              </SafeAreaView>
            )}
          </Container>
        </PageWrapper>
      );
    }),
  ];
  // If survey details exist, add new page to pages array
  if (surveyDetails)
    pages.splice(
      1,
      0,
      <PageWrapper key="survey-details">
        <ScrollView nestedScrollEnabled contentContainerStyle={{ flexGrow: 1 }}>
          <View
            style={{
              marginVertical: 20,
              width: '100%',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <SurveyDetailsPanel surveyDetails={surveyDetails} />
            <Button onPress={setCurrentPage} variant="primary">
              Start this survey
            </Button>
          </View>
        </ScrollView>
      </PageWrapper>,
    );

  return (
    <React.Fragment>
      <Header
        centerComponent={
          <Container
            style={css`
              width: 220px;
            `}
          >
            <ProgressBar progress={progress} />
          </Container>
        }
        rightComponent={
          // Don't show when viewing welcome [0] or survey details [1] intro screens
          surveyDetails && currentPage > 1 ? (
            <Container>
              <Button
                iconColor={Colors.icongray}
                iconName="help"
                onPress={() =>
                  navigation.navigate('Engagement Survey Details', {
                    surveyEntityId,
                  })
                }
                variant="tertiary"
              />
            </Container>
          ) : undefined
        }
        mode="BACK"
        wrapperStyle={css`
          border-bottom-color: transparent;
        `}
        containerStyle={css`
          width: 100%;
          background-color: transparent;
        `}
      />

      <Swiper
        containerStyle={{ backgroundColor: Colors.white }}
        horizontal={false}
        showsPagination={false}
        showsVerticalScrollIndicator
        ref={scrollRef}
        index={currentPage}
        onIndexChanged={d => {
          if (d !== currentPage) {
            // eslint-disable-next-line no-console
            console.log('onIndexChanged', { next: d, currentPage });
            setPage(d);
          }
        }}
        onMomentumScrollEnd={(e, state) => {
          if (state.index !== currentPage) {
            // eslint-disable-next-line no-console
            console.log('onMomentumScrollEnd', { next: state.index, currentPage });
            setPage(state.index);
          }
        }}
      >
        {pages}
      </Swiper>

      <FloatingDownArrow
        isShown={currentPage > introPageCount - 1 && currentPage !== lastPage - 1}
        onPress={setCurrentPage}
      />
    </React.Fragment>
  );
}

export default createQueryRenderer(connectDropdownAlert(Surveys), Surveys, {
  query: graphql`
    query surveysQuery($surveyEntityId: String!) {
      viewer {
        findSurvey(surveyId: $surveyEntityId) {
          id
          entityId
          name
          details
          questions {
            surveyQuestion {
              id
            }
            id
            question {
              __typename
              body
              hasComment
              currentRevision: currentRevisionV2 {
                id
              }
              ... on EngagementMultipleChoiceQuestion {
                questionOptionSet {
                  options {
                    id
                    entityId
                    body
                  }
                }
              }
            }
            response {
              response
              multipleChoiceResponse {
                id
                entityId
                body
              }
            }
          }
        }
      }
    }
  `,
  queriesParams: ({ route }) => ({
    surveyEntityId: route.params.surveyEntityId,
  }),
});

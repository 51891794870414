import { graphql } from 'react-relay';

import commitMutation from './commitMutation';

import type {
  SaveMultipleChoiceSurveyQuestionResponseMutationVariables,
  SaveMultipleChoiceSurveyQuestionResponseInput,
} from 'jakiro-types/SaveMultipleChoiceSurveyQuestionResponseMutation.graphql';

const mutationName = 'saveMultipleChoiceSurveyQuestionResponse';
const mutation = graphql`
  mutation SaveMultipleChoiceSurveyQuestionResponseMutation(
    $input: SaveMultipleChoiceSurveyQuestionResponseInput!
  ) {
    saveMultipleChoiceSurveyQuestionResponse(input: $input) {
      surveyParticipantQuestion {
        submittedAt
        response {
          multipleChoiceResponse {
            entityId
            body
          }
        }
      }
      survey {
        viewerCanSubmit
      }
    }
  }
`;

export function commit(
  args: SaveMultipleChoiceSurveyQuestionResponseInput,
  onCompleted: any,
  onError: any,
) {
  const variables: SaveMultipleChoiceSurveyQuestionResponseMutationVariables = {
    input: {
      ...args,
    },
  };

  return commitMutation({
    mutation,
    mutationName,
    variables,
    onCompleted,
    onError,
  });
}

import * as React from 'react';
import { SafeAreaView, ScrollView, View, Text, ActivityIndicator } from 'react-native';
import { graphql } from 'react-relay';
import { css } from '@emotion/native';
import { Divider, ButtonGroup } from '@rneui/themed';
import { isEmpty } from 'lodash';
import Ionicons from '@expo/vector-icons/Ionicons';
import type { StackNavigationProp } from '@react-navigation/stack';

import * as SavePulseResponse from '../../mutations/savePulseResponse';
import * as SkipPulseResponse from '../../mutations/skipPulseResponse';
import Container from '../../components/Container';
import { AddComment, ActionButton } from '../../components/engagement/Buttons';
import Header from '../../components/Header';
import Done, { isDayTime } from './Done';
import { fontTypes, Colors } from '../../utils/constants';
import createQueryRenderer from '../../utils/createQueryRenderer';
import { connectDropdownAlert, Alert } from '../../hocs/withDropdownAlert';
import {
  initialPulseState,
  pulseResponseReducer,
  SET_REMAINING_PULSES,
  REMOVE_FIRST_PULSE,
} from './helpers';
/* eslint-disable import/no-unresolved */
// Flow/Eslint don't understand that react-native is inferring these file types
// by which platform we're on .native.js .web.js .ios.js .android.js
// @ts-expect-error
import Confirm from '../../components/Confirm';
/* eslint-enable */
import OpinionBar from '../../components/engagement/OpinionBar';
import type { RootStackParamList } from '../../components/Navigation/types';

import type { pulseQueryResponse } from 'jakiro-types/pulseQuery.graphql';

type Props = {
  isLoading: boolean;
  dropdownAlert: Alert;
  navigation: StackNavigationProp<RootStackParamList, 'Pulse Comment'>;
} & pulseQueryResponse;

function Shell({ children }) {
  return (
    <Container
      flex={1}
      direction="column"
      style={css`
        background-color: #fff5e9;
        width: 100%;
      `}
    >
      <Header
        backgroundColor="#fff5e9"
        bottomComponent
        mode="BACK"
        wrapperStyle={css`
          border-bottom-color: transparent;
        `}
        containerStyle={css`
          width: 100%;
          background-color: transparent;
        `}
      />

      {children}
    </Container>
  );
}

function Pulse({ isLoading: isCompLoading, viewer, dropdownAlert, navigation }: Props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedScore, setSelectedScore] = React.useState(null);
  const [selectedENPSChoice, setSelectedENPSChoice] = React.useState(null);
  const [comment, setComment] = React.useState('');
  const [state, dispatch] = React.useReducer(pulseResponseReducer, initialPulseState);

  React.useEffect(() => {
    const remainingPulses = viewer?.findPulseGroup?.remainingPulses || [];

    if (state.remainingPulses.length === 0 && remainingPulses.length > 0) {
      dispatch({ type: SET_REMAINING_PULSES, payload: { remainingPulses } });
    }
  }, [viewer]);

  const pulseGroup = viewer?.findPulseGroup;
  const submittedPulsesCount = viewer?.user.submittedPulsesCount || 0;
  const status = viewer?.findPulseGroup?.status;

  if (isCompLoading || !pulseGroup) {
    return (
      <Container
        flex={1}
        direction="column"
        style={css`
          background-color: #fff5e9;
        `}
      >
        <ActivityIndicator />
      </Container>
    );
  }

  if (status === 'Expired' || status === 'Completed') {
    return (
      <Shell>
        <Done
          rainbow={false}
          title={
            status === 'Expired'
              ? 'Looks like this survey is no longer available'
              : 'Looks like you already completed this survey'
          }
          subtitle={`Enjoy your ${isDayTime() ? 'day' : 'evening'}!`}
        />
      </Shell>
    );
  }

  const selectScore = (opinion: SavePulseResponse.PULSE_OPINION_VALUE) => {
    if (selectedScore === opinion) {
      return setSelectedScore(null);
    }

    setSelectedScore(opinion);
  };

  const selectENPSChoice = (enpsChoice: number) => {
    if (selectedENPSChoice === enpsChoice) {
      return setSelectedENPSChoice(null);
    }

    setSelectedENPSChoice(enpsChoice);
  };

  const onSkipPress = pulse => {
    // eNPS question is not skippable
    if (isENPS) {
      dropdownAlert({
        type: 'error',
        title: 'Whoops, something is not right.',
        body: 'This question cannot be skipped.',
      });

      return;
    }

    Confirm({
      title: 'Pulse Surveys',
      description: 'Are you sure you want to skip this question?',
      onConfirm: () => submitScore(pulse, true),
    });
  };

  const resetState = () => {
    setIsLoading(false);
    setSelectedScore(null);
    setSelectedENPSChoice(null);
    setComment('');
  };

  const submitScore = (pulse, skip = false) => {
    setIsLoading(true);

    if (skip) {
      SkipPulseResponse.commit(
        {
          respondedFrom: 'mobile',
          pulseId: pulse.id,
        },
        () => {
          resetState();
          dispatch({ type: REMOVE_FIRST_PULSE });
        },
        payload => {
          const errors = payload?.errors || [];
          const pulse = payload?.pulse || {};

          resetState();

          if (errors.length > 0 && !isEmpty(pulse)) {
            dispatch({ type: REMOVE_FIRST_PULSE });

            dropdownAlert({
              type: 'error',
              title: 'Whoops, something is not right.',
              body: errors[0],
            });
            return;
          }

          dropdownAlert({
            type: 'error',
            title: 'Whoops, something is not right.',
            body: errors[0] || 'There was an error submitting your pulse, please try again.',
          });
        },
      );
    }

    let responseChoice = null;

    if (isENPS && selectedENPSChoice !== null) {
      responseChoice = { eNPSChoice: selectedENPSChoice };
    }

    if (!isENPS && selectedScore !== null) {
      responseChoice = { opinion: selectedScore };
    }

    if (responseChoice === null) {
      return;
    }

    SavePulseResponse.commit(
      {
        pulseId: pulse.id,
        questionRevisionId: pulse?.question?.currentRevision?.id || '',
        respondedFrom: 'mobile',
        ...responseChoice,
        // Clear comment if the user toggles commenting back off
        comment: comment || undefined,
      },
      () => {
        resetState();
        dispatch({ type: REMOVE_FIRST_PULSE });
      },
      payload => {
        const errors = payload?.errors || [];
        const pulseGroup = payload?.pulseGroup;

        resetState();

        if (errors.length > 0 && !isEmpty(pulseGroup)) {
          dispatch({ type: REMOVE_FIRST_PULSE });
          dropdownAlert({
            type: 'error',
            title: 'Whoops, something is not right.',
            body: errors[0],
          });
          return;
        }

        dropdownAlert({
          type: 'error',
          title: 'Whoops, something is not right.',
          body: errors[0] || 'There was an error submitting your pulse, please try again.',
        });
      },
    );
  };

  if (viewer && state.remainingPulses && state.remainingPulses.length === 0) {
    return (
      <Shell>
        <Done
          rainbow
          title="Thank you for sharing!"
          subtitle={`You've answered ${submittedPulsesCount} questions so far, that's awesome!`}
        />
      </Shell>
    );
  }

  const firstPulse = state.remainingPulses[0];

  if (!firstPulse) return null;

  const isENPS = firstPulse.question.__typename === 'ENPSAndCommentQuestion';

  return (
    <Shell>
      <ScrollView
        bounces={false}
        automaticallyAdjustContentInsets // ="automatic"
        contentContainerStyle={css`
          flex-grow: 1;
        `}
        style={css`
          width: 100%;
        `}
      >
        <View
          style={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 16px;
            margin-top: 24px;
          `}
        >
          <Container align="center" justify="flex-start" direction="row" style={{ marginLeft: 16 }}>
            <Ionicons size={15} color={Colors.darkergray} name="lock-closed" />

            <Text style={[fontTypes.bodySecondary, { marginLeft: 8 }]}>You are anonymous</Text>
          </Container>

          {state.remainingPulses.length > 0 && (
            <Text
              style={[fontTypes.bodySecondary, { marginRight: 16 }]}
            >{`${state.remainingPulses.length} questions left`}</Text>
          )}
        </View>

        <SafeAreaView
          style={css`
            background-color: white;
            width: 100%;
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
            box-shadow: 0 -4px 30px rgba(0, 0, 0, 0.1);

            flex-grow: 1;
            justify-content: space-between;
          `}
        >
          <View>
            <Text
              style={[
                fontTypes.title,
                { textAlign: 'center', paddingHorizontal: 16, paddingVertical: 32 },
              ]}
            >
              {firstPulse.question.body}
            </Text>

            {!isENPS ? (
              <OpinionBar selectScore={selectScore} selectedScore={selectedScore} />
            ) : (
              <ENPSChoiceBar
                selectENPSChoice={selectENPSChoice}
                selectedENPSChoice={selectedENPSChoice}
              />
            )}
          </View>

          <View>
            {firstPulse.question.hasComment && (
              <AddComment
                comment={comment}
                onPress={() => {
                  navigation.push('Pulse Comment', {
                    setComment,
                    comment,
                    pulseGroupEntityId: pulseGroup.entityId,
                  });
                }}
              />
            )}

            <Divider
              style={css`
                margin-bottom: 16px;
              `}
            />

            {isENPS && selectedENPSChoice === null ? (
              <ActionButton disabled title="Cannot skip question" primary={false} />
            ) : !isENPS && selectedScore === null ? (
              <ActionButton
                loading={isLoading}
                onPress={() => onSkipPress(firstPulse)}
                title="Skip question"
                primary={false}
              />
            ) : (
              <ActionButton
                loading={isLoading}
                onPress={() => submitScore(firstPulse, false)}
                primary
                title="Submit"
              />
            )}
          </View>
        </SafeAreaView>
      </ScrollView>
    </Shell>
  );
}

function ENPSChoiceBar({ selectENPSChoice, selectedENPSChoice }) {
  return (
    <SafeAreaView
      style={css`
        width: 100%;
      `}
    >
      <View
        style={css`
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          padding-left: 16px;
          padding-right: 16px;
        `}
      >
        <Text style={fontTypes.bodySecondary}>Not at all likely</Text>
        <Text style={fontTypes.bodySecondary}>Extremely likely</Text>
      </View>

      <ButtonGroup
        buttons={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
        buttonStyle={css`
          height: 50px;
        `}
        containerStyle={css`
          height: 50px;
        `}
        selectedIndex={selectedENPSChoice}
        onPress={choice => selectENPSChoice(choice)}
      />
    </SafeAreaView>
  );
}

export default createQueryRenderer(connectDropdownAlert(Pulse), Pulse, {
  query: graphql`
    query pulseQuery($pulseGroupEntityId: String!) {
      viewer {
        user {
          preferredName
          submittedPulsesCount
        }
        findPulseGroup(pulseGroupEntityId: $pulseGroupEntityId) {
          id
          entityId
          updatedAt
          status
          createdAt
          remainingPulses {
            id
            entityId
            question {
              __typename
              currentRevision: currentRevisionV2 {
                id
              }
              body
              hasComment
            }
          }
          totalPulsesCount
          remainingPulsesCount
          skippedPulsesCount
        }
      }
    }
  `,
  queriesParams: ({ route }) => ({
    pulseGroupEntityId: route.params.pulseGroupEntityId,
  }),
  getLoadingProp: true,
});

import * as Svg from 'react-native-svg';

type Props = {
  color: string | null;
};

export default function Help({ color }: Props) {
  return (
    <Svg.Svg width="24" height="24" viewBox="0 0 24 24" fill={color}>
      <Svg.Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
      />
      <Svg.Path d="M11.2364 14.0733C11.2364 13.4636 11.3104 12.978 11.4585 12.6166C11.6066 12.2551 11.8766 11.9002 12.2685 11.5518C12.6648 11.1991 12.9283 10.9138 13.0589 10.6961C13.1896 10.474 13.2549 10.241 13.2549 9.99713C13.2549 9.26116 12.9152 8.89318 12.2358 8.89318C11.9136 8.89318 11.6545 8.99334 11.4585 9.19366C11.2669 9.38963 11.1667 9.6618 11.158 10.0102H9.26367C9.27238 9.17842 9.5402 8.52737 10.0671 8.05705C10.5984 7.58672 11.3213 7.35156 12.2358 7.35156C13.1591 7.35156 13.8754 7.57584 14.385 8.02439C14.8945 8.46858 15.1492 9.09785 15.1492 9.91221C15.1492 10.2824 15.0665 10.6329 14.901 10.9639C14.7355 11.2905 14.4459 11.6541 14.0322 12.0548L13.5031 12.5578C13.1721 12.8757 12.9827 13.248 12.9348 13.6748L12.9087 14.0733H11.2364ZM11.047 16.0787C11.047 15.7869 11.145 15.5474 11.3409 15.3601C11.5413 15.1685 11.796 15.0727 12.1052 15.0727C12.4144 15.0727 12.667 15.1685 12.8629 15.3601C13.0633 15.5474 13.1634 15.7869 13.1634 16.0787C13.1634 16.3661 13.0654 16.6034 12.8695 16.7907C12.6779 16.9779 12.4231 17.0716 12.1052 17.0716C11.7873 17.0716 11.5304 16.9779 11.3344 16.7907C11.1428 16.6034 11.047 16.3661 11.047 16.0787Z" />
    </Svg.Svg>
  );
}

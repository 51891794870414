import * as React from 'react';

import HandleNetworkError from './HandleNetworkError';
import { captureException } from '../utils/fns';

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<any, State> {
  state = {
    hasError: false,
  };

  componentDidCatch(exception: any) {
    this.setState({ hasError: true }, () => {
      captureException(exception);
    });
  }

  render() {
    if (this.state.hasError) {
      return <HandleNetworkError />;
    }

    return this.props.children;
  }
}

import { graphql } from 'react-relay';
import commitMutation from './commitMutation';

import type {
  createNextRecurringOneOnOneMeetingMutationVariables,
  createNextRecurringOneOnOneMeetingMutationResponse,
} from 'jakiro-types/createNextRecurringOneOnOneMeetingMutation.graphql';

const mutationName = 'createNextRecurringOneOnOneMeeting';
const mutation = graphql`
  mutation createNextRecurringOneOnOneMeetingMutation(
    $input: CreateNextRecurringOneOnOneMeetingInput!
  ) {
    createNextRecurringOneOnOneMeeting(input: $input) {
      meeting {
        entityId
      }
      errors
    }
  }
`;

export function commit(
  args: createNextRecurringOneOnOneMeetingMutationVariables['input'],
  onCompleted?: (payload: createNextRecurringOneOnOneMeetingMutationResponse) => void,
  onError?: () => void,
) {
  const variables = {
    input: {
      ...args,
    },
  };

  return commitMutation({
    mutation,
    mutationName,
    variables,
    onCompleted,
    onError,
  });
}

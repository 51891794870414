import * as React from 'react';
import { graphql } from 'react-relay';
import { ScrollView } from 'react-native';

import createQueryRenderer from '../../utils/createQueryRenderer';
import PastUpdatesList from './PastUpdatesList';
import Header from '../../components/Header';
import type { PastUpdatesQueryResponse } from 'jakiro-types/PastUpdatesQuery.graphql';

type Props = PastUpdatesQueryResponse;

const PastUpdates = ({ viewer }: Props) => {
  return (
    <React.Fragment>
      <Header mode="BACK" title="Previous updates" />

      <ScrollView style={{ flex: 1, backgroundColor: 'white' }}>
        <PastUpdatesList user={viewer?.user} />
      </ScrollView>
    </React.Fragment>
  );
};

export default createQueryRenderer(PastUpdates, PastUpdates, {
  query: graphql`
    query PastUpdatesQuery($first: Int!) {
      viewer {
        user {
          entityId
          ...PastUpdatesList_user @arguments(first: $first)
        }
      }
    }
  `,
  queriesParams: () => ({ first: 20 }),
});

import { graphql } from 'react-relay';

import commitMutation from './commitMutation';

import type { PulseRespondedFromType } from './skipPulseResponse';

const mutation = graphql`
  mutation savePulseResponseMutation($input: SavePulseResponseInput!) {
    savePulseResponse(input: $input) {
      user {
        submittedPulsesCount
        tasksList {
          __typename
          ... on RespondToPulseTask {
            id
            pulseGroup {
              entityId
            }
          }
        }
      }
      pulse {
        id
        skippedAt
        respondedFrom
        question {
          id
          body
        }
        pulseGroup {
          id
          remainingPulsesCount
        }
      }
      pulseGroup {
        remainingPulses {
          id
          question {
            currentRevision: currentRevisionV2 {
              id
            }
            body
            hasComment
          }
        }
        totalPulsesCount
        remainingPulsesCount
        skippedPulsesCount
      }
      errors
    }
  }
`;

export type PULSE_OPINION_VALUE =
  | 'StronglyDisagree'
  | 'Disagree'
  | 'Neutral'
  | 'Agree'
  | 'StronglyAgree';

type Args = {
  pulseId: string;
  questionRevisionId: string;
  respondedFrom: PulseRespondedFromType;
  opinion?: PULSE_OPINION_VALUE;
  eNPSChoice?: number;
  comment: string | null;
};

export function commit(args: Args, onCompleted: any, onError: any) {
  const variables = {
    input: {
      ...args,
    },
  };

  return commitMutation({
    mutation,
    mutationName: 'savePulseResponse',
    variables,
    onCompleted,
    onError,
  });
}

/**
 * @generated SignedSource<<41e0f22b4c0e9cd5f6a320a12a3e6b7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PastOneOnOneList_checkinRelationship$data = {
  readonly oneOnOneMeetings: {
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly scheduledAt: DateString;
        readonly " $fragmentSpreads": FragmentRefs<"PastOneOnOneListItem_oneOnOneMeeting">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "PastOneOnOneList_checkinRelationship";
};
export type PastOneOnOneList_checkinRelationship = PastOneOnOneList_checkinRelationship$data;
export type PastOneOnOneList_checkinRelationship$key = {
  readonly " $data"?: PastOneOnOneList_checkinRelationship$data;
  readonly " $fragmentSpreads": FragmentRefs<"PastOneOnOneList_checkinRelationship">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PastOneOnOneList_checkinRelationship",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "concreteType": "checkinRelationshipOneOnOneMeetingsConnection",
      "kind": "LinkedField",
      "name": "oneOnOneMeetings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "checkinRelationshipOneOnOneMeetingsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OneOnOneMeeting",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "scheduledAt",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PastOneOnOneListItem_oneOnOneMeeting"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CheckinRelationship",
  "abstractKey": null
};

(node as any).hash = "51f93fd1019a1b9e428d5fde12d5cbea";

export default node;

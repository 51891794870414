import { TouchableOpacity } from 'react-native';
import { Card } from '@rneui/themed';

import Container from '../Container';
import GoalTitle from '../GoalTitle';
import GoalBadge from './GoalBadge';
import GoalChildren from './GoalChildren';
import GoalProgress from '../GoalProgress';

import { createFragmentContainer, graphql } from 'react-relay';
import type { GoalCard_goal } from 'jakiro-types/GoalCard_goal.graphql';

import { Colors, fontTypes } from '../../utils/constants';

import badgeColors from './badge-colors';
import styles from './shared-styles';

/**
 * These enums have been added in for a feature of relay generated flow typings
 *
 * An explanation of this relay feature can be found here: https://github.com/facebook/relay/issues/2351
 */
type getColorState = 'active' | 'draft' | 'ended' | 'inactive' | '%future added value';
type getColorStatus = ('green' | 'amber' | 'red' | '%future added value') | null;

function getColors(state: getColorState, status: getColorStatus) {
  let color = 'gray';
  const isActive = state === 'active';
  if (status && isActive && status !== '%future added value') {
    color = status;
  }

  return badgeColors[color];
}

type Props = {
  cardStyle?: any;
  goal: GoalCard_goal;
  onPress: (goalEntityId: string) => void;
};

const GoalCard = ({ cardStyle = { width: 280 }, goal, onPress }: Props) => {
  const { entityId, name, childrenList, state, status } = goal;

  const goalProgressColors = getColors(state, status);

  return (
    <TouchableOpacity onPress={() => onPress(entityId)}>
      <Card wrapperStyle={styles.cardWrapperStyle} containerStyle={[styles.cardStyle, cardStyle]}>
        <Container justify="space-between" align="flex-start" flex={1}>
          <Container
            direction="row"
            justify="space-between"
            align="flex-start"
            flex={1}
            style={{ width: '100%' }}
          >
            <GoalTitle
              title={name}
              titleStyle={[styles.titleStyle, styles.textStyle]}
              numberOfLines={2}
              wrapperStyle={{ flexDirection: 'row', flex: 1, marginTop: 16, marginLeft: 20 }}
              iconColor={Colors.darkestgray}
              iconSize={fontTypes.title.fontSize}
              isPrivate={goal.private}
            />
            <GoalBadge {...goalProgressColors} goal={goal} />
          </Container>
          <Container style={{ paddingLeft: 20, paddingRight: 20 }} flex={1}>
            <GoalChildren childGoals={childrenList ? childrenList.length : 0} />
          </Container>
          <Container style={{ paddingLeft: 20, paddingRight: 20, width: '100%' }} flex={1}>
            <GoalProgress
              goal={goal}
              progressBackgroundStyle={{ backgroundColor: Colors.lightgray }}
              progressFillStyle={{ backgroundColor: goalProgressColors.color }}
              startAndGoalStyle={{ color: '#617080', marginBottom: 16 }}
            />
          </Container>
        </Container>
      </Card>
    </TouchableOpacity>
  );
};

export default createFragmentContainer(GoalCard, {
  goal: graphql`
    fragment GoalCard_goal on Goal {
      childrenList {
        id
      }
      entityId
      id
      name
      private
      state
      status
      ...GoalProgress_goal
      ...GoalBadge_goal
    }
  `,
});

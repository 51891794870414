import { View, Text, StyleSheet } from 'react-native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import Container from './Container';
import { fontTypes } from '../utils/constants';

type Props = {
  title: string;
  isPrivate?: boolean;
  numberOfLines?: number;
  titleStyle?: any;
  iconSize?: number;
  iconColor?: string;
  wrapperStyle?: any;
  testID?: string;
};

const GoalTitle = (props: Props) => {
  const {
    title,
    isPrivate = false,
    numberOfLines = 1,
    titleStyle = fontTypes.bodyTitle,
    iconSize = fontTypes.bodyTitle.fontSize,
    iconColor = fontTypes.bodyTitle.color,
    wrapperStyle = {},
    testID,
  } = props;
  return (
    <View testID={testID} style={{ ...wrapperStyle, width: '100%' }}>
      <Container direction="row" align="baseline" justify="flex-start" style={{ width: '100%' }}>
        {isPrivate && (
          <MaterialIcons name="lock" size={iconSize} color={iconColor} style={styles.iconStyle} />
        )}

        <Text numberOfLines={numberOfLines} style={titleStyle}>
          {title}
        </Text>
      </Container>
    </View>
  );
};

const styles = StyleSheet.create({
  iconStyle: { marginTop: 2.5 },
});

export default GoalTitle;

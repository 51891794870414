import * as React from 'react';

/**
 * Renders static content efficiently by allowing React to short-circuit the
 * reconciliation process. This component should be used when you know that a
 * subtree of components will never need to be updated.
 *
 *   var someValue = ...; // We know for certain this value will never change.
 *   return (
 *     <StaticContainer>
 *       <MyComponent value={someValue} />
 *     </StaticContainer>
 *   );
 *
 * Typically, you will not need to use this component and should opt for normal
 * React reconciliation.
 */

export default class StaticContainer extends React.Component<any> {
  shouldComponentUpdate(nextProps: any): boolean {
    return !!nextProps.shouldUpdate;
  }

  render() {
    const child = this.props.children;

    if (child === null || child === false) {
      return null;
    }

    return React.Children.only(child);
  }
}

type GoalOrMetricType = 'binary' | 'digit' | 'dollar' | 'percent' | '%future added value';

export default function getGoalUpdateText(amount: number | null, type: GoalOrMetricType | null) {
  let amountText = amount;

  if (type === 'percent') {
    // @ts-expect-error
    amountText = amount && `${amount.toFixed(2)}%`;
  } else if (type === 'dollar') {
    // @ts-expect-error
    amountText = amount && `$${amount}`;
  }

  return amountText;
}

/**
 * @generated SignedSource<<c467574e247493e8965993909dec3e15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SaveOneOnOneNoteInput = {
  checkinRelationshipId?: string | null;
  clientMutationId?: string | null;
  meetingId?: string | null;
  note?: string | null;
};
export type saveOneOnOneNoteMutation$variables = {
  input: SaveOneOnOneNoteInput;
};
export type saveOneOnOneNoteMutationVariables = saveOneOnOneNoteMutation$variables;
export type saveOneOnOneNoteMutation$data = {
  readonly saveOneOnOneNote: {
    readonly meeting: {
      readonly note: string | null;
      readonly id: string;
    } | null;
    readonly errors: ReadonlyArray<string | null> | null;
  } | null;
};
export type saveOneOnOneNoteMutationResponse = saveOneOnOneNoteMutation$data;
export type saveOneOnOneNoteMutation = {
  variables: saveOneOnOneNoteMutationVariables;
  response: saveOneOnOneNoteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SaveOneOnOneNotePayload",
    "kind": "LinkedField",
    "name": "saveOneOnOneNote",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OneOnOneMeeting",
        "kind": "LinkedField",
        "name": "meeting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "note",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "saveOneOnOneNoteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "saveOneOnOneNoteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8ce50eb510b501effa8465896dc8fb7a",
    "id": null,
    "metadata": {},
    "name": "saveOneOnOneNoteMutation",
    "operationKind": "mutation",
    "text": "mutation saveOneOnOneNoteMutation(\n  $input: SaveOneOnOneNoteInput!\n) {\n  saveOneOnOneNote(input: $input) {\n    meeting {\n      note\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "2642e10eaab09dd9bbeff50b9b79b9a7";

export default node;

import { graphql, commitMutation } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';

import type { submitReviewRequestMutationVariables } from 'jakiro-types/submitReviewRequestMutation.graphql';

const mutation = graphql`
  mutation submitReviewRequestMutation($input: SubmitReviewRequestInput!) {
    submitReviewRequest(input: $input) {
      reviewRequest {
        submittedAt
      }
    }
  }
`;

type Args = {
  reviewRequestId: string;
};

export function commit(args: Args, onCompleted: any, onError: any) {
  const variables: submitReviewRequestMutationVariables = {
    input: {
      ...args,
    },
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

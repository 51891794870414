import * as React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { Colors } from '../../utils/constants';

import Icon from '../Icon';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'slim';

type Props = {
  children?: React.ReactNode;
  onPress: () => void;
  iconColor?: string;
  iconName?: string;
  variant?: ButtonVariant;
};

export function Button({ children, iconColor, iconName, onPress, variant = 'primary' }: Props) {
  return (
    <View style={{ alignSelf: 'center' }}>
      <TouchableOpacity onPress={onPress} style={getButtonStyle(variant)}>
        {iconName && <Icon color={iconColor ?? variants[variant].text.color} name={iconName} />}
        {/* RN <Text> does not inherit styles so we need to pass them separately */}
        <Text style={getTextStyle(variant)}>{children}</Text>
      </TouchableOpacity>
    </View>
  );
}

const getTextStyle = (variant: ButtonVariant) => {
  const baseTextStyle = {
    fontSize: 17,
    fontWeight: '600',
    fontFamily: 'SFProDisplay-SemiBold',
    lineHeight: 25,
    textAlign: 'center',
    paddingHorizontal: 8,
  } as const;

  switch (variant) {
    case 'primary':
      return { ...baseTextStyle, ...variants.primary.text } as const;

    case 'secondary':
      return { ...baseTextStyle, ...variants.secondary.text } as const;

    case 'tertiary':
      return { ...baseTextStyle, ...variants.tertiary.text } as const;

    case 'slim':
      return { ...baseTextStyle, ...variants.slim.text } as const;

    default:
      return { ...baseTextStyle, ...variants.primary.text } as const;
  }
};

const getButtonStyle = (variant: ButtonVariant) => {
  const baseButtonStyle = {
    alignItems: 'center',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    paddingHorizontal: 8,
    paddingVertical: 8,
  } as const;

  switch (variant) {
    case 'primary':
      return { ...baseButtonStyle, ...variants.primary.button } as const;

    case 'secondary':
      return { ...baseButtonStyle, ...variants.secondary.button } as const;

    case 'tertiary':
      return { ...baseButtonStyle, ...variants.tertiary.button } as const;

    case 'slim':
      return { ...baseButtonStyle, ...variants.slim.button } as const;

    default:
      return { ...baseButtonStyle, ...variants.primary.button } as const;
  }
};

const variants = {
  primary: {
    button: {
      backgroundColor: Colors.brightblue,
    },
    text: {
      color: Colors.white,
    },
  },
  secondary: {
    button: {
      backgroundColor: Colors.white,
      borderWidth: 1,
      borderColor: Colors.gray,
    },
    text: {
      color: Colors.darkestgray,
    },
  },
  tertiary: {
    button: {
      backgroundColor: Colors.white,
    },
    text: {
      color: Colors.darkestgray,
    },
  },
  slim: {
    button: {
      backgroundColor: 'transparent',
      paddingHorizontal: 0,
    },
    text: {
      color: Colors.brightblue,
    },
  },
};

import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Markdown from 'react-native-markdown-display';

import { Colors, fontTypes } from '../../utils/constants';

// Replace <br> tags with new lines so they display correctly
export function formatDetails(surveyDetails: string) {
  if (!surveyDetails.includes('<br>')) return surveyDetails;

  return surveyDetails.replace(/<br>/g, `${'\n'}`);
}

export function SurveyDetailsPanel({ surveyDetails }: { surveyDetails: string }) {
  return (
    <View style={styles.container}>
      <View style={styles.panel}>
        <Text style={fontTypes.bodyTitle}>Survey details</Text>
        {/**
         * TODO investigate whether or not this style cascades
         * @ts-expect-error */}
        <Markdown style={fontTypes.body}>{formatDetails(surveyDetails)}</Markdown>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: 24,
  },
  panel: {
    position: 'relative',
    backgroundColor: `${Colors.white}`,
    borderRadius: 4,
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 3,
    shadowColor: 'rgba(35, 43, 51, 0.16)',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 24,
    borderWidth: 1,
    borderColor: `${Colors.border}`,
    borderStyle: 'solid',
    borderLeftWidth: 8,
    borderLeftColor: '#70b1eb',
  },
});

import { commitMutation } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';

type Args = {
  mutation: any;
  mutationName: string;
  variables?: any;
  onCompleted: any;
  onError: any;
};

export default function commit(args: Args) {
  const { mutation, mutationName, variables, onCompleted, onError } = args;

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    onCompleted: (resp, err) => {
      if (err) return onError(err);

      const payload = resp?.[mutationName];
      const errors = payload?.errors || [];

      if (errors.length) {
        return onError(payload);
      }

      return onCompleted(resp);
    },
    onError,
  });
}

import { commitMutation, graphql } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';
import {
  listRecordAddUpdater /* , optimisticListRecordAddUpdater*/,
} from '../utils/mutationHelpers';
import type { addTalkingPointToNextOneOnOneMeetingMutationVariables } from 'jakiro-types/addTalkingPointToNextOneOnOneMeetingMutation.graphql';

const mutation = graphql`
  mutation addTalkingPointToNextOneOnOneMeetingMutation(
    $input: AddTalkingPointToNextOneOnOneMeetingInput!
  ) {
    addTalkingPointToNextOneOnOneMeeting(input: $input) {
      errors
      talkingPoint {
        __typename
        body
        entityId
        creator {
          avatarUrl
          viewerIsUser
        }
        meeting {
          entityId
        }
        completedAt
      }
    }
  }
`;

type Fn = (val: any) => void;

function commit(
  vars: addTalkingPointToNextOneOnOneMeetingMutationVariables['input'],
  onCompleted: Fn,
  onError: Fn,
  parentId: string,
) {
  const variables = {
    input: {
      ...vars,
    },
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    updater: store => {
      if (parentId) {
        listRecordAddUpdater({
          parentId,
          parentFieldName: 'talkingPoints',
          payloadItemName: 'talkingPoint',
          mutationName: 'addTalkingPointToNextOneOnOneMeeting',
          store,
          behavior: 'append',
        });
      }
    },
    // optimisticUpdater: store => {
    //   optimisticListRecordAddUpdater({
    //     parentId,
    //     parentFieldName: 'talkingPoints',
    //     payload: vars,
    //     payloadItemName: 'talkingPoint',
    //     store,
    //     behavior: 'append',
    //   });
    // },
    onCompleted,
    onError,
  });
}

export default { commit };

import * as React from 'react';
import { graphql } from 'react-relay';
import styled, { css } from '@emotion/native';
import { ListItem } from '@rneui/themed';
import { TouchableOpacity, ScrollView, View, Text } from 'react-native';
import { flatten } from 'lodash';
import type { StackNavigationProp } from '@react-navigation/stack';

import Header from '../../components/Header';
import Container from '../../components/Container';
import { ProgressBar } from '../../components/surveys/ProgressBar';
import type { RootStackParamList } from '../../components/Navigation/types';
import { ListItemChevron } from '../../components/ListItemChevron';

import { cleanConnection } from '../../utils/fns';
import {
  fontTypes,
  Colors,
  APPLICATION_MAX_WIDTH_STRING,
  ListItemStyle,
} from '../../utils/constants';
import createQueryRenderer from '../../utils/createQueryRenderer';

import type { ReviewCycleSelectQueryResponse } from 'jakiro-types/ReviewCycleSelectQuery.graphql';

type Props = ReviewCycleSelectQueryResponse & {
  navigation: StackNavigationProp<RootStackParamList, 'Review Cycle'>;
};

function Shell({ children }) {
  return (
    <React.Fragment>
      <Header
        title="Write Reviews"
        mode="BACK"
        containerStyle={css`
          width: 100%;
        `}
      />

      {children}
    </React.Fragment>
  );
}

function ReviewCycleResponse({ viewer, navigation }: Props) {
  const reviewCycle = viewer?.findReviewCycle;

  if (!reviewCycle) return null;

  const reviewGroupsToRespondNotSorted = reviewCycle.viewerReviewGroupsToRespond || [];
  const reviewCycleEntityId = reviewCycle.entityId;

  const reviewGroupsToRespond = reviewGroupsToRespondNotSorted.reduce((acc, group) => {
    if (!group || !group.type) return acc;

    // flow is being dumb and thinks that we can get here without
    // group.type, regardless of the early return above 🤔
    // which is why we're using the String constructor
    if (acc[group.type]) {
      acc[String(group.type)] = {
        ...acc[group.type],
        reviewRequests: [
          ...acc[group.type].reviewRequests,
          ...cleanConnection(group.reviewRequests),
        ],
      };
    } else {
      acc[String(group.type)] = {
        ...group,
        reviewRequests: cleanConnection(group.reviewRequests),
      };
    }

    return acc;
  }, {});

  const selfReview = reviewGroupsToRespond['self'];
  const downwardReview = reviewGroupsToRespond['manager'];
  const upwardReview = reviewGroupsToRespond['report'];
  const peer = reviewGroupsToRespond['peer'];

  const groups = Object.values(reviewGroupsToRespond);
  // TS doesn't understand that "groups" is a collection of arrays
  // that have already been "cleaned" in the above reduce and instead
  // insists that groups is a collection raw gql connections which
  // don't have reviewRequests (instead have edges.node.submittedAt)
  // @ts-expect-error
  const reviewRequestCountByGroup = groups.map(g => g.reviewRequests).filter(Boolean);

  const totalReviews = flatten(reviewRequestCountByGroup);

  const answered = totalReviews.filter(o => o.submittedAt).length;

  const progress = (answered / totalReviews.length) * 100;

  return (
    <Shell>
      <ScrollView
        style={css`
          width: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          background-color: ${Colors.faint};
        `}
      >
        <Container
          flex={1}
          style={css`
            max-width: ${APPLICATION_MAX_WIDTH_STRING};
            width: 100%;
            margin: 0 auto;
          `}
        >
          {answered === 0 && totalReviews.length === 0 ? (
            // if we're only scoring, let's still render a spacer,
            // but we won't have a progress bar!
            <View
              style={css`
                height: 32px;
                width: 100%;
              `}
            />
          ) : (
            <View
              style={css`
                padding-top: 32px;
                padding-bottom: 32px;
                padding-left: 64px;
                padding-right: 64px;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
              `}
            >
              <ProgressBar progress={progress} />

              <Text style={[fontTypes.bodyTitle, { paddingLeft: 16, minWidth: 60 }]}>
                {answered} / {totalReviews.length}
              </Text>
            </View>
          )}

          {selfReview &&
            Array.isArray(selfReview.reviewRequests) &&
            selfReview.reviewRequests.length > 0 && (
              <Section>
                <Headline>Self Review</Headline>
                {selfReview.reviewRequests.map((rr, i) => (
                  <ReviewItem
                    title={rr.reviewee.user?.name}
                    entityId={rr.entityId}
                    isCompleted={rr.submittedAt || rr.isRejected}
                    key={rr.entityId}
                    isFirst={i === 0}
                    reviewCycleEntityId={reviewCycleEntityId}
                    navigation={navigation}
                  />
                ))}
              </Section>
            )}

          {downwardReview &&
            Array.isArray(downwardReview.reviewRequests) &&
            downwardReview.reviewRequests.length > 0 && (
              <Section>
                <Headline>
                  {reviewCycle.isProjectBased ? 'Team members' : 'Direct reports'}
                </Headline>
                {downwardReview.reviewRequests.map((rr, i) => (
                  <ReviewItem
                    title={rr.reviewee.user?.name}
                    entityId={rr.entityId}
                    isCompleted={rr.submittedAt || rr.isRejected}
                    key={rr.entityId}
                    isFirst={i === 0}
                    reviewCycleEntityId={reviewCycleEntityId}
                    navigation={navigation}
                  />
                ))}
              </Section>
            )}

          {upwardReview &&
            Array.isArray(upwardReview.reviewRequests) &&
            upwardReview.reviewRequests.length > 0 && (
              <Section>
                <Headline>{reviewCycle.isProjectBased ? 'Project lead' : 'Manager'}</Headline>
                {upwardReview.reviewRequests.map((rr, i) => (
                  <ReviewItem
                    title={rr.reviewee.user?.name}
                    entityId={rr.entityId}
                    isCompleted={rr.submittedAt || rr.isRejected}
                    key={rr.entityId}
                    isFirst={i === 0}
                    reviewCycleEntityId={reviewCycleEntityId}
                    navigation={navigation}
                  />
                ))}
              </Section>
            )}

          {peer && Array.isArray(peer.reviewRequests) && peer.reviewRequests.length > 0 && (
            <Section>
              <Headline>Peers</Headline>
              {peer.reviewRequests.map((rr, i) => (
                <ReviewItem
                  title={rr.reviewee.user?.name}
                  entityId={rr.entityId}
                  isCompleted={rr.submittedAt || rr.isRejected}
                  key={rr.entityId}
                  isFirst={i === 0}
                  reviewCycleEntityId={reviewCycleEntityId}
                  navigation={navigation}
                />
              ))}
            </Section>
          )}
        </Container>
      </ScrollView>
    </Shell>
  );
}

const ReviewItem = ({
  entityId,
  reviewCycleEntityId,
  title = 'No name',
  isFirst,
  navigation,
  isCompleted,
}: {
  entityId: string;
  reviewCycleEntityId: string;
  title?: string;
  isFirst: boolean;
  navigation: StackNavigationProp<RootStackParamList, 'Review Cycle'>;
  isCompleted: boolean;
}) => (
  <ListItem
    style={ListItemStyle}
    Component={TouchableOpacity}
    key={entityId}
    onPress={() => {
      navigation.navigate('Review Response', {
        reviewRequestEntityId: entityId,
        reviewCycleEntityId,
      });
    }}
    topDivider={isFirst}
    bottomDivider
  >
    <ListItem.Content>
      <ListItem.Title
        style={css`
          text-decoration-line: ${isCompleted ? 'line-through' : 'none'};
        `}
      >
        {title}
      </ListItem.Title>
    </ListItem.Content>
    <ListItemChevron />
  </ListItem>
);

const Headline = styled(Text)`
  ${fontTypes.bodyTitle}
  padding-left: 16px;
  text-transform: uppercase;
  padding-bottom: 8px;
  color: ${Colors.darkergray};
`;

const Section = styled.View`
  margin-bottom: 32px;
  width: 100%;
`;

export default createQueryRenderer(ReviewCycleResponse, ReviewCycleResponse, {
  query: graphql`
    query ReviewCycleSelectQuery($reviewCycleEntityId: String!) {
      viewer {
        findReviewCycle(reviewCycleId: $reviewCycleEntityId) {
          entityId
          isProjectBased
          name
          scorableRevieweesWithouManagerTemplate: viewerDirectReportsWithoutManagerTemplate(
            first: 100
          ) {
            edges {
              node {
                entityId
                user {
                  name
                }
              }
            }
          }
          viewerReviewGroupsToRespond {
            type
            reviewRequests: viewerReviewRequests(first: 250) {
              edges {
                node {
                  entityId
                  submittedAt
                  isRejected
                  reviewee {
                    user {
                      name
                    }
                    closedAt
                  }
                  reviewGroup {
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  queriesParams: ({ route }) => ({
    reviewCycleEntityId: route.params.reviewCycleEntityId,
  }),
});

import { StyleSheet, View } from 'react-native';

type Props = {
  progress: number;
  style?: any;
  fillStyle?: any;
  extraStyle?: any;
  backgroundStyle?: any;
};

const ProgressBar = (props: Props) => {
  const { progress, style, fillStyle, extraStyle, backgroundStyle } = props;

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={[styles.defaultStyle, style]}>
        <View style={[styles.background, backgroundStyle]}>
          <View style={[styles.fill, fillStyle, { width: `${progress * 100}%` }]} />
          <View style={[styles.extra, extraStyle, { width: `${(1 - progress) * 100}%` }]} />
        </View>
      </View>
    </View>
  );
};

var styles = StyleSheet.create({
  defaultStyle: {
    height: 5,
  },
  background: {
    backgroundColor: '#bbbbbb',
    overflow: 'hidden',
    flex: 1,
    flexDirection: 'row',
  },
  fill: {
    backgroundColor: '#3b5998',
  },
  extra: {
    backgroundColor: 'transparent',
  },
});

export default ProgressBar;

import * as React from 'react';
import { Text, StyleSheet, TouchableOpacity, View } from 'react-native';
import { createFragmentContainer, graphql } from 'react-relay';
import { Avatar } from '@rneui/themed';
import { LinearGradient } from 'expo-linear-gradient';
import { css } from '@emotion/native';
import type { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';

import type { RootStackParamList } from '../../components/Navigation/types';
import { getInitials } from '../../utils/fns';

import type { PersonCell_user } from 'jakiro-types/PersonCell_user.graphql';

type Props = {
  user: PersonCell_user;
};

function PersonCell(props: Props) {
  const { user } = props;

  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate('Profile', {
          userEntityId: user.entityId,
        })
      }
      style={css`
        width: 33%;
      `}
    >
      <View
        style={css`
          position: relative;
          height: 150px;
          width: 100%;
        `}
      >
        <Avatar
          source={{ uri: user.avatarUrl }}
          // this override is legit
          // @ts-expect-error
          style={[
            css`
              width: 100%;
              height: 100%;
            `,
            StyleSheet.absoluteFill,
          ]}
          title={getInitials(user.name)}
          titleStyle={{ fontSize: 48 }}
        />

        <LinearGradient
          colors={['transparent', 'rgba(0,0,0,0.4)']}
          style={css`
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            height: 50px;
          `}
        />

        <View
          style={css`
            position: absolute;
            bottom: 0;
            width: 100%;
            z-index: 10;
          `}
        >
          <Text
            style={css`
              padding-top: 12px;
              padding-left: 8px;
              padding-right: 8px;
              padding-bottom: 12px;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              z-index: 20;
              color: white;
              text-shadow-color: rgba(0, 0, 0, 0.5);
              text-shadow-radius: 10px;
            `}
          >
            {user.name}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

// Never re-render, this prevents a flicker and should be ok? lol
export default createFragmentContainer(React.memo(PersonCell), {
  user: graphql`
    fragment PersonCell_user on User {
      name
      email
      entityId
      avatarUrl(shape: SQUARE)
    }
  `,
});

import * as React from 'react';
import { View, Text } from 'react-native';
import { css } from '@emotion/native';

import { Colors, fontTypes } from '../utils/constants';

type Props = {
  variant: 'warning' | 'info' | 'primary';
  children: React.ReactNode;
  style?: any;
};

const colorMap = {
  warning: {
    backgrounColor: '#fff8eb',
    textColor: '#996806',
  },
  info: {
    backgrounColor: Colors.lightergray,
    textColor: Colors.darkergray,
  },
  primary: {
    backgrounColor: Colors.lighterblue,
    textColor: Colors.darkblue,
  },
};
const Badge = ({ children, variant = 'primary', style = {} }: Props) => {
  return (
    <View
      style={[
        css`
          background-color: ${colorMap[variant].backgrounColor};
          border-radius: 4px;
          padding-top: 4px;
          padding-bottom: 4px;
          padding-left: 8px;
          padding-right: 8px;
        `,
        style,
      ]}
    >
      <Text
        style={[
          fontTypes.informative_text_small,
          {
            color: colorMap[variant].textColor,
          },
        ]}
      >
        {children}
      </Text>
    </View>
  );
};

export default Badge;

/**
 * @generated SignedSource<<2e41ff450a4635d5e99b6fb8e3a0ffab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DestroyOneOnOneTalkingPointCommentInput = {
  clientMutationId?: string | null;
  talkingPointCommentId?: string | null;
  talkingPointId: string;
};
export type destroyOneOnOneTalkingPointCommentMutation$variables = {
  input: DestroyOneOnOneTalkingPointCommentInput;
};
export type destroyOneOnOneTalkingPointCommentMutationVariables = destroyOneOnOneTalkingPointCommentMutation$variables;
export type destroyOneOnOneTalkingPointCommentMutation$data = {
  readonly destroyOneOnOneTalkingPointComment: {
    readonly talkingPoint: {
      readonly id: string;
      readonly comments: ReadonlyArray<{
        readonly id: string;
        readonly createdAt: DateString;
        readonly body: string | null;
        readonly user: {
          readonly viewerIsUser: boolean;
          readonly avatarUrl: string | null;
          readonly name: string | null;
        };
      }>;
    } | null;
    readonly errors: ReadonlyArray<string | null> | null;
  } | null;
};
export type destroyOneOnOneTalkingPointCommentMutationResponse = destroyOneOnOneTalkingPointCommentMutation$data;
export type destroyOneOnOneTalkingPointCommentMutation = {
  variables: destroyOneOnOneTalkingPointCommentMutationVariables;
  response: destroyOneOnOneTalkingPointCommentMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsUser",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "destroyOneOnOneTalkingPointCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DestroyOneOnOneTalkingPointCommentPayload",
        "kind": "LinkedField",
        "name": "destroyOneOnOneTalkingPointComment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "talkingPoint",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OneOnOneTalkingPointComment",
                "kind": "LinkedField",
                "name": "comments",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "destroyOneOnOneTalkingPointCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DestroyOneOnOneTalkingPointCommentPayload",
        "kind": "LinkedField",
        "name": "destroyOneOnOneTalkingPointComment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "talkingPoint",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OneOnOneTalkingPointComment",
                "kind": "LinkedField",
                "name": "comments",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2745e6c9f2585e3b43c28c3e542c94a8",
    "id": null,
    "metadata": {},
    "name": "destroyOneOnOneTalkingPointCommentMutation",
    "operationKind": "mutation",
    "text": "mutation destroyOneOnOneTalkingPointCommentMutation(\n  $input: DestroyOneOnOneTalkingPointCommentInput!\n) {\n  destroyOneOnOneTalkingPointComment(input: $input) {\n    talkingPoint {\n      __typename\n      id\n      comments {\n        id\n        createdAt\n        body\n        user {\n          viewerIsUser\n          avatarUrl\n          name\n          id\n        }\n      }\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "e2408862fbdcd023cdb13741e35cfa9a";

export default node;

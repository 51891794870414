import { graphql } from 'react-relay';

import GoalHeader from './GoalHeader';
import GoalProfileKeyResultsTab from './GoalProfileKeyResultsTab';
import GoalProfileTimelineTab from './GoalProfileTimelineTab';
import GoalProfileInformationTab from './GoalProfileInformationTab';

import createQueryRenderer from '../../utils/createQueryRenderer';

import type { goalsQueryResponse } from 'jakiro-types/goalsQuery.graphql';

type Props = {
  goalId: string;
} & goalsQueryResponse;

const GoalProfile = ({ viewer }: Props) => {
  if (!viewer || !viewer.goal) return null;

  const routes = [
    {
      key: 'timeline',
      title: 'Timeline',
      component: <GoalProfileTimelineTab activities={viewer.goal} />,
    },
    {
      key: 'information',
      title: 'Information',
      component: <GoalProfileInformationTab information={viewer.goal} />,
    },
  ];

  // Prevent the KeyResultsTab from showing when there are no metrics
  const hasChildGoals = viewer.goal.childrenList?.length;
  if (hasChildGoals) {
    routes.unshift({
      key: 'key-results',
      title: 'Key Results',
      component: <GoalProfileKeyResultsTab metricsList={viewer.goal} />,
    });
  }

  return <GoalHeader routes={routes} goal={viewer.goal} />;
};

export default createQueryRenderer(GoalProfile, GoalProfile, {
  query: graphql`
    query goalsQuery($goalId: String!) {
      viewer {
        goal: findGoal(goalId: $goalId) {
          childrenList {
            entityId
          }
          ...GoalHeader_goal
          ...GoalProfileTimelineTab_activities
          ...GoalProfileKeyResultsTab_metricsList
          ...GoalProfileInformationTab_information
        }
      }
    }
  `,
  getLoadingProp: false,
  queriesParams: ({ route }) => {
    const goalIds = route.params.goalIds;
    return { goalId: Array.isArray(goalIds) ? goalIds[goalIds.length - 1] : goalIds };
  },
});

import * as React from 'react';
import { View, Text } from 'react-native';
import { css } from '@emotion/native';

import { fontTypes, Colors } from '../utils/constants';

type Props = {
  children: any;
  containerStyle?: any;
  type?: 'warn' | 'error';
  title?: string | null;
};

const colors = {
  warn: {
    bg: Colors.lighteryellow,
    text: Colors.darkyellow,
  },
  error: {
    bg: Colors.lighterred,
    text: Colors.darkred,
  },
};

export default function CalloutBanner({ children, containerStyle, title, type = 'warn' }: Props) {
  return (
    <View
      style={[
        css`
          padding: 16px;
          margin-bottom: 16px;
          background-color: ${colors[type].bg};
          border-radius: 4px;
        `,
        containerStyle,
      ]}
    >
      {title && (
        <Text style={[fontTypes.title, { paddingBottom: 8, color: Colors.darkyellow }]}>
          {title}
        </Text>
      )}

      <Text style={(fontTypes.body, { color: colors[type].text })}>{children}</Text>
    </View>
  );
}

import { truncate } from 'lodash';
import { css } from '@emotion/native';

import { Colors, fontTypes } from '../../utils/constants';
import Button from '../Button';

type AddCommentProps = {
  comment: string | null;
  title?: string;
  onPress: () => void;
};

export function AddComment({ onPress, comment, title }: AddCommentProps) {
  return (
    <Button
      onPress={onPress}
      title={comment !== '' ? truncate(comment, { length: 40 }) : title || 'Add a comment...'}
      type="clear"
      containerStyle={css`
        width: 100%;
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
      `}
      titleProps={{
        style: css`
          font-size: 17px;
          line-height: 25px;
          font-family: 'SFProDisplay-SemiBold';
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: ${fontTypes.subHeaderBodyTitleSecondary};
        `,
      }}
    />
  );
}

type ActionButtonProps = {
  title: string;
  primary?: boolean;
  onPress?: () => void;
  loading?: boolean;
  disabled?: boolean;
};

export function ActionButton({ title, primary, onPress, loading, disabled }: ActionButtonProps) {
  return (
    <Button
      title={title}
      loadingProps={{
        color: primary ? 'white' : 'black',
        style: css`
          width: 100%;
        `,
      }}
      containerStyle={css`
        width: 100%;
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
      `}
      buttonStyle={css`
        background-color: ${primary ? Colors.iosBlue : 'rgba(0,0,0,0.05)'};
        width: 100%;
        height: 50px;
      `}
      titleProps={{
        style: css`
          font-size: 17px;
          line-height: 25px;
          font-family: 'SFProDisplay-SemiBold';
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: ${primary ? 'white' : 'black'};
        `,
      }}
      disabledStyle={css`
        background-color: rgba(0, 0, 0, 0.05);
        opacity: 0.8;
      `}
      disabled={Boolean(disabled)}
      loading={loading}
      onPress={onPress}
    />
  );
}

/**
 * @generated SignedSource<<e58d9e2ab71140440552624e61cf38db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserStatusEnum = "ACTIVE" | "CREATED" | "DEACTIVATED" | "INVITED";
export type DirectoryDepartmentEmployeesRefetchQuery$variables = {
  first: number;
  after: string;
  departmentEntityId: string;
  status?: ReadonlyArray<UserStatusEnum | null> | null;
};
export type DirectoryDepartmentEmployeesRefetchQueryVariables = DirectoryDepartmentEmployeesRefetchQuery$variables;
export type DirectoryDepartmentEmployeesRefetchQuery$data = {
  readonly viewer: {
    readonly findDepartment: {
      readonly " $fragmentSpreads": FragmentRefs<"DirectoryDepartmentEmployees_department">;
    } | null;
  } | null;
};
export type DirectoryDepartmentEmployeesRefetchQueryResponse = DirectoryDepartmentEmployeesRefetchQuery$data;
export type DirectoryDepartmentEmployeesRefetchQuery = {
  variables: DirectoryDepartmentEmployeesRefetchQueryVariables;
  response: DirectoryDepartmentEmployeesRefetchQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "departmentEntityId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v4 = [
  {
    "kind": "Variable",
    "name": "departmentId",
    "variableName": "departmentEntityId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DirectoryDepartmentEmployeesRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Department",
            "kind": "LinkedField",
            "name": "findDepartment",
            "plural": false,
            "selections": [
              {
                "args": (v5/*: any*/),
                "kind": "FragmentSpread",
                "name": "DirectoryDepartmentEmployees_department"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "DirectoryDepartmentEmployeesRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Department",
            "kind": "LinkedField",
            "name": "findDepartment",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "departmentMembersConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "departmentMembersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "entityId",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "shape",
                                "value": "SQUARE"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "avatarUrl",
                            "storageKey": "avatarUrl(shape:\"SQUARE\")"
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a86d138f320406514a517383d8d3cc89",
    "id": null,
    "metadata": {},
    "name": "DirectoryDepartmentEmployeesRefetchQuery",
    "operationKind": "query",
    "text": "query DirectoryDepartmentEmployeesRefetchQuery(\n  $first: Int!\n  $after: String!\n  $departmentEntityId: String!\n  $status: [UserStatusEnum]\n) {\n  viewer {\n    findDepartment(departmentId: $departmentEntityId) {\n      ...DirectoryDepartmentEmployees_department_2mP0Nu\n      id\n    }\n    id\n  }\n}\n\nfragment DirectoryDepartmentEmployees_department_2mP0Nu on Department {\n  name\n  members(first: $first, after: $after, status: $status) {\n    pageInfo {\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        entityId\n        ...PersonCell_user\n        id\n      }\n    }\n  }\n}\n\nfragment PersonCell_user on User {\n  name\n  email\n  entityId\n  avatarUrl(shape: SQUARE)\n}\n"
  }
};
})();

(node as any).hash = "674a99226736c8eddedbfc44306a2de1";

export default node;

import { getConfig } from './getConfig';

type Data = {
  email: string;
  password: string;
};

export const Login = (
  data: Data,
  companyId: string,
  handleError: any,
  onSuccess: (accessToken: string) => void,
): any =>
  fetch(getConfig('AUTH_URL'), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data, companyId }),
  })
    .then(async res => {
      const results = await res.json();

      if (results?.errors) {
        handleError(results.errors);
        return;
      }

      onSuccess(results.accessToken);
    })
    .catch(handleError);

type PhoneData = {
  countryCode: string;
  subscriberNumber: string;
  companyEntityId: string;
  requestId?: string;
};

export const PhoneLogin = (
  data: PhoneData,
  handleError: any,
  onSuccess: (results: {
    requestId: string;
    userEntityId: string;
    userPhoneDetailEntityId: string;
  }) => void,
): any =>
  fetch(getConfig('PHONE_AUTH_URL'), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(async res => {
      const results = await res.json();

      if (results?.errors) {
        handleError(results.errors);
        return;
      }

      onSuccess(results);
    })
    .catch(handleError);

type PhoneVerificationData = {
  requestId: string;
  userEntityId: string;
  userPhoneDetailEntityId: string;
  verificationCode: string;
};

export const PhoneConfirmationCodeLogin = (
  data: PhoneVerificationData,
  handleError: any,
  onSuccess: (accessToken: string) => void,
): any =>
  fetch(getConfig('PHONE_AUTH_URL_VERIFY'), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(async res => {
      const results = await res.json();

      if (results?.errors) {
        handleError(results.errors);
        return;
      }

      onSuccess(results.accessToken);
    })
    .catch(handleError);

import { useState, useRef, useContext } from 'react';
import { StyleSheet, Image, TouchableOpacity, Keyboard, Linking } from 'react-native';
import { Text } from '@rneui/themed';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { css } from '@emotion/native';
import { RouteProp } from '@react-navigation/native';

import {
  Colors,
  fontTypes,
  ScreenWidth,
  APPLICATION_MAX_WIDTH_STRING,
  APPLICATION_MAX_WIDTH_INT,
} from '../../utils/constants';
import { getConfig } from '../../utils/getConfig';
import withHeader from '../../hocs/withHeader';
import TextInput from '../../components/TextInput';
import Container from '../../components/Container';
import Button, { ButtonStyles } from '../../components/Button';
import { Login } from '../../utils/Login';
import useAuthentication from '../../hooks/useAuthentication';
import { RootStackParamList } from '../../components/Navigation/types';
import { CompanyContext } from '../../hooks/useCompany';

type Props = {
  route: RouteProp<RootStackParamList, 'Login'>;
};

function LoginScreen(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const { setAccessToken } = useAuthentication();
  const { company } = useContext(CompanyContext);

  const passwordRef = useRef(null);

  const { route } = props;

  return (
    <KeyboardAwareScrollView
      style={{ backgroundColor: Colors.white }}
      enableOnAndroid
      scrollEnabled={false}
      contentContainerStyle={styles.container}
    >
      <Container
        flex={1}
        style={css`
          max-width: 100%;
          width: ${APPLICATION_MAX_WIDTH_STRING};
        `}
      >
        <Image
          source={require('../../assets/images/latticeLogo.png')}
          resizeMode="contain"
          style={styles.logo}
        />
        {errorMsg ? (
          <Text style={styles.error}>{errorMsg}</Text>
        ) : (
          <Text style={styles.loginto}>Log into your account</Text>
        )}
        <TextInput
          value={email}
          onChange={value => {
            setEmail(value);
          }}
          keyboardType="email-address"
          placeholder="Email"
          onSubmitEditing={() => {
            passwordRef.current.focus();
          }}
          returnKeyType="next"
        />
        <TextInput
          value={password}
          onChange={value => {
            setPassword(value);
          }}
          placeholder="Password"
          secureTextEntry
          returnKeyType="done"
          inputRef={passwordRef}
        />
        <Button
          title="Continue"
          onPress={async () => {
            Keyboard.dismiss();

            if (!company) return null;

            setIsLoading(true);
            setErrorMsg(null);

            Login(
              { email, password },
              company.entityId,
              (errors: string[]) => {
                setIsLoading(false);
                setErrorMsg(errors[0]);
              },
              accessToken => {
                setAccessToken(accessToken);
                setIsLoading(false);
                setErrorMsg(null);
              },
            );
          }}
          disabled={!(email.length && password.length)}
          disabledStyle={styles.disabled}
          loading={isLoading}
          loadingProps={{ size: 'large', color: 'rgba(111, 202, 186, 1)' }}
          titleStyle={styles.buttonTitle}
          buttonStyle={styles.buttonStyle}
          containerStyle={styles.buttonContainer}
        />
        <TouchableOpacity
          onPress={() => {
            const slug = route.params.company.slug || '';

            Linking.openURL(`${getConfig('SERVER_URL').replace('router', slug)}/reset_password`);
          }}
        >
          <Text style={styles.forgot}>Forgot your password?</Text>
        </TouchableOpacity>
      </Container>
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  forgot: { ...fontTypes.informative_text, marginTop: 30, textDecorationLine: 'underline' },
  loginto: {
    ...fontTypes.bodyTitle,
    marginBottom: 30,
  },
  logo: {
    width: 55,
    height: 31,
    marginBottom: 15,
  },
  disabled: {
    backgroundColor: Colors.gray,
  },
  container: {
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 30,
  },
  buttonTitle: {
    fontSize: 17,
    letterSpacing: -0.41,
    lineHeight: 22,
    fontWeight: '700',
    fontFamily: 'SFProDisplay-SemiBold',
  },
  buttonContainer: {
    marginTop: 15,
  },
  error: {
    ...fontTypes.error,
    marginBottom: 30,
  },
  buttonStyle: {
    ...ButtonStyles.rounded,
    height: 50,
    marginTop: 30,
    backgroundColor: Colors.brightblue,
    width: ScreenWidth - 60,
    maxWidth: APPLICATION_MAX_WIDTH_INT,
  },
});

export default withHeader({ mode: 'BACK', title: 'Log In' })(LoginScreen);

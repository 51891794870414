/**
 * @generated SignedSource<<6214efe20ef0ec1568e879fd8e24e111>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OneOnOneContent_checkinRelationship$data = {
  readonly id: string;
  readonly viewerIsManager: boolean;
  readonly report: {
    readonly products: {
      readonly oneOnOnes: {
        readonly isEnabled: boolean;
      };
    };
  } | null;
  readonly manager: {
    readonly products: {
      readonly oneOnOnes: {
        readonly isEnabled: boolean;
      };
    };
  } | null;
  readonly " $fragmentType": "OneOnOneContent_checkinRelationship";
};
export type OneOnOneContent_checkinRelationship = OneOnOneContent_checkinRelationship$data;
export type OneOnOneContent_checkinRelationship$key = {
  readonly " $data"?: OneOnOneContent_checkinRelationship$data;
  readonly " $fragmentSpreads": FragmentRefs<"OneOnOneContent_checkinRelationship">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserProducts",
    "kind": "LinkedField",
    "name": "products",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProductSettings",
        "kind": "LinkedField",
        "name": "oneOnOnes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEnabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OneOnOneContent_checkinRelationship",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerIsManager",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "report",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "manager",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "CheckinRelationship",
  "abstractKey": null
};
})();

(node as any).hash = "4940b5cbdc64ef749fa66a905c05074f";

export default node;

import * as React from 'react';
import { Text } from 'react-native';
import { Header as Hd } from '@rneui/themed';
import { SafeAreaView } from 'react-native-safe-area-context';

import OptionButton from './OptionButton';
import BackButton from './BackButton';
import Avatar from './Avatar';
import { fontTypes, ScreenWidth, Colors, HEADER_MIN_HEIGHT } from '../utils/constants';
import { getInitials } from '../utils/fns';
import { useNavigation } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';
import type { RootStackParamList } from './Navigation/types';

//  Determines left component
//  BACK renders the arrow
//  CLOSE renders X
//  PROFILE renders Avatar instead.
type Modes = 'HIDE' | 'BACK' | 'CLOSE' | 'PROFILE';

export type Props = {
  title?: string | React.ReactElement<any>;
  handleOptionPress?: () => void;
  mode: Modes;
  handleLeftIconPress?: () => void;
  userImg?: string;
  user?: {
    name: string;
    avatarUrl: string | null;
    email?: string | null;
  };
  bottomComponent?: React.ReactNode | boolean;
  buttonColor?: string;
  backgroundColor?: string;
  tooltipId?: string;
  tooltipText?: string;
  onTooltipClose?: () => void;
  wrapperStyle?: any;
  outerContainerStyles?: any;
  centerComponent?: React.ReactNode;
  hideOptionButton?: boolean;
  containerStyle?: any;
  rightComponent?: React.ReactNode;
  titleColor?: string;
  goBack?: () => void;
};

const Header = ({
  title = '',
  handleOptionPress,
  mode,
  user,
  handleLeftIconPress,
  bottomComponent = null,
  buttonColor,
  backgroundColor = 'white',
  wrapperStyle,
  centerComponent,
  hideOptionButton,
  containerStyle = {},
  rightComponent,
  titleColor,
  goBack,
}: Props) => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const hasBottomComp = Boolean(bottomComponent);
  const hideHeaderBorderBottom = hasBottomComp
    ? { borderBottomColor: 'transparent', borderBottomWidth: 0 }
    : {};

  return (
    <SafeAreaView
      mode="margin"
      edges={['right', 'left']}
      style={[styles.view(!bottomComponent, backgroundColor), wrapperStyle]}
    >
      <Hd
        backgroundColor={backgroundColor}
        containerStyle={{ ...hideHeaderBorderBottom, ...containerStyle }}
        leftComponent={
          mode === 'HIDE' ? null : mode === 'PROFILE' ? (
            user && (
              <Avatar
                avatarUrl={user.avatarUrl}
                title={getInitials(user.name)}
                onPress={handleLeftIconPress}
              />
            )
          ) : (
            <BackButton
              testID="Header_back"
              color={buttonColor}
              type={mode}
              onBack={() => {
                if (goBack) {
                  goBack();
                } else {
                  if (navigation.canGoBack()) {
                    navigation.goBack();
                  } else {
                    navigation.navigate('Home');
                  }
                }
              }}
            />
          )
        }
        centerComponent={
          centerComponent || (
            <Text
              numberOfLines={1}
              style={[styles.headerTitle, titleColor ? { color: titleColor } : {}]}
            >
              {title}
            </Text>
          )
        }
        rightComponent={
          rightComponent ||
          (hideOptionButton ? null : handleOptionPress ? (
            <OptionButton onPress={handleOptionPress} />
          ) : null)
        }
      />
      {bottomComponent}
    </SafeAreaView>
  );
};

const styles = {
  header: (hasBottomComp: boolean): any => ({
    width: ScreenWidth,
    borderBottomColor: Colors.border,
    borderBottomWidth: hasBottomComp ? 0 : 0.5,
    paddingRight: 20,
    paddingLeft: 20,
    height: HEADER_MIN_HEIGHT,
  }),
  headerTitle: {
    ...fontTypes.bodyTitle,
    paddingHorizontal: 30,
  },
  view: (hasBottomComp: boolean, backgroundColor: string): any => ({
    backgroundColor,
    width: ScreenWidth,
    borderBottomWidth: hasBottomComp ? 0.5 : 0,
    borderBottomColor: Colors.border,
  }),
} as const;

export default Header;

/**
 * @generated SignedSource<<67dd94087298980b415c0bd44e9d35b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SaveViewerPrivateOneOnOneNoteInput = {
  checkinRelationshipId?: string | null;
  clientMutationId?: string | null;
  meetingId?: string | null;
  privateNote?: string | null;
};
export type saveViewerPrivateOneOnOneNoteMutation$variables = {
  input: SaveViewerPrivateOneOnOneNoteInput;
};
export type saveViewerPrivateOneOnOneNoteMutationVariables = saveViewerPrivateOneOnOneNoteMutation$variables;
export type saveViewerPrivateOneOnOneNoteMutation$data = {
  readonly saveViewerPrivateOneOnOneNote: {
    readonly meeting: {
      readonly viewerPrivateNote: string | null;
      readonly id: string;
    } | null;
    readonly errors: ReadonlyArray<string | null> | null;
    readonly clientMutationId: string | null;
  } | null;
};
export type saveViewerPrivateOneOnOneNoteMutationResponse = saveViewerPrivateOneOnOneNoteMutation$data;
export type saveViewerPrivateOneOnOneNoteMutation = {
  variables: saveViewerPrivateOneOnOneNoteMutationVariables;
  response: saveViewerPrivateOneOnOneNoteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SaveViewerPrivateOneOnOneNotePayload",
    "kind": "LinkedField",
    "name": "saveViewerPrivateOneOnOneNote",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OneOnOneMeeting",
        "kind": "LinkedField",
        "name": "meeting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerPrivateNote",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "saveViewerPrivateOneOnOneNoteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "saveViewerPrivateOneOnOneNoteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4731c2b5124bca55a078757df4b87588",
    "id": null,
    "metadata": {},
    "name": "saveViewerPrivateOneOnOneNoteMutation",
    "operationKind": "mutation",
    "text": "mutation saveViewerPrivateOneOnOneNoteMutation(\n  $input: SaveViewerPrivateOneOnOneNoteInput!\n) {\n  saveViewerPrivateOneOnOneNote(input: $input) {\n    meeting {\n      viewerPrivateNote\n      id\n    }\n    errors\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "79e87981f4c87b030abdbe04b14a5f28";

export default node;

import * as React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import moment from 'moment';
import { View } from 'react-native';
import { Text } from '@rneui/themed';

import { fontTypes, Colors } from '../utils/constants';
import Container from './Container';
import { UserChipFragment as UserChip } from './UserChip';

import type { TimelineItem_user } from 'jakiro-types/TimelineItem_user.graphql';

type Props = {
  user: TimelineItem_user;
  text?: string;
  children?: React.ReactNode;
  date: Date;
  color: string;
};

const TimelineItem = ({ user, text, children, date, color }: Props) => (
  <View style={styles.view}>
    <Container direction="row" justify="space-between" style={{ paddingBottom: 10 }}>
      <UserChip user={user} />
      <Text style={styles.timeAgo}>{moment(date).fromNow()}</Text>
    </Container>
    <View style={styles.eventText(color)}>
      {text ? <Text style={fontTypes.subHeaderBodyTitle}>{text}</Text> : children}
    </View>
  </View>
);

const styles = {
  title: {
    ...fontTypes.title,
    padding: 10,
  },
  view: {
    width: '100%',
    padding: 20,
  },
  timeAgo: {
    ...fontTypes.subHeaderBodyTitle,
    color: Colors.darkergray,
  },
  eventText: (color: string): any => ({
    borderLeftWidth: 3,
    borderLeftColor: color,
    paddingLeft: 10,
  }),
} as const;

export default createFragmentContainer(TimelineItem, {
  user: graphql`
    fragment TimelineItem_user on User {
      ...UserChip_user
    }
  `,
});

import * as React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { QueryRenderer } from 'react-relay';

import Loading from '../components/Loading';
import HandleNetworkError from '../components/HandleNetworkError';
import environment from './Environment';
import type { GraphqlError } from './Environment';

type Config = {
  query: any;
  queriesParams?: (props: any) => void | null;
  variables?: any;
  endpoint?: string;
  getLoadingProp?: boolean;
  nullablePropName?: string;
  fragmentPropResolver?: (props: any) => void;
};

type Props = any;

export const AuthedEnv = environment();

export default function createQueryRenderer(
  FragmentComponent: React.ComponentType<any>,
  Component: React.ComponentType<any>,
  config: Config,
): React.ComponentType<any> {
  const { query, queriesParams, getLoadingProp, fragmentPropResolver, nullablePropName } = config;

  class QueryRendererWrapper extends React.Component<Props> {
    render() {
      const variables = queriesParams ? queriesParams(this.props) : config.variables;

      return (
        <QueryRenderer
          environment={config.endpoint ? environment(config.endpoint) : AuthedEnv}
          query={query}
          variables={variables}
          render={({ error, props, retry }) => {
            if (error) {
              return <HandleNetworkError error={error as GraphqlError} />;
            }

            if (props) {
              const spreadableProps = fragmentPropResolver ? fragmentPropResolver(props) : props;
              return (
                <FragmentComponent
                  isLoading={false}
                  retry={retry}
                  {...this.props}
                  {...spreadableProps}
                />
              );
            }

            const nullable = {
              [nullablePropName || 'viewer']: null,
            };

            return getLoadingProp ? (
              <FragmentComponent {...nullable} {...this.props} isLoading />
            ) : (
              <Loading />
            );
          }}
        />
      );
    }
  }

  return hoistStatics(QueryRendererWrapper, Component);
}

/**
 * @generated SignedSource<<dcfd4071a8db392fa2b3990146f35d20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FeedbackDeliverableTypeEnum = "managerOnly" | "private" | "privateAndManager" | "public";
export type SubmitFeedbackInput = {
  body: string;
  clientMutationId?: string | null;
  companyValueIds?: ReadonlyArray<string | null> | null;
  competencyAndTrackEntityIds?: competencyAndTrackEntityIds | null;
  deliverableType?: FeedbackDeliverableTypeEnum | null;
  feedbackRequestId?: string | null;
  growthAreaEntityId?: EntityId | null;
  managerOnly?: boolean | null;
  public?: boolean | null;
  targetUserId?: string | null;
  targetUserIds?: ReadonlyArray<string | null> | null;
  via?: string | null;
};
export type competencyAndTrackEntityIds = {
  competencyEntityId: EntityId;
  trackEntityId: EntityId;
};
export type submitFeedbackMutation$variables = {
  input: SubmitFeedbackInput;
};
export type submitFeedbackMutationVariables = submitFeedbackMutation$variables;
export type submitFeedbackMutation$data = {
  readonly submitFeedback: {
    readonly viewer: {
      readonly user: {
        readonly viewerVisibleFeedback: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly __typename: string;
              readonly body: string;
              readonly createdAt: DateString;
              readonly author: {
                readonly avatarUrl: string | null;
                readonly name: string | null;
                readonly preferredName: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      };
    } | null;
    readonly submittedFeedback: {
      readonly targets: ReadonlyArray<{
        readonly receivedViewerVisibleFeedback: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly __typename: string;
              readonly body: string;
              readonly createdAt: DateString;
              readonly author: {
                readonly avatarUrl: string | null;
                readonly name: string | null;
                readonly preferredName: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        readonly givenToUserByViewerVisibleFeedback: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly __typename: string;
              readonly body: string;
              readonly createdAt: DateString;
              readonly author: {
                readonly avatarUrl: string | null;
                readonly name: string | null;
                readonly preferredName: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }>;
    } | null;
    readonly errors: ReadonlyArray<string | null> | null;
  } | null;
};
export type submitFeedbackMutationResponse = submitFeedbackMutation$data;
export type submitFeedbackMutation = {
  variables: submitFeedbackMutationVariables;
  response: submitFeedbackMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v3 = [
  {
    "kind": "Literal",
    "name": "feedbackDirection",
    "value": "GIVEN"
  },
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preferredName",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserVisibleFeedbackEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v12 = [
  {
    "kind": "Literal",
    "name": "feedbackDirection",
    "value": "RECEIVED"
  },
  (v2/*: any*/)
],
v13 = [
  {
    "kind": "Literal",
    "name": "feedbackDirection",
    "value": "GIVEN_TO_USER_BY_VIEWER"
  },
  (v2/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v15 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserVisibleFeedbackEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v4/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isFeedbackInterface"
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "submitFeedbackMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitFeedbackPayload",
        "kind": "LinkedField",
        "name": "submitFeedback",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "UserVisibleFeedbackConnection",
                    "kind": "LinkedField",
                    "name": "viewerVisibleFeedback",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": "viewerVisibleFeedback(feedbackDirection:\"GIVEN\",first:20)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Feedback",
            "kind": "LinkedField",
            "name": "submittedFeedback",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "targets",
                "plural": true,
                "selections": [
                  {
                    "alias": "receivedViewerVisibleFeedback",
                    "args": (v12/*: any*/),
                    "concreteType": "UserVisibleFeedbackConnection",
                    "kind": "LinkedField",
                    "name": "viewerVisibleFeedback",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": "viewerVisibleFeedback(feedbackDirection:\"RECEIVED\",first:20)"
                  },
                  {
                    "alias": "givenToUserByViewerVisibleFeedback",
                    "args": (v13/*: any*/),
                    "concreteType": "UserVisibleFeedbackConnection",
                    "kind": "LinkedField",
                    "name": "viewerVisibleFeedback",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": "viewerVisibleFeedback(feedbackDirection:\"GIVEN_TO_USER_BY_VIEWER\",first:20)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "submitFeedbackMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitFeedbackPayload",
        "kind": "LinkedField",
        "name": "submitFeedback",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "UserVisibleFeedbackConnection",
                    "kind": "LinkedField",
                    "name": "viewerVisibleFeedback",
                    "plural": false,
                    "selections": (v15/*: any*/),
                    "storageKey": "viewerVisibleFeedback(feedbackDirection:\"GIVEN\",first:20)"
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Feedback",
            "kind": "LinkedField",
            "name": "submittedFeedback",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "targets",
                "plural": true,
                "selections": [
                  {
                    "alias": "receivedViewerVisibleFeedback",
                    "args": (v12/*: any*/),
                    "concreteType": "UserVisibleFeedbackConnection",
                    "kind": "LinkedField",
                    "name": "viewerVisibleFeedback",
                    "plural": false,
                    "selections": (v15/*: any*/),
                    "storageKey": "viewerVisibleFeedback(feedbackDirection:\"RECEIVED\",first:20)"
                  },
                  {
                    "alias": "givenToUserByViewerVisibleFeedback",
                    "args": (v13/*: any*/),
                    "concreteType": "UserVisibleFeedbackConnection",
                    "kind": "LinkedField",
                    "name": "viewerVisibleFeedback",
                    "plural": false,
                    "selections": (v15/*: any*/),
                    "storageKey": "viewerVisibleFeedback(feedbackDirection:\"GIVEN_TO_USER_BY_VIEWER\",first:20)"
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "239bf6fa1e46d8b6e725318670a7b081",
    "id": null,
    "metadata": {},
    "name": "submitFeedbackMutation",
    "operationKind": "mutation",
    "text": "mutation submitFeedbackMutation(\n  $input: SubmitFeedbackInput!\n) {\n  submitFeedback(input: $input) {\n    viewer {\n      user {\n        viewerVisibleFeedback(first: 20, feedbackDirection: GIVEN) {\n          edges {\n            node {\n              __typename\n              id\n              __isFeedbackInterface: __typename\n              body\n              createdAt\n              author {\n                avatarUrl\n                name\n                preferredName\n                id\n              }\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n    submittedFeedback {\n      targets {\n        receivedViewerVisibleFeedback: viewerVisibleFeedback(first: 20, feedbackDirection: RECEIVED) {\n          edges {\n            node {\n              __typename\n              id\n              __isFeedbackInterface: __typename\n              body\n              createdAt\n              author {\n                avatarUrl\n                name\n                preferredName\n                id\n              }\n            }\n          }\n        }\n        givenToUserByViewerVisibleFeedback: viewerVisibleFeedback(first: 20, feedbackDirection: GIVEN_TO_USER_BY_VIEWER) {\n          edges {\n            node {\n              __typename\n              id\n              __isFeedbackInterface: __typename\n              body\n              createdAt\n              author {\n                avatarUrl\n                name\n                preferredName\n                id\n              }\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd7d09a10a2072511870f99a0b352411";

export default node;

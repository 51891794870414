import { TouchableOpacity } from 'react-native';
import { ListItem, Text } from '@rneui/themed';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import { Colors, fontTypes } from '../../utils/constants';

import type { PastOneOnOneListItem_oneOnOneMeeting } from 'jakiro-types/PastOneOnOneListItem_oneOnOneMeeting.graphql';

type Props = {
  oneOnOneMeeting: PastOneOnOneListItem_oneOnOneMeeting;
  onPress: (k: string) => void;
};

const PastOneOnOneListItem = ({ oneOnOneMeeting, onPress, ...props }: Props) => {
  return (
    <ListItem
      onPress={() => onPress(oneOnOneMeeting.entityId)}
      Component={TouchableOpacity}
      {...props}
    >
      <ListItem.Content>
        <ListItem.Title style={styles.title}>
          {moment(oneOnOneMeeting.scheduledAt).format('MMM DD, YYYY')}
        </ListItem.Title>
      </ListItem.Content>
      <Text>
        <MaterialIcons color={Colors.icongray} name="chevron-right" size={24} />
      </Text>
    </ListItem>
  );
};

const styles = {
  title: {
    ...fontTypes.bodyTitleRegular,
    color: Colors.darkestgray,
  },
} as const;

const FragmentContainer = createFragmentContainer(PastOneOnOneListItem, {
  oneOnOneMeeting: graphql`
    fragment PastOneOnOneListItem_oneOnOneMeeting on OneOnOneMeeting {
      id
      entityId
      scheduledAt
    }
  `,
});

export default FragmentContainer;

import { StyleSheet, View, Text } from 'react-native';

import ProgressBar from './ProgressBar';
import Container from './Container';

import parseObjectiveProgress from '../utils/parseObjectiveProgress';

import { fontTypes, Colors } from '../utils/constants';

type Props = {
  source: 'goal' | 'metric';
  showProgressLabel: boolean;
  progressFillStyle?: {
    backgroundColor: string;
  };
  progressBackgroundStyle?: {
    backgroundColor: string;
  };
  startAndGoalStyle?: any;
  objective: {
    type: 'qualitative' | 'quantitative';
    amountType: 'digit' | 'dollar' | 'percent';
    goalAmount: number | null;
    progressAmount: number | null;
    startingAmount: number | null;
    computedProgressAmount: number | null;
  };
};

const ObjectiveProgress = (props: Props) => {
  const { source, showProgressLabel, objective } = props;
  const computedProgressAmount = objective.computedProgressAmount;
  const objectiveProgress = parseObjectiveProgress(source, objective);

  if (!objectiveProgress) {
    return null;
  }

  const {
    progressPercentage,
    progressLabel,
    startLabel,
    goalLabel,
    overAchieved,
    formerGoalRelativePercentage,
  } = objectiveProgress;

  const percentage = computedProgressAmount || progressPercentage;

  return (
    <Container direction="row" style={{ width: '100%' }}>
      <View testID="ObjectiveProgress" style={styles.wrapperStyle}>
        {showProgressLabel && (
          <Text style={styles.progressLabelStyle} numberOfLines={1}>
            {progressLabel}
          </Text>
        )}
        {overAchieved ? (
          <ProgressBar
            progress={formerGoalRelativePercentage}
            style={styles.progressStyle}
            backgroundStyle={[styles.overAchievedBackgroundStyle, props.progressBackgroundStyle]}
            fillStyle={[styles.overAchievedBaseFillStyle, props.progressFillStyle]}
            extraStyle={[styles.overAchievedExtraFillStyle, props.progressFillStyle]}
          />
        ) : (
          <ProgressBar
            progress={percentage}
            style={styles.progressStyle}
            backgroundStyle={[styles.progressBackgroundStyle, props.progressBackgroundStyle]}
            fillStyle={[styles.progressFillStyle, props.progressFillStyle]}
          />
        )}
        <Container direction="row" justify="space-between">
          <Text style={[styles.startAndGoalStyle, props.startAndGoalStyle]}>{startLabel}</Text>
          <Text style={[styles.startAndGoalStyle, props.startAndGoalStyle]}>{goalLabel}</Text>
        </Container>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  wrapperStyle: {
    flex: 1,
  },
  progressStyle: {
    flex: 1,
    height: 10,
    marginVertical: 10,
    backgroundColor: 'transparent',
  },
  progressFillStyle: {
    borderRadius: 10,
    backgroundColor: Colors.white,
  },
  progressBackgroundStyle: {
    borderRadius: 10,
    backgroundColor: Colors.progressBackground,
  },
  overAchievedBaseFillStyle: {
    borderRadius: 10,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: Colors.white,
  },
  overAchievedBackgroundStyle: {
    backgroundColor: 'transparent',
  },
  overAchievedExtraFillStyle: {
    borderRadius: 10,
    backgroundColor: Colors.white,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  progressLabelStyle: {
    ...fontTypes.title,
    color: Colors.white,
  },
  startAndGoalStyle: {
    color: Colors.white,
  },
});

export default ObjectiveProgress;

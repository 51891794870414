/**
 * @generated SignedSource<<bc5bff43c1e8b4a01177d142fd569830>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type pastOneOnOnesQuery$variables = {
  first: number;
};
export type pastOneOnOnesQueryVariables = pastOneOnOnesQuery$variables;
export type pastOneOnOnesQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly entityId: string;
    };
    readonly managerCheckinRelationship: {
      readonly " $fragmentSpreads": FragmentRefs<"PastOneOnOneList_checkinRelationship">;
    } | null;
  } | null;
};
export type pastOneOnOnesQueryResponse = pastOneOnOnesQuery$data;
export type pastOneOnOnesQuery = {
  variables: pastOneOnOnesQueryVariables;
  response: pastOneOnOnesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pastOneOnOnesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckinRelationship",
            "kind": "LinkedField",
            "name": "managerCheckinRelationship",
            "plural": false,
            "selections": [
              {
                "args": (v2/*: any*/),
                "kind": "FragmentSpread",
                "name": "PastOneOnOneList_checkinRelationship"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pastOneOnOnesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckinRelationship",
            "kind": "LinkedField",
            "name": "managerCheckinRelationship",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "checkinRelationshipOneOnOneMeetingsConnection",
                "kind": "LinkedField",
                "name": "oneOnOneMeetings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "checkinRelationshipOneOnOneMeetingsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OneOnOneMeeting",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "scheduledAt",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "576e752ef1706baa8473bd7ad1876f0a",
    "id": null,
    "metadata": {},
    "name": "pastOneOnOnesQuery",
    "operationKind": "query",
    "text": "query pastOneOnOnesQuery(\n  $first: Int!\n) {\n  viewer {\n    user {\n      entityId\n      id\n    }\n    managerCheckinRelationship {\n      ...PastOneOnOneList_checkinRelationship_3ASum4\n      id\n    }\n    id\n  }\n}\n\nfragment PastOneOnOneListItem_oneOnOneMeeting on OneOnOneMeeting {\n  id\n  entityId\n  scheduledAt\n}\n\nfragment PastOneOnOneList_checkinRelationship_3ASum4 on CheckinRelationship {\n  oneOnOneMeetings(first: $first) {\n    pageInfo {\n      hasNextPage\n    }\n    edges {\n      node {\n        scheduledAt\n        ...PastOneOnOneListItem_oneOnOneMeeting\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fffbee0df4d1b3737a1152f373210e6f";

export default node;

/**
 * @generated SignedSource<<97fb38e0aa95d2455101f8ecd89f1806>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RejectPeerReviewRequestInput = {
  clientMutationId?: string | null;
  reviewRequestId: string;
};
export type rejectPeerReviewRequestMutation$variables = {
  input: RejectPeerReviewRequestInput;
};
export type rejectPeerReviewRequestMutationVariables = rejectPeerReviewRequestMutation$variables;
export type rejectPeerReviewRequestMutation$data = {
  readonly rejectPeerReviewRequest: {
    readonly rejectedReviewRequest: {
      readonly isRejected: boolean;
    } | null;
    readonly errors: ReadonlyArray<string | null> | null;
  } | null;
};
export type rejectPeerReviewRequestMutationResponse = rejectPeerReviewRequestMutation$data;
export type rejectPeerReviewRequestMutation = {
  variables: rejectPeerReviewRequestMutationVariables;
  response: rejectPeerReviewRequestMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRejected",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "rejectPeerReviewRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RejectPeerReviewRequestPayload",
        "kind": "LinkedField",
        "name": "rejectPeerReviewRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReviewRequest",
            "kind": "LinkedField",
            "name": "rejectedReviewRequest",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "rejectPeerReviewRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RejectPeerReviewRequestPayload",
        "kind": "LinkedField",
        "name": "rejectPeerReviewRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReviewRequest",
            "kind": "LinkedField",
            "name": "rejectedReviewRequest",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b2f97506abd396416a37e04d4effcde3",
    "id": null,
    "metadata": {},
    "name": "rejectPeerReviewRequestMutation",
    "operationKind": "mutation",
    "text": "mutation rejectPeerReviewRequestMutation(\n  $input: RejectPeerReviewRequestInput!\n) {\n  rejectPeerReviewRequest(input: $input) {\n    rejectedReviewRequest {\n      isRejected\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "7a080ed001961df295a52ee53882ea84";

export default node;

import { camelCase, uniqueId } from 'lodash';

type Behavior = 'prepend' | 'append';

type RelaySelectorUpdater = any;

type OptimisticAddUpdaterOptions = {
  parentFieldName: string; // the name of the list, ex: talkingPoints is the parent of talkingPoint.
  payload: any; // the name of the object which you will use to update.
  payloadItemName: string;
  parentId: string; // id of the parent where you are rendering the above object.
  store: RelaySelectorUpdater;
  behavior: Behavior;
};

type ListRecordAddUpdaterOptions = {
  parentFieldName: string; // the name of the list, ex: talkingPoints is the parent of talkingPoint.
  mutationName: string;
  payloadItemName: string; // the name of the object which you will use to update.
  parentId: string; // id of the parent where you are rendering the above object.
  store: RelaySelectorUpdater;
  behavior: Behavior;
};

type ListRecordRemoveUpdaterOptions = {
  parentId: string;
  itemId: string;
  parentFieldName: string;
  store: RelaySelectorUpdater;
};

function mergeArray<T>(arr: T[], unit: T, behavior: Behavior): T[] {
  return behavior === 'append' ? [...arr, unit] : [unit, ...arr];
}

export function listRecordAddUpdater({
  parentId,
  parentFieldName,
  payloadItemName,
  mutationName,
  store,
  behavior,
}: ListRecordAddUpdaterOptions) {
  const field = store.getRootField(mutationName);
  const node = field.getLinkedRecord(payloadItemName);

  const parentProxy = store.get(parentId);

  const items = parentProxy.getLinkedRecords(parentFieldName);
  parentProxy.setLinkedRecords(mergeArray(items, node, behavior), parentFieldName);
}

export function listRecordRemoveUpdater({
  parentId,
  itemId,
  parentFieldName,
  store,
}: ListRecordRemoveUpdaterOptions) {
  const parentProxy = store.get(parentId);
  const items = parentProxy.getLinkedRecords(parentFieldName);

  parentProxy.setLinkedRecords(
    items.filter(record => record._dataID !== itemId),
    parentFieldName,
  );
}

export function optimisticListRecordAddUpdater({
  parentId,
  parentFieldName,
  payload,
  payloadItemName,
  store,
  behavior,
}: OptimisticAddUpdaterOptions) {
  const nodeId = uniqueId(`client:${parentFieldName}:new${camelCase(payloadItemName)}:`);
  const node = store.create(nodeId, 'TalkingPoint');

  node.setValue(nodeId, 'id');
  Object.entries(payload).map(kv => {
    const key = kv[0];
    const value = kv[1];

    node.setValue(value, key);
  });

  const parentProxy = store.get(parentId);

  const items = parentProxy.getLinkedRecords(parentFieldName);
  parentProxy.setLinkedRecords(mergeArray(items, node, behavior), parentFieldName);
}

/**
 * @generated SignedSource<<dcb39b82ac73314f924c045aa5223acb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OneOnOneSyncQuery$variables = {
  id: string;
  currentTime: DateString;
};
export type OneOnOneSyncQueryVariables = OneOnOneSyncQuery$variables;
export type OneOnOneSyncQuery$data = {
  readonly hasBeenModifiedSince: boolean;
};
export type OneOnOneSyncQueryResponse = OneOnOneSyncQuery$data;
export type OneOnOneSyncQuery = {
  variables: OneOnOneSyncQueryVariables;
  response: OneOnOneSyncQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currentTime"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "ifModifiedSince",
        "variableName": "currentTime"
      }
    ],
    "kind": "ScalarField",
    "name": "hasBeenModifiedSince",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OneOnOneSyncQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OneOnOneSyncQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8407b17e42b9a5c537c2de803d07b9bf",
    "id": null,
    "metadata": {},
    "name": "OneOnOneSyncQuery",
    "operationKind": "query",
    "text": "query OneOnOneSyncQuery(\n  $id: ID!\n  $currentTime: DateTime!\n) {\n  hasBeenModifiedSince(id: $id, ifModifiedSince: $currentTime)\n}\n"
  }
};
})();

(node as any).hash = "e1c9084c894dffceb40b03c9c5f64df1";

export default node;

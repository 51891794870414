import { commitMutation, graphql } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';
import type { editTalkingPointToNextOneOnOneMeetingInput } from 'jakiro-types/editTalkingPointToNextOneOnOneMeetingMutation.graphql';

const mutation = graphql`
  mutation editTalkingPointToNextOneOnOneMeetingMutation(
    $input: editTalkingPointToNextOneOnOneMeetingInput!
  ) {
    editTalkingPointToNextOneOnOneMeeting(input: $input) {
      errors
      talkingPoint {
        __typename
        body
        entityId
        creator {
          avatarUrl
          viewerIsUser
        }
        completedAt
      }
    }
  }
`;

type Fn = (val: any) => void;

function commit(vars: editTalkingPointToNextOneOnOneMeetingInput, onCompleted: Fn, onError: Fn) {
  const variables = {
    input: {
      ...vars,
    },
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

export default { commit };

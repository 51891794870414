/**
 * @generated SignedSource<<57a05c1d640502f374077061bed5912c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PastOneOnOnesTabQuery$variables = {
  first: number;
  entityId: string;
  viewerIsUser: boolean;
};
export type PastOneOnOnesTabQueryVariables = PastOneOnOnesTabQuery$variables;
export type PastOneOnOnesTabQuery$data = {
  readonly viewer?: {
    readonly managerCheckinRelationship: {
      readonly " $fragmentSpreads": FragmentRefs<"PastOneOnOnesList_checkinRelationship">;
    } | null;
  } | null;
  readonly user?: {
    readonly viewerUserRelationship: {
      readonly " $fragmentSpreads": FragmentRefs<"PastOneOnOnesList_checkinRelationship">;
    } | null;
  } | null;
};
export type PastOneOnOnesTabQueryResponse = PastOneOnOnesTabQuery$data;
export type PastOneOnOnesTabQuery = {
  variables: PastOneOnOnesTabQueryVariables;
  response: PastOneOnOnesTabQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewerIsUser"
},
v3 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v4 = [
  {
    "args": (v3/*: any*/),
    "kind": "FragmentSpread",
    "name": "PastOneOnOnesList_checkinRelationship"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "entityId",
    "variableName": "entityId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": (v3/*: any*/),
    "concreteType": "checkinRelationshipOneOnOneMeetingsConnection",
    "kind": "LinkedField",
    "name": "oneOnOneMeetings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "checkinRelationshipOneOnOneMeetingsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OneOnOneMeeting",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduledAt",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entityId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PastOneOnOnesTabQuery",
    "selections": [
      {
        "condition": "viewerIsUser",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CheckinRelationship",
                "kind": "LinkedField",
                "name": "managerCheckinRelationship",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "viewerIsUser",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CheckinRelationship",
                "kind": "LinkedField",
                "name": "viewerUserRelationship",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PastOneOnOnesTabQuery",
    "selections": [
      {
        "condition": "viewerIsUser",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CheckinRelationship",
                "kind": "LinkedField",
                "name": "managerCheckinRelationship",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "viewerIsUser",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CheckinRelationship",
                "kind": "LinkedField",
                "name": "viewerUserRelationship",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "f32784ae80913ba1a73944fee85b28d7",
    "id": null,
    "metadata": {},
    "name": "PastOneOnOnesTabQuery",
    "operationKind": "query",
    "text": "query PastOneOnOnesTabQuery(\n  $first: Int!\n  $entityId: String!\n  $viewerIsUser: Boolean!\n) {\n  viewer @include(if: $viewerIsUser) {\n    managerCheckinRelationship {\n      ...PastOneOnOnesList_checkinRelationship_3ASum4\n      id\n    }\n    id\n  }\n  user(entityId: $entityId) @skip(if: $viewerIsUser) {\n    viewerUserRelationship {\n      ...PastOneOnOnesList_checkinRelationship_3ASum4\n      id\n    }\n    id\n  }\n}\n\nfragment PastOneOnOneListItem_oneOnOneMeeting on OneOnOneMeeting {\n  id\n  entityId\n  scheduledAt\n}\n\nfragment PastOneOnOnesList_checkinRelationship_3ASum4 on CheckinRelationship {\n  oneOnOneMeetings(first: $first) {\n    pageInfo {\n      hasNextPage\n    }\n    edges {\n      node {\n        scheduledAt\n        ...PastOneOnOneListItem_oneOnOneMeeting\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2232125162e059b8fc4e2e54d113c769";

export default node;

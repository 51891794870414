import { StyleSheet, Text } from 'react-native';
import { createFragmentContainer, graphql } from 'react-relay';
import { MarkdownView } from 'react-native-markdown-view';

import TimelineItem from '../../../../components/TimelineItem';
import getGoalStatusColor from '../../../../utils/getGoalStatusColor';
import getGoalUpdateText from '../../../../utils/getGoalUpdateText';

import type { GoalUpdate_update } from 'jakiro-types/GoalUpdate_update.graphql';

type Props = {
  update: GoalUpdate_update;
};

const GoalUpdate = ({ update }: Props) => {
  const { creator, createdAt, status, increase, comment, goal } = update;

  if (!goal) return null;

  const preposition =
    increase && increase < 0 && goal && goal.goalAmount && goal.goalAmount > 0 ? 'from' : 'towards';

  const actionText = increase && increase >= 0 ? 'Added' : 'Subtracted';

  const increaseText = getGoalUpdateText(increase, goal.amountType);
  const goalAmountText = getGoalUpdateText(goal.goalAmount, goal.amountType);

  if (!creator) return null;
  return (
    <TimelineItem date={new Date(createdAt)} user={creator} color={getGoalStatusColor(status)}>
      {!!increase && (
        <Text style={[styles.container, comment && styles.increase]}>
          <Text style={styles.highlight}>
            {actionText} {increaseText}{' '}
          </Text>
          {preposition} goal of {goal && <Text style={styles.highlight}>{goalAmountText}</Text>}
        </Text>
      )}
      {comment && <MarkdownView>{comment}</MarkdownView>}
    </TimelineItem>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 10,
    paddingTop: 10,
  },
  increase: {
    paddingBottom: 10,
  },
  highlight: {
    fontWeight: 'bold',
  },
});

const FragmentContainer = createFragmentContainer(GoalUpdate, {
  update: graphql`
    fragment GoalUpdate_update on GoalUpdate {
      entityId
      createdAt
      creator {
        name
        avatarUrl
        ...TimelineItem_user
      }
      increase
      status: updateStatus
      comment
      goal {
        entityId
        goalAmount
        amountType
        type
      }
    }
  `,
});

export default FragmentContainer;

import { commitMutation, graphql } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';
import type { UnCompleteTalkingPointInput } from 'jakiro-types/unCompleteTalkingPointMutation.graphql';

const mutation = graphql`
  mutation unCompleteTalkingPointMutation($input: UnCompleteTalkingPointInput!) {
    unCompleteTalkingPoint(input: $input) {
      talkingPoint {
        id
        body
        entityId
        creator {
          avatarUrl
          name
          viewerIsUser
        }
        completedAt
      }
    }
  }
`;

type Fn = (val: any) => void;

function commit(vars: UnCompleteTalkingPointInput, onCompleted: Fn, onError: Fn) {
  const variables = {
    input: {
      ...vars,
    },
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

export default { commit };

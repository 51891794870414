import { graphql, commitMutation } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';

import type { publishCheckinMutationVariables } from 'jakiro-types/publishCheckinMutation.graphql';

const mutation = graphql`
  mutation publishCheckinMutation($input: PublishCheckinInput!) {
    publishCheckin(input: $input) {
      errors
    }
  }
`;

type Args = {
  checkinId: string;
};

export function commit(args: Args, onCompleted: any, onError: any) {
  const variables: publishCheckinMutationVariables = {
    input: {
      ...args,
    },
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

import * as Svg from 'react-native-svg';

type Props = {
  color: string | null;
};

export default ({ color }: Props) => (
  <Svg.Svg width="24" height="24" viewBox="0 0 24 24" fill={color}>
    <Svg.Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.1 5.868A9.957 9.957 0 0 0 2 12c0 5.523 4.477 10 10 10a9.96 9.96 0 0 0 6.132-2.1l1.542 1.542a.625.625 0 0 0 .884-.884L3.442 3.442a.625.625 0 1 0-.884.884L4.1 5.868zm1.428 1.428a8 8 0 0 0 11.176 11.176l-1.438-1.438a6 6 0 0 1-8.3-8.3L5.528 7.296zm2.9 2.9a4 4 0 0 0 5.375 5.375l-1.583-1.583a2 2 0 0 1-2.208-2.208l-1.583-1.583z"
    />
    <Svg.Path d="M22 12a9.96 9.96 0 0 1-2.1 6.132l-1.428-1.428A8 8 0 0 0 7.296 5.528L5.868 4.1A9.957 9.957 0 0 1 12 2c5.523 0 10 4.477 10 10z" />
    <Svg.Path d="M18 12a5.972 5.972 0 0 1-.966 3.266l-1.463-1.463a4 4 0 0 0-5.375-5.375L8.735 6.967A6 6 0 0 1 18 12z" />
    <Svg.Path d="M14 12a2 2 0 0 1-.012.22l-2.208-2.208A2 2 0 0 1 14 12z" />
  </Svg.Svg>
);

/**
 * @generated SignedSource<<0da5dc47b6c52045694d19c77d610e58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type editTalkingPointToNextOneOnOneMeetingInput = {
  body: string;
  clientMutationId?: string | null;
  talkingPointId: string;
};
export type editTalkingPointToNextOneOnOneMeetingMutation$variables = {
  input: editTalkingPointToNextOneOnOneMeetingInput;
};
export type editTalkingPointToNextOneOnOneMeetingMutationVariables = editTalkingPointToNextOneOnOneMeetingMutation$variables;
export type editTalkingPointToNextOneOnOneMeetingMutation$data = {
  readonly editTalkingPointToNextOneOnOneMeeting: {
    readonly errors: ReadonlyArray<string | null> | null;
    readonly talkingPoint: {
      readonly __typename: string;
      readonly body: string;
      readonly entityId: string;
      readonly creator: {
        readonly avatarUrl: string | null;
        readonly viewerIsUser: boolean;
      };
      readonly completedAt: DateString | null;
    } | null;
  } | null;
};
export type editTalkingPointToNextOneOnOneMeetingMutationResponse = editTalkingPointToNextOneOnOneMeetingMutation$data;
export type editTalkingPointToNextOneOnOneMeetingMutation = {
  variables: editTalkingPointToNextOneOnOneMeetingMutationVariables;
  response: editTalkingPointToNextOneOnOneMeetingMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsUser",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editTalkingPointToNextOneOnOneMeetingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "editTalkingPointToNextOneOnOneMeetingPayload",
        "kind": "LinkedField",
        "name": "editTalkingPointToNextOneOnOneMeeting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OneOnOneTalkingPoint",
            "kind": "LinkedField",
            "name": "talkingPoint",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editTalkingPointToNextOneOnOneMeetingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "editTalkingPointToNextOneOnOneMeetingPayload",
        "kind": "LinkedField",
        "name": "editTalkingPointToNextOneOnOneMeeting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OneOnOneTalkingPoint",
            "kind": "LinkedField",
            "name": "talkingPoint",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "914d6d1dcfd86ed152f364d33a657dd2",
    "id": null,
    "metadata": {},
    "name": "editTalkingPointToNextOneOnOneMeetingMutation",
    "operationKind": "mutation",
    "text": "mutation editTalkingPointToNextOneOnOneMeetingMutation(\n  $input: editTalkingPointToNextOneOnOneMeetingInput!\n) {\n  editTalkingPointToNextOneOnOneMeeting(input: $input) {\n    errors\n    talkingPoint {\n      __typename\n      body\n      entityId\n      creator {\n        avatarUrl\n        viewerIsUser\n        id\n      }\n      completedAt\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f75a8f9112d318b26d8b64c542325f2e";

export default node;

import { graphql } from 'react-relay';

import commitMutation from './commitMutation';

import type { submitSurveyResponsesMutationVariables } from 'jakiro-types/submitSurveyResponsesMutation.graphql';

const mutation = graphql`
  mutation submitSurveyResponsesMutation($input: SubmitSurveyResponsesInput!) {
    submitSurveyResponses(input: $input) {
      survey {
        viewerDidSubmit
      }
      user {
        tasksList {
          __typename
          ... on RespondToSurveyTask {
            survey {
              name
              entityId
            }
          }
        }
      }
    }
  }
`;

type Args = {
  surveyId: string;
};

export function commit(args: Args, onCompleted: any, onError: any) {
  const variables: submitSurveyResponsesMutationVariables = {
    input: {
      ...args,
    },
  };

  return commitMutation({
    mutationName: 'submitSurveyResponses',
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

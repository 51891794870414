import * as React from 'react';
import { TouchableOpacity, Clipboard, Text } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { graphql } from 'react-relay';
import styled, { css } from '@emotion/native';
import * as Updates from 'expo-updates';
import { Divider, ListItem } from '@rneui/themed';
import { RouteProp } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';

import Container from '../../components/Container';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import Header from '../../components/Header';
import type { RootStackParamList } from '../../components/Navigation/types';
import { fontTypes, Colors, COMPANY_SLUG } from '../../utils/constants';
import createQueryRenderer from '../../utils/createQueryRenderer';
import { getInstallationId } from '../../utils/getInstallationId';

import { autoSaveDeviceToken } from '../you';

import type { adminQueryResponse } from 'jakiro-types/adminQuery.graphql';
import useAuthentication from '../../hooks/useAuthentication';

type AdminProps = adminQueryResponse & {
  route: RouteProp<RootStackParamList, 'Admin'>;
};

function Admin({ viewer, route }: AdminProps) {
  const email = route.params.email;
  const userId = route.params.userId;

  const [accessToken, setAccessToken] = React.useState('');
  const [slug, setSlug] = React.useState('');
  const [renderErrorComponent, setRenderErrorComponent] = React.useState(false);
  const { setAccessToken: persistAccessToken } = useAuthentication();
  const [deviceId, setDeviceId] = React.useState(null);
  const { track } = useAnalytics();

  async function setCredentials() {
    track('Jakiro.Impersonation', {
      userId,
      properties: {
        slug,
        email,
      },
    });

    await persistAccessToken(accessToken);

    await AsyncStorage.setItem(COMPANY_SLUG, slug);

    Updates.reloadAsync();
  }

  async function copyToClipboard(text) {
    Clipboard.setString(text);
  }

  React.useEffect(() => {
    const findDeviceId = async () => {
      const deviceUniqueId = await getInstallationId();
      setDeviceId(deviceUniqueId);
    };
    findDeviceId();
  }, []);

  return (
    <Container flex={1}>
      <Header mode="BACK" title="Ladmin" containerStyle={{ width: '100%' }} />

      <Wrapper keyboardShouldPersistTaps="always" keyboardDismissMode="on-drag">
        <Section
          style={css`
            margin-top: 24px;
            margin-bottom: 32px;
          `}
        >
          <Headline
            style={css`
              padding-bottom: 8px;
            `}
          >
            Company Slug
          </Headline>

          <TextInput
            placeholder=""
            onChange={value => setSlug(value)}
            value={slug}
            inputStyle={{ width: '100%' }}
            style={{ paddingHorizontal: 0, marginTop: 16 }}
            returnKeyType={null}
            editable
          />
        </Section>

        <Section>
          <Headline>Access Token</Headline>

          <TextInput
            placeholder=""
            onChange={value => setAccessToken(value)}
            value={accessToken}
            inputStyle={{ width: '100%' }}
            style={{ paddingHorizontal: 0, marginTop: 16 }}
            returnKeyType={null}
            editable
          />
        </Section>

        <Button
          title="Impersonate"
          onPress={setCredentials}
          disabled={!slug || !accessToken}
          style={css`
            border-radius: 4px;
            margin: 0 16px 16px;
            backgroundcolor: ${Colors.brightblue};
            elevation: 0;
          `}
        />

        <Divider />

        <Section>
          <ListItem
            Component={TouchableOpacity}
            onPress={() => copyToClipboard(deviceId)}
            bottomDivider
          >
            <ListItem.Content>
              <ListItem.Title>
                <Text numberOfLines={1}>{deviceId}</Text>
              </ListItem.Title>
              <ListItem.Subtitle>UDID</ListItem.Subtitle>
            </ListItem.Content>
          </ListItem>
        </Section>

        <Section>
          <Button
            type="clear"
            onPress={() => {
              autoSaveDeviceToken(viewer?.user.nativeDeviceTokens);
            }}
            title="Save device token"
          />

          <Button
            type="clear"
            onPress={() => {
              setRenderErrorComponent(true);
            }}
            title="Test exception handling"
            containerStyle={{
              marginTop: 8,
            }}
            titleStyle={{
              color: 'red',
            }}
          />

          <Button
            type="clear"
            onPress={() => {
              AsyncStorage.clear();
            }}
            title="Clear local storage"
            containerStyle={{
              marginTop: 8,
            }}
          />

          {renderErrorComponent && <ComponentThatThrows />}
        </Section>
      </Wrapper>
    </Container>
  );
}

export default createQueryRenderer(Admin, Admin, {
  query: graphql`
    query adminQuery {
      viewer {
        user {
          nativeDeviceTokens {
            deviceUniqueId
            deviceToken
          }
        }
      }
    }
  `,
});

const ComponentThatThrows = () => {
  throw new Error('TestException');
};

const Headline = styled.Text`
  ${fontTypes.title};
`;

const Section = styled.View`
  padding: 0 16px;
  margin-bottom: 32px;
`;

const Wrapper = styled.ScrollView`
  flex: 1;
  width: 100%;
`;

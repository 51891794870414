import { useState, useEffect, useRef } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import LottieView from 'lottie-react-native';
import * as SplashScreen from 'expo-splash-screen';

import LoginScreen from '../../pages/login/LoginScreen';
import Home from '../../pages/login';
import Settings from '../../pages/settings';
import OneOnOne from '../../pages/oneOnOne/OneOnOne';
import GoalProfile from '../../pages/goals';
import SecretNoteView from '../../pages/oneOnOne/SecretNoteView';
import WriteTalkingPointComment from '../../pages/oneOnOne/WriteTalkingPointComment';
import Directory from '../../pages/directory';
import Admin from '../../pages/admin';
import DirectoryProfile from '../../pages/directory/DirectoryProfile';
import DirectoryDepartments from '../../pages/directory/DirectoryDepartments';
import DirectoryDepartmentEmployees from '../../pages/directory/DirectoryDepartmentEmployees';
import DirectoryAllEmployees from '../../pages/directory/DirectoryAllEmployees';
import Feedback from '../../pages/feedback';
import CreateUpdate from '../../pages/updates';
import PastUpdates from '../../pages/updates/PastUpdates';
import Pulse from '../../pages/pulse';
import AddPulseComment from '../../pages/pulse/AddComment';
import AddEngagementSurveyComment from '../../pages/surveys/AddComment';
import Surveys from '../../pages/surveys';
import SurveyDetailsHeaderInfo from '../../pages/surveys/SurveyDetailsHeaderInfo';
import FeedbackVisibility from '../../pages/feedback/FeedbackVisibility';
import SharedNoteEditView from '../../pages/oneOnOne/SharedNoteEditView';
import PastOneOnOnesList from '../../pages/pastOneOnOnes';
import EnableNotification from '../../pages/enableNotification';
import ReviewCycleSelect from '../../pages/reviews/ReviewCycleSelect';
import ReviewCycleResponseForm from '../../pages/reviews/ReviewCycleResponseForm';
import FaceMatch from '../../pages/faceMatch';
import LoginPhone from '../../pages/login/LoginPhone';
import LoginPhoneConfirmation from '../../pages/login/LoginPhoneConfirmation';

// TODO: Replace this with: https://reactnavigation.org/docs/bottom-tab-navigator/
import TabView from '../TabView';
import { isWeb } from '../../utils/constants';
import type { RootStackParamList } from './types';

const Stack = createStackNavigator<RootStackParamList>();

function Loading({ setIsReady }) {
  const animation = useRef<LottieView>();

  useEffect(() => {
    if (animation.current) {
      animation.current.play();
    }

    SplashScreen.hideAsync();
  }, [animation]);

  return (
    <LottieView
      ref={animation}
      style={{
        flex: 1,
        backgroundColor: 'white',
      }}
      source={require('../../assets/splash.json')}
      loop={false}
      onAnimationFinish={async () => {
        setIsReady(true);
      }}
    />
  );
}

const linking = {
  prefixes: [],
  config: {
    screens: {
      Loading: '/loading',
      Welcome: '/welcome',
      Login: '/login',
      'Phone Login': 'login-phone',
      'Phone Login Confirmation': 'login-phone-confirmation',
      Home: '/',
      Profile: '/users/:userEntityId',
      Directory: '/directory',
      'Directory Employees': '/directory/employees',
      'Directory Departments': '/directory/departments',
      'Directory Department Employees': '/directory/department/:departmentEntityId',
      Settings: '/settings',
      Goal: '/goals',
      'Office Party': '/office-party',
      Admin: '/admin',
      'Enable Notifications': '/enable-notifications',
      'Engagement Survey': '/surveys/:surveyEntityId',
      'Engagement Survey Details': '/surveys/:surveyEntityId/details',
      'Engagement Survey Comment': '/surveys/:surveyEntityId/comment',
      'Pulse Survey': '/pulse/:pulseGroupEntityId',
      'Pulse Comment': '/pulse/:pulseGroupEntityId/comment',
      Updates: '/updates',
      Update: '/updates/:weekId',
      'Review Cycle': '/reviews/:reviewCycleEntityId',
      'Review Response': '/reviews/:reviewCycleEntityId/:reviewRequestEntityId',
      'Give Feedback': 'give-feedback',
      'Feedback Visibility': 'feedback-visibility',
      'Past One On Ones': '/1-1s',
      'One On One': '/1-1s/:userId/meetings/:meetingId',
      'Private Notes': '/1-1s/:userId/meetings/:meetingId/private-notes',
      'Shared Notes': '/1-1s/:userId/meetings/:meetingId/shared-notes',
      'Talking Point Comment': '/1-1s/:userId/meetings/:meetingId/talking-point-comment',
    },
  },
};

export default function Navigation({ isLoggedIn }) {
  const [isReady, setIsReady] = useState(false);

  return (
    <NavigationContainer
      linking={linking}
      documentTitle={{
        formatter: (options, route) => `${options?.title ?? route?.name} - Lattice`,
      }}
    >
      <Stack.Navigator
        screenOptions={{
          animationEnabled: true, // We want this enabled on all platforms
        }}
      >
        {!isWeb && !isReady && (
          <Stack.Screen
            name="Loading"
            options={{
              headerShown: false,
            }}
          >
            {() => <Loading setIsReady={setIsReady} />}
          </Stack.Screen>
        )}

        {!isLoggedIn && (
          <Stack.Group
            screenOptions={{
              headerShown: false,
              gestureEnabled: true,
            }}
          >
            <Stack.Screen name="Welcome" component={Home} />
            <Stack.Screen name="Login" component={LoginScreen} />
            <Stack.Screen name="Phone Login" component={LoginPhone} />
            <Stack.Screen name="Phone Login Confirmation" component={LoginPhoneConfirmation} />
          </Stack.Group>
        )}

        {isLoggedIn && (
          <Stack.Group
            screenOptions={{
              headerShown: false,
              gestureEnabled: true,
            }}
          >
            <Stack.Screen name="Home" component={TabView} />
            <Stack.Screen name="Give Feedback" component={Feedback} />
            <Stack.Screen name="Feedback Visibility" component={FeedbackVisibility} />
            <Stack.Screen name="Past One On Ones" component={PastOneOnOnesList} />
            <Stack.Screen name="One On One" component={OneOnOne} />
            <Stack.Screen
              name="Private Notes"
              component={SecretNoteView}
              options={{
                presentation: 'modal',
              }}
            />
            <Stack.Screen
              name="Shared Notes"
              component={SharedNoteEditView}
              options={{
                presentation: 'modal',
              }}
            />
            <Stack.Screen
              name="Talking Point Comment"
              component={WriteTalkingPointComment}
              options={{
                presentation: 'modal',
              }}
            />
            <Stack.Screen name="Profile" component={DirectoryProfile} />
            <Stack.Screen name="Engagement Survey" component={Surveys} />
            <Stack.Screen
              name="Engagement Survey Details"
              component={SurveyDetailsHeaderInfo}
              options={{
                presentation: 'modal',
              }}
            />
            <Stack.Screen
              name="Engagement Survey Comment"
              component={AddEngagementSurveyComment}
              options={{
                presentation: 'modal',
              }}
            />
            <Stack.Screen name="Pulse Survey" component={Pulse} />
            <Stack.Screen
              name="Pulse Comment"
              component={AddPulseComment}
              options={{
                presentation: 'modal',
              }}
            />
            <Stack.Screen name="Updates" component={PastUpdates} />
            <Stack.Screen name="Update" component={CreateUpdate} />
            <Stack.Screen name="Review Cycle" component={ReviewCycleSelect} />
            <Stack.Screen name="Review Response" component={ReviewCycleResponseForm} />
            <Stack.Screen name="Office Party" component={FaceMatch} />

            <Stack.Screen name="Directory" component={Directory} />
            <Stack.Screen name="Directory Employees" component={DirectoryAllEmployees} />
            <Stack.Screen
              name="Directory Department Employees"
              component={DirectoryDepartmentEmployees}
            />
            <Stack.Screen name="Directory Departments" component={DirectoryDepartments} />

            <Stack.Screen name="Enable Notifications" component={EnableNotification} />
            <Stack.Screen name="Settings" component={Settings} />
            <Stack.Screen name="Admin" component={Admin} />
            <Stack.Screen name="Goal" component={GoalProfile} />
          </Stack.Group>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

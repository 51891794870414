/**
 * @generated SignedSource<<ee4bbcd32f09cae9279e47679e9c272e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SaveSurveyQuestionResponseInput = {
  clientMutationId?: string | null;
  comment?: string | null;
  eNPSChoice?: number | null;
  opinion?: number | null;
  questionRevisionId: string;
  surveyParticipantQuestionId: string;
};
export type saveSurveyQuestionResponseMutation$variables = {
  input: SaveSurveyQuestionResponseInput;
};
export type saveSurveyQuestionResponseMutationVariables = saveSurveyQuestionResponseMutation$variables;
export type saveSurveyQuestionResponseMutation$data = {
  readonly saveSurveyQuestionResponse: {
    readonly surveyParticipantQuestion: {
      readonly submittedAt: DateString | null;
      readonly response: {
        readonly response: any | null;
      } | null;
    } | null;
    readonly survey: {
      readonly viewerCanSubmit: boolean | null;
    } | null;
  } | null;
};
export type saveSurveyQuestionResponseMutationResponse = saveSurveyQuestionResponseMutation$data;
export type saveSurveyQuestionResponseMutation = {
  variables: saveSurveyQuestionResponseMutationVariables;
  response: saveSurveyQuestionResponseMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "submittedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "response",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanSubmit",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "saveSurveyQuestionResponseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveSurveyQuestionResponsePayload",
        "kind": "LinkedField",
        "name": "saveSurveyQuestionResponse",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SurveyParticipantQuestion",
            "kind": "LinkedField",
            "name": "surveyParticipantQuestion",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "QuestionResponse",
                "kind": "LinkedField",
                "name": "response",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerSurvey",
            "kind": "LinkedField",
            "name": "survey",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "saveSurveyQuestionResponseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveSurveyQuestionResponsePayload",
        "kind": "LinkedField",
        "name": "saveSurveyQuestionResponse",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SurveyParticipantQuestion",
            "kind": "LinkedField",
            "name": "surveyParticipantQuestion",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "QuestionResponse",
                "kind": "LinkedField",
                "name": "response",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerSurvey",
            "kind": "LinkedField",
            "name": "survey",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8079ee2e857a371111bf4fa8a8c2d555",
    "id": null,
    "metadata": {},
    "name": "saveSurveyQuestionResponseMutation",
    "operationKind": "mutation",
    "text": "mutation saveSurveyQuestionResponseMutation(\n  $input: SaveSurveyQuestionResponseInput!\n) {\n  saveSurveyQuestionResponse(input: $input) {\n    surveyParticipantQuestion {\n      submittedAt\n      response {\n        response\n        id\n      }\n      id\n    }\n    survey {\n      viewerCanSubmit\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5545220030b8bf0c3bcb3a9290ec1284";

export default node;

import * as React from 'react';
import { last, uniqBy } from 'lodash';
import { ActivityIndicator, FlatList, View } from 'react-native';
import type { StackNavigationProp } from '@react-navigation/stack';
import { graphql, createRefetchContainer } from 'react-relay';
import { css } from '@emotion/native';

import type { RootStackParamList } from '../../components/Navigation/types';
import { cleanConnection } from '../../utils/fns';
import Header from '../../components/Header';
import createQueryRenderer from '../../utils/createQueryRenderer';
import Person from './PersonCell';

import type { DirectoryDepartmentEmployees_department } from 'jakiro-types/DirectoryDepartmentEmployees_department.graphql';

type Props = {
  department: DirectoryDepartmentEmployees_department;
  relay: any;
  navigation: StackNavigationProp<RootStackParamList, 'Directory Department Employees'>;
};

const PER_PAGE = 30;

function DirectoryDepartmentEmployees(props: Props) {
  const [people, setPeople] = React.useState([]);

  const { department, navigation } = props;

  const hasNextPage = department.members?.pageInfo.hasNextPage;
  const lastCursor = last(department.members?.edges)?.cursor;

  React.useEffect(() => {
    if (!department || !department.members) return;

    const users = cleanConnection(department.members);

    if (users.length) {
      setPeople(people => uniqBy([...people, ...users], 'entityId'));
    }
  }, [department]);

  const onLoadMore = () => {
    if (!lastCursor || !hasNextPage) return;

    props.relay.refetch(
      v => ({
        ...v,
        after: lastCursor || '',
        first: PER_PAGE,
      }),
      null,
    );
  };

  if (!department) return null;

  return (
    <React.Fragment>
      <Header
        mode="BACK"
        handleLeftIconPress={() => navigation.navigate('Directory')}
        title={department.name || 'Loading...'}
        bottomComponent
      />

      <FlatList
        style={css`
          height: 100%;
          width: 100%;
          flex-direction: column;
          flex: 1;
          display: flex;
        `}
        keyExtractor={item => item.entityId}
        data={people}
        numColumns={3}
        renderItem={({ item }) => <Person user={item} key={item.entityId} />}
        onEndReached={onLoadMore}
        onEndReachedThreshold={0.25}
        initialNumToRender={PER_PAGE}
        ListFooterComponent={() => {
          if (!hasNextPage) return null;

          return (
            <View
              style={css`
                padding-top: 16px;
                padding-bottom: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <ActivityIndicator />
            </View>
          );
        }}
      />
    </React.Fragment>
  );
}

const DirectoryDepartmentEmployeesRefetch = createRefetchContainer(
  DirectoryDepartmentEmployees,
  {
    department: graphql`
      fragment DirectoryDepartmentEmployees_department on Department
      @argumentDefinitions(
        first: { type: "Int!", defaultValue: 10 }
        after: { type: "String!", defaultValue: "" }
        status: { type: "[UserStatusEnum]", defaultValue: [ACTIVE] }
      ) {
        name
        members(first: $first, after: $after, status: $status) {
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              entityId
              ...PersonCell_user
            }
          }
        }
      }
    `,
  },
  graphql`
    query DirectoryDepartmentEmployeesRefetchQuery(
      $first: Int!
      $after: String!
      $departmentEntityId: String!
      $status: [UserStatusEnum]
    ) {
      viewer {
        findDepartment(departmentId: $departmentEntityId) {
          ...DirectoryDepartmentEmployees_department
            @arguments(first: $first, after: $after, status: $status)
        }
      }
    }
  `,
);

export default createQueryRenderer(
  DirectoryDepartmentEmployeesRefetch,
  DirectoryDepartmentEmployees,
  {
    query: graphql`
      query DirectoryDepartmentEmployeesQuery($first: Int!, $departmentEntityId: String!) {
        viewer {
          findDepartment(departmentId: $departmentEntityId) {
            ...DirectoryDepartmentEmployees_department @arguments(first: $first)
          }
        }
      }
    `,
    fragmentPropResolver: props => ({
      department: props?.viewer?.findDepartment,
    }),
    queriesParams: ({ route }) => ({
      departmentEntityId: route.params.departmentEntityId || '',
      first: PER_PAGE,
      status: ['ACTIVE'],
    }),
  },
);

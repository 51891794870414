import Constants from 'expo-constants';

type Key =
  | 'AUTH_URL'
  | 'PHONE_AUTH_URL'
  | 'PHONE_AUTH_URL_VERIFY'
  | 'GRAPHQL_ENDPOINT_PUBLIC'
  | 'GRAPHQL_ENDPOINT'
  | 'SEGMENT_KEY'
  | 'SERVER_URL'
  | 'MOBILE_WEBSITE'
  | 'AUTH_GOOGLE'
  | 'UPLOAD_IMGIX_HOST'
  | 'SENTRY';

export function getConfig(key: Key) {
  // This should happen in code paths that don't first process the `app.config.ts` file.
  // The only known case so far is in Jest tests.
  if (!Constants.expoConfig.extra) {
    return null;
  }

  return Constants.expoConfig.extra[key];
}

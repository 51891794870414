import { graphql } from 'react-relay';

import commitMutation from './commitMutation';

import type { saveSurveyQuestionResponseMutationVariables } from 'jakiro-types/saveSurveyQuestionResponseMutation.graphql';

const mutation = graphql`
  mutation saveSurveyQuestionResponseMutation($input: SaveSurveyQuestionResponseInput!) {
    saveSurveyQuestionResponse(input: $input) {
      surveyParticipantQuestion {
        submittedAt
        response {
          response
        }
      }
      survey {
        viewerCanSubmit
      }
    }
  }
`;

type Base = {
  surveyParticipantQuestionId: string;
  questionRevisionId: string;
};

type CommentOnly = {
  comment: string | null;
} & Base;

type OpinionOnly = {
  opinion: number | string | null;
} & Base;

type OpinionAndComment = {
  opinion: number | string | null;
} & CommentOnly;

type eNPSChoice = {
  eNPSChoice: number | string | null;
} & CommentOnly;

type Args = OpinionOnly | CommentOnly | OpinionAndComment | eNPSChoice;

export function commit(args: Args, onCompleted: any | undefined, onError: any | undefined) {
  const variables: saveSurveyQuestionResponseMutationVariables = {
    input: {
      ...args,
      ...('eNPSChoice' in args && {
        eNPSChoice:
          typeof args.eNPSChoice === 'string' ? parseInt(args.eNPSChoice, 10) : args.eNPSChoice,
      }),
      ...('opinion' in args && {
        opinion: typeof args.opinion === 'string' ? parseInt(args.opinion, 10) : args.opinion,
      }),
    },
  };

  return commitMutation({
    mutationName: 'saveSurveyQuestionResponse',
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

import { graphql, commitMutation } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';

import type { logoutMutationVariables } from 'jakiro-types/logoutMutation.graphql';

const mutation = graphql`
  mutation logoutMutation($input: LogoutInput!) {
    logout(input: $input) {
      clientMutationId
    }
  }
`;

export function commit() {
  const variables: logoutMutationVariables = {
    input: {},
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
  });
}

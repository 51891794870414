import { ListItem, CheckBox } from '@rneui/themed';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import { isWeb, Colors, isIOS } from '../../../utils/constants';
import { getInitials } from '../../../utils/fns';
import OptionButton from '../../../components/OptionButton';
import Avatar from '../../../components/Avatar';
import MultilineTextInput from '../../../components/MultilineTextInput';

type Props = {
  recurring?: boolean;
  completed: boolean;
  toggleCheckBox: (j: string, k: boolean) => void;
  onSubmit: (k: boolean) => void;
  value: string;
  onChange: (k: string, o: string | undefined) => void;
  onDelete: (k: string) => void;
  id: string;
  status?: 'NEW' | 'ONGOING' | 'DONE' | 'IS_LOADING';
  isDisabled?: boolean;
  user?: {
    avatarUrl: string | null;
    name: string | null;
    viewerIsUser: boolean;
  };
  getInputRef?: (k: any) => void;
  updatePosition: () => void;
  blurOnSubmit?: boolean;
  isOneOnOnesDisabled: boolean;
};

function TalkingPointInput(props: Props) {
  const {
    onSubmit,
    completed,
    user,
    value,
    onChange,
    toggleCheckBox,
    status,
    onDelete,
    id,
    isDisabled,
    getInputRef,
    updatePosition,
    blurOnSubmit,
    recurring,
    isOneOnOnesDisabled,
  } = props;

  return (
    <ListItem pointerEvents={isOneOnOnesDisabled ? 'none' : 'auto'} containerStyle={styles.content}>
      {status === 'NEW' ? (
        <MaterialIcons
          containerStyle={{ paddingTop: 10 }}
          color={Colors.darkergray}
          name="lens"
          size={8}
        />
      ) : (
        <CheckBox
          containerStyle={styles.checkbox}
          center
          iconType="ionicon"
          onPress={() => !recurring && toggleCheckBox(id, completed)}
          checkedIcon="md-checkbox"
          uncheckedIcon="md-square-outline"
          checkedColor="#617080"
          uncheckedColor="#617080"
          checked={completed}
        />
      )}
      <MultilineTextInput
        submitOnDone
        blurOnSubmit={blurOnSubmit}
        updatePosition={updatePosition}
        getInputRef={getInputRef}
        placeholder="Add talking point..."
        onBlur={onSubmit}
        value={value}
        onChange={body => onChange(body, id)}
        inputStyle={styles.input(completed)}
        returnKeyType="done"
        editable={!isDisabled && !completed}
        strikeThrough={completed}
      />
      {value && !recurring ? (
        !user || (user && user.viewerIsUser) ? (
          <OptionButton
            containerStyle={styles.icon}
            onPress={() => value.length && onDelete(id)}
            iconStyle={{ paddingLeft: 8, paddingRight: isWeb ? 0 : 8, marginLeft: 'auto' }}
          />
        ) : (
          <Avatar xsmall avatarUrl={user && user.avatarUrl} title={getInitials(user.name)} />
        )
      ) : null}
    </ListItem>
  );
}

const styles = {
  checkbox: {
    margin: 0,
    marginLeft: 0,
    padding: 0,
    paddingTop: isWeb ? 0 : 3,
    marginRight: isWeb ? 8 : 0,
  },
  icon: {
    paddingTop: 0,
  },
  content: {
    alignItems: 'flex-start',
    padding: 0,
  },
  container: {
    borderBottomWidth: 0,
  },
  input: (completed: boolean) => ({
    padding: 0,
    marginTop: isIOS ? 3 : 0,
    color: Colors.darkestgray,
    textDecorationLine: completed ? 'line-through' : 'none',
  }),
} as const;

export default TalkingPointInput;

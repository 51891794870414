import { useState } from 'react';
import { View, FlatList } from 'react-native';
import { graphql, createRefetchContainer } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';
import moment from 'moment';

import PastOneOnOnesListItem from './PastOneOnOneListItem';
import LoadMore from '../LoadMore';
import EmptyState from '../EmptyState';
import { getLength } from '../../utils/fns';
import Separator from '../Separator';
import type { RootStackParamList } from '../Navigation/types';

import type { PastOneOnOnesList_checkinRelationship } from 'jakiro-types/PastOneOnOnesList_checkinRelationship.graphql';

type Props = {
  checkinRelationship: PastOneOnOnesList_checkinRelationship;
  userId: string;
  viewerIsUser: boolean;
  relay: any;
  toggleModal: () => void;
};

const filterNewOnes = (oneOnOnes: any) => {
  const now = moment();
  if (!oneOnOnes) return [];
  return oneOnOnes.filter(e => moment(e.node.scheduledAt).isBefore(now));
};

function PastOneOnOnesList(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const { checkinRelationship, viewerIsUser, relay, toggleModal, userId } = props;
  const { oneOnOneMeetings } = checkinRelationship;
  const pastOneOnOnes = oneOnOneMeetings ? filterNewOnes(oneOnOneMeetings.edges) : [];

  // TODO: Use managerCheckinRelationship.hasAnyOneOnOneMeetings instead!
  if (!oneOnOneMeetings || !pastOneOnOnes.length) {
    return (
      <EmptyState
        title="No past 1:1s"
        body={`${viewerIsUser ? 'You have' : 'There are'} no past 1:1s.`}
      />
    );
  }

  return (
    oneOnOneMeetings && (
      <View style={{ flex: 1, paddingLeft: 10 }}>
        {oneOnOneMeetings.edges && (
          <FlatList
            data={pastOneOnOnes}
            keyExtractor={(_, index) => String(index)}
            renderItem={({ item: { node } }) => (
              <PastOneOnOnesListItem
                onPress={(id: string) => {
                  toggleModal();
                  navigation.navigate('One On One', {
                    meetingId: id,
                    userId,
                  });
                }}
                oneOnOneMeeting={node}
              />
            )}
            ItemSeparatorComponent={() => <Separator />}
            ListFooterComponent={
              oneOnOneMeetings.pageInfo.hasNextPage && (
                <LoadMore
                  isLoading={isLoading}
                  handleLoadMore={() => {
                    setIsLoading(true);

                    const count = getLength(oneOnOneMeetings?.edges);
                    const refetchVariables = () => ({
                      first: count + 10,
                    });

                    relay.refetch(refetchVariables, null, () => {
                      setIsLoading(false);
                    });
                  }}
                />
              )
            }
          />
        )}
      </View>
    )
  );
}

export default createRefetchContainer(
  PastOneOnOnesList,
  {
    checkinRelationship: graphql`
      fragment PastOneOnOnesList_checkinRelationship on CheckinRelationship
      @argumentDefinitions(first: { type: "Int!" }) {
        oneOnOneMeetings(first: $first) {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              scheduledAt
              ...PastOneOnOneListItem_oneOnOneMeeting
            }
          }
        }
      }
    `,
  },
  graphql`
    query PastOneOnOnesListRefetchQuery($first: Int!, $entityId: String!, $viewerIsUser: Boolean!) {
      viewer @include(if: $viewerIsUser) {
        managerCheckinRelationship {
          ...PastOneOnOnesList_checkinRelationship @arguments(first: $first)
        }
      }
      user(entityId: $entityId) @skip(if: $viewerIsUser) {
        viewerUserRelationship {
          ...PastOneOnOnesList_checkinRelationship @arguments(first: $first)
        }
      }
    }
  `,
);

import { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import type { StackNavigationProp } from '@react-navigation/stack';
import { ListItem } from '@rneui/themed';
import { graphql } from 'react-relay';
import { uniqBy } from 'lodash';
import styled, { css } from '@emotion/native';
import Feather from '@expo/vector-icons/Feather';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import { connectDropdownAlert, Alert } from '../../hocs/withDropdownAlert';
import SubmitFeedback from '../../mutations/submitFeedback';
import Container from '../../components/Container';
import FindUserInput from '../../components/FindUserInput';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import Header from '../../components/Header';
import Badge from '../../components/Badge';
import Avatar from '../../components/Avatar';
import type { RootStackParamList } from '../../components/Navigation/types';
import { ListItemChevron } from '../../components/ListItemChevron';
import {
  fontTypes,
  Colors,
  isWeb,
  APPLICATION_MAX_WIDTH_STRING,
  ListItemStyle,
} from '../../utils/constants';
import { getInitials } from '../../utils/fns';
import createQueryRenderer from '../../utils/createQueryRenderer';
import { privacyOptions } from './FeedbackVisibility';

import type { feedbackQueryResponse } from 'jakiro-types/feedbackQuery.graphql';

type Props = {
  relay: any;
  dropdownAlert: Alert;
  navigation: StackNavigationProp<RootStackParamList, 'Give Feedback'>;
} & feedbackQueryResponse;

const FEEDBACK_PUBLIC_RECIPIENT_MAXIMUM = 10;

function CreateFeedback(props: Props) {
  const { viewer, dropdownAlert, navigation } = props;

  const company = viewer?.company;
  if (!company) return null;

  const [isLoading, setIsLoading] = useState(false);
  const [body, setBody] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [values, setValues] = useState([]);

  const feedbackDeliverablePublicEnabled = company.feedbackDeliverablePublicEnabled ?? false;
  const feedbackDeliverablePrivateEnabled = company.feedbackDeliverablePrivateEnabled ?? false;
  const feedbackDeliverableManagerOnlyEnabled =
    company.feedbackDeliverableManagerOnlyEnabled ?? false;
  const feedbackDeliverableManagerAndPrivateEnabled =
    company.feedbackDeliverableManagerAndPrivateEnabled ?? false;

  const [visibility, setVisibility] = useState(
    feedbackDeliverablePrivateEnabled
      ? 1
      : feedbackDeliverableManagerAndPrivateEnabled
      ? 2
      : feedbackDeliverableManagerOnlyEnabled
      ? 3
      : feedbackDeliverablePublicEnabled
      ? 0
      : // Should not be possible. We require at least 1 feedback type to be enabled
        -1,
  );

  const valuesEnabled = company.valuesEnabled;
  const companyValues = company.values;
  const hasSelectedPeople = recipients.length > 0;
  const invalidForm = body.length < 30 || hasSelectedPeople === false;

  const toggleValue = (v: { id: string; name: string }) => () => {
    const id = v.id || '';
    const name = v.name || '';

    const valueIndex = values.findIndex(a => a.id === id);

    if (valueIndex > -1) {
      setValues([...values.slice(0, valueIndex), ...values.slice(valueIndex + 1)]);
    } else {
      setValues([...values, { id, name }]);
    }
  };

  return (
    <Container
      flex={1}
      style={css`
        background-color: ${Colors.faint};
      `}
    >
      <Header mode="BACK" title="Give feedback" containerStyle={{ width: '100%' }} />

      <Wrapper keyboardShouldPersistTaps="always" keyboardDismissMode="on-drag">
        <Inner>
          <Section
            style={css`
              padding: 0 16px;
              margin-top: 24px;
              margin-bottom: ${recipients.length ? '8px' : '32px'};
              width: 100%;
            `}
          >
            <Headline
              style={css`
                padding: 0 0 16px;
              `}
            >
              Who is the feedback about?
            </Headline>

            <FindUserInput
              onPersonAdd={person => {
                setRecipients(uniqBy([...recipients, person], 'id'));
              }}
              disabled={recipients.length === FEEDBACK_PUBLIC_RECIPIENT_MAXIMUM}
              isOptionDisabled={option => {
                if (!option.products.feedback.isEnabled) {
                  return 'Feedback disabled';
                }

                return null;
              }}
            />
          </Section>

          {hasSelectedPeople && (
            <Section
              style={css`
                padding-top: 8px;
                padding-left: 0px;
                padding-right: 0px;
              `}
            >
              {recipients.map((p, i) => {
                return (
                  <ListItem key={i} topDivider={i === 0} style={ListItemStyle} bottomDivider>
                    <View
                      style={css`
                        margin-right: ${isWeb ? '16px' : '0px'};
                      `}
                    >
                      <Avatar small avatarUrl={p.avatarUrl} title={getInitials(p.name)} />
                    </View>
                    <ListItem.Content>
                      <ListItem.Title>{p.name}</ListItem.Title>
                      <ListItem.Subtitle>{p.title}</ListItem.Subtitle>
                    </ListItem.Content>
                    <Feather
                      name="x"
                      size={16}
                      Component={TouchableOpacity}
                      color={Colors.darkergray}
                      onPress={() => {
                        setRecipients([...recipients.slice(0, i), ...recipients.slice(i + 1)]);
                      }}
                      style={css`
                        padding-top: 8px;
                        padding-bottom: 8px;
                      `}
                    />
                  </ListItem>
                );
              })}
            </Section>
          )}

          <Section>
            <Headline>What’s your feedback?</Headline>

            <TextInput
              placeholder=""
              multiline
              onChange={newBody => {
                setBody(newBody);
              }}
              value={body}
              inputStyle={css`
                text-align-vertical: top;
                height: 120px;
                width: 100%;
                background-color: white;
                padding: 16px;
              `}
              inputContainerStyle={css`
                margin: 0 8px;
              `}
              returnKeyType={null}
              editable
            />
          </Section>

          <Section>
            <Headline>Who will see this feedback?</Headline>

            <ListItem
              style={ListItemStyle}
              Component={TouchableOpacity}
              onPress={() => {
                navigation.navigate('Feedback Visibility', {
                  updateVisibility: (index: number) => {
                    setVisibility(index);
                  },
                  selected: visibility,
                  feedbackDeliverablePublicEnabled,
                  feedbackDeliverablePrivateEnabled,
                  feedbackDeliverableManagerOnlyEnabled,
                  feedbackDeliverableManagerAndPrivateEnabled,
                });
              }}
              topDivider
              bottomDivider
            >
              <MaterialIcons
                color={Colors.darkergray}
                name={privacyOptions[visibility].leftIcon}
                style={{ marginRight: isWeb ? 16 : 0 }}
                size={24}
              />
              <ListItem.Content>
                <ListItem.Title>{privacyOptions[visibility].title}</ListItem.Title>
                <ListItem.Subtitle style={fontTypes.bodySecondary}>
                  {privacyOptions[visibility].description}
                </ListItem.Subtitle>
              </ListItem.Content>
              <ListItemChevron color={Colors.darkergray} />
            </ListItem>
          </Section>

          {valuesEnabled && Array.isArray(companyValues) && companyValues.length > 0 && (
            <Section>
              <Headline>Which values did they embody?</Headline>

              <ValuesContainer>
                {Array.isArray(companyValues) &&
                  companyValues.map((v, i) => {
                    const name = v?.name || '';
                    const id = v?.id || '';

                    const isSelected = values.findIndex(a => a.id === id) !== -1;

                    if (!name) return null;

                    return (
                      <TouchableOpacity onPress={toggleValue({ id, name })} key={i}>
                        <Badge
                          style={{ marginRight: 8, marginBottom: 4 }}
                          variant={isSelected ? 'primary' : 'info'}
                        >
                          {name}
                        </Badge>
                      </TouchableOpacity>
                    );
                  })}
              </ValuesContainer>
            </Section>
          )}
        </Inner>
      </Wrapper>

      <SubmitOuterContainer>
        <Button
          title={isLoading ? 'Submitting...' : 'Give feedback'}
          onPress={() => {
            setIsLoading(true);

            SubmitFeedback.commit(
              {
                // deliverableType is an enum, but ts treats it as a string
                deliverableType: privacyOptions[visibility].value,
                targetUserIds: recipients.map(k => k.id),
                companyValueIds: values.map(v => v.id),
                body,
              },
              () => {
                dropdownAlert({
                  type: 'success',
                  title: 'Feedback submitted!',
                  body: '',
                });

                setIsLoading(false);

                setTimeout(() => {
                  navigation.navigate('Home');
                }, 250);
              },
              () => {
                dropdownAlert({
                  type: 'error',
                  title: 'Whoops!',
                  body: 'Something is not right, please try again',
                });
              },
            );
          }}
          loading={isLoading}
          disabled={isLoading || invalidForm}
          style={css`
            border-radius: 4px;
            margin-top: 0;
            margin-bottom: 0;
            margin-left: ${isWeb ? '0px' : '16px'};
            margin-right: ${isWeb ? '0px' : '16px'};
            elevation: 0;
          `}
          buttonStyle={css`
            height: 50px;
          `}
        />
      </SubmitOuterContainer>
    </Container>
  );
}

const Headline = styled.Text`
  ${fontTypes.title};
  padding: 0 16px 16px;
`;

const Section = styled.View`
  margin-bottom: 32px;
`;

const SubmitOuterContainer = styled.SafeAreaView`
  position: relative;
  width: 100%;
  padding: 16px;
  margin-top: ${isWeb ? '0px' : '8px'};
  max-width: ${APPLICATION_MAX_WIDTH_STRING};
`;

const Wrapper = styled.ScrollView`
  flex: 1;
  width: 100%;
`;

const Inner = styled.View`
  max-width: ${APPLICATION_MAX_WIDTH_STRING};
  margin: 0 auto;
  width: 100%;
`;

const ValuesContainer = styled.View`
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  display: flex;
  padding: 0 16px;
`;

export default createQueryRenderer(connectDropdownAlert(CreateFeedback), CreateFeedback, {
  query: graphql`
    query feedbackQuery {
      viewer {
        user {
          preferredName
        }
        company {
          feedbackDeliverablePublicEnabled
          feedbackDeliverablePrivateEnabled
          feedbackDeliverableManagerOnlyEnabled
          feedbackDeliverableManagerAndPrivateEnabled
          valuesEnabled
          values {
            id
            name
          }
        }
      }
    }
  `,
  getLoadingProp: true,
});

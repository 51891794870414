import { TouchableOpacity } from 'react-native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import { Colors } from '../utils/constants';

type Props = {
  onBack: () => void;
  type: 'BACK' | 'CLOSE';
  color?: string;
  testID?: string;
};

const BackButton = ({ onBack, type, color, testID }: Props) => (
  <TouchableOpacity testID={testID} onPress={onBack}>
    <MaterialIcons
      name={type === 'BACK' ? 'arrow-back' : 'close'}
      color={color || Colors.icongray}
      size={24}
    />
  </TouchableOpacity>
);

export default BackButton;

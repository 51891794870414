export const initialReviewCycleState = {
  responses: {},
};

export const SET_QUESTION_RESPONSES = 'SET_QUESTION_RESPONSES';
export const SET_QUESTION_RESPONSE = 'SET_QUESTION_RESPONSE';

type ResponseType = {
  isInvalid?: boolean;
  complete?: boolean;
  content?: {
    rating?: number | null;
    body?: string;
    multipleSelect?: string[];
  } | null;
  rating?: number | null;
  body?: string | null;
  multipleSelect?: string[];
  id?: string | null;
  isRejected?: boolean;
};

export type StateType = {
  responses: Record<string, ResponseType>;
};

type SET_QUESTION_RESPONSE_TYPE = {
  type: 'SET_QUESTION_RESPONSE';
  payload: {
    reviewRequestQuestionId: string;
    response: ResponseType;
  };
};

type SET_QUESTION_RESPONSES_TYPE = {
  type: 'SET_QUESTION_RESPONSES';
  payload: {
    responses: Record<string, ResponseType>;
  };
};

type ActionType = SET_QUESTION_RESPONSE_TYPE | SET_QUESTION_RESPONSES_TYPE;

export function reviewCycleResponseReducer(state: StateType, action: ActionType) {
  switch (action.type) {
    case SET_QUESTION_RESPONSES:
      return {
        ...state,
        responses: action.payload.responses,
      };

    case SET_QUESTION_RESPONSE:
      return {
        ...state,
        responses: {
          ...state.responses,
          [action.payload.reviewRequestQuestionId]: {
            ...state.responses[action.payload.reviewRequestQuestionId],
            ...action.payload.response,
          },
        },
      };

    default:
      throw new Error('Unknown action type!');
  }
}

export const BodyQuestion = 'BodyQuestion';
export const RatingQuestion = 'RatingQuestion';
export const MultipleSelectQuestion = 'MultipleSelectQuestion';
export const MultipleChoiceQuestion = 'MultipleChoiceQuestion';
export const RatingAndBodyQuestion = 'RatingAndBodyQuestion';
export const MultipleSelectAndBodyQuestion = 'MultipleSelectAndBodyQuestion';
export const MultipleChoiceAndBodyQuestion = 'MultipleChoiceAndBodyQuestion';

type QuestionTypeNameType =
  | 'BodyQuestion'
  | 'RatingQuestion'
  | 'MultipleSelectQuestion'
  | 'MultipleChoiceQuestion'
  | 'RatingAndBodyQuestion'
  | 'MultipleSelectAndBodyQuestion'
  | 'MultipleChoiceAndBodyQuestion';

type PayloadProps = {
  value: {
    rating?: string | undefined;
    body?: string;
    multipleSelect?: string[];
  };
  type: QuestionTypeNameType;
  response?: ResponseType | null;
};

// This function is invoked right before sending our data to the backend
// The backend response object exposes these fields on a top level like this:
// response: {
//   rating: "5",
//   body: ..
// }
// Though when we write data, it expects the shape to look like this:
// response: {
//   content: {
//     rating: "5",
//     body: ..
//   }
// }
// In the case that we don't have internet, we begin queueing all changes
// in the content object .. that way a user can change their rating and
// also write a comment while offline. As soon as they reconnect we
// try syncing the final content object which should have accumulated all
// changes
export const formatPayload = ({ value, response, type }: PayloadProps) => {
  const { isInvalid, ...res } = response || {};

  switch (type) {
    case BodyQuestion:
      return {
        body: res.content?.body || res.body || undefined,
        ...value,
      };
    case RatingQuestion:
      return {
        rating: res.content?.rating || res.rating || undefined,
        ...value,
      };
    case RatingAndBodyQuestion:
      return {
        rating: res.content?.rating || res.rating || undefined,
        body: res.content?.body || res.body || undefined,
        ...value,
      };
    case MultipleSelectQuestion:
      return {
        multipleSelect: res.content?.multipleSelect || res.multipleSelect || undefined,
        ...value,
      };
    case MultipleChoiceQuestion:
      return {
        multipleSelect: res.content?.multipleSelect || res.multipleSelect || undefined,
        ...value,
      };
    case MultipleSelectAndBodyQuestion:
      return {
        multipleSelect: res.content?.multipleSelect || res.multipleSelect || undefined,
        body: res.body || undefined,
        ...value,
      };
    case MultipleChoiceAndBodyQuestion:
      return {
        multipleSelect: res.content?.multipleSelect || res.multipleSelect || undefined,
        body: res.body || undefined,
        ...value,
      };
    default:
      throw new Error('Unkown question type');
  }
};

type ContentType = {
  body?: string | undefined;
  rating?: string | undefined;
  multipleSelect?: string[] | undefined;
  rejected?: boolean | undefined;
};

type QuestionType = {
  __typename: string;
  entityId: string;
  isOptional?: boolean;
  shouldHideNumbers?: boolean;
  maximumSelection?: number | null;
  isRatingOptional?: boolean;
  isBodyOptional?: boolean;
  isMultipleSelectOptional?: boolean;
  isMultipleChoiceOptional?: boolean;
};

export const isInvalid = (content: ContentType, question: QuestionType) => {
  if (content.rejected) return false;

  switch (question.__typename) {
    case BodyQuestion: {
      if (!question.isOptional) {
        if (!content) return true;
        if (!content.body) return true;
      }

      return false;
    }
    case RatingQuestion: {
      if (!question.isOptional) {
        if (!content) return true;
        if (!content.rating) return true;
      }

      return false;
    }
    case MultipleSelectQuestion: {
      if (!question.isOptional) {
        if (!content) return true;
        if (!content.multipleSelect) return true;
        if (content.multipleSelect.length === 0) return true;
      }

      return false;
    }
    case MultipleChoiceQuestion: {
      if (!question.isOptional) {
        if (!content) return true;
        if (!content.multipleSelect) return true;
        if (content.multipleSelect.length === 0) return true;
      }

      return false;
    }
    case RatingAndBodyQuestion: {
      if (!question.isRatingOptional) {
        if (!content) return true;
        if (!content.rating) return true;
      }
      if (!question.isBodyOptional) {
        if (!content) return true;
        if (!content.body) return true;
      }

      return false;
    }
    case MultipleSelectAndBodyQuestion: {
      if (!question.isMultipleSelectOptional) {
        if (!content) return true;
        if (!content.multipleSelect) return true;
        if (content.multipleSelect.length === 0) return true;
      }
      if (!question.isBodyOptional) {
        if (!content) return true;
        if (!content.body) return true;
      }

      return false;
    }
    case MultipleChoiceAndBodyQuestion: {
      if (!question.isMultipleChoiceOptional) {
        if (!content) return true;
        if (!content.multipleSelect) return true;
        if (content.multipleSelect.length === 0) return true;
      }
      if (!question.isBodyOptional) {
        if (!content) return true;
        if (!content.body) return true;
      }

      return false;
    }
    default: {
      // Don't let people submit a question we don't understand
      return true;
    }
  }
};

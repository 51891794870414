import { commitMutation, graphql } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';
import type { SaveOneOnOneNoteInput } from 'jakiro-types/saveOneOnOneNoteMutation.graphql';

const mutation = graphql`
  mutation saveOneOnOneNoteMutation($input: SaveOneOnOneNoteInput!) {
    saveOneOnOneNote(input: $input) {
      meeting {
        note
        id
      }
      errors
    }
  }
`;

type Fn = (val: any) => void;

function commit(vars: SaveOneOnOneNoteInput, onCompleted: Fn, onError: Fn) {
  const variables = {
    input: {
      ...vars,
    },
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

export default { commit };

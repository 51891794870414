import * as React from 'react';
import { ActivityIndicator, Text, View, TouchableOpacity } from 'react-native';
import type { StackNavigationProp } from '@react-navigation/stack';
import { ListItem, Image } from '@rneui/themed';
import { graphql } from 'react-relay';
import { css } from '@emotion/native';
import { useNavigation } from '@react-navigation/native';

import {
  ListItemStyle,
  fontTypes,
  Colors,
  APPLICATION_MAX_WIDTH_STRING,
} from '../../utils/constants';
import { getConfig } from '../../utils/getConfig';
import createQueryRenderer from '../../utils/createQueryRenderer';
import Header from '../../components/Header';
import type { RootStackParamList } from '../../components/Navigation/types';
import { ListItemChevron } from '../../components/ListItemChevron';

import type { directoryQueryResponse } from 'jakiro-types/directoryQuery.graphql';

type Props = {
  relay: any;
} & directoryQueryResponse;

const URL_REGEX = /(?:https?:\/\/)?([^\s/]+)/;

function Directory(props: Props) {
  const { viewer } = props;

  const navigation = useNavigation<StackNavigationProp<RootStackParamList, 'Directory'>>();
  const companyLogo = viewer?.company.logoFileName;
  const companyUrl = viewer?.company.url;
  const companyName = viewer?.company.name;
  const isOfficePartyEnabled = viewer?.company?.settings?.isOfficePartyEnabled;

  let fullCompanyImageUrl = '';

  if (companyLogo) {
    fullCompanyImageUrl = `${getConfig('UPLOAD_IMGIX_HOST')}/${companyLogo}`;
  } else {
    const match = companyUrl && companyUrl.match(URL_REGEX);
    const hostname = (match && match[1]) || '';

    fullCompanyImageUrl = `https://logo.clearbit.com/${hostname}?size=${100}`;
  }

  return (
    <React.Fragment>
      <Header
        mode="PROFILE"
        handleLeftIconPress={() => navigation.navigate('Settings')}
        user={viewer?.user}
        wrapperStyle={{
          borderBottomColor: Colors.border,
          borderBottomWidth: 1,
        }}
        title="Directory"
        bottomComponent
      />

      <View
        style={css`
          flex: 1;
          background-color: ${Colors.faint};
          align-items: center;
          width: 100%;
        `}
      >
        <View
          style={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 100%;
            padding-top: 60px;
            max-width: ${APPLICATION_MAX_WIDTH_STRING};
          `}
        >
          <Image
            source={{ uri: fullCompanyImageUrl }}
            style={{ width: 120, height: 120 }}
            PlaceholderContent={<ActivityIndicator />}
          />

          <Text
            style={[
              fontTypes.header,
              css`
                margin-top: 24px;
                margin-bottom: 32px;
              `,
            ]}
          >
            {companyName}
          </Text>

          <View
            style={css`
              display: flex;
              flex-direction: column;
              width: 100%;
            `}
          >
            <ListItem
              Component={TouchableOpacity}
              onPress={() => navigation.navigate('Directory Employees')}
              bottomDivider
              topDivider
              style={ListItemStyle}
            >
              <ListItem.Content>
                <ListItem.Title>All employees</ListItem.Title>
              </ListItem.Content>
              <ListItemChevron />
            </ListItem>

            <ListItem
              Component={TouchableOpacity}
              onPress={() => navigation.navigate('Directory Departments')}
              bottomDivider
              style={ListItemStyle}
            >
              <ListItem.Content>
                <ListItem.Title>By department</ListItem.Title>
              </ListItem.Content>
              <ListItemChevron />
            </ListItem>

            {isOfficePartyEnabled && (
              <ListItem
                Component={TouchableOpacity}
                onPress={() => navigation.navigate('Office Party')}
                bottomDivider
                style={ListItemStyle}
              >
                <ListItem.Content>
                  <ListItem.Title>Office Party</ListItem.Title>
                </ListItem.Content>
                <ListItemChevron />
              </ListItem>
            )}
          </View>
        </View>
      </View>
    </React.Fragment>
  );
}

export default createQueryRenderer(Directory, Directory, {
  query: graphql`
    query directoryQuery {
      viewer {
        user {
          name
          avatarUrl
          email
        }
        company {
          name
          logoFileName
          url
          settings {
            isOfficePartyEnabled
          }
        }
      }
    }
  `,
  variables: {},
});

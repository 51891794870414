/**
 * @generated SignedSource<<297c02f065a6abdca4daab636adbdc25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimelineItem_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserChip_user">;
  readonly " $fragmentType": "TimelineItem_user";
};
export type TimelineItem_user = TimelineItem_user$data;
export type TimelineItem_user$key = {
  readonly " $data"?: TimelineItem_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimelineItem_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimelineItem_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserChip_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "828aa8639f4d9bb67eee128f6b9a9aa0";

export default node;

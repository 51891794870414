import * as React from 'react';
import { css } from '@emotion/native';
import { View } from 'react-native';

import { Colors } from '../../utils/constants';

export function ProgressBar({ progress }: { progress: number }) {
  return (
    <View style={{ flexDirection: 'row', width: '100%' }}>
      <View
        style={css`
          height: 5px;
          width: 100%;
        `}
      >
        <View
          style={css`
            flex: 1;
            overflow: hidden;
            background-color: #bbb;
            border-radius: 10px;
            flex-direction: row;
          `}
        >
          <View
            style={[
              css`
                background-color: ${Colors.iosBlue};
              `,
              { width: `${progress}%` },
            ]}
          />
          <View
            style={[
              css`
                background-color: transparent;
              `,
              { width: `${100 - progress}%` },
            ]}
          />
        </View>
      </View>
    </View>
  );
}

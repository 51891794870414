/**
 * @generated SignedSource<<934bc3567145b20045c3a877a7fa9304>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OneOnOne_checkinRelationship$data = {
  readonly viewerIsManager: boolean;
  readonly id: string;
  readonly entityId: string;
  readonly isOneOnOneActive: boolean;
  readonly report: {
    readonly preferredName: string | null;
    readonly viewerIsUser: boolean;
    readonly entityId: string;
  } | null;
  readonly manager: {
    readonly preferredName: string | null;
    readonly viewerIsUser: boolean;
    readonly entityId: string;
  } | null;
  readonly oneOnOneMeetings: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly entityId: string;
        readonly scheduledAt: DateString;
      } | null;
    } | null> | null;
  } | null;
  readonly findOneOnOneMeeting?: {
    readonly scheduledAt: DateString;
    readonly entityId: string;
    readonly previousOneOnOneMeeting: {
      readonly entityId: string;
    } | null;
    readonly nextOneOnOneMeeting: {
      readonly entityId: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"OneOnOneContent_oneOnOne">;
  } | null;
  readonly nextRecurringOneOnOneMeeting: {
    readonly __typename: string;
    readonly id: string;
    readonly scheduledAt: DateString;
    readonly entityId?: string;
    readonly " $fragmentSpreads": FragmentRefs<"OneOnOneContent_oneOnOne">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"OneOnOneContent_checkinRelationship">;
  readonly " $fragmentType": "OneOnOne_checkinRelationship";
};
export type OneOnOne_checkinRelationship = OneOnOne_checkinRelationship$data;
export type OneOnOne_checkinRelationship$key = {
  readonly " $data"?: OneOnOne_checkinRelationship$data;
  readonly " $fragmentSpreads": FragmentRefs<"OneOnOne_checkinRelationship">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "preferredName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "viewerIsUser",
    "storageKey": null
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduledAt",
  "storageKey": null
},
v4 = [
  (v1/*: any*/)
],
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "OneOnOneContent_oneOnOne"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hasNoMeetingId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "meetingId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OneOnOne_checkinRelationship",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerIsManager",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOneOnOneActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "report",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "manager",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "pastOnly",
          "value": true
        }
      ],
      "concreteType": "checkinRelationshipOneOnOneMeetingsConnection",
      "kind": "LinkedField",
      "name": "oneOnOneMeetings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "checkinRelationshipOneOnOneMeetingsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OneOnOneMeeting",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "oneOnOneMeetings(first:1,pastOnly:true)"
    },
    {
      "condition": "hasNoMeetingId",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "meetingId",
              "variableName": "meetingId"
            }
          ],
          "concreteType": "OneOnOneMeeting",
          "kind": "LinkedField",
          "name": "findOneOnOneMeeting",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "OneOnOneMeeting",
              "kind": "LinkedField",
              "name": "previousOneOnOneMeeting",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OneOnOneMeeting",
              "kind": "LinkedField",
              "name": "nextOneOnOneMeeting",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "nextRecurringOneOnOneMeeting",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            (v5/*: any*/)
          ],
          "type": "OneOnOneMeeting",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OneOnOneContent_checkinRelationship"
    }
  ],
  "type": "CheckinRelationship",
  "abstractKey": null
};
})();

(node as any).hash = "845be450ba00d683bc08b7b8f8c063d2";

export default node;

import { useState } from 'react';
import { View } from 'react-native';
import { graphql, createRefetchContainer, RelayRefetchProp } from 'react-relay';
import type { StackNavigationProp } from '@react-navigation/stack';
import moment from 'moment';

import PastOneOnOneListItem from '../../components/PastOneOnOnesList/PastOneOnOneListItem';
import LoadMore from '../../components/LoadMore';
import EmptyState from '../../components/EmptyState';
import { getLength, cleanConnection } from '../../utils/fns';
import Separator from '../../components/Separator';
import type { RootStackParamList } from '../../components/Navigation/types';

import type { PastOneOnOneList_checkinRelationship } from 'jakiro-types/PastOneOnOneList_checkinRelationship.graphql';
import { useNavigation } from '@react-navigation/native';

type Props = {
  checkinRelationship: PastOneOnOneList_checkinRelationship;
  userId: string;
  viewerIsUser: boolean;
  relay: RelayRefetchProp;
};

function PasOneOnOneList(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList, 'Past One On Ones'>>();

  const { checkinRelationship, viewerIsUser, userId, relay } = props;

  if (!checkinRelationship) return null;

  const oneOnOnesConnection = checkinRelationship.oneOnOneMeetings;
  const oneOnOneMeetings = cleanConnection(oneOnOnesConnection);

  if (!oneOnOnesConnection) {
    if (viewerIsUser) {
      return <EmptyState title="No past 1:1s" body="You have no past 1:1s." />;
    }

    return <EmptyState title="No past 1:1s" body="There are no past 1:1s." />;
  }

  const now = moment();

  return (
    <View
      style={{
        borderRadius: 4,
        borderWidth: 0.5,
        borderColor: '#d6d7da',
      }}
    >
      {oneOnOneMeetings
        .filter(m => moment(m.scheduledAt).isBefore(now))
        .map((node, index) => (
          <View key={index}>
            <PastOneOnOneListItem
              key={index}
              onPress={(id: string) => {
                navigation.navigate('One On One', {
                  meetingId: id,
                  userId,
                });
              }}
              oneOnOneMeeting={node}
            />
            <Separator />
          </View>
        ))}

      {oneOnOnesConnection.pageInfo.hasNextPage && (
        <LoadMore
          isLoading={isLoading}
          handleLoadMore={() => {
            setIsLoading(true);

            const count = getLength(oneOnOnesConnection.edges);

            const refetchVariables = () => ({
              first: count + 10,
            });

            relay.refetch(refetchVariables, null, () => {
              setIsLoading(false);
            });
          }}
        />
      )}
    </View>
  );
}

export default createRefetchContainer(
  PasOneOnOneList,
  {
    checkinRelationship: graphql`
      fragment PastOneOnOneList_checkinRelationship on CheckinRelationship
      @argumentDefinitions(first: { type: "Int!" }) {
        oneOnOneMeetings(first: $first) {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              scheduledAt
              ...PastOneOnOneListItem_oneOnOneMeeting
            }
          }
        }
      }
    `,
  },
  graphql`
    query PastOneOnOneListRefetchQuery($first: Int!) {
      viewer {
        managerCheckinRelationship {
          ...PastOneOnOneList_checkinRelationship @arguments(first: $first)
        }
      }
    }
  `,
);

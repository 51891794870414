import { ComponentType, useEffect } from 'react';
import { addNotificationReceivedListener } from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';

import { RootStackParamList } from '../components/Navigation/types';

export default function withNotificationSubscription<T>(Component: ComponentType<T>) {
  // eslint-disable-next-line react/display-name
  return (props: T) => {
    const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

    useEffect(() => {
      const subscription = addNotificationReceivedListener(notification => {
        const { data } = notification.request.content.data;

        if (data.pulseGroupEntityId) {
          navigation.navigate('Pulse Survey', {
            pulseGroupEntityId: data.pulseGroupEntityId,
          });
        }
      });

      return () => subscription.remove();
    }, []);

    return <Component {...props} />;
  };
}

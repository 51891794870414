import * as React from 'react';
import {
  TouchableWithoutFeedback,
  TextInput,
  StyleSheet,
  View,
  Text,
  ActivityIndicator,
} from 'react-native';

import { Colors, ScreenWidth } from '../utils/constants';

type Props = {
  value: string | null;
  onChange: (k: string) => void;
  isLoading: boolean;
  onSubmit: () => void;
};

const getWidth = (val: string): {} => (!val ? { maxWidth: 375, width: ScreenWidth - 80 } : {});

class CompanyNameInput extends React.Component<Props> {
  textInput: any;

  getFocused = () => {
    this.textInput && this.textInput.focus();
  };

  render() {
    const { value, isLoading, onChange, onSubmit } = this.props;
    return (
      <TouchableWithoutFeedback onPress={this.getFocused}>
        <View style={styles.box}>
          <TextInput
            keyboardAppearance="dark"
            ref={e => (this.textInput = e)}
            autoCapitalize="none"
            testID="UnAuthedHome_slug"
            returnKeyType="done"
            placeholder="team domain"
            style={[styles.input, getWidth(value)]}
            value={value}
            autoCorrect={false}
            onSubmitEditing={onSubmit}
            onChangeText={onChange}
            placeholderTextColor={Colors.overlay_bright_50}
            underlineColorAndroid="transparent"
          />
          <Text style={[styles.input, styles.latticehq]}>.latticehq.com</Text>
          {isLoading ? (
            <ActivityIndicator style={styles.loading} size="small" color={Colors.lightgray} />
          ) : (
            <View />
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
  latticehq: {
    position: 'absolute',
    right: 10,
    bottom: 10,
  },
  loading: { flex: 1, alignItems: 'flex-end' },
  input: {
    fontSize: 17,
    color: Colors.white,
    alignSelf: 'flex-end',
    padding: 0,
    margin: 0,
    borderWidth: 0,
  },
  box: {
    height: 44,
    padding: 10,
    width: '100%',
    flexDirection: 'row',
    alignSelf: 'center',
    backgroundColor: Colors.overlay_bright_15,
    borderColor: Colors.overlay_bright_15,
    borderWidth: 1,
    borderRadius: 8,
  },
});
export default CompanyNameInput;

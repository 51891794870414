import { graphql } from 'react-relay';

import createQueryRenderer from '../../../../utils/createQueryRenderer';
import PastOneOnOnesList from '../../../../components/PastOneOnOnesList';
import type { PastOneOnOnesTabQueryResponse } from 'jakiro-types/PastOneOnOnesTabQuery.graphql';

type Props = {
  toggleModal: () => void;
  userId: string;
  viewerIsUser: boolean;
} & PastOneOnOnesTabQueryResponse;

const PastOneOnOnesTab = ({ viewer, user, viewerIsUser, ...rest }: Props) => {
  const relationship = viewer
    ? viewer.managerCheckinRelationship
    : user
    ? user.viewerUserRelationship
    : null;

  if (!relationship) return null;

  return (
    <PastOneOnOnesList {...rest} checkinRelationship={relationship} viewerIsUser={viewerIsUser} />
  );
};

/*
if viewer is user then get info through viewer.managerCheckinRelationship
else
then get through user.viewerUserRelationship
Motivation for this is that if user viewing this is not a manager he can't see
the checkin relationship through the user schema, only through the viewer.managerCheckinRelationship schema.
*/

export default createQueryRenderer(PastOneOnOnesTab, PastOneOnOnesTab, {
  query: graphql`
    query PastOneOnOnesTabQuery($first: Int!, $entityId: String!, $viewerIsUser: Boolean!) {
      viewer @include(if: $viewerIsUser) {
        managerCheckinRelationship {
          ...PastOneOnOnesList_checkinRelationship @arguments(first: $first)
        }
      }
      user(entityId: $entityId) @skip(if: $viewerIsUser) {
        viewerUserRelationship {
          ...PastOneOnOnesList_checkinRelationship @arguments(first: $first)
        }
      }
    }
  `,
  queriesParams: props => ({ entityId: props.userId, first: 10, viewerIsUser: props.viewerIsUser }),
});

import * as React from 'react';
import { View, Text, TouchableOpacity, ActivityIndicator } from 'react-native';
import { css } from '@emotion/native';
import { noop } from 'lodash';
import type { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import saveOneOnOneTalkingPointComment from '../../mutations/saveOneOnOneTalkingPointComment';
import MultilineTextInput from '../../components/MultilineTextInput';
import Markdown from '../../components/Markdown';
import withHeader from '../../hocs/withHeader';
import withKeyboardAware from '../../hocs/withKeyboardAware';
import { connectDropdownAlert, Alert } from '../../hocs/withDropdownAlert';
import { fontTypes, isIOS, Colors } from '../../utils/constants';
import { RootStackParamList } from '../../components/Navigation/types';

type Props = {
  isKeyboardOpen: boolean;
  keyboardHeight: number;
  dropdownAlert: Alert;
  navigation: StackNavigationProp<RootStackParamList, 'Talking Point Comment'>;
  route: RouteProp<RootStackParamList, 'Talking Point Comment'>;
};

function WriteTalkingPointComment({ dropdownAlert, keyboardHeight, navigation, route }: Props) {
  const {
    comment: commentFromParams,
    talkingPointBody,
    talkingPointCommentId,
    talkingPointId,
  } = route.params;

  const [comment, setComment] = React.useState(commentFromParams);
  const [loading, setLoading] = React.useState(false);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [inputRef]);

  const submit = () => {
    if (!comment.trim().length || loading) return;

    setLoading(true);

    saveOneOnOneTalkingPointComment.commit(
      {
        talkingPointCommentId,
        talkingPointId,
        body: comment.trim(),
      },
      () => {
        setLoading(false);
        navigation.goBack();
      },
      () => {
        setLoading(false);
        dropdownAlert({
          type: 'error',
          title: 'Whoops, something is not right.',
          body: 'There was an error with this talking point comment, please try again. If the problem persist, contact customer support.',
        });
      },
    );
  };

  return (
    <React.Fragment>
      <View
        style={css`
          flex: 1;
          padding: 20px;
        `}
      >
        <Text
          style={[
            css`
              margin-bottom: 16px;
            `,
          ]}
        >
          <Markdown>{talkingPointBody}</Markdown>
        </Text>

        <MultilineTextInput
          getInputRef={ref => {
            if (inputRef.current === null) {
              inputRef.current = ref;
            }
          }}
          onChange={setComment}
          value={comment || ''}
          inputStyle={{ color: 'black' }}
          placeholder="Write a comment"
          blurOnSubmit={false}
          returnKeyType={null}
          editable
        />
      </View>
      <View style={{ marginTop: 10, marginBottom: isIOS ? keyboardHeight : 0 }}>
        <TouchableOpacity
          onPress={comment.length ? submit : noop}
          disabled={!comment.length}
          style={css`
            background-color: ${comment.length ? Colors.iosBlue : Colors.lightgray};
            padding: 16px;
            align-items: center;
            justify-content: center;
            display: flex;
          `}
        >
          {loading ? (
            <ActivityIndicator color="white" />
          ) : (
            <Text
              style={[fontTypes.bodyTitle, { color: comment.length ? 'white' : Colors.darkergray }]}
            >
              {talkingPointCommentId ? 'Update' : 'Save'} comment
            </Text>
          )}
        </TouchableOpacity>
      </View>
    </React.Fragment>
  );
}
export default withHeader({ title: 'Comment', mode: 'BACK' })(
  connectDropdownAlert(withKeyboardAware(WriteTalkingPointComment)),
);

import { View, Text } from 'react-native';
import { css } from '@emotion/native';

import ChevBtn from '../../../components/ChevBtn';
import { Colors, fontTypes, APPLICATION_MAX_WIDTH_STRING } from '../../../utils/constants';

type Props = {
  title: string;
  onBack?: (() => void) | null;
  onNext?: (() => void) | null;
};

const OneOnOnePager = ({ title, onBack, onNext }: Props) => {
  return (
    <View style={styles.container}>
      <View
        style={css`
          max-width: ${APPLICATION_MAX_WIDTH_STRING};
          width: 100%;
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <ChevBtn onPress={onBack} direction="LEFT" />
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <ChevBtn onPress={onNext} direction="RIGHT" />
      </View>
    </View>
  );
};

const styles = {
  container: {
    height: 50,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
    backgroundColor: Colors.lightestgray,
  },
  titleContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  chev: direction => ({
    display: 'flex',
    minWidth: 80,
    alignItems: direction === 'LEFT' ? 'flex-start' : 'flex-end',
  }),
  title: {
    paddingBottom: 4,
    textAlign: 'center',
    ...fontTypes.body_semi_bold,
  },
} as const;

export default OneOnOnePager;

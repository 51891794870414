/**
 * @generated SignedSource<<6dd6a2b5f9c263473e01e5bf0c8462f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PastOneOnOneListItem_oneOnOneMeeting$data = {
  readonly id: string;
  readonly entityId: string;
  readonly scheduledAt: DateString;
  readonly " $fragmentType": "PastOneOnOneListItem_oneOnOneMeeting";
};
export type PastOneOnOneListItem_oneOnOneMeeting = PastOneOnOneListItem_oneOnOneMeeting$data;
export type PastOneOnOneListItem_oneOnOneMeeting$key = {
  readonly " $data"?: PastOneOnOneListItem_oneOnOneMeeting$data;
  readonly " $fragmentSpreads": FragmentRefs<"PastOneOnOneListItem_oneOnOneMeeting">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PastOneOnOneListItem_oneOnOneMeeting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledAt",
      "storageKey": null
    }
  ],
  "type": "OneOnOneMeeting",
  "abstractKey": null
};

(node as any).hash = "d2b6056a3b95d04c74b5a477ad92503c";

export default node;

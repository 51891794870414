import { Dimensions, Platform } from 'react-native';
import { css } from '@emotion/native';

const Screen = Dimensions.get('window');

export const ScreenWidth: number = Screen.width;
export const ScreenHeight: number = Screen.height;
export const isIOS = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';
export const isWeb = Platform.OS === 'web';
export const isIOS_X = isIOS && ScreenHeight === 812;
export const HEADER_MIN_HEIGHT = isIOS_X ? 90 : 80;
export const TABS_SIZE = 57;
export const APPLICATION_MAX_WIDTH_INT = 700;
export const APPLICATION_MAX_WIDTH_STRING = '700px';
export const isMaxWidth = ScreenWidth > APPLICATION_MAX_WIDTH_INT;
export const isDevelopment = process.env.NODE_ENV === 'development';

// OS a function so its compatiable with the graphQL deviceOs type (ios | android | web)
export const OS = () => {
  let deviceOs: 'ios' | 'android' | 'web';

  if (isIOS) {
    deviceOs = 'ios';
  } else if (isAndroid) {
    deviceOs = 'android';
  } else if (isWeb) {
    deviceOs = 'web';
  } else {
    throw new Error('Device is not iOS or Android!');
  }
  return deviceOs;
};

export const COMPANY_SLUG = 'COMPANY_SLUG';
export const HAS_ASKED_FOR_NOTIFICATION_PERMISSION = 'HAS_ASKED_FOR_NOTIFICATION_PERMISSION';

const baseColors = {
  // Blues
  blue: '#0174E0',
  iosBlue: '#007AFF',
  darkblue: '#0060BF',
  lightblue: '#A1CFF8',
  lighterblue: '#EBF6FF',
  brightblue: '#007AFF',
  // Greens
  green: '#1FAE96',
  darkgreen: '#167B6A',
  lightgreen: '#A5DED5',
  lightergreen: '#E9F7F4',
  // Reds
  red: '#E14E38',
  iosRed: '#FE3F34',
  darkred: '#B43E2C',
  lightred: '#F3B8AF',
  lighterred: '#FCEDEB',
  // Yellows
  yellow: '#F5AF22',
  darkyellow: '#C78E1C',
  lightyellow: '#FBDFA6',
  lighteryellow: '#FFF8EB',
  // Gradients
  // gradient: 'linear-gradient(-45deg, #3FA8B0 0%, #1FAE96 100%)',
  disabled_icon: 'rgba(96, 111, 127, 0.4)',
  // Grays
  icongray: '#606f7f',
  darkestgray: '#222D38',
  darkergray: '#617080',
  darkgray: '#92A0AD',
  gray: '#C5CED6',
  disabled_gray: '#E6EBF0',
  lightgray: '#DCE1E5',
  lightergray: '#F0F2F5',
  lightestgray: '#F7F9FA', // used on backgrounds
  faint: '#FAFBFC',
  // Borders
  border: '#DCE1E6',
  // Whites
  white: '#FFFFFF',
  white72: 'rgba(255, 255, 255, 0.72)',
  white52: 'rgba(255, 255, 255, 0.52)',
  white32: 'rgba(255, 255, 255, 0.32)',
  white16: 'rgba(255, 255, 255, 0.16)',
  white08: 'rgba(255, 255, 255, 0.08)',

  overlay: 'rgba(0,0,0,0.4)',
  overlay_bright: 'rgba(250, 250, 250, 0.70)',
  overlay_bright_50: 'rgba(250, 250, 250, 0.50)',
  overlay_bright_30: 'rgba(250, 250, 250, 0.30)',
  overlay_bright_15: 'rgba(250, 250, 250, 0.15)',

  black: '#000',
};

const extraColors = {
  good: baseColors.green,
  warn: baseColors.yellow,
  bad: baseColors.red,
  notice: baseColors.blue,
  grayblue: '#75828f',
  buttonPrimary: baseColors.blue,
  buttonPrimaryHover: baseColors.darkblue,
  buttonDestructive: baseColors.red,
  buttonDestructiveHover: baseColors.darkred,
  dropDown: baseColors.yellow,
};

const extraBackgroundColors = {
  badgeBackground: 'rgba(0,0,0,0.32)',
  progressBackground: 'rgba(0,0,0,0.48)',
  overAchievedProgressBackground: 'rgba(255,255,255,0.52)',
  goalHeaderTabsBackground: 'rgba(0, 0, 0, 0.08)',
};

export const Colors = {
  ...extraColors,
  ...baseColors,
  ...extraBackgroundColors,
};

export const fontTypes = {
  sub_header: {
    fontSize: 34,
    lineHeight: 41,
    color: Colors.darkestgray,
    fontFamily: 'SFProDisplay-Bold',
  },
  header: {
    fontSize: 28,
    lineHeight: 34,
    color: Colors.darkestgray,
    fontFamily: 'SFProDisplay-SemiBold',
  },
  title: {
    fontSize: 20,
    lineHeight: 25,
    color: Colors.darkestgray,
    fontFamily: 'SFProDisplay-SemiBold',
  },
  bodyTitle: {
    fontSize: 17,
    lineHeight: 25,
    fontFamily: 'SFProDisplay-SemiBold',
    color: Colors.darkestgray,
  },
  bodyTitleRegular: {
    fontSize: 17,
    lineHeight: 25,
    fontFamily: 'SFProDisplay-Regular',
    color: Colors.darkergray,
  },
  subHeaderBodyTitle: {
    fontSize: 15,
    fontFamily: 'SFProDisplay-Regular',
    lineHeight: 20,
    color: Colors.darkestgray,
  },
  subHeaderBodyTitleSecondary: {
    fontSize: 15,
    fontFamily: 'SFProDisplay-Regular',
    lineHeight: 20,
    color: Colors.darkergray,
  },
  body: {
    fontSize: 15,
    lineHeight: 20,
    color: Colors.darkestgray,
    fontFamily: 'SFProDisplay-Regular',
  },
  body_semi_bold: {
    fontSize: 15,
    lineHeight: 18,
    color: Colors.darkergray,
    fontFamily: 'SFProDisplay-SemiBold',
  },
  bodySecondary: {
    fontSize: 13,
    lineHeight: 18,
    color: Colors.darkergray,
    fontFamily: 'SFProDisplay-Regular',
    fontWeight: 'normal',
  },
  informative_text: {
    fontFamily: 'SFProDisplay-Regular',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 20,
    color: Colors.darkestgray,
  },
  informative_text_semibold: {
    fontFamily: 'SFProDisplay-Semibold',
    fontSize: 15,
    lineHeight: 20,
    color: Colors.darkestgray,
  },
  alert: {
    fontFamily: 'SanFranciscoText-Regular',
    fontSize: 16,
    lineHeight: 22,
    color: 'white',
  },
  informative_text_small: {
    fontSize: 12,
    fontFamily: 'SFProDisplay-SemiBold',
    lineHeight: 16,
    textTransform: 'uppercase',
    color: Colors.darkestgray,
  },
  error: {
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 12,
    lineHeight: 16,
    color: Colors.red,
  },
} as const;

export const ListItemStyle = css`
  margin-left: ${isMaxWidth ? '16px' : '0px'};
  margin-right: ${isMaxWidth ? '16px' : '0px'};
  border-left-width: ${isMaxWidth ? '1' : '0'};
  border-right-width: ${isMaxWidth ? '1' : '0'};
  border-left-color: ${Colors.border};
  border-right-color: ${Colors.border};
`;

/**
 * @generated SignedSource<<a69f9c8c9c9659fcac12cbe631b2a9ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SaveOneOnOneTalkingPointCommentInput = {
  body: string;
  clientMutationId?: string | null;
  talkingPointCommentId?: string | null;
  talkingPointId: string;
};
export type saveOneOnOneTalkingPointCommentMutation$variables = {
  input: SaveOneOnOneTalkingPointCommentInput;
};
export type saveOneOnOneTalkingPointCommentMutationVariables = saveOneOnOneTalkingPointCommentMutation$variables;
export type saveOneOnOneTalkingPointCommentMutation$data = {
  readonly saveOneOnOneTalkingPointComment: {
    readonly talkingPoint: {
      readonly id: string;
      readonly comments: ReadonlyArray<{
        readonly id: string;
        readonly createdAt: DateString;
        readonly body: string | null;
        readonly user: {
          readonly viewerIsUser: boolean;
          readonly avatarUrl: string | null;
          readonly name: string | null;
        };
      }>;
    } | null;
    readonly errors: ReadonlyArray<string | null> | null;
  } | null;
};
export type saveOneOnOneTalkingPointCommentMutationResponse = saveOneOnOneTalkingPointCommentMutation$data;
export type saveOneOnOneTalkingPointCommentMutation = {
  variables: saveOneOnOneTalkingPointCommentMutationVariables;
  response: saveOneOnOneTalkingPointCommentMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsUser",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "saveOneOnOneTalkingPointCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveOneOnOneTalkingPointCommentPayload",
        "kind": "LinkedField",
        "name": "saveOneOnOneTalkingPointComment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "talkingPoint",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OneOnOneTalkingPointComment",
                "kind": "LinkedField",
                "name": "comments",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "saveOneOnOneTalkingPointCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveOneOnOneTalkingPointCommentPayload",
        "kind": "LinkedField",
        "name": "saveOneOnOneTalkingPointComment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "talkingPoint",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OneOnOneTalkingPointComment",
                "kind": "LinkedField",
                "name": "comments",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4e48d2c6d870408a3f8e5c740fc12821",
    "id": null,
    "metadata": {},
    "name": "saveOneOnOneTalkingPointCommentMutation",
    "operationKind": "mutation",
    "text": "mutation saveOneOnOneTalkingPointCommentMutation(\n  $input: SaveOneOnOneTalkingPointCommentInput!\n) {\n  saveOneOnOneTalkingPointComment(input: $input) {\n    talkingPoint {\n      __typename\n      id\n      comments {\n        id\n        createdAt\n        body\n        user {\n          viewerIsUser\n          avatarUrl\n          name\n          id\n        }\n      }\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "8008f2e371441f546ce302d529ae8bbd";

export default node;

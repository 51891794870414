import { StyleSheet, View } from 'react-native';

import UserChip from '../../../components/UserChip';
import type { UserChip_user } from 'jakiro-types/UserChip_user.graphql';

type Props = {
  owners: UserChip_user[];
};

const OwnersList = ({ owners }: Props) => {
  return (
    <View style={styles.wrapperStyle}>
      {owners.map((owner, i) => (
        <View key={i} style={styles.itemWrapper}>
          <UserChip user={owner} />
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapperStyle: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
  },
  itemWrapper: {
    marginRight: 10,
  },
});

export default OwnersList;

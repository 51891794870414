/**
 * @generated SignedSource<<c186948c1d9d6fcb1130186c53a915a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserStatusEnum = "ACTIVE" | "CREATED" | "DEACTIVATED" | "INVITED";
export type DirectoryAllEmployeesQuery$variables = {
  query: string;
  hasQuery: boolean;
  first: number;
  status: UserStatusEnum;
};
export type DirectoryAllEmployeesQueryVariables = DirectoryAllEmployeesQuery$variables;
export type DirectoryAllEmployeesQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"DirectoryAllEmployees_viewer">;
  } | null;
};
export type DirectoryAllEmployeesQueryResponse = DirectoryAllEmployeesQuery$data;
export type DirectoryAllEmployeesQuery = {
  variables: DirectoryAllEmployeesQueryVariables;
  response: DirectoryAllEmployeesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasQuery"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v4 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v5 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v6 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "entityId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "shape",
        "value": "SQUARE"
      }
    ],
    "kind": "ScalarField",
    "name": "avatarUrl",
    "storageKey": "avatarUrl(shape:\"SQUARE\")"
  },
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DirectoryAllEmployeesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v4/*: any*/),
              {
                "kind": "Variable",
                "name": "hasQuery",
                "variableName": "hasQuery"
              },
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "DirectoryAllEmployees_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "DirectoryAllEmployeesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "condition": "hasQuery",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "after",
                        "value": ""
                      },
                      (v4/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": "LAST_NAME"
                      },
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "concreteType": "companyUsersConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "companyUsersEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "condition": "hasQuery",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v5/*: any*/)
                ],
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "searchUsers",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ]
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ba0722513ba01f80366d3b08c361b9e",
    "id": null,
    "metadata": {},
    "name": "DirectoryAllEmployeesQuery",
    "operationKind": "query",
    "text": "query DirectoryAllEmployeesQuery(\n  $query: String!\n  $hasQuery: Boolean!\n  $first: Int!\n  $status: UserStatusEnum!\n) {\n  viewer {\n    ...DirectoryAllEmployees_viewer_15uHF5\n    id\n  }\n}\n\nfragment DirectoryAllEmployees_viewer_15uHF5 on Viewer {\n  company {\n    users(orderBy: LAST_NAME, query: $query, first: $first, after: \"\", status: $status) @skip(if: $hasQuery) {\n      pageInfo {\n        hasNextPage\n      }\n      edges {\n        cursor\n        node {\n          entityId\n          ...PersonCell_user\n          id\n        }\n      }\n    }\n    id\n  }\n  searchUsers(query: $query) @include(if: $hasQuery) {\n    entityId\n    ...PersonCell_user\n    id\n  }\n}\n\nfragment PersonCell_user on User {\n  name\n  email\n  entityId\n  avatarUrl(shape: SQUARE)\n}\n"
  }
};
})();

(node as any).hash = "b2f3dcb78639a1af8ef72b061803c9a1";

export default node;

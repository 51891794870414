import { graphql, commitMutation } from 'react-relay';

import { AuthedEnv } from '../utils/createQueryRenderer';

import type { editCheckinMutationVariables } from 'jakiro-types/editCheckinMutation.graphql';

const mutation = graphql`
  mutation editCheckinMutation($input: EditCheckinInput!) {
    editCheckin(input: $input) {
      checkin {
        id
      }
      errors
    }
  }
`;

type Args = {
  data: {
    public?: boolean | null;
    sentimentScore?: number | null;
    sentimentAnswer?: string | null;
    answers?: {
      questionId: string;
      body?: string | null;
      id?: string | null;
    }[];
  };
  checkinId: string;
};

export function commit(args: Args, onCompleted: any, onError: any) {
  const variables: editCheckinMutationVariables = {
    input: {
      ...args,
    },
  };

  return commitMutation(AuthedEnv, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

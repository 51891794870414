import { StyleSheet, View, Text } from 'react-native';

import Avatar from './Avatar';
import { fontTypes, Colors } from '../utils/constants';
import { getInitials } from '../utils/fns';

type Props = {
  title: string;
  subtitle?: string | null;
  avatarUrl: string | null;
  onPressAvatar?: () => void;
};

const UserProfile = ({ title, subtitle, avatarUrl, onPressAvatar }: Props) => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <Avatar
          large
          rounded
          onPress={onPressAvatar}
          avatarUrl={avatarUrl}
          title={getInitials(title)}
        />
        <View style={styles.titleAndSubtitle}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.subtitle}>{subtitle || 'Direct Report'}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'center',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleAndSubtitle: {
    marginLeft: 10,
  },
  title: {
    ...fontTypes.title,
    color: Colors.darkestgray,
  },
  subtitle: {
    ...fontTypes.bodyTitleRegular,
    color: Colors.darkergray,
  },
});

export default UserProfile;

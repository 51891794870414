import * as React from 'react';

// If you want to stop the poll, set the time out to -1
export default function usePoll(poller: () => void, timeout: number, deps: any) {
  let timerId;
  React.useEffect(() => {
    if (timeout < 0) {
      clearTimeout(timerId);
      return;
    }

    async function poll() {
      await Promise.resolve(poller());
      timerId = setTimeout(poll, timeout);
    }

    timerId = setTimeout(poll, timeout);
    return () => clearTimeout(timerId);
  }, [poller, timeout, ...deps]);
}

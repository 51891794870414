import { graphql } from 'react-relay';
import { ScrollView, StyleSheet, View } from 'react-native';
import Markdown from 'react-native-markdown-display';

import { formatDetails } from '../../components/surveys/SurveyDetailsPanel';
import withHeader from '../../hocs/withHeader';
import createQueryRenderer from '../../utils/createQueryRenderer';

import type { SurveyDetailsHeaderInfoQueryResponse } from 'jakiro-types/SurveyDetailsHeaderInfoQuery.graphql';

type Props = SurveyDetailsHeaderInfoQueryResponse;

function SurveyDetailsHeaderInfo(props: Props) {
  const { viewer } = props;
  const { survey } = viewer;

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.details}>
          <Markdown>{formatDetails(survey.details)}</Markdown>
        </View>
      </ScrollView>
    </View>
  );
}

export default createQueryRenderer(
  withHeader({ title: 'Survey details', mode: 'BACK' })(SurveyDetailsHeaderInfo),
  SurveyDetailsHeaderInfo,
  {
    query: graphql`
      query SurveyDetailsHeaderInfoQuery($surveyEntityId: String!) {
        viewer {
          survey: findSurvey(surveyId: $surveyEntityId) {
            id
            details
          }
        }
      }
    `,
    queriesParams: ({ route }) => ({
      surveyEntityId: route.params.surveyEntityId,
    }),
  },
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    flex: 1,
  },
  details: {
    paddingHorizontal: 24,
    paddingVertical: 24,
    alignSelf: 'center',
  },
});

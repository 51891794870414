import * as React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import { View, Text } from 'react-native';
import styled from '@emotion/native';

import { fontTypes } from '../../utils/constants';
import { getInitials } from '../../utils/fns';
import Avatar from '../Avatar';

import type { UserIntro_user } from 'jakiro-types/UserIntro_user.graphql';

type Props = {
  user: UserIntro_user;
};

function UserIntro({ user }: Props) {
  return (
    <Intro>
      <Avatar large avatarUrl={user.avatarUrl} title={getInitials(user.name)} />

      <View style={{ marginLeft: 16 }}>
        <Text style={fontTypes.bodyTitle}>{user.name}</Text>
        <Text style={fontTypes.bodySecondary}>{user.title}</Text>
      </View>
    </Intro>
  );
}

const Intro = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
`;

export default createFragmentContainer(UserIntro, {
  user: graphql`
    fragment UserIntro_user on User {
      avatarUrl
      title
      name
    }
  `,
});

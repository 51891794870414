import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import { Colors } from '../utils/constants';

const Separator = () => <View style={styles.separator} />;

const styles = StyleSheet.create({
  separator: {
    flex: 1,
    height: 0.5,
    backgroundColor: Colors.border,
    marginLeft: 15,
  },
});

export default Separator;

import * as React from 'react';
import { RefreshControl, Platform, View, SafeAreaView } from 'react-native';
import moment from 'moment';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { css } from '@emotion/native';
import { createFragmentContainer, graphql } from 'react-relay';
import { differenceInHours, parseISO } from 'date-fns';

import {
  ScreenWidth,
  isIOS,
  Colors,
  ScreenHeight,
  APPLICATION_MAX_WIDTH_STRING,
} from '../../../utils/constants';
import { connectDropdownAlert } from '../../../hocs/withDropdownAlert';
import { formatDate } from '../../../utils/fns';
import TalkingPoints from './TalkingPoints';
import type { Status } from './TalkingPoints';
import FooterStatus from './FooterStatus';
import Container from '../../../components/Container';
import type { OneOnOneContent_oneOnOne } from 'jakiro-types/OneOnOneContent_oneOnOne.graphql';
import type { OneOnOneContent_checkinRelationship } from 'jakiro-types/OneOnOneContent_checkinRelationship.graphql';
import OneOnOneSync from './OneOnOneSync';

type Props = {
  userId: string;
  oneOnOne?: OneOnOneContent_oneOnOne;
  checkinRelationship?: OneOnOneContent_checkinRelationship;
  onRefetch: () => void;
  isLoading: boolean;
  isRefetching: boolean;
  handleNext: () => void | undefined;
  handleBack: () => void | undefined;
  dropdownAlert?: (args: any) => void;
  viewerIsManager: boolean;
  setOneOnOne: (k: string | undefined) => void;
  otherUser:
    | {
        preferredName: string | undefined;
      }
    | undefined;
};

const getExtraHeight = () => {
  if (ScreenHeight < 600) {
    return 80;
  }
  if (ScreenHeight < 700) {
    return 170;
  }
  if (ScreenHeight < 800) {
    return 180;
  }
  return 200;
};

const LONG_SYNC_DELAY = 1000 * 30; // thirty seconds
const SHORT_SYNC_DELAY = 1000 * 5; // five seconds
const TWO_HOURS = 2;

const OneOnOneContent = ({
  userId,
  handleNext,
  handleBack,
  oneOnOne,
  checkinRelationship,
  isLoading,
  onRefetch,
  isRefetching,
  viewerIsManager,
  otherUser,
  setOneOnOne,
}: Props) => {
  const enableAutoScroll = (oneOnOne?.talkingPoints || []).length >= 3;
  const scheduled = oneOnOne?.scheduledAt || Date.now();
  const cancelledAt = oneOnOne?.cancelledAt;
  const scheduledAsDate = typeof scheduled === 'string' ? parseISO(scheduled) : scheduled;
  const onRefetchMeeting = React.useCallback(onRefetch, []);
  const isOneOnOnesDisabled = checkinRelationship?.viewerIsManager
    ? !checkinRelationship?.manager?.products?.oneOnOnes?.isEnabled
    : !checkinRelationship?.report?.products?.oneOnOnes?.isEnabled;

  let keyboardAwareScrollView;
  const [showFooter, setShowFooter] = React.useState(true);

  const getCurrentStatus = (): Status => {
    if (!oneOnOne) return 'NEW';
    const isDone = oneOnOne.reviewedAt ? 'DONE' : 'ONGOING';
    return isLoading ? 'IS_LOADING' : isDone;
  };

  const toggleFooter = () => {
    if (
      keyboardAwareScrollView &&
      keyboardAwareScrollView.props &&
      keyboardAwareScrollView.props.position.y < 0
    ) {
      keyboardAwareScrollView.props.scrollToPosition(0, 0);
    }

    setShowFooter(showFooter => !showFooter);
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: 'white',
      }}
    >
      <OneOnOneSync
        meeting={oneOnOne}
        refetch={onRefetchMeeting} // this is intentially pass by reference
        timeout={
          differenceInHours(new Date(), scheduledAsDate) < TWO_HOURS
            ? SHORT_SYNC_DELAY
            : LONG_SYNC_DELAY
        }
        deps={[oneOnOne]}
      />
      <KeyboardAwareScrollView
        innerRef={ref => {
          keyboardAwareScrollView = ref;
        }}
        pointerEvents={isLoading ? 'none' : 'auto'}
        onKeyboardDidShow={toggleFooter}
        onKeyboardDidHide={toggleFooter}
        enableAutomaticScroll={enableAutoScroll}
        extraHeight={getExtraHeight()}
        enableOnAndroid
        style={{
          width: ScreenWidth,
          backgroundColor: 'transparent',
          opacity: isLoading ? 0.4 : 1,
        }}
        refreshControl={
          Platform.OS === 'web' ? null : (
            <RefreshControl refreshing={isRefetching} onRefresh={onRefetch} />
          )
        }
      >
        <Container
          flex={1}
          style={css`
            max-width: ${APPLICATION_MAX_WIDTH_STRING};
            width: 100%;
            margin: 0 auto;
          `}
        >
          <TalkingPoints
            userId={userId}
            isCancelled={cancelledAt}
            scrollViewRef={enableAutoScroll ? keyboardAwareScrollView : { update: () => {} }}
            status={getCurrentStatus()}
            talkingPoints={oneOnOne?.talkingPoints || []}
            checkinRelationshipId={checkinRelationship?.id ?? ''}
            oneOnOneId={oneOnOne?.id || null}
            meetingId={oneOnOne?.entityId || null}
            nextOneOnOneId={oneOnOne?.nextOneOnOneMeeting?.entityId || null}
            viewerNotes={{
              note: viewerIsManager ? oneOnOne?.note : oneOnOne?.reportNote,
            }}
            otherParticipantNotes={{
              otherUser: otherUser || { preferredName: '' },
              note: viewerIsManager ? oneOnOne?.reportNote : oneOnOne?.note,
            }}
            privateNote={oneOnOne?.viewerPrivateNote}
            setOneOnOne={setOneOnOne}
            isOneOnOnesDisabled={isOneOnOnesDisabled}
          />
        </Container>
      </KeyboardAwareScrollView>

      {(isIOS || showFooter) && (
        <SafeAreaView style={{ backgroundColor: Colors.lightestgray }}>
          <FooterStatus
            onBack={handleBack}
            onNext={handleNext}
            status={getCurrentStatus()}
            startDate={formatDate(moment(scheduled))}
          />
        </SafeAreaView>
      )}
    </View>
  );
};

export default createFragmentContainer(connectDropdownAlert(OneOnOneContent), {
  checkinRelationship: graphql`
    fragment OneOnOneContent_checkinRelationship on CheckinRelationship {
      id
      viewerIsManager
      report {
        products {
          oneOnOnes {
            isEnabled
          }
        }
      }
      manager {
        products {
          oneOnOnes {
            isEnabled
          }
        }
      }
    }
  `,
  oneOnOne: graphql`
    fragment OneOnOneContent_oneOnOne on OneOnOneMeeting {
      previousOneOnOneMeeting {
        ... on OneOnOneMeeting {
          entityId
        }
      }
      nextOneOnOneMeeting {
        ... on OneOnOneMeeting {
          entityId
        }
      }
      id
      entityId
      scheduledAt
      cancelledAt
      reviewedAt
      note
      reportNote
      viewerPrivateNote
      talkingPoints {
        id
        body
        entityId
        creator {
          avatarUrl
          name
          viewerIsUser
        }
        comments {
          id
          createdAt
          body
          user {
            viewerIsUser
            avatarUrl
            name
          }
        }
        completedAt
      }
    }
  `,
});

import { useEffect } from 'react';
import { graphql } from 'react-relay';
import styled, { css } from '@emotion/native';
import { SafeAreaView, Platform, ScrollView, TouchableOpacity, View, Text } from 'react-native';
import { capitalize } from 'lodash';
import AsyncStorage from '@react-native-async-storage/async-storage';
import type { StackNavigationProp } from '@react-navigation/stack';
import moment from 'moment';
import { LinearGradient } from 'expo-linear-gradient';
import Svg, { G, Path } from 'react-native-svg';
import * as Notifications from 'expo-notifications';

import type { RootStackParamList } from '../../components/Navigation/types';
import NextMeetingCard, { ChevronRight } from '../../components/NextMeetingCard';
import { ActiveGoalsTab } from '../../components/ActiveGoals';
import FeedbackCard from '../../components/FeedbackCard';
import Avatar from '../../components/Avatar';
import Loading from '../../components/Loading';
import OptionButton from '../../components/OptionButton';
import Container from '../../components/Container';
import createQueryRenderer from '../../utils/createQueryRenderer';
import { captureException } from '../../utils/fns';
import {
  Colors,
  fontTypes,
  HAS_ASKED_FOR_NOTIFICATION_PERMISSION,
  isIOS,
  isAndroid,
  APPLICATION_MAX_WIDTH_STRING,
  isWeb,
} from '../../utils/constants';
import { cleanConnection, formatDate, getInitials, getWeekId } from '../../utils/fns';
import { saveToken } from '../enableNotification';

import type { youQueryResponse } from 'jakiro-types/youQuery.graphql';
import { useNavigation } from '@react-navigation/native';

type Props = youQueryResponse;

const gradients = {
  morning: ['#9AF4FA', '#0F63B8'],
  afternoon: ['#EAB858', '#D65414'],
  evening: ['#648AEC', '#5920B1'],
};

const allowListedTasks = ['RespondToSurveyTask', 'RespondToPulseTask', 'PerformReviewsTask'];

type DeviceTokenProps =
  | readonly {
      readonly deviceUniqueId: string;
      readonly deviceToken: string;
    }[]
  | undefined;

export async function autoSaveDeviceToken(deviceTokens: DeviceTokenProps) {
  try {
    let { data: token } = await Notifications.getExpoPushTokenAsync();

    if (
      deviceTokens &&
      deviceTokens.length > 0 &&
      deviceTokens.some(j => j.deviceToken === token)
    ) {
      return;
    }

    await saveToken({ token });
  } catch (e) {
    captureException(e);
  }
}

const YouTab = ({ viewer }: Props) => {
  const user = viewer?.user;

  const navigation = useNavigation<StackNavigationProp<RootStackParamList, 'Home'>>();

  if (!user) {
    return <Loading />;
  }

  const tasks = user.tasksList.filter(k => k && allowListedTasks.includes(k.__typename));

  const hasTasks = tasks.length > 0;

  const timeOfDay = getPartOfDay() || 'morning';
  const gradient = gradients[timeOfDay];
  const hasManagerCheckinRelationship = viewer?.managerCheckinRelationship;
  // TODO: Use managerCheckinRelationship.hasAnyOneOnOneMeetings instead!
  const hasManyOneOnOnes =
    (viewer?.managerCheckinRelationship?.oneOnOneMeetings?.edges?.length || 0) > 0;

  const reports = viewer?.user.reportsList || [];
  const siblingsConnection = viewer?.user.siblings;
  const feedbackConnection = viewer?.receivedFeedback;
  const activeGoalsConnection = viewer?.user.activeGoals;
  const feedback = cleanConnection(feedbackConnection);
  const siblings = cleanConnection(siblingsConnection);
  const userHasGoals = (activeGoalsConnection?.edges || []).length > 0;

  const updatesProductEnabled = viewer?.user?.products?.updates?.isEnabled;
  const isCheckinsActive =
    viewer?.managerCheckinRelationship?.isCheckinsActive && updatesProductEnabled;
  const isWeeklyCheckinPublished = viewer?.managerCheckinRelationship?.checkinByWeekId?.published;
  const hasAnyCheckins = viewer?.managerCheckinRelationship?.hasAnyCheckins;

  const manager = viewer?.managerCheckinRelationship?.manager;

  const activeOneOnOneRelationships = viewer?.user.userActiveOneOnOneRelationshipUsers || [];

  let activeOneOnOnes = [...activeOneOnOneRelationships];

  // Sort 1:1s by scheduledAt
  // take first 4 custom 1:1s

  const getNextMeetingInfo = o => {
    const hasNextOneOnOneScheduled = o.viewerUserRelationship?.nextRecurringOneOnOneMeeting;
    const oneOnOneFromConnection = o.viewerUserRelationship?.oneOnOneMeetings?.edges[0]?.node;

    let scheduledAt = null;
    let meetingId = null;

    if (hasNextOneOnOneScheduled !== null) {
      scheduledAt = o.viewerUserRelationship?.nextRecurringOneOnOneMeeting?.scheduledAt;
      meetingId = o.viewerUserRelationship?.nextRecurringOneOnOneMeeting?.entityId;
    } else if (oneOnOneFromConnection) {
      scheduledAt = oneOnOneFromConnection?.scheduledAt;
      meetingId = oneOnOneFromConnection?.entityId;
    }

    return [scheduledAt || Date.now(), meetingId];
  };

  const currentDate = new Date();
  let pastOneOnOnes = [];
  let upcomingOneOnOnes = [];
  activeOneOnOnes.forEach(o => {
    const [scheduledAt] = getNextMeetingInfo(o);
    if (new Date(scheduledAt) >= currentDate) {
      upcomingOneOnOnes.push(o);
    } else {
      pastOneOnOnes.push(o);
    }
  });

  upcomingOneOnOnes = upcomingOneOnOnes
    .sort((a, b) => {
      const [aScheduledAt] = getNextMeetingInfo(a);
      const [bScheduledAt] = getNextMeetingInfo(b);
      return new Date(aScheduledAt) > new Date(bScheduledAt) ? 1 : -1;
    })
    .slice(0, 4);

  pastOneOnOnes = pastOneOnOnes
    .sort((a, b) => {
      const [aScheduledAt] = getNextMeetingInfo(a);
      const [bScheduledAt] = getNextMeetingInfo(b);

      // sort by most recent first
      return new Date(aScheduledAt) > new Date(bScheduledAt) ? -1 : 1;
    })
    .slice(0, 2);

  const hasUpcomingOneOnOnes = activeOneOnOnes.length > 0;
  const hasPastOneOnOnes = pastOneOnOnes.length > 0;

  const planOneOnOne =
    ({ id, user }) =>
    () => {
      navigation.navigate('One On One', {
        meetingId: id,
        userId: user.entityId,
      });
    };

  const weekId = getWeekId();

  const createUpdate = () => {
    navigation.navigate('Update', { weekId });
  };

  const pastUpdates = () => {
    navigation.navigate('Updates');
  };

  const takePulseSurvey = pulseGroupEntityId => () => {
    navigation.navigate('Pulse Survey', { pulseGroupEntityId });
  };

  const takeEngagementSurvey = surveyEntityId => () => {
    navigation.navigate('Engagement Survey', { surveyEntityId });
  };

  const pastOneOnOnesNav = () => {
    navigation.navigate('Past One On Ones');
  };

  const selectReviewFromCycle = reviewCycleEntityId => () => {
    navigation.navigate('Review Cycle', { reviewCycleEntityId });
  };

  const people = [manager, ...reports, ...siblings];

  const feedbackEnabled = viewer?.user?.products?.feedback?.isEnabled;

  useEffect(() => {
    shouldWePromptForNotifications();
  }, [viewer]);

  async function shouldWePromptForNotifications() {
    if (Platform.OS === 'web') return;

    const { status } = await Notifications.getPermissionsAsync();
    const hasAskedPermission = await AsyncStorage.getItem(HAS_ASKED_FOR_NOTIFICATION_PERMISSION);

    if (!hasAskedPermission && status !== 'granted' && isIOS) {
      await AsyncStorage.setItem(HAS_ASKED_FOR_NOTIFICATION_PERMISSION, 'true');

      navigation.navigate('Enable Notifications');
    } else if (isAndroid) {
      await autoSaveDeviceToken(viewer?.user.nativeDeviceTokens);
    }
  }

  const showUpcomingSection = isCheckinsActive || hasUpcomingOneOnOnes || hasAnyCheckins;
  const isYouTabEmpty =
    !hasTasks &&
    !hasUpcomingOneOnOnes &&
    !hasPastOneOnOnes &&
    !isCheckinsActive &&
    userHasGoals === false &&
    feedback.length === 0;

  return (
    <View
      style={css`
        flex: 1;
        display: flex;
        flex-shrink: 0;
      `}
    >
      {feedbackEnabled && (
        <FeedbackButton onPress={() => navigation.navigate('Give Feedback')} color="black">
          <FeedbackIcon />

          <Text
            style={css`
              ${fontTypes.body_semi_bold};
              color: white;
              font-weight: 600;
              margin-left: 8px;
              font-size: 16px;
            `}
          >
            Give feedback
          </Text>
        </FeedbackButton>
      )}

      <ScrollView>
        <Gradient
          colors={gradient}
          start={{ x: 0.05, y: 0.05 }}
          end={{ x: 0.95, y: 0.95 }}
          style={css`
            position: relative;
          `}
        >
          <SafeAreaView
            style={css`
              position: absolute;
              top: 16px;
              right: 16px;
            `}
          >
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('Settings');
              }}
            >
              <OptionButton color="white" />
            </TouchableOpacity>
          </SafeAreaView>
          <View
            style={css`
              max-width: ${APPLICATION_MAX_WIDTH_STRING};
              margin: 0 auto;
              padding-bottom: 24px;
              width: 100%;
              padding-left: 16px;
              padding-right: 16px;
            `}
          >
            <Greeting>
              {capitalize(timeOfDay)}
              {', '}
              {'\n'}
              {user.preferredName} 👋
            </Greeting>
          </View>
        </Gradient>

        {people.length > 0 && (
          <View
            style={css`
              background-color: white;
              width: 100%;
            `}
          >
            <StoryRow horizontal>
              {people.filter(Boolean).map(k => (
                <TouchableOpacity
                  key={k.email}
                  onPress={() => {
                    navigation.navigate('Profile', {
                      userEntityId: k.entityId,
                    });
                  }}
                >
                  <StoryItem>
                    <Avatar
                      avatarUrl={k.avatarUrl}
                      title={getInitials(k.name)}
                      medium
                      containerStyle={css`
                        height: 48px;
                        width: 48px;
                        margin-bottom: 8px;
                      `}
                    />

                    <Text style={{ maxWidth: 60 }} numberOfLines={1}>
                      {k.preferredName}
                    </Text>
                  </StoryItem>
                </TouchableOpacity>
              ))}
            </StoryRow>
          </View>
        )}

        {isYouTabEmpty && (
          <Card>
            <Container
              direction="column"
              align="center"
              style={css`
                max-width: 300px;
                margin: 0 auto;
                margin-top: 24px;
                margin-bottom: 24px;
              `}
            >
              <Text
                style={css`
                  font-size: 48px;
                `}
              >
                🌱
              </Text>
              <Text style={[fontTypes.title, { fontSize: 24, marginTop: 16, marginBottom: 16 }]}>
                Help others grow
              </Text>
              <Text
                style={[
                  fontTypes.bodySecondary,
                  { fontSize: 17, lineHeight: 25, textAlign: 'center' },
                ]}
              >
                Share feedback with your colleagues to let them know how they've been doing.
              </Text>
            </Container>
          </Card>
        )}

        {hasTasks && (
          <Section
            style={css`
              margin-top: 16px;
            `}
          >
            <Headline style={{ marginBottom: 16 }}>
              {tasks.length} task{tasks.length > 1 ? 's' : ''}
            </Headline>

            {tasks.map(task => {
              if (!task || !task.__typename) return null;

              switch (task.__typename) {
                case 'RespondToPulseTask':
                  const pulseGroupEntityId = task.pulseGroup?.entityId || '';

                  if (!pulseGroupEntityId) return null;

                  return (
                    <TouchableOpacity
                      onPress={takePulseSurvey(pulseGroupEntityId)}
                      key={pulseGroupEntityId}
                    >
                      <Card>
                        <Container direction="row" justify="space-between">
                          <Container direction="column" align="flex-start">
                            <Text style={fontTypes.bodyTitle}>Share your perspective</Text>
                            <Text style={fontTypes.bodySecondary}>
                              Take a minute to share your thoughts on these topics
                            </Text>
                          </Container>

                          <ChevronRight />
                        </Container>
                      </Card>
                    </TouchableOpacity>
                  );

                case 'RespondToSurveyTask':
                  const taskGroupEngagementEntityId = task.survey.entityId || '';
                  const taskGroupEngagementName = task.survey.name || '';

                  if (!taskGroupEngagementEntityId || !taskGroupEngagementName) return null;

                  return (
                    <TouchableOpacity onPress={takeEngagementSurvey(taskGroupEngagementEntityId)}>
                      <Card>
                        <Container direction="row" justify="space-between">
                          <Container direction="column" align="flex-start">
                            <Text style={fontTypes.bodyTitle}>Respond to a survey</Text>
                            <Text style={fontTypes.bodySecondary}>{taskGroupEngagementName}</Text>
                          </Container>

                          <ChevronRight />
                        </Container>
                      </Card>
                    </TouchableOpacity>
                  );

                case 'PerformReviewsTask':
                  const taskGroupReviewCycleEntityId = task.reviewCycle.entityId || '';
                  const taskGroupReviewCycleName = task.reviewCycle.name || '';

                  if (!taskGroupReviewCycleEntityId || !taskGroupReviewCycleName) return null;

                  return (
                    <TouchableOpacity onPress={selectReviewFromCycle(taskGroupReviewCycleEntityId)}>
                      <Card>
                        <Container direction="row" justify="space-between">
                          <Container direction="column" align="flex-start">
                            <Text style={fontTypes.bodyTitle}>Perform review</Text>
                            <Text style={fontTypes.bodySecondary}>{taskGroupReviewCycleName}</Text>
                          </Container>

                          <ChevronRight />
                        </Container>
                      </Card>
                    </TouchableOpacity>
                  );
                default:
                  return null;
              }
            })}
          </Section>
        )}

        {showUpcomingSection && (
          <Section
            style={css`
              margin-top: 16px;
            `}
          >
            <Headline>Upcoming</Headline>

            {isCheckinsActive ? (
              <TouchableOpacity
                onPress={createUpdate}
                style={css`
                  margin-top: 16px;
                `}
              >
                <Card>
                  <Container direction="row" justify="space-between">
                    <Text style={fontTypes.bodyTitle}>
                      {isWeeklyCheckinPublished ? 'View' : 'Write'} your weekly update
                    </Text>

                    <ChevronRight />
                  </Container>
                </Card>
              </TouchableOpacity>
            ) : hasAnyCheckins ? (
              <TouchableOpacity
                onPress={pastUpdates}
                style={css`
                  margin-top: 16px;
                `}
              >
                <Card>
                  <Container direction="row" justify="space-between">
                    <Text style={fontTypes.bodyTitle}>See past weekly updates</Text>

                    <ChevronRight />
                  </Container>
                </Card>
              </TouchableOpacity>
            ) : null}

            {hasUpcomingOneOnOnes ? (
              upcomingOneOnOnes.map((o, i) => {
                if (!o) return null;

                const [scheduledAt, meetingId] = getNextMeetingInfo(o);
                const date = formatDate(moment(scheduledAt));

                return (
                  <View
                    key={`${o.id}-${i}`}
                    style={css`
                      margin-top: ${isCheckinsActive || i !== 0 ? '8px' : '16px'};
                      margin-left: 16px;
                      margin-right: 16px;
                      margin-bottom: ${i === upcomingOneOnOnes.length - 1 ? '24px' : '8px'};
                    `}
                  >
                    <NextMeetingCard
                      onPress={planOneOnOne({
                        id: meetingId,
                        user: o,
                      })}
                      user={o}
                      date={date}
                    />
                  </View>
                );
              })
            ) : manager && hasManagerCheckinRelationship && hasManyOneOnOnes ? (
              <View
                style={css`
                  margin: 16px;
                  margin-top: ${isCheckinsActive || hasAnyCheckins ? '0px' : '16px'};
                  margin-bottom: ${hasUpcomingOneOnOnes ? '8px' : '16px'};
                `}
              >
                <NextMeetingCard onPress={pastOneOnOnesNav} user={manager} date={null} />
              </View>
            ) : null}
          </Section>
        )}

        {hasPastOneOnOnes && (
          <Section
            style={css`
              margin-top: ${showUpcomingSection ? '0px' : '16px'};
            `}
          >
            <Headline>Past 1:1s</Headline>
            {pastOneOnOnes.map((o, i) => {
              if (!o) return null;

              const [scheduledAt, meetingId] = getNextMeetingInfo(o);
              const date = formatDate(moment(scheduledAt));

              return (
                <View
                  key={`${o.id}-${i}`}
                  style={css`
                    margin-top: ${i !== 0 ? '8px' : '16px'};
                    margin-left: 16px;
                    margin-right: 16px;
                    margin-bottom: ${i === pastOneOnOnes.length - 1 ? '24px' : '8px'};
                  `}
                >
                  <NextMeetingCard
                    onPress={planOneOnOne({
                      id: meetingId,
                      user: o,
                    })}
                    user={o}
                    date={date}
                  />
                </View>
              );
            })}
          </Section>
        )}

        {userHasGoals && (
          <Section>
            <Headline>Your active goals</Headline>

            <ActiveGoalsTab
              viewer={viewer}
              cardStyle={{ marginBottom: 16, marginTop: 0, marginRight: 0, marginLeft: 16 }}
            />
          </Section>
        )}

        {feedbackEnabled && feedback.length > 0 && (
          <Section>
            <Headline>Feedback you’ve received</Headline>
            <View
              style={css`
                margin: 16px;
              `}
            >
              {feedback.map((k, i) => (
                <FeedbackCard viewer={viewer} feedback={k} key={i} />
              ))}
            </View>
          </Section>
        )}
      </ScrollView>
    </View>
  );
};

const FeedbackIcon = () => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="white">
    <G fillRule="evenodd" transform="translate(2 2)">
      <Path
        fillRule="nonzero"
        d="M2,17.2251482 L5.67544468,16 L16,16 C17.1045695,16 18,15.1045695 18,14 L18,4 C18,2.8954305 17.1045695,2 16,2 L4,2 C2.8954305,2 2,2.8954305 2,4 L2,17.2251482 Z M6,18 L0,20 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 L16,0 C18.209139,-4.05812251e-16 20,1.790861 20,4 L20,14 C20,16.209139 18.209139,18 16,18 L6,18 Z"
      />
      <Path d="M6,10 C5.44771525,10 5,9.55228475 5,9 C5,8.44771525 5.44771525,8 6,8 C6.55228475,8 7,8.44771525 7,9 C7,9.55228475 6.55228475,10 6,10 Z M10,10 C9.44771525,10 9,9.55228475 9,9 C9,8.44771525 9.44771525,8 10,8 C10.5522847,8 11,8.44771525 11,9 C11,9.55228475 10.5522847,10 10,10 Z M14,10 C13.4477153,10 13,9.55228475 13,9 C13,8.44771525 13.4477153,8 14,8 C14.5522847,8 15,8.44771525 15,9 C15,9.55228475 14.5522847,10 14,10 Z" />
    </G>
  </Svg>
);

const Card = styled.View`
  padding: 16px;
  box-shadow: 0px 1px 3px rgba(34, 45, 56, 0.16);
  background-color: white;
  border-radius: 4px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
`;

const FeedbackButton = styled(TouchableOpacity)`
  padding: 16px;
  background-color: ${Colors.blue};
  position: absolute;
  margin: 16px;
  right: 0;
  bottom: 0;
  z-index: 100;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
`;

const Greeting = styled(Text)`
  ${fontTypes.title};
  color: white;
  font-size: 28px;
  line-height: 31px;
  font-weight: 600;
`;

const StoryItem = styled.View`
  margin-right: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const StoryRow = styled.ScrollView`
  padding: 16px;
  background-color: white;
  margin-bottom: 24px;
  max-width: ${APPLICATION_MAX_WIDTH_STRING};
  margin: 0 auto;
  width: 100%;
`;

const Gradient = styled(LinearGradient)`
  flex: 1;
  flex-shrink: 0;
  height: 170px;
  min-height: 170px;
  display: flex;
  justify-content: flex-end;
`;

const Section = styled.View`
  margin-bottom: 32px;
  max-width: ${APPLICATION_MAX_WIDTH_STRING};
  width: 100%;
  margin: 0 auto;
`;

const Headline = styled(Text)`
  ${fontTypes.title}
  padding-left: 16px;
`;

export default createQueryRenderer(YouTab, YouTab, {
  query: graphql`
    query youQuery($weekId: String!, $includeDraft: Boolean!) {
      viewer {
        user {
          userActiveOneOnOneRelationshipUsers {
            id
            name
            email
            entityId
            viewerIsUser
            ...NextMeetingCard_user
            viewerUserRelationship {
              isOneOnOneActive
              ... on CheckinRelationship {
                oneOnOneMeetings(first: 1) {
                  edges {
                    node {
                      entityId
                      scheduledAt
                    }
                  }
                }
                nextRecurringOneOnOneMeeting {
                  scheduledAt
                  ... on OneOnOneMeeting {
                    entityId
                  }
                }
              }
            }
          }
          nativeDeviceTokens {
            deviceUniqueId
            deviceToken
          }
          tasksList {
            __typename
            ... on RespondToSurveyTask {
              survey {
                name
                entityId
              }
            }
            ... on PerformReviewsTask {
              reviewCycle {
                entityId
                name
              }
            }
            ... on RespondToPulseTask {
              id
              pulseGroup {
                entityId
              }
            }
          }
        }
        managerCheckinRelationship {
          manager {
            entityId
            preferredName
            title
            email
            name
            avatarUrl
            ...NextMeetingCard_user
          }
          hasAnyCheckins
          isCheckinsActive
          checkinByWeekId(weekId: $weekId, includeDraft: $includeDraft) {
            published
          }
          oneOnOneMeetings(first: 5) {
            edges {
              node {
                entityId
                id
                scheduledAt
              }
            }
          }
          nextRecurringOneOnOneMeeting {
            ... on OneOnOneMeeting {
              scheduledAt
              id
            }
          }
        }
        receivedFeedback(first: 10) {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              ...FeedbackCard_feedback
            }
          }
        }
        user {
          activeGoals: ownedGoals(state: ACTIVE, last: 100, orderBy: Priority) {
            edges {
              node {
                ...GoalCard_goal
              }
            }
          }
          siblings(first: 15) {
            edges {
              node {
                entityId
                preferredName
                title
                email
                name
                avatarUrl
              }
            }
          }
          viewerIsUser
          entityId
          name
          preferredName
          title
          startDate
          phoneNumber
          timezone
          realTimezone
          email
          avatarUrl
          department {
            name
          }
          manager {
            email
            entityId
          }
          reportsList {
            entityId
            preferredName
            avatarUrl
            title
            email
            name
          }
          products {
            updates {
              isEnabled
            }
            feedback {
              isEnabled
            }
          }
        }
      }
    }
  `,
  queriesParams: () => ({
    weekId: getWeekId(),
    includeDraft: true,
  }),
});

function getPartOfDay() {
  const today = new Date();
  const currentHour = today.getHours();

  let greeting = 'evening';

  if (currentHour < 12) {
    greeting = 'morning';
  } else if (currentHour < 18) {
    greeting = 'afternoon';
  }

  return greeting;
}

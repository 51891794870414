import * as React from 'react';
import { Text, Linking, Pressable } from 'react-native';
import Markdown from 'react-native-markdown-display';
import { Colors, isWeb } from '../utils/constants';

/**
 * Shamelessly copied this from here https://stackoverflow.com/questions/11300906/check-if-a-string-starts-with-http-using-javascript
 * This will try to fix up a url by adding it's schema if needed. For example, google.com becomes http://google.com
 */
const makeValidUrl = (url: string = ''): string => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, '');

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`;
  }

  return newUrl;
};

/**
 * Rules are set of overrides for react-native-markdown-dispaly
 * See https://github.com/iamacup/react-native-markdown-display#rules-and-styles
 */
const markdownRules = {
  link: (node, children, parent, styles) => {
    const url = makeValidUrl(node.attributes.href);
    return (
      <Text
        key={node.key}
        style={styles.link}
        onPress={() => {
          if (isWeb) {
            // always check for window in case your in an env where window doesn't exist
            window && window.open(url, '_blank');
          } else {
            // on a mobile device
            Linking.openURL(url);
          }
        }}
      >
        {children}
      </Text>
    );
  },
};

/**
 * Rather than passing in more props to MarkdownView, we export a set of styles that can be applied
 * to any MarkdowView. This allows for extra flexiblity and keeps our markdown implementation simple
 */

const defaultStyles = {
  body: {
    fontSize: 18,
    height: 'auto',
    width: '100%',
    padding: 2,
  },
  paragraph: { marginTop: 0, marginBottom: 0 },
  link: {
    color: Colors.buttonPrimary,
  },
};

export const strikeThroughStyles = {
  body: {
    ...defaultStyles.body,
    textDecorationLine: 'line-through',
  },
  link: {
    ...defaultStyles.link,
    textDecorationLine: 'line-through',
  },
  paragraph: {
    ...defaultStyles.paragraph,
    textDecorationLine: 'line-through',
  },
};

/**
 * Currently MarkdownView does not support modifying rules. You'll need to change markdownRules
 * if you're trying to accomplish something custom. Styles will get passed through as the
 * style property if you're attempting to change styles.
 */
type MarkdownViewProps = {
  children: any;
  onPress?: () => void;
  strikeThrough?: boolean;
};
export default function MarkdownView({
  children,
  onPress,
  strikeThrough = false,
}: MarkdownViewProps) {
  const markdownStyles = strikeThrough ? strikeThroughStyles : defaultStyles;
  return (
    <Pressable onPress={onPress}>
      <Markdown mergeStyle style={markdownStyles} rules={markdownRules}>
        {children}
      </Markdown>
    </Pressable>
  );
}

/**
 * @generated SignedSource<<dee92c8c242ce8e1ca017f9354d01dd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CompleteTalkingPointInput = {
  clientMutationId?: string | null;
  talkingPointId: string;
};
export type completeTalkingPointMutation$variables = {
  input: CompleteTalkingPointInput;
};
export type completeTalkingPointMutationVariables = completeTalkingPointMutation$variables;
export type completeTalkingPointMutation$data = {
  readonly completeTalkingPoint: {
    readonly talkingPoint: {
      readonly id: string;
      readonly body: string;
      readonly entityId: string;
      readonly creator: {
        readonly avatarUrl: string | null;
        readonly name: string | null;
        readonly viewerIsUser: boolean;
      };
      readonly completedAt: DateString | null;
    } | null;
  } | null;
};
export type completeTalkingPointMutationResponse = completeTalkingPointMutation$data;
export type completeTalkingPointMutation = {
  variables: completeTalkingPointMutationVariables;
  response: completeTalkingPointMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsUser",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "completeTalkingPointMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteTalkingPointPayload",
        "kind": "LinkedField",
        "name": "completeTalkingPoint",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OneOnOneTalkingPoint",
            "kind": "LinkedField",
            "name": "talkingPoint",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "completeTalkingPointMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteTalkingPointPayload",
        "kind": "LinkedField",
        "name": "completeTalkingPoint",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OneOnOneTalkingPoint",
            "kind": "LinkedField",
            "name": "talkingPoint",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fa039075ed0808ca916eda65de6b05f4",
    "id": null,
    "metadata": {},
    "name": "completeTalkingPointMutation",
    "operationKind": "mutation",
    "text": "mutation completeTalkingPointMutation(\n  $input: CompleteTalkingPointInput!\n) {\n  completeTalkingPoint(input: $input) {\n    talkingPoint {\n      id\n      body\n      entityId\n      creator {\n        avatarUrl\n        name\n        viewerIsUser\n        id\n      }\n      completedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dda349825f2c882d017a06ba778b2c6a";

export default node;

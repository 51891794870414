import * as React from 'react';
import { Keyboard } from 'react-native';

type State = {
  isKeyboardOpen: boolean;
  keyboardHeight: number;
};

const withKeyboardAware = (WrappedComponent: React.ComponentType<any>) =>
  class extends React.Component<any, State> {
    state = {
      isKeyboardOpen: false,
      keyboardHeight: 0,
    };

    keyboardDidShowListener: any;
    keyboardDidHideListener: any;

    componentDidMount() {
      this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this._keyboardDidShow);
      this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this._keyboardDidHide);
    }

    componentWillUnmount() {
      this.keyboardDidShowListener.remove();
      this.keyboardDidHideListener.remove();
    }

    _keyboardDidShow = (e: any) => {
      this.setState({ isKeyboardOpen: true, keyboardHeight: e.endCoordinates.height });
    };

    _keyboardDidHide = () => {
      this.setState({ isKeyboardOpen: false });
    };

    render() {
      const { keyboardHeight, isKeyboardOpen } = this.state;
      return (
        <WrappedComponent
          keyboardHeight={keyboardHeight}
          isKeyboardOpen={isKeyboardOpen}
          {...this.props}
        />
      );
    }
  };

export default withKeyboardAware;

/**
 * @generated SignedSource<<ad1ee8a17f6e423e0ce0f60566d5b22a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PastUpdatesListRefetchQuery$variables = {
  first: number;
};
export type PastUpdatesListRefetchQueryVariables = PastUpdatesListRefetchQuery$variables;
export type PastUpdatesListRefetchQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"PastUpdatesList_user">;
    };
  } | null;
};
export type PastUpdatesListRefetchQueryResponse = PastUpdatesListRefetchQuery$data;
export type PastUpdatesListRefetchQuery = {
  variables: PastUpdatesListRefetchQueryVariables;
  response: PastUpdatesListRefetchQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PastUpdatesListRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": (v1/*: any*/),
                "kind": "FragmentSpread",
                "name": "PastUpdatesList_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PastUpdatesListRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UserCheckinsConnection",
                "kind": "LinkedField",
                "name": "checkins",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserCheckinsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Checkin",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "weekId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b3d0818e14b6c20d1439ccd726a3c095",
    "id": null,
    "metadata": {},
    "name": "PastUpdatesListRefetchQuery",
    "operationKind": "query",
    "text": "query PastUpdatesListRefetchQuery(\n  $first: Int!\n) {\n  viewer {\n    user {\n      ...PastUpdatesList_user_3ASum4\n      id\n    }\n    id\n  }\n}\n\nfragment PastUpdatesList_user_3ASum4 on User {\n  checkins(first: $first) {\n    pageInfo {\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        weekId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9f492bf34d47bacbc06f987f20540e7d";

export default node;

import * as Svg from 'react-native-svg';

type Props = {
  color: string | null;
};

export default ({ color }: Props) => (
  <Svg.Svg width="24" height="24" viewBox="0 0 24 24" fill={color}>
    <Svg.Path d="M10 10a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v10h-4V10zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v6H5v-6z" />
    <Svg.Path opacity=".4" d="M15 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v14h-4V6z" />
  </Svg.Svg>
);

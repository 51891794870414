import { useState, useEffect } from 'react';
import { View } from 'react-native';
import { RouteProp } from '@react-navigation/native';

import type { RootStackParamList } from '../../components/Navigation/types';
import MultilineTextInput from '../../components/MultilineTextInput';
import withHeader from '../../hocs/withHeader';

type Props = {
  route: RouteProp<RootStackParamList, 'Engagement Survey Comment'>;
};

function AddComment(props: Props) {
  const { comment, setComment } = props.route.params;

  const [value, setValue] = useState(comment);

  let inputRef;

  useEffect(() => {
    const timer = setTimeout(() => {
      inputRef.focus();
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <View style={{ flex: 1, padding: 20, backgroundColor: 'white' }}>
      <MultilineTextInput
        getInputRef={i => {
          inputRef = i;
        }}
        onChange={newValue => {
          setValue(newValue);
          setComment(newValue);
        }}
        value={value}
        inputStyle={{ color: 'black' }}
        placeholder="Write a comment"
        blurOnSubmit={false}
        returnKeyType={null}
        editable
      />
    </View>
  );
}

export default withHeader({ title: 'Comment', mode: 'BACK' })(AddComment);

import * as React from 'react';
import { View, Pressable, Keyboard } from 'react-native';

import Header from '../components/Header';
import type { Props as HeaderProps } from '../components/Header';

const withHeader = (headerProps: HeaderProps) => (WrappedComponent: any) =>
  class extends React.Component<any> {
    render() {
      return (
        <View style={{ flex: 1 }}>
          <Pressable
            onPress={() => {
              Keyboard.dismiss();
            }}
          >
            <Header {...headerProps} />
          </Pressable>
          <WrappedComponent {...this.props} />
        </View>
      );
    }
  };

export default withHeader;

import { StyleSheet, View } from 'react-native';
import { createFragmentContainer, graphql } from 'react-relay';

import Activity from './Activity';

import EmptyState from '../../../components/EmptyState';
import { Colors } from '../../../utils/constants';
import { cleanConnection } from '../../../utils/fns';

import type { GoalProfileTimelineTab_activities } from 'jakiro-types/GoalProfileTimelineTab_activities.graphql';

type Props = {
  activities: GoalProfileTimelineTab_activities;
};

const GoalProfileTimelineTab = ({ activities: { activities } }: Props) => {
  const nodes = cleanConnection(activities);
  const sorted = [...nodes].sort((a: any, b: any) => {
    return new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1;
  });
  return (
    <View testID="GoalProfileTimelineTab" style={styles.contentStyle}>
      {nodes.length > 0 ? (
        <>
          {sorted.map((activity, index) => {
            return <Activity key={index} activity={activity} />;
          })}
        </>
      ) : (
        <EmptyState title="No updates" body="This goal has no timeline updates yet." />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  contentStyle: {
    backgroundColor: Colors.lightestgray,
  },
});

const FragmentContainer = createFragmentContainer(GoalProfileTimelineTab, {
  activities: graphql`
    fragment GoalProfileTimelineTab_activities on Goal {
      activities(includeChildren: false) {
        edges {
          node {
            createdAt
            ...Activity_activity
          }
        }
      }
    }
  `,
});

export default FragmentContainer;

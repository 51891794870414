/**
 * @generated SignedSource<<080f135faa2b71e938c5ad4eaaba2872>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PastOneOnOneListRefetchQuery$variables = {
  first: number;
};
export type PastOneOnOneListRefetchQueryVariables = PastOneOnOneListRefetchQuery$variables;
export type PastOneOnOneListRefetchQuery$data = {
  readonly viewer: {
    readonly managerCheckinRelationship: {
      readonly " $fragmentSpreads": FragmentRefs<"PastOneOnOneList_checkinRelationship">;
    } | null;
  } | null;
};
export type PastOneOnOneListRefetchQueryResponse = PastOneOnOneListRefetchQuery$data;
export type PastOneOnOneListRefetchQuery = {
  variables: PastOneOnOneListRefetchQueryVariables;
  response: PastOneOnOneListRefetchQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PastOneOnOneListRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckinRelationship",
            "kind": "LinkedField",
            "name": "managerCheckinRelationship",
            "plural": false,
            "selections": [
              {
                "args": (v1/*: any*/),
                "kind": "FragmentSpread",
                "name": "PastOneOnOneList_checkinRelationship"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PastOneOnOneListRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckinRelationship",
            "kind": "LinkedField",
            "name": "managerCheckinRelationship",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "checkinRelationshipOneOnOneMeetingsConnection",
                "kind": "LinkedField",
                "name": "oneOnOneMeetings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "checkinRelationshipOneOnOneMeetingsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OneOnOneMeeting",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "scheduledAt",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "entityId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "02e2109af3aef2df6a62fa52a2e29ae6",
    "id": null,
    "metadata": {},
    "name": "PastOneOnOneListRefetchQuery",
    "operationKind": "query",
    "text": "query PastOneOnOneListRefetchQuery(\n  $first: Int!\n) {\n  viewer {\n    managerCheckinRelationship {\n      ...PastOneOnOneList_checkinRelationship_3ASum4\n      id\n    }\n    id\n  }\n}\n\nfragment PastOneOnOneListItem_oneOnOneMeeting on OneOnOneMeeting {\n  id\n  entityId\n  scheduledAt\n}\n\nfragment PastOneOnOneList_checkinRelationship_3ASum4 on CheckinRelationship {\n  oneOnOneMeetings(first: $first) {\n    pageInfo {\n      hasNextPage\n    }\n    edges {\n      node {\n        scheduledAt\n        ...PastOneOnOneListItem_oneOnOneMeeting\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "714b48a1eacfc7347d84634b76ba3966";

export default node;

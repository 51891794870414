import { useState } from 'react';
import { Image, StyleSheet } from 'react-native';
import { Text } from '@rneui/themed';
import type { StackNavigationProp } from '@react-navigation/stack';
import { StackActions } from '@react-navigation/native';
import * as Notifications from 'expo-notifications';

import createNativeDeviceToken from '../../mutations/createNativeDeviceToken';
import toggleDeviceNotifications from '../../mutations/toggleNativeDeviceNotifications';

import Button, { ButtonStyles } from '../../components/Button';
import { connectDropdownAlert, Alert } from '../../hocs/withDropdownAlert';
import { fontTypes, Colors, ScreenWidth, OS } from '../../utils/constants';
import { pify, captureException } from '../../utils/fns';
import Container from '../../components/Container';
import type { RootStackParamList } from '../../components/Navigation/types';
import { getInstallationId } from '../../utils/getInstallationId';

const createNativeToken = pify(createNativeDeviceToken.commit);
const toggleNotifications = pify(toggleDeviceNotifications.commit);

export async function saveToken({ token }: { token: string }) {
  const deviceUniqueId = await getInstallationId();

  await createNativeToken({
    deviceToken: token,
    deviceUniqueId,
    deviceOs: OS(),
  });

  await toggleNotifications({
    notificationsEnabled: true,
    deviceUniqueId,
  });
}

type Props = {
  dropdownAlert: Alert;
  navigation: StackNavigationProp<RootStackParamList, 'Enable Notifications'>;
};

function EnableNotification(props: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const { dropdownAlert, navigation } = props;

  return (
    <Container style={styles.container} flex={1} justify="space-around">
      <Image style={styles.image} source={require('../../assets/images/oneOnOnes.png')} />
      <Container>
        <Text style={styles.title}>Welcome</Text>
        <Text style={styles.bodyTxt}>
          {`Plan and collaborate on your 1:1s.\nAllow notifications to get reminders!`}
        </Text>
      </Container>
      <Container>
        <Button
          onPress={async () => {
            const { status: existingStatus } = await Notifications.getPermissionsAsync();

            let finalStatus = existingStatus;

            // only ask if permissions have not already been determined, because
            // iOS won't necessarily prompt the user a second time.
            if (existingStatus !== 'granted') {
              // Android remote notification permissions are granted during the app
              // install, so this will only ask on iOS
              const { status } = await Notifications.requestPermissionsAsync();
              finalStatus = status;
            }

            // Stop here if the user did not grant permissions
            if (finalStatus !== 'granted') {
              navigation.dispatch(StackActions.popToTop());
              navigation.goBack();
              return;
            }

            // Get the token that uniquely identifies this device
            let { data: token } = await Notifications.getExpoPushTokenAsync();

            setIsLoading(true);

            try {
              await saveToken({ token });

              setIsLoading(false);

              navigation.dispatch(StackActions.popToTop());
              navigation.goBack();
              return;
            } catch (err) {
              setIsLoading(false);

              captureException(err);

              dropdownAlert({
                type: 'error',
                title: 'Whoops, something is not right.',
                body: 'There was an error saving your notification preferences, please try again.',
              });
            }
          }}
          buttonStyle={styles.mainBtn}
          titleStyle={styles.allowTitle}
          loading={isLoading}
          title="Allow notifications"
        />
        <Button
          onPress={() => {
            navigation.dispatch(StackActions.popToTop());
            navigation.goBack();
          }}
          titleStyle={styles.notNow}
          buttonStyle={styles.secondarybtn}
          title="Not right now"
        />
      </Container>
    </Container>
  );
}

const styles = StyleSheet.create({
  allowTitle: {
    ...fontTypes.bodyTitle,
    color: 'white',
  },
  image: {
    marginTop: 70,
  },
  container: {
    padding: 20,
    backgroundColor: 'white',
  },
  title: {
    ...fontTypes.sub_header,
    color: 'black',
  },
  bodyTxt: {
    ...fontTypes.bodyTitleRegular,
    color: Colors.darkergray,
    textAlign: 'center',
    padding: 20,
  },
  mainBtn: {
    ...ButtonStyles.rounded,
    width: ScreenWidth - 40,
    marginBottom: 10,
    height: 48,
  },
  secondarybtn: {
    ...ButtonStyles.rounded,
    height: 48,
    width: ScreenWidth - 40,
    backgroundColor: 'white',
    elevation: 0,
  },
  notNow: {
    color: Colors.darkestgray,
  },
});

export default connectDropdownAlert(EnableNotification);

/**
 * @generated SignedSource<<1b3e93cbe3a95068315a9bcb02c5ffb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NextMeetingCard_user$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserChip_user">;
  readonly " $fragmentType": "NextMeetingCard_user";
};
export type NextMeetingCard_user = NextMeetingCard_user$data;
export type NextMeetingCard_user$key = {
  readonly " $data"?: NextMeetingCard_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"NextMeetingCard_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NextMeetingCard_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserChip_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "08753ccd153722886491780619d1041f";

export default node;

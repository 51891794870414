/**
 * @generated SignedSource<<8847bf639ba74999ea3e7149d603e514>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GoalCreated_goal$data = {
  readonly entityId: string;
  readonly createdAt: DateString;
  readonly creator: {
    readonly name: string | null;
    readonly avatarUrl: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"TimelineItem_user">;
  } | null;
  readonly " $fragmentType": "GoalCreated_goal";
};
export type GoalCreated_goal = GoalCreated_goal$data;
export type GoalCreated_goal$key = {
  readonly " $data"?: GoalCreated_goal$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoalCreated_goal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalCreated_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatarUrl",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TimelineItem_user"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Goal",
  "abstractKey": null
};

(node as any).hash = "06eccc52fb618240cec51de0b6b97b1a";

export default node;

import * as React from 'react';
import { View, TouchableWithoutFeedback, Text } from 'react-native';
import { TabView } from 'react-native-tab-view';

import { Colors, ScreenWidth, fontTypes } from '../utils/constants';
import TabViewHeader from './TabViewHeader';
import Modal from 'react-native-modal';

type Props = {
  tabs: Route[];
  isVisible: boolean;
  toggle: () => void;
};

type Route = {
  key: string;
  title: string;
  component: React.ReactNode;
};

type State = {
  routes: Route[];
  index: number;
};

class BottomOverlayTab extends React.Component<Props, State> {
  state = {
    routes: [],
    index: 0,
  };

  static getDerivedStateFromProps(nextProps: Props, state: State) {
    if (state.routes !== nextProps.tabs) {
      return {
        routes: nextProps.tabs,
        index: 0,
      };
    }

    return null;
  }

  renderTabScene = ({ route }: { route: Route }) => {
    return route.component;
  };

  renderHeader = (tabViewConfig: any) => {
    return (
      <TabViewHeader
        tabViewConfig={tabViewConfig}
        handleChange={this.handleChangeTab}
        getLabelText={route => route.title}
      />
    );
  };

  handleChangeTab = (index: number) => {
    this.setState({
      index,
    });
  };

  renderModalContent = () => {
    const { tabs } = this.props;

    if (tabs.length === 1) {
      const tab = tabs[0];
      return (
        <View style={{ flex: 1 }}>
          <View style={styles.singleElementTitle}>
            <Text style={styles.singleElementTitleLabel}>{tab.title}</Text>
          </View>
          <View style={{ flex: 1 }}>{tab.component}</View>
        </View>
      );
    }

    if (tabs.length > 1) {
      return (
        <TabView
          swipeEnabled={false}
          navigationState={this.state}
          renderScene={this.renderTabScene}
          renderTabBar={this.renderHeader}
          onIndexChange={this.handleChangeTab}
          initialLayout={{
            height: 0,
            width: ScreenWidth,
          }}
        />
      );
    }

    return null;
  };

  render() {
    const { isVisible, toggle } = this.props;
    return (
      <View>
        <Modal
          useNativeDriver
          hideModalContentWhileAnimating
          style={styles.modal}
          backdropColor={Colors.overlay}
          backdropOpacity={1}
          isVisible={isVisible}
        >
          <View style={{ flex: 1 }}>
            <TouchableWithoutFeedback onPress={toggle}>
              <View style={{ flex: 1, backgroundColor: 'transparent', width: ScreenWidth }} />
            </TouchableWithoutFeedback>
            <View style={styles.card}>{this.renderModalContent()}</View>
          </View>
        </Modal>
      </View>
    );
  }
}

const styles = {
  card: {
    backgroundColor: 'white',
    borderTopRightRadius: 25,
    borderTopLeftRadius: 25,
    height: 295,
  },
  modal: {
    margin: 0,
    justifyContent: 'flex-end',
  },
  singleElementTitle: {
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 0.5,
    borderColor: Colors.border,
  },
  singleElementTitleLabel: {
    backgroundColor: 'transparent',
    margin: 8,
    ...fontTypes.bodyTitle,
  },
} as const;

export default BottomOverlayTab;

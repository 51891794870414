/**
 * @generated SignedSource<<5086494b896d13f88b1941801a6dc3e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BaseReviewGrouptypeEnumType = "manager" | "peer" | "report" | "self";
export type DeliverableTypeManagerReviews = "both" | "only_review" | "only_summary";
export type DeliverableTypePeerReviews = "admin_only" | "all" | "anonymous" | "anonymous_managers" | "managers";
export type DeliverableTypeUpwardReviews = "admin_only" | "all" | "anonymous" | "anonymous_managers" | "managers";
import { FragmentRefs } from "relay-runtime";
export type ReviewCycleIntro_reviewRequest$data = {
  readonly id: string;
  readonly hasPrivateQuestions: boolean;
  readonly hasOnlyPrivateQuestions: boolean;
  readonly lockedForSubmission: boolean;
  readonly reviewGroup: {
    readonly type: BaseReviewGrouptypeEnumType;
  };
  readonly isRejected: boolean;
  readonly rejectedAt: DateString | null;
  readonly reviewee: {
    readonly viewerIsPostReviewGiver: boolean;
    readonly reviewCycle: {
      readonly allowDraftResponsesDuringPeerSelection: boolean;
      readonly launchEmailSentAt: DateString | null;
      readonly deliverableTypeManagerReviews: DeliverableTypeManagerReviews | null;
      readonly deliverableTypeUpwardReviews: DeliverableTypeUpwardReviews | null;
      readonly deliverableTypePeerReviews: DeliverableTypePeerReviews | null;
    } | null;
    readonly user: {
      readonly preferredName: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"UserIntro_user">;
    } | null;
    readonly postReviewGiver: {
      readonly preferredName: string | null;
    } | null;
  };
  readonly " $fragmentType": "ReviewCycleIntro_reviewRequest";
};
export type ReviewCycleIntro_reviewRequest = ReviewCycleIntro_reviewRequest$data;
export type ReviewCycleIntro_reviewRequest$key = {
  readonly " $data"?: ReviewCycleIntro_reviewRequest$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewCycleIntro_reviewRequest">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preferredName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewCycleIntro_reviewRequest",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPrivateQuestions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasOnlyPrivateQuestions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockedForSubmission",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReviewGroup",
      "kind": "LinkedField",
      "name": "reviewGroup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRejected",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rejectedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Reviewee",
      "kind": "LinkedField",
      "name": "reviewee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerIsPostReviewGiver",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ReviewCycle",
          "kind": "LinkedField",
          "name": "reviewCycle",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allowDraftResponsesDuringPeerSelection",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "launchEmailSentAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deliverableTypeManagerReviews",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deliverableTypeUpwardReviews",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deliverableTypePeerReviews",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UserIntro_user"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "postReviewGiver",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ReviewRequest",
  "abstractKey": null
};
})();

(node as any).hash = "4a2b5117d65ea47d324eeb67b8077385";

export default node;

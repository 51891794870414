/**
 * @generated SignedSource<<49c70455eb34be5c05d5720223a7a84c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DirectoryAllEmployees_viewer$data = {
  readonly company: {
    readonly users?: {
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly entityId: string;
          readonly " $fragmentSpreads": FragmentRefs<"PersonCell_user">;
        };
      } | null> | null;
    };
  };
  readonly searchUsers?: ReadonlyArray<{
    readonly entityId: string;
    readonly " $fragmentSpreads": FragmentRefs<"PersonCell_user">;
  } | null> | null;
  readonly " $fragmentType": "DirectoryAllEmployees_viewer";
};
export type DirectoryAllEmployees_viewer = DirectoryAllEmployees_viewer$data;
export type DirectoryAllEmployees_viewer$key = {
  readonly " $data"?: DirectoryAllEmployees_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DirectoryAllEmployees_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "entityId",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "PersonCell_user"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 30,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "hasQuery"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "defaultValue": "ACTIVE",
      "kind": "LocalArgument",
      "name": "status"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DirectoryAllEmployees_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "condition": "hasQuery",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "after",
                  "variableName": "after"
                },
                {
                  "kind": "Variable",
                  "name": "first",
                  "variableName": "first"
                },
                {
                  "kind": "Literal",
                  "name": "orderBy",
                  "value": "LAST_NAME"
                },
                (v0/*: any*/),
                {
                  "kind": "Variable",
                  "name": "status",
                  "variableName": "status"
                }
              ],
              "concreteType": "companyUsersConnection",
              "kind": "LinkedField",
              "name": "users",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "companyUsersEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "condition": "hasQuery",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            (v0/*: any*/)
          ],
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "searchUsers",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "b11e594b44e799e20f097894e5c93269";

export default node;

/**
 * @generated SignedSource<<9b01e9f2464222b40f2b279da3317616>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BaseGoalamountTypeEnumType = "digit" | "dollar" | "percent";
export type BaseGoaltypeEnumType = "qualitative" | "quantitative";
import { FragmentRefs } from "relay-runtime";
export type GoalProgress_goal$data = {
  readonly id: string;
  readonly amountType: BaseGoalamountTypeEnumType | null;
  readonly goalAmount: number | null;
  readonly progressAmount: number | null;
  readonly startingAmount: number | null;
  readonly type: BaseGoaltypeEnumType;
  readonly computedProgressAmount: number | null;
  readonly " $fragmentType": "GoalProgress_goal";
};
export type GoalProgress_goal = GoalProgress_goal$data;
export type GoalProgress_goal$key = {
  readonly " $data"?: GoalProgress_goal$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoalProgress_goal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalProgress_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "amountType",
      "args": null,
      "kind": "ScalarField",
      "name": "computedAmountType",
      "storageKey": null
    },
    {
      "alias": "goalAmount",
      "args": null,
      "kind": "ScalarField",
      "name": "computedGoalAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "progressAmount",
      "storageKey": null
    },
    {
      "alias": "startingAmount",
      "args": null,
      "kind": "ScalarField",
      "name": "computedStartingAmount",
      "storageKey": null
    },
    {
      "alias": "type",
      "args": null,
      "kind": "ScalarField",
      "name": "computedType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "computedProgressAmount",
      "storageKey": null
    }
  ],
  "type": "Goal",
  "abstractKey": null
};

(node as any).hash = "84ea531b92dc756a68803566874bcc4d";

export default node;

import { useState } from 'react';
import { StyleSheet, Dimensions, Animated, View } from 'react-native';
import { Badge } from '@rneui/themed';
import { css } from '@emotion/native';
import { TabView, TabBar } from 'react-native-tab-view';
import { createFragmentContainer, graphql } from 'react-relay';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';

import Header from '../../../components/Header';
import GoalTitle from '../../../components/GoalTitle';
import GoalProgress from '../../../components/GoalProgress';
import type { RootStackParamList } from '../../../components/Navigation/types';
import {
  fontTypes,
  Colors,
  ScreenHeight,
  HEADER_MIN_HEIGHT,
  TABS_SIZE,
  isMaxWidth,
  APPLICATION_MAX_WIDTH_STRING,
} from '../../../utils/constants';
import getGoalStatusColor from '../../../utils/getGoalStatusColor';

import { interpolations } from './interpolations';

import type { GoalHeader_goal } from 'jakiro-types/GoalHeader_goal.graphql';

type Props = {
  goal: GoalHeader_goal;
  routes: any;
};

function GoalHeader(props: Props) {
  const { goal, routes } = props;

  const navigation = useNavigation<StackNavigationProp<RootStackParamList, 'Goal'>>();
  const route = useRoute<RouteProp<RootStackParamList, 'Goal'>>();
  const [scrollViewScrollY, _setScrollViewScrollY] = useState(new Animated.Value(0));
  const [headerWrapperMaxHeight, setHeaderWrapperMaxHeight] = useState(
    HEADER_MIN_HEIGHT + TABS_SIZE,
  );
  const [tabViewContainerState, setTabViewContainerState] = useState({
    index: 0,
    routes,
  });

  if (!goal) return null;

  const goalIds = route.params.goalIds || [];
  const goBack = () => {
    const poppedGoalIds =
      Array.isArray(goalIds) && goalIds.length > 1 ? goalIds.slice(0, goalIds.length - 1) : [];

    if (poppedGoalIds.length) {
      navigation.navigate('Goal', { goalIds: poppedGoalIds });
    } else {
      navigation.goBack();
    }
  };

  return (
    <View style={styles.fill}>
      {/**
       * This is the main ScrollView, wrapping everything and is the one used
       * to animated the header itself.
       **/}
      <Animated.ScrollView
        style={styles.fill}
        scrollEventThrottle={1}
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollViewScrollY } } }], {
          useNativeDriver: true,
        })}
        testID="GoalHeader_animatedScrollView"
      >
        <View style={{ paddingTop: headerWrapperMaxHeight }}>
          <TabView
            navigationState={tabViewContainerState}
            renderScene={props => {
              // @ts-expect-error FIXME! Property 'component' does not exist on type 'Route'.
              const component = props.route.component || null;

              return (
                <Container>
                  <View
                    style={[
                      styles.fill,
                      { minHeight: ScreenHeight - HEADER_MIN_HEIGHT - TABS_SIZE },
                    ]}
                  >
                    {component}
                  </View>
                </Container>
              );
            }}
            renderTabBar={() => <View style={{ display: 'none' }} />}
            swipeEnabled={false}
            onIndexChange={index => {
              setTabViewContainerState({
                ...tabViewContainerState,
                index,
              });
            }}
            // https://github.com/satya164/react-native-tab-view/issues/1274#issuecomment-940029265
            style={{ height: Dimensions.get('window').height }}
            initialLayout={{
              height: 0,
              width: Dimensions.get('window').width,
            }}
          />
        </View>
      </Animated.ScrollView>

      <Animated.View
        style={[
          styles.header,
          css`
            background-color: ${getGoalStatusColor(goal.status)};
          `,
          {
            transform: [
              {
                translateY: interpolations.wrapperTranslate(
                  headerWrapperMaxHeight,
                  scrollViewScrollY,
                ),
              },
            ],
          },
        ]}
        onLayout={event => {
          setHeaderWrapperMaxHeight(event.nativeEvent.layout.height);
        }}
      >
        <Animated.View
          style={[
            styles.collapsedHeaderWrapper,
            {
              transform: [
                {
                  translateY: interpolations.headerTranslate(
                    headerWrapperMaxHeight,
                    scrollViewScrollY,
                  ),
                },
              ],
            },
          ]}
        >
          <Header
            bottomComponent
            containerStyle={{ borderBottomColor: 'red' }}
            backgroundColor={getGoalStatusColor(goal.status) || Colors.gray}
            buttonColor={Colors.white}
            mode={goalIds && goalIds.length > 1 ? 'BACK' : 'CLOSE'}
            goBack={goBack}
            centerComponent={
              <Animated.View
                style={{
                  opacity: interpolations.titleOpacity(headerWrapperMaxHeight, scrollViewScrollY),
                }}
              >
                <GoalTitle
                  title={goal.name}
                  titleStyle={[fontTypes.bodyTitle, { color: Colors.white }]}
                  iconSize={fontTypes.bodyTitle.fontSize}
                  iconColor={Colors.white}
                  isPrivate={goal.private}
                  numberOfLines={1}
                  wrapperStyle={{ paddingHorizontal: 30, maxWidth: 260 }}
                  testID="GoalHeader_goalTitle-collapsing"
                />
              </Animated.View>
            }
          />

          <Animated.View
            style={[
              styles.collapsedHeader,
              {
                opacity: interpolations.headerOpacity(headerWrapperMaxHeight, scrollViewScrollY),
              },
            ]}
          >
            <Header
              bottomComponent
              backgroundColor={getGoalStatusColor(goal.status)}
              wrapperStyle={{ borderBottomWidth: 0 }}
              buttonColor={Colors.white}
              mode={goalIds && goalIds.length > 1 ? 'BACK' : 'CLOSE'}
              goBack={goBack}
              centerComponent={
                <View>
                  <GoalTitle
                    title={goal.name}
                    titleStyle={styles.collapsedHeaderTitle}
                    iconSize={fontTypes.bodyTitle.fontSize}
                    iconColor={Colors.white}
                    isPrivate={goal.private}
                    numberOfLines={1}
                    wrapperStyle={{ paddingHorizontal: 30, maxWidth: 260 }}
                    testID="GoalHeader_goalTitle-collapsed"
                  />
                </View>
              }
            />
          </Animated.View>
        </Animated.View>

        <Container>
          <Animated.View
            style={[
              styles.goalExcerpt,
              { paddingHorizontal: isMaxWidth ? 0 : 20 },
              {
                opacity: interpolations.goalExcerptOpacity(
                  headerWrapperMaxHeight,
                  scrollViewScrollY,
                ),
              },
            ]}
          >
            <Animated.View
              style={[
                { flexDirection: 'row' },
                {
                  opacity: interpolations.goalTitleOpacity(
                    headerWrapperMaxHeight,
                    scrollViewScrollY,
                  ),
                },
              ]}
            >
              <GoalTitle
                title={goal.name}
                titleStyle={[styles.goalTitleStyle, styles.goalTextStyle]}
                iconColor={Colors.white}
                iconSize={fontTypes.header.fontSize}
                isPrivate={goal.private}
                numberOfLines={3}
                wrapperStyle={{ flex: 1 }}
                testID="GoalHeader_goalTitle-excerpt"
              />
              {goal.priority && (
                <Badge
                  textStyle={styles.goalPriorityBadgeTextStyle}
                  badgeStyle={[
                    styles.goalPriorityBadgeContainerStyle,
                    {
                      backgroundColor: Colors.badgeBackground,
                      borderColor: 'transparent',
                      display: 'flex',
                      flexDirection: 'row',
                      flexShrink: 0,
                      minWidth: 40,
                    },
                  ]}
                  value={`P${goal.priority}`}
                />
              )}
            </Animated.View>

            <View>
              <GoalProgress showProgressLabel goal={goal} />
            </View>
          </Animated.View>
        </Container>

        <Container>
          <TabView
            navigationState={tabViewContainerState}
            renderScene={() => <View style={{ display: 'none' }} />}
            renderTabBar={props => (
              <TabBar
                {...props}
                style={styles.tabBar}
                labelStyle={styles.tabLabel}
                indicatorStyle={styles.tabIndicator}
                renderLabel={scene => (
                  <Animated.Text
                    testID={`GoalProfileHeader_tabViewHeaderTab-${scene.route.key}`}
                    style={[styles.tabLabel]}
                  >
                    {scene.route.title}
                  </Animated.Text>
                )}
              />
            )}
            swipeEnabled={false}
            onIndexChange={index => {
              setTabViewContainerState({
                ...tabViewContainerState,
                index,
              });
            }}
            initialLayout={{
              height: 0,
              width: Dimensions.get('window').width,
            }}
          />
        </Container>
      </Animated.View>
    </View>
  );
}
const Container = p => {
  return (
    <View
      style={css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      `}
    >
      <View
        style={css`
          max-width: ${APPLICATION_MAX_WIDTH_STRING};
          width: 100%;
        `}
      >
        {p.children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  fill: {
    flex: 1,
    backgroundColor: Colors.lightestgray,
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    borderBottomWidth: 0.5,
    borderColor: Colors.lightgray,
  },
  collapsedHeaderWrapper: {
    zIndex: 2,
  },
  collapsedHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    opacity: 0,
    zIndex: 3,
  },
  collapsedHeaderTitle: {
    ...fontTypes.bodyTitle,
    color: Colors.white,
  },
  goalExcerpt: {
    paddingTop: 10,
    paddingBottom: 15,
  },
  goalTitleStyle: {
    ...fontTypes.header,
    marginBottom: 10,
    alignSelf: 'flex-start',
    flex: 1,
  },
  goalTextStyle: {
    color: Colors.white,
    fontWeight: '600',
  },
  goalPriorityBadgeContainerStyle: {
    padding: 4,
    width: 32,
    height: 32,
    marginLeft: 10,
  },
  goalPriorityBadgeTextStyle: {
    ...fontTypes.body_semi_bold,
    color: Colors.white,
  },
  tabBar: {
    backgroundColor: Colors.goalHeaderTabsBackground,
    shadowOpacity: 0,
    elevation: 0,
  },
  tabLabel: {
    backgroundColor: 'transparent',
    margin: 8,
    ...fontTypes.bodyTitle,
    color: Colors.white,
  },
  tabIndicator: {
    backgroundColor: Colors.white,
  },
});

export default createFragmentContainer(GoalHeader, {
  goal: graphql`
    fragment GoalHeader_goal on Goal {
      id
      entityId
      name
      private
      priority
      status
      ...GoalProgress_goal
    }
  `,
});

import Ionicons from '@expo/vector-icons/Ionicons';
import type { IconProps } from '@rneui/themed';

import { Colors } from '../utils/constants';

type Props = {
  onPress?: IconProps['onPress'];
  color?: IconProps['color'];
  containerStyle?: IconProps['containerStyle'];
  iconStyle?: IconProps['iconStyle'];
};

const OptionButton = ({ onPress, color, containerStyle, iconStyle }: Props) => (
  <Ionicons
    onPress={onPress}
    style={iconStyle || { paddingRight: 10, paddingLeft: 10 }}
    name="ellipsis-horizontal"
    type="ionicon"
    size={28}
    // @ts-expect-error Variance between types
    color={color || Colors.icongray}
    testID="OptionButton"
    containerStyle={containerStyle}
  />
);

export default OptionButton;

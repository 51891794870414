import Edit from './assets/Edit';
import GoalComplete from './assets/GoalComplete';
import GoalIncomplete from './assets/GoalIncomplete';
import Help from './assets/Help';
import OffTrack from './assets/OffTrack';
import OnTrack from './assets/OnTrack';
import Progressing from './assets/Progressing';

const icons = {
  edit: Edit,
  goal_complete: GoalComplete,
  goal_incomplete: GoalIncomplete,
  help: Help,
  off_track: OffTrack,
  on_track: OnTrack,
  progressing: Progressing,
};

type Props = {
  color: string;
  name: string;
};

function Icon({ color, name }: Props) {
  let IconToRender = icons[name];
  if (IconToRender) {
    return <IconToRender color={color} />;
  }

  throw new Error(`The icon ${name} is not currently in the Lattice icon library.`);
}

export default Icon;

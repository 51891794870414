import * as React from 'react';
import { Text } from 'react-native';
import styled, { css } from '@emotion/native';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Svg from 'react-native-svg';

import { UserChipFragment } from './UserChip';
import Container from './Container';
import { fontTypes, Colors } from '../utils/constants';

import type { NextMeetingCard_user } from 'jakiro-types/NextMeetingCard_user.graphql';

type Props = {
  user: NextMeetingCard_user;
  date: string | null;
  onPress: () => void;
};

function NextMeetingCard({ user, date, onPress }: Props) {
  return (
    <Card onPress={onPress}>
      <Container direction="row" justify="flex-start">
        <Text style={[fontTypes.bodyTitle, { paddingRight: 8 }]}>1:1 with </Text>

        <UserChipFragment user={user} xsmall />
      </Container>

      <Container
        direction="row"
        justify="space-between"
        style={css`
          margin-top: 16px;
        `}
      >
        <Text style={fontTypes.bodySecondary}>{date || 'No future meetings'}</Text>

        <Container direction="row">
          <Text
            style={
              (fontTypes.bodyTitle, { color: Colors.blue, fontWeight: '600', paddingRight: 8 })
            }
          >
            {date === null ? 'View previous' : 'Plan your'} 1:1{date === null ? 's' : ''}
          </Text>
          <ChevronRight />
        </Container>
      </Container>
    </Card>
  );
}

export const ChevronRight = () => (
  <Svg.Svg width="6" height="11" viewBox="0 0 6 11" fill="none">
    <Svg.Path
      d="M0.414 8.90725C0.0885627 9.23268 0.0885627 9.76032 0.414 10.0858C0.739436 10.4112 1.26707 10.4112 1.59251 10.0858L5.45952 6.21875C5.95067 5.72759 5.95516 4.93656 5.45952 4.44092L1.59251 0.573913C1.26707 0.248476 0.739436 0.248476 0.414 0.573913C0.0885627 0.89935 0.0885627 1.42699 0.414 1.75242L3.99141 5.32983L0.414 8.90725Z"
      fill="#0174E0"
    />
  </Svg.Svg>
);

const Card = styled.TouchableOpacity`
  padding: 16px;
  box-shadow: 0px 1px 3px rgba(34, 45, 56, 0.16);
  background-color: white;
  border-radius: 4px;
`;

export default createFragmentContainer(NextMeetingCard, {
  user: graphql`
    fragment NextMeetingCard_user on User {
      id
      ...UserChip_user
    }
  `,
});

import AsyncStorage from '@react-native-async-storage/async-storage';

import { getConfig } from './getConfig';
import { COMPANY_SLUG } from './constants';
import { captureException } from './fns';

export type GetCompanyInfoQueryResponse = {
  company:
    | {
        slug: string;
        name: string;
        entityId: string;
        emailDomain: string | undefined;
        ssoLoginUrl: string | undefined;
        forceSSOLogin: boolean;
        logoFileName: string | undefined;
        allowSelfRegistration: boolean;
        allowPhoneNumberAuth: boolean;
      }
    | undefined;
};

export default (
  slug: string,
  onSuccess: (company: Pick<GetCompanyInfoQueryResponse, 'company'>) => void,
  onError: (k: any, o?: any) => void,
) => {
  const query = `
    query GetCompanyInfoQuery($slug: String!) {
      company(slug: $slug) {
        slug
        name
        entityId
        emailDomain
        ssoLoginUrl
        forceSSOLogin
        logoFileName
        allowSelfRegistration
        allowPhoneNumberAuth
      }
    }
  `;

  const variables = {
    slug,
  };

  // This doesn't go through Relay because it uses the Public GraphQL schema
  // and Relay doesn't currently support multiple GraphQL schemas in the same app.
  // See: https://github.com/latticehr/lattice/pull/27985
  fetch(getConfig('GRAPHQL_ENDPOINT_PUBLIC'), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      mode: 'cors',
      credentials: 'omit',
    },
    body: JSON.stringify({ query, variables }),
  })
    .then(async res => {
      if (!res.ok) {
        const errorText = await res.text();
        onError(errorText);
        return;
      }

      const { data } = await res.json();
      const { company } = data;

      try {
        const blob = JSON.stringify(company);

        await AsyncStorage.setItem(COMPANY_SLUG, blob);
      } catch (e) {
        captureException(e);
      }

      company ? onSuccess(company) : onError('No company', { extra: { slug: variables.slug } });
    })
    .catch(e => {
      onError(e);
    });
};

export const initialPulseState = {
  remainingPulses: [],
};

export const SET_REMAINING_PULSES = 'SET_REMAINING_PULSES';
export const REMOVE_FIRST_PULSE = 'REMOVE_FIRST_PULSE';

export type StateType = {
  remainingPulses: {
    id: string;
    question: {
      __typename: string;
      hasComment: boolean;
      currentRevision: {
        id: string;
      };
      body: string;
    };
  }[];
};

type ActionType = {
  type: string;
  payload?: any;
};

export function pulseResponseReducer(state: StateType, action: ActionType) {
  switch (action.type) {
    case SET_REMAINING_PULSES:
      return {
        ...state,
        ...action.payload,
      };

    case REMOVE_FIRST_PULSE:
      return {
        ...state,
        remainingPulses: [...state.remainingPulses.slice(1)],
      };

    default:
      throw new Error('Unknown action type!');
  }
}

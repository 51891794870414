import * as React from 'react';
import { Image, Text } from 'react-native';
import { css } from '@emotion/native';

import Container from '../../components/Container';
import { fontTypes } from '../../utils/constants';

type Props = {
  title: string;
  subtitle: string;
  rainbow: boolean;
};

export function isDayTime() {
  const today = new Date();
  const currentHour = today.getHours();

  return currentHour > 6 && currentHour < 18;
}

export default function Done({ title, subtitle, rainbow }: Props) {
  return (
    <Container
      flex={1}
      direction="column"
      style={css`
        background-color: #fff5e9;
      `}
    >
      <Image
        source={
          rainbow
            ? require('../../assets/images/rainbow.gif')
            : isDayTime()
            ? require('../../assets/images/sun.gif')
            : require('../../assets/images/moon.gif')
        }
        style={{ width: 240, height: 240 }}
      />

      <Text
        style={[fontTypes.bodyTitle, { textAlign: 'center', fontWeight: 'bold', maxWidth: 295 }]}
      >
        {title}
      </Text>

      <Text
        style={[fontTypes.informative_text, { textAlign: 'center', maxWidth: 295, marginTop: 24 }]}
      >
        {subtitle}
      </Text>
    </Container>
  );
}

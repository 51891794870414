import * as Svg from 'react-native-svg';

type Props = {
  color: string | null;
};

export default ({ color }: Props) => (
  <Svg.Svg width="24" height="24" viewBox="0 0 24 24" fill={color}>
    <Svg.G clipPath="url(#a)">
      <Svg.Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.986 6.994l-.996.996-1.98-1.98 1-1a1.4 1.4 0 1 1 1.976 1.984zM8 15.98l6.99-6.99-1.98-1.98L6.02 14H6v1.5a.5.5 0 0 0 .5.5H8v-.02zm-1.942 3.065h11.448a1 1 0 1 0 0-2H6.058a1 1 0 1 0 0 2z"
      />
    </Svg.G>
    <Svg.Defs>
      <Svg.ClipPath id="a">
        <Svg.Path d="M0 0h24v24H0z" />
      </Svg.ClipPath>
    </Svg.Defs>
  </Svg.Svg>
);

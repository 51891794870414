/**
 * @generated SignedSource<<9c87c5a5f79befb900f58e89464853fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BaseGoalstateEnumType = "active" | "draft" | "ended" | "inactive";
export type BaseGoalstatusEnumType = "amber" | "green" | "red";
import { FragmentRefs } from "relay-runtime";
export type GoalCard_goal$data = {
  readonly childrenList: ReadonlyArray<{
    readonly id: string;
  } | null> | null;
  readonly entityId: string;
  readonly id: string;
  readonly name: string;
  readonly private: boolean;
  readonly state: BaseGoalstateEnumType;
  readonly status: BaseGoalstatusEnumType | null;
  readonly " $fragmentSpreads": FragmentRefs<"GoalProgress_goal" | "GoalBadge_goal">;
  readonly " $fragmentType": "GoalCard_goal";
};
export type GoalCard_goal = GoalCard_goal$data;
export type GoalCard_goal$key = {
  readonly " $data"?: GoalCard_goal$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoalCard_goal">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalCard_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Goal",
      "kind": "LinkedField",
      "name": "childrenList",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "private",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalProgress_goal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalBadge_goal"
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
})();

(node as any).hash = "7da0f0afaccd40ad2443683ae60798bf";

export default node;

import * as Sentry from 'sentry-expo';
import { isArray } from 'lodash';

import { isWeb, isDevelopment } from './constants';

export function cleanArray<T>(
  arr: readonly (T | null | undefined)[] | null | undefined,
): readonly T[] {
  if (!arr) return [];

  return arr.filter(Boolean);
}

type GraphQLNode<NodeType> = NodeType | null | undefined;
type Edges<NodeType> = readonly (
  | {
      readonly node: GraphQLNode<NodeType> | null | undefined;
    }
  | null
  | undefined
)[];
export type Connection<NodeType> =
  | {
      readonly edges?: Edges<NodeType> | null | undefined;
    }
  | null
  | undefined;

export function cleanConnection<NodeType>(conn?: Connection<NodeType>): readonly NodeType[] {
  if (!conn) return [];

  return cleanArray(cleanArray(conn.edges).map(e => e.node));
}

export function weekNumber(date: Date = new Date()) {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  // @ts-expect-error
  return Math.ceil(((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7 + 1) / 7);
}

function yearNumber(date: Date = new Date()) {
  // If the first week of the new year started on the previous year, return the new year
  if (date > new Date(date.getFullYear(), 11, 25)) {
    return date.getFullYear() + 1;
  }

  return date.getFullYear();
}

export function getWeekId(date: Date = new Date()): string {
  return `${yearNumber(date)}${weekNumber(date)}`;
}

function getFirstDayOfYear(year: number) {
  return new Date(year, 0, 0).getDay();
}

const checkFirstDayOfWeekInThisYear = (year: number) => {
  const janFirst = new Date(year, 0, 1);
  const janFirstWeekNum = weekNumber(janFirst);
  return !(janFirst.getDay() !== 1 && janFirstWeekNum === 1);
};

const getFirstMondayOfYear = (year: number) => {
  const date = new Date(year, 0, 1);
  while (date.getDay() !== 1) {
    date.setDate(date.getDate() + 1);
  }
  return date;
};

export function getFullWeekDates(weekId: string) {
  const year = parseInt(weekId.slice(0, 4), 10);
  const weekNum = parseInt(weekId.substr(4), 10);
  const dateFromFirstMonday = getFirstMondayOfYear(year);
  dateFromFirstMonday.setDate(dateFromFirstMonday.getDate() + (weekNum - 1) * 7);

  const date = checkFirstDayOfWeekInThisYear(year)
    ? dateFromFirstMonday
    : new Date(year, 0, 1 - getFirstDayOfYear(year) + (weekNum - 1) * 7);
  const endDate = new Date(date.getTime());
  endDate.setDate(endDate.getDate() + 6);

  const weekStartDate = date.toISOString().split('T')[0];
  const weekEndDate = endDate.toISOString().split('T')[0];

  return { weekStartDate, weekEndDate };
}

export const pify =
  (fn: any) =>
  (payload: any): Promise<any> => {
    return new Promise((...args) => fn(payload, ...args));
  };

export const dumbUrlCheck = (st: string | null): boolean | null =>
  // temporary check while uploading is not working just so we can display the img.
  st ? /(https?)/.test(st) || /(file?)/.test(st) || /(assets?)/.test(st) : null;

export const formatDate = (time: any, upperCase: boolean = true): string => {
  const next = `${time.format('ll')} @ ${time.format('LT')}`;

  if (!upperCase) {
    return next;
  }

  return next.toUpperCase();
};

export const getLength = (a: any): number => {
  if (!a) return 0;

  if (typeof a === 'object' && !isArray(a)) return Object.keys(a).length;

  return a.length;
};

export function dateToPreviousCloserQuarter(date: Date): Date {
  const dateCopy = new Date(date.getTime());
  const minutes = dateCopy.getMinutes();

  if (minutes > 45) {
    dateCopy.setMinutes(45);
  } else if (minutes > 30) {
    dateCopy.setMinutes(30);
  } else if (minutes > 15) {
    dateCopy.setMinutes(15);
  } else {
    dateCopy.setMinutes(0);
  }

  return dateCopy;
}

export const getInitials = (name: string | null): string => {
  if (!name || typeof name !== 'string') return '';

  const letters = name
    .split(' ')
    .filter(Boolean) // Remove empty strings
    .map(part => part[0])
    .slice(0, 2)
    .join('')
    .toUpperCase();

  return letters;
};

export const navigationOptions = (withGesture: boolean = true) => ({
  navigationOptions: { headerShown: false, gestureEnabled: withGesture },
});

const getArea = (a: number, b: number): number => a * b;

const getPointDistance = (a: number[], b: number[]): number =>
  Math.sqrt(Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2));

type Coord = {
  x: number;
  y: number;
};

/*
  ~Tooltip coordinate system:~
  The tooltip coordinates are based on the element which it is wrapping.
  We take the x and y coordinates of the element and find the best position
  to place the tooltip. To find the best position we look for the side with the
  most space. In order to find the side with the most space we divide the the
  surroundings in four quadrants and check for the one with biggest area.
  Once we know the quandrant with the biggest area it place the tooltip in that
  direction.

  To find the areas we first get 5 coordinate points. The center and the other 4 extreme points
  which together make a perfect cross shape.

  Once we know the coordincates we can get the length of the vertices which form each quadrant.
  Since they are squares we only need two.
*/

export const getTooltipCoordinate = (
  x: number,
  y: number,
  width: number,
  height: number,
  ScreenWidth: number,
  ScreenHeight: number,
  tooltipWidth: number,
  tooltipHeight: number,
  withPointer: boolean,
): Coord => {
  // The following are point coordinates: [x, y]
  const center = [x + width / 2, y + height / 2];
  const pOne = [center[0], 0];
  const pTwo = [ScreenWidth, center[1]];
  const pThree = [center[0], ScreenHeight];
  const pFour = [0, center[1]];

  // vertices
  const vOne = getPointDistance(center, pOne);
  const vTwo = getPointDistance(center, pTwo);
  const vThree = getPointDistance(center, pThree);
  const vFour = getPointDistance(center, pFour);

  // Quadrant areas.
  type Areas = {
    area: number;
    id: number;
  };

  const areas: Areas[] = [
    getArea(vOne, vFour),
    getArea(vOne, vTwo),
    getArea(vTwo, vThree),
    getArea(vThree, vFour),
  ].map((each, index) => ({ area: each, id: index }));

  const sortedArea = areas.sort((a, b) => b.area - a.area);

  // deslocated points
  const dX = 0.001;
  const dY = height / 2;

  // Deslocate the coordinates in the direction of the quadrant.
  const directionCorrection = [
    [-1, -1],
    [1, -1],
    [1, 1],
    [-1, 1],
  ];
  const deslocateReferencePoint = [
    [-tooltipWidth, -tooltipHeight],
    [0, -tooltipHeight],
    [0, 0],
    [-tooltipWidth, 0],
  ];

  // current quadrant index
  const qIndex = sortedArea[0].id;

  const getWithPointerOffsetY = () => (withPointer ? 10 * directionCorrection[qIndex][1] : 0);
  const getWithPointerOffsetX = () =>
    withPointer ? center[0] - 18 * directionCorrection[qIndex][0] : center[0];

  const newX =
    getWithPointerOffsetX() +
    (dX * directionCorrection[qIndex][0] + deslocateReferencePoint[qIndex][0]);

  return {
    x: constraintX(newX, qIndex, center[0], ScreenWidth, tooltipWidth),
    y:
      center[1] +
      (dY * directionCorrection[qIndex][1] + deslocateReferencePoint[qIndex][1]) +
      getWithPointerOffsetY(),
  };
};

const constraintX = (
  newX: number,
  qIndex: number,
  x: number,
  ScreenWidth: number,
  tooltipWidth: number,
): number => {
  switch (qIndex) {
    // 0 and 3 are the left side quadrants.
    case 0:
    case 3:
      const maxWidth = newX > ScreenWidth ? ScreenWidth - 10 : newX;
      return newX < 1 ? 10 : maxWidth;
    // 1 and 2 are the right side quadrants
    case 1:
    case 2:
      const leftOverSpace = ScreenWidth - newX;
      return leftOverSpace >= tooltipWidth ? newX : newX - (tooltipWidth - leftOverSpace + 10);
    default:
      return 0;
  }
};

export function captureException(
  e: (Error | string) | null,
  context?: { extra: Record<string, any> } | null,
) {
  if (isDevelopment) return;

  if (isWeb) {
    if (context?.extra) {
      Sentry.Browser.withScope(scope => {
        scope.setExtras(context.extra);
      });
    }

    Sentry.Browser.captureException(e);
  } else {
    if (context?.extra) {
      Sentry.Native.withScope(scope => {
        scope.setExtras(context.extra);
      });
    }

    Sentry.Native.captureException(e);
  }
}

import { useState, RefObject } from 'react';
import { TextInput as RNTextInput } from 'react-native';
import { Input as BaseInput } from '@rneui/base';
import { Input } from '@rneui/themed';
import type { InputProps } from '@rneui/themed';

import { Colors } from '../utils/constants';

// See: https://github.com/react-native-elements/react-native-elements/issues/3202#issuecomment-1504246880
type RNEInputRef = RefObject<RNTextInput & BaseInput>;

interface WrappedInputProps extends InputProps {
  inputRef: RNEInputRef;
}

function WrappedInput(props: WrappedInputProps): JSX.Element {
  const { inputRef, ...otherProps } = props;
  return <Input {...otherProps} ref={inputRef} />;
}

type Props = {
  value: string;
  onChange: (o: string) => void;
  placeholder: string;
  style?: any;
  errorMessage?: string;
  styleType?: 'NORMAL' | 'WHITE';
  inputStyle?: any;
  focusedColor?: string;
  onfocusColor?: string;
  inputContainerStyle?: any;
  inputRef?: RNEInputRef;
} & InputProps;

export default function TextInput(props: Props) {
  const {
    inputContainerStyle,
    focusedColor = Colors.blue,
    onfocusColor = Colors.lightgray,
    inputStyle = {},
    errorMessage,
    value,
    onChange,
    placeholder,
    style,
    inputRef,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const toggleFocus = () => {
    setIsFocused(!isFocused);
  };

  return (
    <WrappedInput
      inputRef={inputRef}
      onFocus={toggleFocus}
      onBlur={toggleFocus}
      autoCorrect
      autoCapitalize="none"
      value={value}
      containerStyle={[styles.container, style]}
      onChangeText={onChange}
      inputContainerStyle={[
        styles.input(isFocused, focusedColor, onfocusColor),
        inputContainerStyle,
      ]}
      inputStyle={[{ height: 45, padding: 8 }, inputStyle]}
      placeholder={placeholder}
      errorMessage={errorMessage}
      {...props}
    />
  );
}

export const styles = {
  container: {
    width: '100%',
  },
  input: (isFocused: boolean, focusedColor: string, onfocusColor: string): any => ({
    borderWidth: 1,
    borderRadius: 5,
    borderColor: isFocused ? focusedColor : onfocusColor,
    overflow: 'hidden',
  }),
} as const;

/**
 * @generated SignedSource<<6f44fa3c1941b44d51d0f37afa464108>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SaveMultipleChoiceSurveyQuestionResponseInput = {
  clientMutationId?: string | null;
  multipleChoiceSelectionEntityId?: string | null;
  questionRevisionId: string;
  surveyParticipantQuestionId: string;
};
export type SaveMultipleChoiceSurveyQuestionResponseMutation$variables = {
  input: SaveMultipleChoiceSurveyQuestionResponseInput;
};
export type SaveMultipleChoiceSurveyQuestionResponseMutationVariables = SaveMultipleChoiceSurveyQuestionResponseMutation$variables;
export type SaveMultipleChoiceSurveyQuestionResponseMutation$data = {
  readonly saveMultipleChoiceSurveyQuestionResponse: {
    readonly surveyParticipantQuestion: {
      readonly submittedAt: DateString | null;
      readonly response: {
        readonly multipleChoiceResponse: {
          readonly entityId: string;
          readonly body: string;
        } | null;
      } | null;
    } | null;
    readonly survey: {
      readonly viewerCanSubmit: boolean | null;
    } | null;
  } | null;
};
export type SaveMultipleChoiceSurveyQuestionResponseMutationResponse = SaveMultipleChoiceSurveyQuestionResponseMutation$data;
export type SaveMultipleChoiceSurveyQuestionResponseMutation = {
  variables: SaveMultipleChoiceSurveyQuestionResponseMutationVariables;
  response: SaveMultipleChoiceSurveyQuestionResponseMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "submittedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanSubmit",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SaveMultipleChoiceSurveyQuestionResponseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveMultipleChoiceSurveyQuestionResponsePayload",
        "kind": "LinkedField",
        "name": "saveMultipleChoiceSurveyQuestionResponse",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SurveyParticipantQuestion",
            "kind": "LinkedField",
            "name": "surveyParticipantQuestion",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "QuestionResponse",
                "kind": "LinkedField",
                "name": "response",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SurveysQuestionOption",
                    "kind": "LinkedField",
                    "name": "multipleChoiceResponse",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerSurvey",
            "kind": "LinkedField",
            "name": "survey",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SaveMultipleChoiceSurveyQuestionResponseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveMultipleChoiceSurveyQuestionResponsePayload",
        "kind": "LinkedField",
        "name": "saveMultipleChoiceSurveyQuestionResponse",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SurveyParticipantQuestion",
            "kind": "LinkedField",
            "name": "surveyParticipantQuestion",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "QuestionResponse",
                "kind": "LinkedField",
                "name": "response",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SurveysQuestionOption",
                    "kind": "LinkedField",
                    "name": "multipleChoiceResponse",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerSurvey",
            "kind": "LinkedField",
            "name": "survey",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "60dc1ebc644aeb075ff622a273c55a62",
    "id": null,
    "metadata": {},
    "name": "SaveMultipleChoiceSurveyQuestionResponseMutation",
    "operationKind": "mutation",
    "text": "mutation SaveMultipleChoiceSurveyQuestionResponseMutation(\n  $input: SaveMultipleChoiceSurveyQuestionResponseInput!\n) {\n  saveMultipleChoiceSurveyQuestionResponse(input: $input) {\n    surveyParticipantQuestion {\n      submittedAt\n      response {\n        multipleChoiceResponse {\n          entityId\n          body\n          id\n        }\n        id\n      }\n      id\n    }\n    survey {\n      viewerCanSubmit\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "25d3fc849644448beeab56e36da7ca7e";

export default node;

import { OptionSetOptionType } from '../../components/surveys/MultipleChoiceSelect';

export const initialQuestionState = {};

export const SET_QUESTION = 'SET_QUESTION';
export const SET_QUESTION_OPINION = 'SET_QUESTION_OPINION';
export const SET_QUESTIONS = 'SET_QUESTIONS';

export type StateType = Record<
  string,
  {
    surveyParticipantQuestionId: string;
    questionRevisionId: string;
    opinion: 'StrongAgree' | 'Agree' | 'Neutral' | 'Disagree' | 'StrongDisagree' | null;
    eNPSChoice?: number;
    option: OptionSetOptionType | null;
    comment: string | null;
    loading: boolean | null;
  }
>;

type ActionType = {
  type: string;
  payload: any;
};

export function questionsReducer(state: StateType, action: ActionType) {
  switch (action.type) {
    case SET_QUESTIONS:
      return {
        ...state,
        ...action.payload,
      };

    case SET_QUESTION_OPINION:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          opinion: action.payload.opinion,
        },
      };

    case SET_QUESTION:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload,
        },
      };

    default:
      throw new Error('Unknown action type!');
  }
}

import { TouchableOpacity, ActivityIndicator } from 'react-native';
import { Text } from '@rneui/themed';

import Container from './Container';

type Props = {
  handleLoadMore: () => void;
  isLoading: boolean;
  backgroundColor?: string;
};

const LoadMore = ({ handleLoadMore, isLoading, backgroundColor = 'white' }: Props) => (
  <TouchableOpacity style={{ backgroundColor }} onPress={handleLoadMore}>
    <Container direction="row" style={{ height: 60 }}>
      {isLoading ? <ActivityIndicator size="small" /> : <Text>Load more...</Text>}
    </Container>
  </TouchableOpacity>
);

export default LoadMore;

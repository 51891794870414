/**
 * @generated SignedSource<<072f21d7b29db4cfed163b9c395c0e1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubmitReviewRequestInput = {
  clientMutationId?: string | null;
  reviewRequestId: string;
};
export type submitReviewRequestMutation$variables = {
  input: SubmitReviewRequestInput;
};
export type submitReviewRequestMutationVariables = submitReviewRequestMutation$variables;
export type submitReviewRequestMutation$data = {
  readonly submitReviewRequest: {
    readonly reviewRequest: {
      readonly submittedAt: DateString | null;
    } | null;
  } | null;
};
export type submitReviewRequestMutationResponse = submitReviewRequestMutation$data;
export type submitReviewRequestMutation = {
  variables: submitReviewRequestMutationVariables;
  response: submitReviewRequestMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "submittedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "submitReviewRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitReviewRequestPayload",
        "kind": "LinkedField",
        "name": "submitReviewRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReviewRequest",
            "kind": "LinkedField",
            "name": "reviewRequest",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "submitReviewRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitReviewRequestPayload",
        "kind": "LinkedField",
        "name": "submitReviewRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReviewRequest",
            "kind": "LinkedField",
            "name": "reviewRequest",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6b4b4ecffef02859ff30090ff507c588",
    "id": null,
    "metadata": {},
    "name": "submitReviewRequestMutation",
    "operationKind": "mutation",
    "text": "mutation submitReviewRequestMutation(\n  $input: SubmitReviewRequestInput!\n) {\n  submitReviewRequest(input: $input) {\n    reviewRequest {\n      submittedAt\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e28d541c1b1808f622c2c1599e16888c";

export default node;

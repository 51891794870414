/**
 * @generated SignedSource<<c500728251777fabf6f760eef2bd87ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BaseGoalstateEnumType = "active" | "draft" | "ended" | "inactive";
export type BaseGoalstatusEnumType = "amber" | "green" | "red";
export type BaseGoaltypeEnumType = "qualitative" | "quantitative";
import { FragmentRefs } from "relay-runtime";
export type GoalBadge_goal$data = {
  readonly computedProgressAmount: number | null;
  readonly entityId: string;
  readonly id: string;
  readonly state: BaseGoalstateEnumType;
  readonly status: BaseGoalstatusEnumType | null;
  readonly type: BaseGoaltypeEnumType;
  readonly " $fragmentType": "GoalBadge_goal";
};
export type GoalBadge_goal = GoalBadge_goal$data;
export type GoalBadge_goal$key = {
  readonly " $data"?: GoalBadge_goal$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoalBadge_goal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalBadge_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "computedProgressAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": "type",
      "args": null,
      "kind": "ScalarField",
      "name": "computedType",
      "storageKey": null
    }
  ],
  "type": "Goal",
  "abstractKey": null
};

(node as any).hash = "79c5e39d505bc1114f98cf6c7f76761c";

export default node;

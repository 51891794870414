import * as React from 'react';
import { last, uniqBy } from 'lodash';
import { ActivityIndicator, FlatList, SafeAreaView, View, TouchableOpacity } from 'react-native';
import type { StackNavigationProp } from '@react-navigation/stack';
import { ListItem } from '@rneui/themed';
import { graphql, createRefetchContainer } from 'react-relay';
import { css } from '@emotion/native';

import {
  ListItemStyle,
  Colors,
  fontTypes,
  APPLICATION_MAX_WIDTH_STRING,
} from '../../utils/constants';
import { cleanConnection } from '../../utils/fns';
import Header from '../../components/Header';
import type { RootStackParamList } from '../../components/Navigation/types';
import createQueryRenderer from '../../utils/createQueryRenderer';

import type { DirectoryDepartments_viewer } from 'jakiro-types/DirectoryDepartments_viewer.graphql';
import { ListItemChevron } from '../../components/ListItemChevron';

type Props = {
  viewer: DirectoryDepartments_viewer;
  relay: any;
  navigation: StackNavigationProp<RootStackParamList, 'Directory Departments'>;
};

const PER_PAGE = 30;

function DirectoryDepartments(props: Props) {
  const [departments, setDepartments] = React.useState([]);

  const { viewer, navigation } = props;

  const hasNextPage = viewer.company.departmentsConnection?.pageInfo.hasNextPage;
  const lastCursor = last(viewer.company.departmentsConnection?.edges)?.cursor;

  React.useEffect(() => {
    if (!viewer.company.departmentsConnection) return;

    const newDepartments = cleanConnection(viewer.company.departmentsConnection) || [];

    if (newDepartments.length) {
      setDepartments(departments => uniqBy([...departments, ...newDepartments], 'entityId'));
    }
  }, [viewer]);

  const onLoadMore = () => {
    if (!lastCursor || !hasNextPage) return;

    props.relay.refetch(
      v => ({
        ...v,
        after: lastCursor || '',
        first: PER_PAGE,
      }),
      null,
    );
  };

  const departmentsWithMembers = departments.filter(j => j.members?.total);

  return (
    <View
      style={css`
        flex: 1;
        display: flex;
        background-color: ${Colors.faint};
      `}
    >
      <Header
        mode="BACK"
        handleLeftIconPress={() => navigation.navigate('Directory')}
        title="Departments"
      />
      <SafeAreaView
        style={css`
          flex: 1;
          height: 100%;
          width: 100%;
        `}
      >
        <FlatList
          style={css`
            height: 100%;
            width: 100%;
            max-width: ${APPLICATION_MAX_WIDTH_STRING};
            margin: 0 auto;
            flex-direction: column;
            flex: 1;
            display: flex;
          `}
          keyExtractor={item => item.entityId}
          data={departmentsWithMembers}
          numColumns={1}
          renderItem={({ item, index }) => (
            <Department navigation={navigation} index={index} item={item} key={item.entityId} />
          )}
          onEndReached={onLoadMore}
          onEndReachedThreshold={0.25}
          initialNumToRender={PER_PAGE}
          ListFooterComponent={() => {
            if (!hasNextPage) return null;

            return (
              <View
                style={css`
                  padding-top: 16px;
                  padding-bottom: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <ActivityIndicator />
              </View>
            );
          }}
        />
      </SafeAreaView>
    </View>
  );
}

function Department(props: {
  item: {
    entityId: string;
    name: string;
    members: {
      total: number;
    };
  };
  index: number;
  navigation: StackNavigationProp<RootStackParamList, 'Directory Departments'>;
}) {
  const { item, index, navigation } = props;

  return (
    <ListItem
      topDivider={index === 0}
      Component={TouchableOpacity}
      onPress={() => {
        navigation.navigate('Directory Department Employees', {
          departmentEntityId: item.entityId,
        });
      }}
      bottomDivider
      style={[
        ListItemStyle,
        css`
          margin-top: ${index === 0 ? '24px' : '0px'};
        `,
      ]}
    >
      <ListItem.Content>
        <ListItem.Title style={fontTypes.bodyTitle}>{item.name}</ListItem.Title>
        <ListItem.Subtitle
          style={{ ...fontTypes.bodySecondary }}
        >{`${item.members.total} members`}</ListItem.Subtitle>
      </ListItem.Content>
      <ListItemChevron />
    </ListItem>
  );
}

const DirectoryRefetch = createRefetchContainer(
  DirectoryDepartments,
  {
    viewer: graphql`
      fragment DirectoryDepartments_viewer on Viewer
      @argumentDefinitions(
        first: { type: "Int!", defaultValue: 15 }
        status: { type: "[UserStatusEnum]", defaultValue: [ACTIVE] }
        after: { type: "String!", defaultValue: "" }
      ) {
        company {
          departmentsConnection(first: $first, after: $after) {
            pageInfo {
              hasNextPage
            }
            edges {
              cursor
              node {
                entityId
                name
                members(first: 1, status: $status) {
                  total
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query DirectoryDepartmentsRefetchQuery($first: Int!, $after: String!) {
      viewer {
        ...DirectoryDepartments_viewer @arguments(first: $first, after: $after)
      }
    }
  `,
);
export default createQueryRenderer(DirectoryRefetch, DirectoryDepartments, {
  query: graphql`
    query DirectoryDepartmentsQuery($first: Int!) {
      viewer {
        ...DirectoryDepartments_viewer @arguments(first: $first)
      }
    }
  `,
  variables: {
    first: PER_PAGE,
  },
});

import * as React from 'react';
import * as Svg from 'react-native-svg';

export default () => (
  <Svg.Svg width={180} height="32" viewBox="0 0 180 32" fill="none">
    <Svg.Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4588 7.34516L20.4478 12.1361L16.286 16.3023C14.9364 17.6532 12.7479 17.6532 11.3983 16.3023L1.01218 5.90573C-0.337392 4.55438 -0.337392 2.36411 1.01218 1.01319C2.36218 -0.33773 4.55026 -0.33773 5.89982 1.01319L13.8421 8.96346L15.4588 7.34516ZM16.7557 6.047L21.1745 1.62378L26.0617 6.51632L21.9928 10.5894L16.7557 6.047Z"
      transform="translate(0 14.6846)"
      fill="white"
    />
    <Svg.Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.4166 17.8373L24.2091 13.2649L36.4465 1.01523C37.7961 -0.335685 39.9842 -0.335685 41.3342 1.01523C42.6837 2.36615 42.6837 4.55642 41.3342 5.90777L29.4166 17.8373ZM27.7971 19.4584L23.6179 23.6418C22.2679 24.9928 20.0798 24.9928 18.7302 23.6418L1.01218 5.90605C-0.337392 4.55471 -0.337392 2.36443 1.01218 1.01351C2.36174 -0.337838 4.54982 -0.337838 5.89982 1.01351L21.174 16.303L22.892 14.5834L27.7971 19.4584Z"
      transform="translate(7.33228 7.34485)"
      fill="white"
    />
    <Svg.Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5754 19.4824L19.4399 14.5671L28.506 23.6423L36.4466 15.6937C37.7966 14.3428 39.9846 14.3428 41.3342 15.6937C42.6838 17.0451 42.6838 19.2353 41.3342 20.5863L30.9498 30.9811C29.6002 32.332 27.4122 32.332 26.0626 30.9811L14.5754 19.4824ZM12.5601 17.4651L1.01218 5.90562C-0.337392 4.5547 -0.337392 2.36443 1.01218 1.01351C2.36218 -0.337837 4.55026 -0.337837 5.89982 1.01351L17.4597 12.585L12.5601 17.4651Z"
      transform="translate(14.6641 0.00561523)"
      fill="white"
    />
    <Svg.Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1601 1.01319C18.8106 -0.33773 16.6225 -0.33773 15.2725 1.01319L0 16.301L4.88765 21.1935L20.1601 5.90573C21.5097 4.55438 21.5097 2.36411 20.1601 1.01319Z"
      transform="translate(21.1742 0.00756836)"
      fill="white"
    />
    <Svg.Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25 0.135986H0V25.126H15.572V21.352H4.25V0.135986ZM24.77 7.64999C27.354 7.64999 29.088 8.70399 30.04 9.99599L30.244 7.98999H33.916V25.126H30.244L30.04 23.12C29.088 24.412 27.354 25.466 24.77 25.466C20.112 25.466 16.95 21.692 16.95 16.558C16.95 11.39 20.112 7.64999 24.77 7.64999ZM25.654 11.254C23.002 11.254 21.098 13.328 21.098 16.558C21.098 19.788 23.002 21.862 25.654 21.862C27.694 21.862 28.918 20.774 29.836 19.312V13.804C28.918 12.342 27.694 11.254 25.654 11.254ZM44.202 2.24399H40.122V7.98999H36.892V11.458H40.122V19.074C40.122 23.222 42.604 25.466 46.514 25.466C48.214 25.466 49.472 24.99 50.322 24.446L49.234 21.25C48.724 21.556 47.976 21.794 46.888 21.794C45.29 21.794 44.202 20.774 44.202 19.04V11.458H49.37V7.98999H44.202V2.24399ZM58.262 2.24399H54.182V7.98999H50.952V11.458H54.182V19.074C54.182 23.222 56.664 25.466 60.574 25.466C62.274 25.466 63.532 24.99 64.382 24.446L63.294 21.25C62.784 21.556 62.036 21.794 60.948 21.794C59.35 21.794 58.262 20.774 58.262 19.04V11.458H63.43V7.98999H58.262V2.24399ZM71.2 7.98999H67.12V25.126H71.2V7.98999ZM69.16 0C67.732 0 66.644 1.054 66.644 2.516C66.644 3.978 67.732 5.032 69.16 5.032C70.622 5.032 71.71 3.978 71.71 2.516C71.71 1.054 70.622 0 69.16 0ZM83.526 7.64999C78.256 7.64999 74.754 11.39 74.754 16.558C74.754 21.692 78.29 25.466 83.56 25.466C86.654 25.466 89 24.072 90.53 21.692L87.606 19.414C86.79 20.774 85.464 21.896 83.594 21.896C80.568 21.896 78.902 19.55 78.902 16.558C78.902 13.532 80.5 11.22 83.56 11.22C85.464 11.22 86.722 12.308 87.504 13.668L90.462 11.424C88.932 9.04399 86.654 7.64999 83.526 7.64999ZM100.51 7.64999C105.78 7.64999 108.364 11.594 108.364 16.388V17.816H96.26C96.362 20.536 97.994 22.032 100.85 22.032C102.856 22.032 104.318 21.148 105.1 20.06L107.922 22.372C106.222 24.412 103.978 25.466 100.816 25.466C95.512 25.466 92.112 21.726 92.112 16.592C92.112 11.424 95.58 7.64999 100.51 7.64999ZM100.442 10.914C98.028 10.914 96.396 12.512 96.26 14.892H104.352C104.25 12.546 102.89 10.914 100.442 10.914Z"
      transform="translate(70.652 3.27399)"
      fill="white"
    />
  </Svg.Svg>
);

import { FormattedNumber } from 'react-intl-native';

type Props = {
  color: string;
  value?: number | null;
};

function QuantitativeIcon(props: Props) {
  const { color, value = 0 } = props;

  return <FormattedNumber formatStyle="percent" style={{ color }} value={value || 0} />;
}

export default QuantitativeIcon;

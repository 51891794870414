import { useContext, useEffect, useState } from 'react';
import { ScrollView, ImageBackground, View } from 'react-native';
import { Text } from '@rneui/themed';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as WebBrowser from 'expo-web-browser';
import * as Linking from 'expo-linking';
import { toLower } from 'lodash';
import { css } from '@emotion/native';

import {
  ScreenWidth,
  isWeb,
  isIOS,
  fontTypes,
  Colors,
  COMPANY_SLUG,
  APPLICATION_MAX_WIDTH_STRING,
} from '../../utils/constants';
import { getConfig } from '../../utils/getConfig';
import withKeyboardAware from '../../hocs/withKeyboardAware';
import Container from '../../components/Container';
import CompanyNameInput from '../../components/CompanyNameInput';
import LoginOptions from './LoginOptions';
import LatticeLogoSvg from '../../components/LatticeLogoSvg';
import getCompanyInfo from '../../utils/GetCompanyInfo';
import useAuthentication from '../../hooks/useAuthentication';
import { CompanyContext } from '../../hooks/useCompany';

const safeString = (obj: any, val: string): string => obj?.[val] || ''; // make flow happy.

function Login() {
  const [shouldGetSlug, setShouldGetSlug] = useState(true);
  const [slug, setSlug] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setAccessToken } = useAuthentication();
  const { company, setCompany } = useContext(CompanyContext);

  const getAuthParams = () => {
    if (isWeb) {
      // We take them back to this component where we'll grab the access token in `handleOpenURL`
      return `isApp=true&linkingUri=${getConfig('MOBILE_WEBSITE')}/login`;
    }

    return `isApp=true&linkingUri=${Linking.createURL('')}`;
  };

  const logUserIn = (accessToken: string) => {
    /*
      Return early to prevent extra navigate actions.
      On Android when you log out and return to the Auth stack
      the Linking listener is calling handleOpenURL
      for some unknown reason.
      To prevent bugs we return early here.
    */
    if (!company) return;

    setAccessToken(accessToken);
    setErrorMsg(null);
    setIsLoading(true);
  };

  const handleOpenURL = ({ url }: { url: string }) => {
    // Extract stringified user string out of the URL
    const accessToken = url.match(/access_token=([^#&]+)/);
    const error = url.match(/loginError=([^#&]+)/);

    if (error) {
      setErrorMsg(decodeURIComponent(error[1]));
      return;
    }

    if (accessToken) {
      return logUserIn(decodeURIComponent(accessToken[1]));
    }
  };

  useEffect(() => {
    const findCompany = async () => {
      // If we get here it means a user has logged out
      // instead of showing them the getSlug page, we try
      // to read from local storage and hydrate from the company
      // info we're persisting there
      try {
        const companyPayload = await AsyncStorage.getItem(COMPANY_SLUG);
        const payload = JSON.parse(companyPayload);

        if (payload.slug) {
          // we invoke onSubmit if we have a company slug to ensure
          // we have the latest company authentication preferences
          // 95% of the time this should go unnoticed, but if a company
          // does force SSO or enable frontline or something the new UI
          // will be revealed after the getCompanyInfo call
          setSlug(payload.slug);

          setErrorMsg(null);
          setIsLoading(true);

          getCompanyInfo(
            payload.slug,
            c => {
              setShouldGetSlug(false);
              setCompany(c);
              setIsLoading(false);
            },
            () => {
              setErrorMsg('Couldn’t find company slug');
              setShouldGetSlug(true);
              setIsLoading(false);
            },
          );
        }
        // eslint-disable-next-line no-empty
      } catch (_e) {}
    };

    Linking.addEventListener('url', handleOpenURL);
    // Launched from an external URL
    Linking.getInitialURL().then(url => {
      if (url) {
        handleOpenURL({ url });
      }
    });

    findCompany();
  }, []);

  return (
    <ScrollView
      scrollEnabled={false}
      contentContainerStyle={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
    >
      <ImageBackground
        imageStyle={{}}
        style={{ width: ScreenWidth, flex: 1 }}
        source={require('../../assets/images/home2x.png')}
      >
        <View style={{ position: 'relative', top: isWeb ? 80 : 120, left: ScreenWidth / 2 - 90 }}>
          <LatticeLogoSvg />
        </View>

        <Container flex={1} style={{ margin: 20 }}>
          <Container
            flex={1}
            style={css`
              max-width: ${APPLICATION_MAX_WIDTH_STRING};
              width: 100%;
            `}
          >
            {shouldGetSlug ? (
              <>
                <Text style={{ ...fontTypes.bodyTitle, color: Colors.white, marginBottom: 20 }}>
                  Enter your team domain
                </Text>
                <CompanyNameInput
                  onSubmit={() => {
                    setErrorMsg(null);
                    setIsLoading(true);

                    getCompanyInfo(
                      slug,
                      c => {
                        setShouldGetSlug(false);
                        setCompany(c);
                        setIsLoading(false);
                      },
                      () => {
                        setErrorMsg('Couldn’t find company slug');
                        setShouldGetSlug(true);
                        setIsLoading(false);
                      },
                    );
                  }}
                  isLoading={isLoading}
                  onChange={newSlug => {
                    setSlug(toLower(newSlug.trim()));
                  }}
                  value={slug}
                />
                <Text style={[fontTypes.error, { marginTop: 10 }]}>{errorMsg || ''}</Text>
              </>
            ) : (
              <LoginOptions
                errorMsg={errorMsg}
                handleGoogleAuth={async () => {
                  setErrorMsg(null);

                  const authAddress = `${getConfig('AUTH_GOOGLE')}?company_id=${safeString(
                    company,
                    'entityId',
                  )}&slug=${safeString(company, 'slug')}&${getAuthParams()}`;

                  if (isIOS) {
                    // we manually handle redirecting (and capturing metadata from the URL)
                    // @ts-expect-error
                    const { type, url } = await WebBrowser.openAuthSessionAsync(
                      authAddress,
                      Linking.createURL(''),
                    );
                    if (type === 'success') {
                      handleOpenURL({ url });
                    }
                  } else {
                    Linking.openURL(authAddress);
                  }
                }}
                resetSlug={async () => {
                  setSlug('');
                  setCompany(null);
                  setShouldGetSlug(true);
                  await AsyncStorage.removeItem(COMPANY_SLUG);
                }}
                company={company}
                handleSSO={async () => {
                  setErrorMsg(null);

                  const authAddress = `${safeString(company, 'ssoLoginUrl')}?${getAuthParams()}`;

                  Linking.openURL(authAddress);
                }}
              />
            )}
          </Container>
        </Container>
      </ImageBackground>
    </ScrollView>
  );
}

export default withKeyboardAware(Login);

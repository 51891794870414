import * as React from 'react';
import { View, TextInputProps } from 'react-native';
import { Input } from '@rneui/themed';
import Markdown from './Markdown';

import { Colors } from '../utils/constants';

type Props = {
  submitOnDone?: boolean;
  multiline?: boolean;
  value: string | null;
  placeholder: string;
  onChange?: (k: string) => void;
  onSubmit?: () => void;
  inputStyle?: any;
  inputContainerStyle?: any;
  containerStyle?: any;
  height: number;
  getInputRef?: ((any) => void) | null;
  onBlur?: (k: boolean) => void;
  editable?: boolean;
  blurOnSubmit?: boolean;
  updatePosition?: () => void;
  returnKeyType: TextInputProps['returnKeyType'] | null;
  strikeThrough?: boolean;
};

type State = {
  height: number;
  isFocused: boolean;
};

const getHeightIncrease = (d: number): any => d * 0.5222;

class MultilineTextInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isFocused: false,
      height: props.height,
    };
  }

  static defaultProps = {
    returnKeyType: 'done',
    submitOnDone: false,
    multiline: true,
    blurOnSubmit: true,
    inputContainerStyle: {},
    containerStyle: {},
    height: 45,
  };

  inputRef: any;

  onContentChange = (event: any) => {
    const { updatePosition } = this.props;
    const contentHeight = event.nativeEvent.contentSize.height;
    const totalHeight = contentHeight + getHeightIncrease(this.props.height);
    if (totalHeight != this.state.height) {
      updatePosition && updatePosition();
      this.setState({
        height: contentHeight + getHeightIncrease(this.props.height),
      });
    }
  };

  toggleFocus = (callback?: () => void) => {
    this.setState(
      ({ isFocused }) => ({ isFocused: !isFocused }),
      () => {
        if (typeof callback == 'function') {
          callback();
        }
      },
    );
  };

  showWhen = (when: boolean) => ({ width: when ? 'auto' : 0, height: when ? 'auto' : 0 });

  handleInputRef = (e: any) => {
    const { getInputRef } = this.props;
    this.inputRef = e;
    getInputRef && e && getInputRef(e.input);
  };

  handleOnFocus = () => {
    this.setState({
      isFocused: true,
    });
  };

  handleOnBlur = () => {
    const { onBlur } = this.props;
    this.toggleFocus();
    onBlur && onBlur(false);
  };

  handleOnEnterSubmit = (e: any) => {
    const { submitOnDone, blurOnSubmit, onBlur } = this.props;

    if (submitOnDone && !blurOnSubmit && e.nativeEvent.key === 'Enter') {
      onBlur && onBlur(true);
    }
  };

  render() {
    const { height, isFocused } = this.state;
    const {
      onChange,
      onSubmit,
      blurOnSubmit,
      placeholder,
      inputStyle,
      returnKeyType,
      inputContainerStyle,
      containerStyle,
      editable,
      value,
      strikeThrough,
    } = this.props;

    const displayAsText = !editable || (!isFocused && !!value);
    const displayNoneStyleInput = !displayAsText ? {} : { display: 'none' };

    return (
      <>
        <View style={displayAsText ? {} : { display: 'none' }}>
          <Markdown
            strikeThrough={strikeThrough}
            onPress={
              // Returning undefined to avoid the Markdown cursor: pointer styling from being applied
              editable
                ? () => {
                    this.toggleFocus(() => this.inputRef && this.inputRef.input.focus());
                  }
                : undefined
            }
          >
            {value}
          </Markdown>
        </View>
        <Input
          multiline
          placeholderTextColor="rgba(34, 45, 56, 0.3)"
          ref={this.handleInputRef}
          placeholder={placeholder}
          value={value}
          onChangeText={onChange}
          onSubmitEditing={onSubmit}
          blurOnSubmit={blurOnSubmit}
          returnKeyType={returnKeyType}
          onKeyPress={this.handleOnEnterSubmit}
          containerStyle={[
            { width: '100%', paddingHorizontal: 0 },
            containerStyle,
            displayNoneStyleInput,
          ]}
          inputContainerStyle={[styles.container, inputContainerStyle]}
          inputStyle={styles.input(Math.max(45, height), inputStyle)}
          onBlur={this.handleOnBlur}
          onFocus={this.handleOnFocus}
          editable={editable}
          onContentSizeChange={this.onContentChange}
        />
      </>
    );
  }
}

const styles = {
  url: {
    color: Colors.buttonPrimary,
  },
  placeholderText: (height: number, inputStyle: any) => ({
    ...inputStyle,
    height: 'auto',
    width: '100%',
    fontSize: 18,
  }),
  container: {
    marginTop: 0,
    borderBottomWidth: 0,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    marginRight: 0,
  },
  completed: {
    fontSize: 18,
    marginLeft: 0,
    color: Colors.darkgray,
    backgroundColor: 'transparent',
    textDecorationLine: 'line-through',
    padding: 5,
  },
  input: (height: number, inputStyle: any) => ({
    fontSize: 18,
    width: '100%',
    height: 'auto',
    marginLeft: 0,
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 2,
    ...inputStyle,
  }),
} as const;

export default MultilineTextInput;

// This is an @expo/vector-icons compatible component of:
// https://github.com/react-native-elements/react-native-elements/blob/5a48d3335a7a8e5d4002f95b78cfaa17b34792f5/packages/base/src/ListItem/ListItem.Chevron.tsx#L1
import { Platform, View } from 'react-native';
import { IconProps } from '@rneui/base';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import Ionicons from '@expo/vector-icons/Ionicons';

interface ListItemChevronProps extends Partial<IconProps> {}

export function ListItemChevron({ color }: ListItemChevronProps) {
  if (Platform.OS === 'ios') {
    return (
      <View style={{ alignSelf: 'center', marginLeft: 'auto' }}>
        <Ionicons color={(color as string) ?? '#D1D1D6'} name="chevron-forward-outline" size={24} />
      </View>
    );
  }

  return (
    <View style={{ alignSelf: 'center', marginLeft: 'auto' }}>
      <MaterialIcons color={(color as string) ?? '#D1D1D6'} name="keyboard-arrow-right" size={24} />
    </View>
  );
}

import { View } from 'react-native';

import OneOnOnePager from './OneOnOnePager';
import Starting from '../../../components/Starting';

type OneOnOneStatus = 'NEW' | 'ONGOING' | 'DONE' | 'IS_LOADING';

type Props = {
  status: OneOnOneStatus;
  startDate: string | null;
  onBack: (() => void) | null;
  onNext: (() => void) | null;
};

const renderStatus = (status, startDate, onBack, onNext) => {
  switch (status) {
    case 'ONGOING':
    case 'NEW':
    case 'DONE':
      return <OneOnOnePager onBack={onBack} onNext={onNext} title={startDate || ''} />;
    case 'IS_LOADING':
      return <Starting />;
    default:
      return null;
  }
};

const FooterStatus = ({ status, startDate, onBack, onNext }: Props) => (
  <View>{renderStatus(status, startDate, onBack, onNext)}</View>
);

export default FooterStatus;

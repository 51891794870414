import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment';

import LabeledValue from '../../../components/LabeledValue';
import Separator from '../../../components/Separator';
import OwnersList from './OwnersList';
import TagsList from './TagsList';

import { Colors } from '../../../utils/constants';

import type { GoalProfileInformationTab_information } from 'jakiro-types/GoalProfileInformationTab_information.graphql';

type Props = {
  information: GoalProfileInformationTab_information;
};

const GoalProfileInformationTab = ({ information }: Props) => {
  return (
    <View testID="GoalProfileInformationTab" style={styles.contentStyle}>
      {information.ownersList.length > 0 && (
        <View style={{ padding: 15 }}>
          <LabeledValue label="OWNERS">
            {/* This works but it probably shouldn't, we should refactor */}
            {/* this code to properly use relay fragments! */}
            {/* @ts-expect-error */}
            <OwnersList owners={information.ownersList} />
          </LabeledValue>
        </View>
      )}
      <Separator />
      <View style={{ padding: 15 }}>
        <LabeledValue
          label="CREATED"
          value={moment(information.createdAt).format('MMMM DD, YYYY')}
        />
      </View>
      <Separator />
      <View style={{ padding: 15 }}>
        <LabeledValue label="DUE" value={moment(information.dueDate).format('MMMM DD, YYYY')} />
      </View>
      <Separator />
      <View style={{ padding: 15 }}>
        <LabeledValue
          label="TYPE"
          value={
            information.companyGoal
              ? 'Company Goal'
              : information.isDepartmentGoal
              ? 'Department Goal'
              : 'Individual Goal'
          }
        />
      </View>
      {information.tags.length > 0 && (
        <React.Fragment>
          <Separator />
          <View style={{ padding: 15 }}>
            <LabeledValue label="TAGS">
              <TagsList tags={information.tags.map(k => ({ name: k.name }))} />
            </LabeledValue>
          </View>
        </React.Fragment>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  contentStyle: {
    backgroundColor: Colors.lightestgray,
  },
});

const FragmentContainer = createFragmentContainer(GoalProfileInformationTab, {
  information: graphql`
    fragment GoalProfileInformationTab_information on Goal {
      ownersList {
        name
        avatarUrl
        preferredName
      }
      createdAt
      dueDate
      isDepartmentGoal
      companyGoal
      tags {
        entityId
        name
      }
    }
  `,
});

export default FragmentContainer;

import * as React from 'react';
import Modal from 'react-native-modal';
import { Text } from 'react-native';
import { css } from '@emotion/native';
import * as Updates from 'expo-updates';

import { fontTypes, isWeb } from '../utils/constants';
import { captureException } from '../utils/fns';
import Button from '../components/Button';
import Container from '../components/Container';

type State = {
  hasUpdate: boolean;
};

export default (WrappedComponent: any) => {
  // 'expo-updates' doesn't work on web
  // See: https://docs.expo.dev/versions/latest/sdk/updates/
  if (isWeb) {
    return WrappedComponent;
  }

  // eslint-disable-next-line react/display-name
  return class extends React.Component<void, State> {
    state = {
      hasUpdate: false,
    };

    updatePoller: any;

    componentDidMount() {
      this.checkForUpdates();

      this.updatePoller = setInterval(this.checkForUpdates, 25 * 1000);
    }

    componentWillUnmount() {
      clearInterval(this.updatePoller);
    }

    checkForUpdates = async () => {
      try {
        const update = await Updates.checkForUpdateAsync();

        if (update.isAvailable) {
          clearInterval(this.updatePoller);
          await Updates.fetchUpdateAsync();
          this.setState({ hasUpdate: true });
        }
      } catch (e) {
        captureException(e);
      }
    };

    render() {
      return (
        <>
          <WrappedComponent
            // @ts-expect-error FIXME! Spread types may only be created from object types.
            {...this.props}
          />

          <Modal useNativeDriver isVisible={this.state.hasUpdate}>
            <Container
              style={css`
                background-color: white;
                padding: 16px;
                border-radius: 16px;
              `}
            >
              <Text style={[fontTypes.title, { marginBottom: 16 }]}>Update available</Text>
              <Text style={[fontTypes.body, { marginBottom: 16 }]}>
                We’re constantly improving our application to give you the best experience possible
              </Text>

              <Button
                title="Update"
                onPress={() => Updates.reloadAsync()}
                containerStyle={css`
                  width: 100%;
                `}
              />
            </Container>
          </Modal>
        </>
      );
    }
  };
};

import { ActivityIndicator } from 'react-native';
import { Text } from '@rneui/themed';

import Container from './Container';

import { fontTypes } from '../utils/constants';

const Starting = () => (
  <Container
    direction="row"
    style={{
      height: 50,
    }}
  >
    <Text
      style={{
        ...fontTypes.informative_text,
        marginRight: 25,
      }}
    >
      Starting...
    </Text>
    <ActivityIndicator />
  </Container>
);

export default Starting;

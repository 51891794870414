import { Text } from 'react-native';

import Container from '../Container';

type Props = {
  childGoals: number;
};

const GoalChildren = ({ childGoals }: Props) => {
  const total = childGoals;
  if (total === 0) return null;

  return (
    <Container align="flex-start" flex={1}>
      <Text style={{ color: '#617080' }}>{`${total} key result${total ? 's' : ''}`}</Text>
    </Container>
  );
};

export default GoalChildren;

import * as React from 'react';
import { Dimensions, StyleSheet, ScrollView } from 'react-native';
import { graphql } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';

import { cleanConnection } from '../../utils/fns';
import type { RootStackParamList } from '../../components/Navigation/types';
import GoalCard from '../GoalCard';
import EmptyState from '../EmptyState';

import createQueryRenderer from '../../utils/createQueryRenderer';

import type { ActiveGoalsTabQueryResponse } from 'jakiro-types/ActiveGoalsTabQuery.graphql';

type Props = {
  scrollView?: boolean;
  cardStyle: any;
} & ActiveGoalsTabQueryResponse;

export const ActiveGoalsTab = ({ scrollView = true, viewer, cardStyle }: Props) => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const user = viewer?.user;
  const viewerIsUser = viewer?.user?.viewerIsUser;
  const activeGoals = cleanConnection(user?.activeGoals);

  if (!user) return null;

  if (activeGoals.length === 0)
    return (
      <EmptyState
        title="No active goals"
        body={`${viewerIsUser ? 'You have' : 'There are'} no active goals.`}
      />
    );

  const cards: React.ReactNode[] = activeGoals.map((goal, i) => {
    return (
      <GoalCard
        key={i}
        goal={goal}
        cardStyle={{ ...cardStyle, width: Dimensions.get('window').width * 0.85, maxWidth: 320 }}
        onPress={(goalEntityId: string) => {
          navigation.navigate('Goal', { goalIds: [goalEntityId] });
        }}
      />
    );
  });

  if (!scrollView) return <>{cards}</>;

  return (
    <ScrollView testID="ActiveGoalsTab_scrollView" horizontal style={styles.cardScroller}>
      {cards}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  cardScroller: {
    marginTop: 15,
  },
});

export default createQueryRenderer(ActiveGoalsTab, ActiveGoalsTab, {
  query: graphql`
    query ActiveGoalsTabQuery($userId: String!) {
      viewer {
        user: findUser(userId: $userId) {
          viewerIsUser
          activeGoals: ownedGoals(state: ACTIVE, last: 100, orderBy: Priority) {
            edges {
              node {
                ...GoalCard_goal
              }
            }
          }
        }
      }
    }
  `,
  getLoadingProp: false,
  queriesParams: props => ({ userId: props.userId }),
});

import { graphql } from 'react-relay';

import commitMutation from './commitMutation';

import type { destroyOneOnOneTalkingPointCommentMutationVariables } from 'jakiro-types/destroyOneOnOneTalkingPointCommentMutation.graphql';

const mutationName = 'destroyOneOnOneTalkingPointComment';
const mutation = graphql`
  mutation destroyOneOnOneTalkingPointCommentMutation(
    $input: DestroyOneOnOneTalkingPointCommentInput!
  ) {
    destroyOneOnOneTalkingPointComment(input: $input) {
      talkingPoint {
        id
        comments {
          id
          createdAt
          body
          user {
            viewerIsUser
            avatarUrl
            name
          }
        }
      }
      errors
    }
  }
`;

type Args = {
  talkingPointId: string;
  talkingPointCommentId: string | null;
};

function commit(args: Args, onCompleted: any, onError: any) {
  const variables: destroyOneOnOneTalkingPointCommentMutationVariables = {
    input: {
      ...args,
    },
  };

  return commitMutation({
    mutationName,
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

export default { commit };

import * as Svg from 'react-native-svg';

type Props = {
  color: string | null;
};

export default ({ color }: Props) => (
  <Svg.Svg width="24" height="24" viewBox="0 0 24 24" fill={color}>
    <Svg.G clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd">
      <Svg.Path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2-8a2 2 0 1 0 4 0 2 2 0 0 0-4 0z" />
      <Svg.Path d="M6 12a6 6 0 1 0 12 0 6 6 0 0 0-12 0zm10 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0z" />
    </Svg.G>
    <Svg.Defs>
      <Svg.ClipPath id="a">
        <Svg.Path d="M0 0h24v24H0z" />
      </Svg.ClipPath>
    </Svg.Defs>
  </Svg.Svg>
);

import * as React from 'react';
import NetInfo from '@react-native-community/netinfo';

import { isWeb } from '../utils/constants';

export default function useHasNetwork(init: boolean = true) {
  const [hasNetwork, setHasNetwork] = React.useState(init);

  React.useEffect(() => {
    // NetInfo's module for web compatability is broken at the moment 😔
    if (isWeb) return;

    const unsubscribe = NetInfo.addEventListener(state => {
      setHasNetwork(state.isConnected);
    });

    return () => {
      unsubscribe();
    };
  }, [hasNetwork]);

  return hasNetwork;
}

import * as React from 'react';
import { graphql } from 'react-relay';
import { View, SafeAreaView } from 'react-native';
import { Svg, Path, G } from 'react-native-svg';
import styled, { css } from '@emotion/native';
import { useAnalytics } from '@segment/analytics-react-native';

import TabButton from './TabButton';
import getColor from '../../utils/getSelectedColor';

import { Colors, isIOS, ScreenWidth } from '../../utils/constants';
import Directory from '../../pages/directory';
import You from '../../pages/you';
import withKeyboardAware from '../../hocs/withKeyboardAware';
import withNotificationSubscription from '../../hocs/withNotificationSubscription';

import createQueryRenderer from '../../utils/createQueryRenderer';
import type { TabViewQueryResponse } from 'jakiro-types/TabViewQuery.graphql';

export type Tabs = 'You' | 'Directory';

const withIdentify = (Component: any) => {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    const { identify } = useAnalytics();

    return <Component identify={identify} {...props} />;
  };
};

type Props = {
  isLoading: boolean;
  relay: any;
  dropdownAlert?: (args: any) => void;
  isKeyboardOpen: boolean;
  defaultTab: Tabs;
  identify: ReturnType<typeof useAnalytics>['identify'];
} & TabViewQueryResponse;

type State = {
  tab: Tabs;
};

class TabView extends React.Component<Props, State> {
  state = {
    tab: this.props.defaultTab,
  };

  static defaultProps = {
    defaultTab: 'You',
  };

  componentDidUpdate(prevProps) {
    const user = this.props.viewer?.user;
    const prevUser = prevProps.viewer?.user;

    if (!prevUser && user) {
      const { isManager, company, entityId: userId } = user;

      this.props.identify(userId, {
        isManager,
        company,
      });
    }
  }

  handleNavigation = (tab: Tabs) => this.setState({ tab });

  getActiveTab = () => {
    const { tab } = this.state;

    switch (tab) {
      case 'You':
        return <You />;

      case 'Directory':
        return <Directory />;

      default:
        return <You />;
    }
  };

  render() {
    const { tab } = this.state;
    const { viewer, isKeyboardOpen } = this.props;

    if (!viewer) return null;

    return (
      <>
        <View
          style={css`
            background-color: ${Colors.lightestgray};
            flex: 1;
          `}
        >
          {this.getActiveTab()}
        </View>

        {(isIOS || !isKeyboardOpen) && (
          <BottomTab handleNavigation={this.handleNavigation} selected={tab} />
        )}
      </>
    );
  }
}

export default createQueryRenderer(
  withNotificationSubscription(withKeyboardAware(withIdentify(TabView))),
  TabView,
  {
    query: graphql`
      query TabViewQuery {
        viewer {
          managerCheckinRelationship {
            isOneOnOneActive
          }
          user {
            viewerIsUser
            avatarUrl
            entityId
            isManager
            name
            email
            company {
              name
              entityId
            }
            manager {
              id
            }
          }
        }
      }
    `,
    getLoadingProp: true,
    variables: {},
  },
);

type BottomTabProps = {
  handleNavigation: (p: Tabs) => void;
  selected: Tabs;
};

type SvgProps = {
  isSelected: boolean;
};

const SinglePersonIcon = (props: SvgProps) => (
  <Svg width={28} height={28} fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.939 15.42c-.608-.553-1.126-1.294-1.509-2.092-.42-.213-.718-.754-.718-1.389 0-.2.03-.39.083-.564a5.43 5.43 0 0 1-.083-.92 4.454 4.454 0 1 1 8.91 0c0 .295-.03.604-.084.921.053.174.083.364.083.563 0 .635-.299 1.176-.718 1.389-.383.798-.9 1.539-1.51 2.092v.974h.743l.507 1.014c2.348.435 3.948 1.45 3.948 3.44v.485a1 1 0 0 1-1 1H7.742a1 1 0 0 1-1-1v-.484c0-1.991 1.6-3.006 3.948-3.441l.507-1.014h.742v-.974z"
      fill={getColor(props.isSelected)}
    />
  </Svg>
);

const DirectoryIcon = (props: SvgProps) => (
  <Svg viewBox="0 0 70 70" width={28} height={28}>
    <Path
      fill={getColor(props.isSelected)}
      d="M53.347 60h-30.19a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h30.19c2.1 0 3.81 1.71 3.81 3.81v42.38c0 2.1-1.71 3.81-3.81 3.81zm-29.19-2h29.19c.998 0 1.81-.812 1.81-1.81V13.81c0-.998-.812-1.81-1.81-1.81h-29.19v46z"
    />
    <Path
      fill={getColor(props.isSelected)}
      d="M23.156 60h-6a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v48a1 1 0 0 1-1 1zm-5-2h4V12h-4v46z"
    />
    <Path
      fill={getColor(props.isSelected)}
      d="M19.531 16.542h-5.687a1 1 0 1 1 0-2h5.687a1 1 0 1 1 0 2zm0 6.486h-5.687a1 1 0 1 1 0-2h5.687a1 1 0 1 1 0 2zm0 6.486h-5.687a1 1 0 1 1 0-2h5.687a1 1 0 1 1 0 2zm0 6.486h-5.687a1 1 0 1 1 0-2h5.687a1 1 0 1 1 0 2zm0 6.486h-5.687a1 1 0 1 1 0-2h5.687a1 1 0 1 1 0 2zm0 6.486h-5.687a1 1 0 1 1 0-2h5.687a1 1 0 1 1 0 2zm0 6.487h-5.687a1 1 0 1 1 0-2h5.687a1 1 0 1 1 0 2z"
    />
    <G>
      <Path
        fill={getColor(props.isSelected)}
        d="M51.906 41.406h-24.5a1 1 0 1 1 0-2h24.5a1 1 0 1 1 0 2zm0 5.334h-24.5a1 1 0 1 1 0-2h24.5a1 1 0 1 1 0 2zm0 5.333h-24.5a1 1 0 1 1 0-2h24.5a1 1 0 1 1 0 2z"
      />
      <G>
        <Path
          fill={getColor(props.isSelected)}
          d="M45.29 37.323H34.023a1.06 1.06 0 0 1-1.035-1.035V30.55a4.06 4.06 0 0 1 4.055-4.055 1 1 0 0 1 .837.452l1.775 2.712 1.777-2.712c.185-.282.5-.452.837-.452a4.06 4.06 0 0 1 4.054 4.055v5.738a1.06 1.06 0 0 1-1.035 1.035zm-10.3-2h9.334V30.55c0-.96-.662-1.77-1.554-1.994l-2.277 3.477a1 1 0 0 1-1.674 0l-2.275-3.477a2.058 2.058 0 0 0-1.555 1.994v4.773zm4.666-9.82c-2.088 0-3.787-1.7-3.787-3.788s1.7-3.788 3.787-3.788c2.09 0 3.788 1.7 3.788 3.788s-1.699 3.787-3.788 3.787zm0-5.576c-.985 0-1.787.802-1.787 1.788s.802 1.787 1.787 1.787c.987 0 1.788-.802 1.788-1.787s-.801-1.788-1.788-1.788z"
        />
      </G>
    </G>
  </Svg>
);

const BottomTab = ({ handleNavigation, selected }: BottomTabProps) => (
  <SafeAreaView style={{ backgroundColor: 'white' }}>
    <BottomBar>
      <TabButton
        icon={<SinglePersonIcon isSelected={selected === 'You'} />}
        handlePress={handleNavigation}
        label="You"
        isSelected={selected === 'You'}
      />
      <TabButton
        icon={<DirectoryIcon isSelected={selected === 'Directory'} />}
        handlePress={handleNavigation}
        label="Directory"
        isSelected={selected === 'Directory'}
      />
    </BottomBar>
  </SafeAreaView>
);

const BottomBar = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-top-width: 0.5;
  width: ${ScreenWidth};
  height: 60px;
  border-top-color: ${Colors.lightgray};
  background-color: white;
`;

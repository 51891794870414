/**
 * @generated SignedSource<<8f3f0f682b422f4ff05f79494b71865d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddTalkingPointToNextOneOnOneMeetingInput = {
  body: string;
  checkinRelationshipId: string;
  clientMutationId?: string | null;
  meetingId?: string | null;
  recommendedTalkingPointId?: string | null;
};
export type addTalkingPointToNextOneOnOneMeetingMutation$variables = {
  input: AddTalkingPointToNextOneOnOneMeetingInput;
};
export type addTalkingPointToNextOneOnOneMeetingMutationVariables = addTalkingPointToNextOneOnOneMeetingMutation$variables;
export type addTalkingPointToNextOneOnOneMeetingMutation$data = {
  readonly addTalkingPointToNextOneOnOneMeeting: {
    readonly errors: ReadonlyArray<string | null> | null;
    readonly talkingPoint: {
      readonly __typename: string;
      readonly body: string;
      readonly entityId: string;
      readonly creator: {
        readonly avatarUrl: string | null;
        readonly viewerIsUser: boolean;
      };
      readonly meeting: {
        readonly entityId: string;
      };
      readonly completedAt: DateString | null;
    } | null;
  } | null;
};
export type addTalkingPointToNextOneOnOneMeetingMutationResponse = addTalkingPointToNextOneOnOneMeetingMutation$data;
export type addTalkingPointToNextOneOnOneMeetingMutation = {
  variables: addTalkingPointToNextOneOnOneMeetingMutationVariables;
  response: addTalkingPointToNextOneOnOneMeetingMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsUser",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addTalkingPointToNextOneOnOneMeetingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddTalkingPointToNextOneOnOneMeetingPayload",
        "kind": "LinkedField",
        "name": "addTalkingPointToNextOneOnOneMeeting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OneOnOneTalkingPoint",
            "kind": "LinkedField",
            "name": "talkingPoint",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OneOnOneMeeting",
                "kind": "LinkedField",
                "name": "meeting",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addTalkingPointToNextOneOnOneMeetingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddTalkingPointToNextOneOnOneMeetingPayload",
        "kind": "LinkedField",
        "name": "addTalkingPointToNextOneOnOneMeeting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OneOnOneTalkingPoint",
            "kind": "LinkedField",
            "name": "talkingPoint",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OneOnOneMeeting",
                "kind": "LinkedField",
                "name": "meeting",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cf177b4197aacee3d54942cc2a1eb064",
    "id": null,
    "metadata": {},
    "name": "addTalkingPointToNextOneOnOneMeetingMutation",
    "operationKind": "mutation",
    "text": "mutation addTalkingPointToNextOneOnOneMeetingMutation(\n  $input: AddTalkingPointToNextOneOnOneMeetingInput!\n) {\n  addTalkingPointToNextOneOnOneMeeting(input: $input) {\n    errors\n    talkingPoint {\n      __typename\n      body\n      entityId\n      creator {\n        avatarUrl\n        viewerIsUser\n        id\n      }\n      meeting {\n        entityId\n        id\n      }\n      completedAt\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cd269f2d4578441fc3204aa6ea60450a";

export default node;

import * as React from 'react';
import { View } from 'react-native';
import { CheckBox } from '@rneui/themed';
import { css } from '@emotion/native';
import Svg, { Circle, Rect, Path } from 'react-native-svg';
import { truncate } from 'lodash';
import { createFragmentContainer, graphql } from 'react-relay';

import { Colors } from '../../utils/constants';
import Button, { ButtonStyles } from '../Button';
import {
  BodyQuestion,
  RatingQuestion,
  RatingAndBodyQuestion,
  MultipleSelectQuestion,
  MultipleChoiceQuestion,
  MultipleSelectAndBodyQuestion,
  MultipleChoiceAndBodyQuestion,
  formatPayload,
} from '../../pages/reviews/reviewCycleResponseFormReducer';
import type { StateType } from '../../pages/reviews/reviewCycleResponseFormReducer';

import type { ReviewCycleQuestionResponse_reviewRequestQuestion } from 'jakiro-types/ReviewCycleQuestionResponse_reviewRequestQuestion.graphql';

import Markdown from '../Markdown';
import TextInput from '../TextInput';

const EMPTY_ARRAY = [];

type Props = {
  disabled: boolean;
  state: StateType;
  saveResponse: ({
    value,
    reviewRequestQuestionId,
  }: {
    value: any;
    reviewRequestQuestionId: string;
  }) => void;
  reviewRequestQuestion: ReviewCycleQuestionResponse_reviewRequestQuestion;
};

function ReviewCycleQuestionResponse({
  reviewRequestQuestion,
  disabled,
  saveResponse,
  state,
}: Props) {
  const questionRevision = reviewRequestQuestion.questionRevision;
  const response = state.responses[reviewRequestQuestion.id] || {};

  if (!questionRevision) return null;

  const { shouldHideNumbers } = questionRevision.question;

  const maximumSelection = questionRevision.question.maximumSelection;
  const hasCustomMaximumSelection = maximumSelection != null;

  const [body, setBody] = React.useState(response.body);
  const [rating, setRating] = React.useState(Number(response.rating));
  const [multiSelect, setMultipleAnswerQuestion] = React.useState(
    response.multipleSelect ? response.multipleSelect.map(Number) : EMPTY_ARRAY,
  );

  const setAndSaveBody = type => e => {
    setBody(e);

    saveResponse({
      value: formatPayload({ value: { body: e }, type, response }),
      reviewRequestQuestionId: reviewRequestQuestion.id,
    });
  };

  const setMultipleChoice = (answer, type) => {
    let next = [];

    if (multiSelect.includes(answer)) {
      setMultipleAnswerQuestion(next);
    } else {
      next = [answer];
      setMultipleAnswerQuestion(next);
    }

    saveResponse({
      value: formatPayload({
        response,
        value: { multipleSelect: next.map(v => v.toString()) },
        type,
      }),
      reviewRequestQuestionId: reviewRequestQuestion.id,
    });
  };

  const setMultipleSelect = (answer, type) => {
    let next = [];

    if (multiSelect.includes(answer)) {
      const index = multiSelect.findIndex(o => answer == o);
      next = [...multiSelect.slice(0, index), ...multiSelect.slice(index + 1)];
    } else {
      next = [...multiSelect, answer];
    }

    // if our multiple select question has maximumSelection, let's respect it
    if (Boolean(hasCustomMaximumSelection) && Number(maximumSelection) < next.length) {
      next = next.slice(1);
    }

    setMultipleAnswerQuestion(next);

    saveResponse({
      value: formatPayload({
        type,
        response,
        value: { multipleSelect: next.map(v => v.toString()) },
      }),
      reviewRequestQuestionId: reviewRequestQuestion.id,
    });
  };

  const choices =
    Array.isArray(questionRevision.choices) && questionRevision.choices.length > 0
      ? questionRevision.choices
      : [];

  const scale =
    Array.isArray(questionRevision.scale) && questionRevision.scale.length > 0
      ? questionRevision.scale
      : [];

  switch (questionRevision.question.__typename) {
    case RatingQuestion:
      return (
        <React.Fragment>
          {scale.map((type, index) => {
            const value = index + 1;
            const isSelected = value === Number(rating);

            return (
              <RatingButton
                key={index}
                onPress={() => {
                  if (disabled) return;

                  let nextValue = value.toString();

                  if (isSelected) {
                    nextValue = null;
                  }

                  setRating(isSelected ? null : value);

                  saveResponse({
                    value: formatPayload({
                      response,
                      value: { rating: nextValue },
                      type: RatingQuestion,
                    }),
                    reviewRequestQuestionId: reviewRequestQuestion.id,
                  });
                }}
                title={shouldHideNumbers ? truncate(scale[index], { length: 40 }) : index + 1}
                isSelected={isSelected}
              />
            );
          })}
        </React.Fragment>
      );

    case RatingAndBodyQuestion:
      return (
        <React.Fragment>
          {scale.map((type, index) => {
            const value = index + 1;
            const isSelected = value === Number(rating);

            return (
              <RatingButton
                key={index}
                onPress={() => {
                  if (disabled) return;

                  let nextValue = value.toString();
                  if (isSelected) {
                    nextValue = null;
                  }

                  setRating(isSelected ? null : value);

                  saveResponse({
                    value: formatPayload({
                      response,
                      value: { rating: nextValue },
                      type: RatingAndBodyQuestion,
                    }),
                    reviewRequestQuestionId: reviewRequestQuestion.id,
                  });
                }}
                title={shouldHideNumbers ? truncate(scale[index], { length: 40 }) : index + 1}
                isSelected={isSelected}
              />
            );
          })}

          <CommentBody
            isOptional={Boolean(questionRevision.question.isBodyOptional)}
            body={body}
            setAndSaveBody={setAndSaveBody(RatingAndBodyQuestion)}
            disabled={disabled}
            style={css`
              margin-top: 8px;
            `}
          />
        </React.Fragment>
      );

    case MultipleSelectQuestion:
      return (
        <React.Fragment>
          {choices.map((type, index) => {
            const isSelected = multiSelect.includes(index);

            return (
              <MultiSelectCheckbox
                key={index}
                title={type}
                isSelected={isSelected}
                onPress={() => {
                  if (disabled) return;

                  setMultipleSelect(index, MultipleSelectQuestion);
                }}
              />
            );
          })}
        </React.Fragment>
      );

    case MultipleSelectAndBodyQuestion:
      return (
        <React.Fragment>
          {choices.map((type, index) => {
            const isSelected = multiSelect.includes(index);

            return (
              <MultiSelectCheckbox
                key={index}
                title={type}
                isSelected={isSelected}
                onPress={() => {
                  if (disabled) return;

                  setMultipleSelect(index, MultipleSelectQuestion);
                }}
              />
            );
          })}
          <CommentBody
            isOptional={Boolean(questionRevision.question.isBodyOptional)}
            body={body}
            setAndSaveBody={setAndSaveBody(MultipleSelectAndBodyQuestion)}
            disabled={disabled}
            style={css`
              margin-top: 8px;
            `}
          />
        </React.Fragment>
      );

    case MultipleChoiceQuestion:
      return (
        <React.Fragment>
          {choices.map((type, index) => {
            const isSelected = multiSelect.includes(index);

            return (
              <MultipleChoiceRadio
                key={index}
                title={type}
                isSelected={isSelected}
                onPress={() => {
                  if (disabled) return;

                  setMultipleChoice(index, MultipleChoiceQuestion);
                }}
              />
            );
          })}
        </React.Fragment>
      );

    case MultipleChoiceAndBodyQuestion:
      return (
        <React.Fragment>
          {choices.map((type, index) => {
            const isSelected = multiSelect.includes(index);

            return (
              <MultipleChoiceRadio
                key={index}
                title={type}
                isSelected={isSelected}
                onPress={() => {
                  if (disabled) return;

                  setMultipleChoice(index, MultipleChoiceAndBodyQuestion);
                }}
              />
            );
          })}

          <CommentBody
            isOptional={Boolean(questionRevision.question.isBodyOptional)}
            body={body}
            setAndSaveBody={setAndSaveBody(MultipleChoiceAndBodyQuestion)}
            disabled={disabled}
            style={css`
              margin-top: 8px;
            `}
          />
        </React.Fragment>
      );

    case BodyQuestion:
      return (
        <CommentBody
          isOptional={Boolean(questionRevision.question.isBodyOptional)}
          body={body}
          setAndSaveBody={setAndSaveBody(BodyQuestion)}
          disabled={disabled}
          style={{}}
        />
      );

    default:
      return null;
  }
}

type CommentBodyProps = {
  isOptional: boolean;
  body: string | null;
  setAndSaveBody: (k: string) => void;
  disabled: boolean;
  style: any;
};

function CommentBody({ isOptional, body, setAndSaveBody, disabled, style }: CommentBodyProps) {
  if (disabled) {
    return (
      <View
        style={{
          width: '100%',
          height: 120,
          padding: 16,
          borderRadius: 8,
          backgroundColor: Colors.lightergray,
        }}
      >
        <Markdown>{body}</Markdown>
      </View>
    );
  }
  return (
    <TextInput
      placeholder={`${isOptional ? '' : 'Required:'} Write your response...`}
      multiline
      // @ts-expect-error
      onChange={setAndSaveBody}
      value={body || ''}
      inputStyle={css`
        width: 100%;
        height: 120px;
        padding: 16px;
        text-align-vertical: top;
      `}
      inputContainerStyle={css`
        border-color: transparent;
      `}
      style={[
        css`
          border-radius: 8px;
          background-color: ${Colors.lightergray};
          padding-left: 0;
          padding-right: 0;
        `,
        style,
      ]}
      returnKeyType={null}
      disabled={disabled}
    />
  );
}

export function RatingButton({
  onPress,
  title,
  isSelected,
}: {
  onPress: () => void;
  title: string | number;
  isSelected: boolean;
}) {
  return (
    <Button
      containerStyle={css`
        margin: 5px 0;
        width: 100%;
      `}
      buttonStyle={css`
        ${ButtonStyles.rounded}
        background-color: ${isSelected ? Colors.darkgray : Colors.lightergray};
        height: 40px;
      `}
      titleStyle={css`
        color: ${isSelected ? Colors.white : Colors.darkergray};
        font-weight: 600;
      `}
      title={String(title)}
      key="type"
      onPress={onPress}
    />
  );
}

function MultiSelectCheckbox({ title, onPress, isSelected }) {
  return (
    <CheckBox
      checkedIcon={<MultiSelectChecked />}
      uncheckedIcon={<MultiSelectUnChecked />}
      containerStyle={css`
        padding-left: 0;
        margin: 5px 0;
        border: none;
        background-color: white;
      `}
      title={title}
      onPress={onPress}
      checked={isSelected}
    />
  );
}

function MultipleChoiceRadio({ title, onPress, isSelected }) {
  return (
    <CheckBox
      containerStyle={css`
        padding-left: 0;
        margin: 5px 0;
        border: none;
        background-color: white;
      `}
      title={title}
      onPress={onPress}
      checked={isSelected}
      checkedIcon={<MultipleChoiceChecked />}
      uncheckedIcon={<MultipleChoiceUnChecked />}
    />
  );
}

const MultiSelectChecked = props => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Rect width={24} height={24} rx={6} fill="#617080" />
    <Path
      d="M9.643 15.88l7.66-7.66A.833.833 0 1118.482 9.4l-8.245 8.244-.005.005a.83.83 0 01-1.178 0l-.005-.004-3.531-3.531a.833.833 0 011.179-1.179l2.946 2.947z"
      fill="#fff"
    />
  </Svg>
);

const MultiSelectUnChecked = props => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Rect x={1} y={1} width={22} height={22} rx={5} stroke="#617080" strokeWidth={2} />
  </Svg>
);

const MultipleChoiceUnChecked = props => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Circle cx={12} cy={12} r={11} stroke="#617080" strokeWidth={2} />
  </Svg>
);

const MultipleChoiceChecked = props => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Circle cx={12} cy={12} r={11} stroke="#617080" strokeWidth={2} />
    <Circle cx={12} cy={12} r={8} fill="#617080" />
  </Svg>
);

export default createFragmentContainer(ReviewCycleQuestionResponse, {
  reviewRequestQuestion: graphql`
    fragment ReviewCycleQuestionResponse_reviewRequestQuestion on ModernReviewRequestQuestion {
      id
      isPrivate
      reviewResponse {
        content
      }
      goal {
        ...GoalCard_goal
      }
      questionRevision: questionRevisionV2 {
        entityId
        body
        ... on RatingQuestionRevision {
          scale
        }
        ... on MultipleSelectQuestionRevision {
          choices
        }
        ... on MultipleChoiceQuestionRevision {
          choices
        }
        ... on RatingAndBodyQuestionRevision {
          scale
        }
        ... on MultipleSelectAndBodyQuestionRevision {
          choices
        }
        ... on MultipleChoiceAndBodyQuestionRevision {
          choices
        }
        question {
          __typename
          entityId
          ... on ReviewCycleQuestionInterface {
            isOptional
          }
          ... on RatingQuestion {
            shouldHideNumbers
          }
          ... on MultipleSelectQuestion {
            maximumSelection
          }
          ... on RatingAndBodyQuestion {
            shouldHideNumbers
            isRatingOptional
            isBodyOptional
          }
          ... on MultipleSelectAndBodyQuestion {
            maximumSelection
            isMultipleSelectOptional
            isBodyOptional
          }
          ... on MultipleChoiceAndBodyQuestion {
            isMultipleChoiceOptional
            isBodyOptional
          }
        }
      }
    }
  `,
});

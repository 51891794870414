import * as React from 'react';
import { graphql, fetchQuery_DEPRECATED } from 'react-relay';
import { noop } from 'lodash';
import { getConfig } from '../../../utils/getConfig';

import { captureException } from '../../../utils/fns';
import environment from '../../../utils/Environment';
import usePoll from '../../../hooks/usePoll';
import type { OneOnOneSyncQueryResponse } from 'jakiro-types/OneOnOneSyncQuery.graphql';

type Props = {
  meeting: any; // OneOnOneContent_meeting,
  timeout: number;
  refetch: any;
  deps: any[];
};

export default function OneOnOneSync(props: Props) {
  const { timeout, meeting, refetch, deps } = props;
  const [pollTime, setPollTime] = React.useState(new Date().toISOString());

  let pollTimeout = timeout;
  const syncCallback = React.useCallback(() => {
    fetchHasBeenModifiedSince({
      id: meeting.id,
      currentTime: pollTime,
    })
      .then((result: OneOnOneSyncQueryResponse) => {
        if (result && result.hasBeenModifiedSince) {
          refetch({ meetingID: meeting.id }, null, noop, { force: true });
        }
        setPollTime(new Date().toISOString());
      })
      .catch(e => {
        pollTimeout = -1; // causes usePoll to stop
        captureException(e);
      });
  }, [meeting.id, refetch, pollTime, pollTimeout]);

  usePoll(syncCallback, pollTimeout, deps);

  return null; // this component doesn't render anything
}

function fetchHasBeenModifiedSince({ id, currentTime }) {
  const MODIFIED_QUERY = graphql`
    query OneOnOneSyncQuery($id: ID!, $currentTime: DateTime!) {
      hasBeenModifiedSince(id: $id, ifModifiedSince: $currentTime)
    }
  `;

  return fetchQuery_DEPRECATED(environment(getConfig('GRAPHQL_ENDPOINT')), MODIFIED_QUERY, {
    id,
    currentTime,
  });
}

/**
 * @generated SignedSource<<0b33fd3301c7dbc44ce1beec4f701d41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedbackCard_feedback$data = {
  readonly body: string;
  readonly createdAt: DateString;
  readonly companyValues: ReadonlyArray<{
    readonly name: string;
  } | null> | null;
  readonly targets: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly author: {
    readonly avatarUrl: string | null;
    readonly name: string | null;
    readonly preferredName: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"UserChip_user">;
  };
  readonly competency: {
    readonly name: string;
  } | null;
  readonly " $fragmentType": "FeedbackCard_feedback";
};
export type FeedbackCard_feedback = FeedbackCard_feedback$data;
export type FeedbackCard_feedback$key = {
  readonly " $data"?: FeedbackCard_feedback$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedbackCard_feedback">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedbackCard_feedback",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyValue",
      "kind": "LinkedField",
      "name": "companyValues",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "targets",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatarUrl",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "preferredName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserChip_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Competency",
      "kind": "LinkedField",
      "name": "competency",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Feedback",
  "abstractKey": null
};
})();

(node as any).hash = "4cd05abd19bd72c058e823bb0134958e";

export default node;

/**
 * @generated SignedSource<<20c12fa6764d31af567a26bfa66a03ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DirectoryDepartmentEmployeesQuery$variables = {
  first: number;
  departmentEntityId: string;
};
export type DirectoryDepartmentEmployeesQueryVariables = DirectoryDepartmentEmployeesQuery$variables;
export type DirectoryDepartmentEmployeesQuery$data = {
  readonly viewer: {
    readonly findDepartment: {
      readonly " $fragmentSpreads": FragmentRefs<"DirectoryDepartmentEmployees_department">;
    } | null;
  } | null;
};
export type DirectoryDepartmentEmployeesQueryResponse = DirectoryDepartmentEmployeesQuery$data;
export type DirectoryDepartmentEmployeesQuery = {
  variables: DirectoryDepartmentEmployeesQueryVariables;
  response: DirectoryDepartmentEmployeesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "departmentEntityId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = [
  {
    "kind": "Variable",
    "name": "departmentId",
    "variableName": "departmentEntityId"
  }
],
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DirectoryDepartmentEmployeesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Department",
            "kind": "LinkedField",
            "name": "findDepartment",
            "plural": false,
            "selections": [
              {
                "args": [
                  (v3/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "DirectoryDepartmentEmployees_department"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DirectoryDepartmentEmployeesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Department",
            "kind": "LinkedField",
            "name": "findDepartment",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "after",
                    "value": ""
                  },
                  (v3/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "status",
                    "value": [
                      "ACTIVE"
                    ]
                  }
                ],
                "concreteType": "departmentMembersConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "departmentMembersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "entityId",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "shape",
                                "value": "SQUARE"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "avatarUrl",
                            "storageKey": "avatarUrl(shape:\"SQUARE\")"
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e2a1adf506fc74c24dc2d95f4a06602",
    "id": null,
    "metadata": {},
    "name": "DirectoryDepartmentEmployeesQuery",
    "operationKind": "query",
    "text": "query DirectoryDepartmentEmployeesQuery(\n  $first: Int!\n  $departmentEntityId: String!\n) {\n  viewer {\n    findDepartment(departmentId: $departmentEntityId) {\n      ...DirectoryDepartmentEmployees_department_3ASum4\n      id\n    }\n    id\n  }\n}\n\nfragment DirectoryDepartmentEmployees_department_3ASum4 on Department {\n  name\n  members(first: $first, after: \"\", status: [ACTIVE]) {\n    pageInfo {\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        entityId\n        ...PersonCell_user\n        id\n      }\n    }\n  }\n}\n\nfragment PersonCell_user on User {\n  name\n  email\n  entityId\n  avatarUrl(shape: SQUARE)\n}\n"
  }
};
})();

(node as any).hash = "37fbace9beac9215cf76433eab16a82c";

export default node;

import * as React from 'react';
import { View, TouchableOpacity, Text } from 'react-native';

import Container from './Container';
import { Colors, fontTypes } from '../utils/constants';

type Props = {
  tabViewConfig: any;
  horizontalSpacing?: number;
  handleChange: (o: number) => void;
  backgroundColor: string;
  lineColor: string;
  activeColor: string;
  inactiveColor: string;
  getLabelText?: (x: any) => string;
};

type NativeEventLayout = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type NativeEventOnLayout = {
  layout: NativeEventLayout;
};

type Event = {
  nativeEvent: NativeEventOnLayout;
};

type State = {
  labelsRect: NativeEventLayout[];
};

const getColor = (
  currentIndex: number,
  index: number,
  activeColor: string,
  inactiveColor: string,
): string => (currentIndex === index ? activeColor : inactiveColor);

class TabViewHeader extends React.Component<Props, State> {
  state = {
    labelsRect: [],
  };

  static defaultProps = {
    lineColor: Colors.iosBlue,
    backgroundColor: 'transparent',
    activeColor: 'black',
    inactiveColor: Colors.darkgray,
  };

  onLayoutLabelButton = (index: number) => (event: Event) => {
    const { labelsRect } = this.state;

    if (!labelsRect[index]) {
      labelsRect[index] = event.nativeEvent.layout;
      this.setState({ labelsRect });
      this.forceUpdate();
    }
  };

  render() {
    const {
      tabViewConfig,
      handleChange,
      activeColor,
      inactiveColor,
      lineColor,
      backgroundColor,
      getLabelText,
    } = this.props;

    return (
      <View style={styles.wrap}>
        <Container
          direction="row"
          justify="space-around"
          align="flex-start"
          style={styles.labelWrapper(backgroundColor)}
        >
          {!!tabViewConfig.navigationState &&
            !!tabViewConfig.navigationState.routes &&
            tabViewConfig.navigationState.routes.map((route, index) => {
              const isActive = tabViewConfig.navigationState.index === index;

              return (
                <TouchableOpacity
                  key={route.key}
                  onPress={() => {
                    handleChange(index);
                  }}
                  onLayout={this.onLayoutLabelButton(index)}
                  style={styles.tab(tabViewConfig.navigationState.routes.length)}
                >
                  <Text
                    style={{
                      ...fontTypes.bodyTitle,
                      color: getColor(
                        tabViewConfig.navigationState.index,
                        index,
                        activeColor,
                        inactiveColor,
                      ),
                    }}
                  >
                    {getLabelText ? getLabelText(route) : route.title.toUpperCase()}
                  </Text>

                  <View style={styles.activeLine(lineColor, isActive)} />
                </TouchableOpacity>
              );
            })}
        </Container>
      </View>
    );
  }
}

const styles = {
  wrap: {
    borderBottomWidth: 0.5,
    borderBottomColor: Colors.lightgray,
  },
  tab: tabSize =>
    ({
      width: `${100 / tabSize}%`,
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    } as const),
  labelWrapper: (backgroundColor: string) =>
    ({
      backgroundColor,
      marginBottom: 2,
    } as const),
  activeLine: (lineColor: string, isActive: boolean) =>
    ({
      backgroundColor: isActive ? lineColor || Colors.iosBlue : 'white',
      borderRadius: 1,
      width: '100%',
      bottom: -2,
      height: 3,
      flexShrink: 0,
      zIndex: 10,
      position: 'absolute',
    } as const),
} as const;

export default TabViewHeader;

import { Platform } from 'react-native';
import * as Application from 'expo-application';
import { v5 as uuidv5, v4 as uuidv4 } from 'uuid';

const UUID_NAMESPACE = '29cc8a0d-747c-5f85-9ff9-f2f16636d963'; // uuidv5(0, "expo")

// See: https://github.com/expo/expo/pull/10997
export async function getInstallationId() {
  let installationId: string;

  if (['android', 'ios'].includes(Platform.OS)) {
    let identifierForVendor: string;

    if (Platform.OS === 'android') {
      identifierForVendor = Application.androidId;
    } else {
      // iOS
      identifierForVendor = await Application.getIosIdForVendorAsync();
    }

    const bundleIdentifier = Application.applicationId;

    // It's unlikely identifierForVendor will be null (it returns null if the
    // device has been restarted but not yet unlocked), but let's handle this
    // case.
    if (identifierForVendor) {
      installationId = uuidv5(`${bundleIdentifier}-${identifierForVendor}`, UUID_NAMESPACE);
    } else {
      const installationTime = await Application.getInstallationTimeAsync();
      installationId = uuidv5(`${bundleIdentifier}-${installationTime.getTime()}`, UUID_NAMESPACE);
    }
  } else {
    // Web
    installationId = uuidv4();
  }

  return installationId;
}

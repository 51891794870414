/**
 * @generated SignedSource<<f694c73c39b3d4ed8b793deb6fa51acb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OneOnOneContent_oneOnOne$data = {
  readonly previousOneOnOneMeeting: {
    readonly entityId: string;
  } | null;
  readonly nextOneOnOneMeeting: {
    readonly entityId: string;
  } | null;
  readonly id: string;
  readonly entityId: string;
  readonly scheduledAt: DateString;
  readonly cancelledAt: DateString | null;
  readonly reviewedAt: DateString | null;
  readonly note: string | null;
  readonly reportNote: string | null;
  readonly viewerPrivateNote: string | null;
  readonly talkingPoints: ReadonlyArray<{
    readonly id: string;
    readonly body: string;
    readonly entityId: string;
    readonly creator: {
      readonly avatarUrl: string | null;
      readonly name: string | null;
      readonly viewerIsUser: boolean;
    };
    readonly comments: ReadonlyArray<{
      readonly id: string;
      readonly createdAt: DateString;
      readonly body: string | null;
      readonly user: {
        readonly viewerIsUser: boolean;
        readonly avatarUrl: string | null;
        readonly name: string | null;
      };
    }>;
    readonly completedAt: DateString | null;
  } | null>;
  readonly " $fragmentType": "OneOnOneContent_oneOnOne";
};
export type OneOnOneContent_oneOnOne = OneOnOneContent_oneOnOne$data;
export type OneOnOneContent_oneOnOne$key = {
  readonly " $data"?: OneOnOneContent_oneOnOne$data;
  readonly " $fragmentSpreads": FragmentRefs<"OneOnOneContent_oneOnOne">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsUser",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OneOnOneContent_oneOnOne",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OneOnOneMeeting",
      "kind": "LinkedField",
      "name": "previousOneOnOneMeeting",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OneOnOneMeeting",
      "kind": "LinkedField",
      "name": "nextOneOnOneMeeting",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    (v2/*: any*/),
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cancelledAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reviewedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reportNote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerPrivateNote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OneOnOneTalkingPoint",
      "kind": "LinkedField",
      "name": "talkingPoints",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "creator",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OneOnOneTalkingPointComment",
          "kind": "LinkedField",
          "name": "comments",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v6/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OneOnOneMeeting",
  "abstractKey": null
};
})();

(node as any).hash = "c92f6608c95831d81f1264731ed7b557";

export default node;

/**
 * @generated SignedSource<<a369a71c1d473654b269b58853ec586c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCheckinInput = {
  clientMutationId?: string | null;
  data: CreateCheckinDataInput;
  relationshipId: string;
};
export type CreateCheckinDataInput = {
  answers?: ReadonlyArray<CreateCheckinAnswerInput | null> | null;
  public?: boolean | null;
  sentimentAnswer?: string | null;
  sentimentScore?: number | null;
  weekId?: string | null;
};
export type CreateCheckinAnswerInput = {
  body?: string | null;
  questionId: string;
};
export type createCheckinMutation$variables = {
  input: CreateCheckinInput;
};
export type createCheckinMutationVariables = createCheckinMutation$variables;
export type createCheckinMutation$data = {
  readonly createCheckin: {
    readonly checkin: {
      readonly id: string;
    } | null;
    readonly user: {
      readonly id: string;
    } | null;
    readonly errors: ReadonlyArray<string | null> | null;
  } | null;
};
export type createCheckinMutationResponse = createCheckinMutation$data;
export type createCheckinMutation = {
  variables: createCheckinMutationVariables;
  response: createCheckinMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateCheckinPayload",
    "kind": "LinkedField",
    "name": "createCheckin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Checkin",
        "kind": "LinkedField",
        "name": "checkin",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createCheckinMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createCheckinMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "74c8c8d3f4c713f8e3479e55ce29ebe2",
    "id": null,
    "metadata": {},
    "name": "createCheckinMutation",
    "operationKind": "mutation",
    "text": "mutation createCheckinMutation(\n  $input: CreateCheckinInput!\n) {\n  createCheckin(input: $input) {\n    checkin {\n      id\n    }\n    user {\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "83f9efa7523996a79044af95670e2fcb";

export default node;

import * as React from 'react';
import { ScrollView, Image, Keyboard } from 'react-native';
import { Text } from '@rneui/themed';
import PhoneInput from 'react-native-phone-input';
import { css } from '@emotion/native';
import type { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import { Colors, fontTypes } from '../../utils/constants';
import Container from '../../components/Container';
import Button, { ButtonStyles } from '../../components/Button';
import Header from '../../components/Header';
import { PhoneLogin } from '../../utils/Login';
import { RootStackParamList } from '../../components/Navigation/types';

type Props = {
  navigation: StackNavigationProp<RootStackParamList, 'Phone Login'>;
  route: RouteProp<RootStackParamList, 'Phone Login'>;
};

export default function LoginPhone({ navigation, route }: Props) {
  const [phone, setPhone] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [requestId, setRequestId] = React.useState(null);

  const phoneRef = React.useRef(null);

  React.useEffect(() => {
    if (phoneRef.current && phoneRef.current.focus) {
      phoneRef.current.focus();
    }
  }, []);

  const submit = () => {
    if (
      phone.length === 0 ||
      (phoneRef.current?.isValidNumber && phoneRef.current.isValidNumber() === false)
    ) {
      return;
    }

    Keyboard.dismiss();
    setIsLoading(true);
    const companyId = route.params.company.entityId;

    let payload = {
      countryCode: phoneRef.current?.getCountryCode && phoneRef.current.getCountryCode(),
      companyEntityId: companyId,
      subscriberNumber: phone.match(/\d+/g).join(''),
    };

    if (requestId) {
      payload = {
        ...payload,
        // @ts-expect-error
        requestId,
      };
    }

    PhoneLogin(
      payload,
      (errors: string[]) => {
        setIsLoading(false);
        setRequestId(null);
        setErrorMsg(errors[0]);
      },
      res => {
        setIsLoading(false);
        setRequestId(null);
        navigation.navigate('Phone Login Confirmation', { ...res, setRequestId });
      },
    );
  };

  return (
    <Container style={{ flex: 1, backgroundColor: 'white' }}>
      <Header
        title=""
        mode="BACK"
        bottomComponent
        containerStyle={css`
          width: 100%;
        `}
      />

      <ScrollView
        contentContainerStyle={css`
          flex: 1;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
        `}
        style={css`
          width: 300px;
          padding-top: 60px;
        `}
      >
        <Image
          source={require('../../assets/images/latticeLogo.png')}
          resizeMode="contain"
          style={css`
            width: 55px;
            height: 31px;
            margin-bottom: 15px;
          `}
        />
        {errorMsg ? (
          <Text
            style={css`
              ${fontTypes.bodyTitle}
              ${fontTypes.error}
              margin-bottom: 30px;
            `}
          >
            {errorMsg}
          </Text>
        ) : (
          <Text
            style={css`
              ${fontTypes.bodyTitle}
              margin-bottom: 30px;
            `}
          >
            Enter your phone number
          </Text>
        )}

        <PhoneInput
          onChangePhoneNumber={setPhone}
          autoFormat
          initialCountry="us"
          ref={phoneRef}
          style={css`
            border: 1px solid rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            padding: 12px 12px;
            margin-bottom: 24px;
          `}
        />

        <Button
          title="Continue"
          onPress={submit}
          disabled={phone.length < 5}
          disabledStyle={css`
            background-color: ${Colors.gray};
          `}
          loading={isLoading}
          loadingProps={{ size: 'large', color: 'rgba(111, 202, 186, 1)' }}
          containerStyle={css`
            margin: 5px 0;
            width: 100%;
          `}
          buttonStyle={css`
            ${ButtonStyles.rounded}
            background-color: ${Colors.iosBlue};
            height: 50px;
          `}
          titleStyle={css`
            color: ${Colors.white};
            font-weight: 600;
          `}
        />
      </ScrollView>
    </Container>
  );
}

/**
 * @generated SignedSource<<a6b4acb4ee5c7811e86fd72019590cf3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActiveGoalsTabQuery$variables = {
  userId: string;
};
export type ActiveGoalsTabQueryVariables = ActiveGoalsTabQuery$variables;
export type ActiveGoalsTabQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly viewerIsUser: boolean;
      readonly activeGoals: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"GoalCard_goal">;
          };
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type ActiveGoalsTabQueryResponse = ActiveGoalsTabQuery$data;
export type ActiveGoalsTabQuery = {
  variables: ActiveGoalsTabQueryVariables;
  response: ActiveGoalsTabQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsUser",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "last",
    "value": 100
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "Priority"
  },
  {
    "kind": "Literal",
    "name": "state",
    "value": "ACTIVE"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActiveGoalsTabQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "user",
            "args": (v1/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "findUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "activeGoals",
                "args": (v3/*: any*/),
                "concreteType": "userOwnedGoalsConnection",
                "kind": "LinkedField",
                "name": "ownedGoals",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "userOwnedGoalsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Goal",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "GoalCard_goal"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "ownedGoals(last:100,orderBy:\"Priority\",state:\"ACTIVE\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActiveGoalsTabQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "user",
            "args": (v1/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "findUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "activeGoals",
                "args": (v3/*: any*/),
                "concreteType": "userOwnedGoalsConnection",
                "kind": "LinkedField",
                "name": "ownedGoals",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "userOwnedGoalsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Goal",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Goal",
                            "kind": "LinkedField",
                            "name": "childrenList",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "entityId",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "private",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "state",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": "amountType",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "computedAmountType",
                            "storageKey": null
                          },
                          {
                            "alias": "goalAmount",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "computedGoalAmount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "progressAmount",
                            "storageKey": null
                          },
                          {
                            "alias": "startingAmount",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "computedStartingAmount",
                            "storageKey": null
                          },
                          {
                            "alias": "type",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "computedType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "computedProgressAmount",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "ownedGoals(last:100,orderBy:\"Priority\",state:\"ACTIVE\")"
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "61fe522737ad1fdc29cc954de813d736",
    "id": null,
    "metadata": {},
    "name": "ActiveGoalsTabQuery",
    "operationKind": "query",
    "text": "query ActiveGoalsTabQuery(\n  $userId: String!\n) {\n  viewer {\n    user: findUser(userId: $userId) {\n      viewerIsUser\n      activeGoals: ownedGoals(state: ACTIVE, last: 100, orderBy: Priority) {\n        edges {\n          node {\n            ...GoalCard_goal\n            id\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment GoalBadge_goal on Goal {\n  computedProgressAmount\n  entityId\n  id\n  state\n  status\n  type: computedType\n}\n\nfragment GoalCard_goal on Goal {\n  childrenList {\n    id\n  }\n  entityId\n  id\n  name\n  private\n  state\n  status\n  ...GoalProgress_goal\n  ...GoalBadge_goal\n}\n\nfragment GoalProgress_goal on Goal {\n  id\n  amountType: computedAmountType\n  goalAmount: computedGoalAmount\n  progressAmount\n  startingAmount: computedStartingAmount\n  type: computedType\n  computedProgressAmount\n}\n"
  }
};
})();

(node as any).hash = "e4e0871bfd8a93692c8e8067f2d828cd";

export default node;

import { StyleSheet } from 'react-native';
import { Badge } from '@rneui/themed';
import { Colors } from '../utils/constants';

type Props = {
  label: string;
  tagStyle?: any;
  textStyle?: any;
  onPress?: any;
};

const Tag = ({ label, tagStyle, textStyle, onPress }: Props) => (
  <Badge
    value={label}
    badgeStyle={tagStyle || styles.containerStyle}
    textStyle={textStyle || styles.textStyle}
    onPress={onPress}
  />
);

const styles = StyleSheet.create({
  containerStyle: {
    backgroundColor: Colors.lightgray,
    height: 32,
  },
  textStyle: {
    color: Colors.darkergray,
  },
});

export default Tag;

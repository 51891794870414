/**
 * @generated SignedSource<<ba150fe572e904ac05581cf91fefeb1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserStatusEnum = "ACTIVE" | "CREATED" | "DEACTIVATED" | "INVITED";
export type DirectoryAllEmployeesRefetchQuery$variables = {
  query: string;
  hasQuery: boolean;
  first: number;
  after: string;
  status: UserStatusEnum;
};
export type DirectoryAllEmployeesRefetchQueryVariables = DirectoryAllEmployeesRefetchQuery$variables;
export type DirectoryAllEmployeesRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"DirectoryAllEmployees_viewer">;
  } | null;
};
export type DirectoryAllEmployeesRefetchQueryResponse = DirectoryAllEmployeesRefetchQuery$data;
export type DirectoryAllEmployeesRefetchQuery = {
  variables: DirectoryAllEmployeesRefetchQueryVariables;
  response: DirectoryAllEmployeesRefetchQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasQuery"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v5 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v6 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v7 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v8 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "entityId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "shape",
        "value": "SQUARE"
      }
    ],
    "kind": "ScalarField",
    "name": "avatarUrl",
    "storageKey": "avatarUrl(shape:\"SQUARE\")"
  },
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DirectoryAllEmployeesRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "hasQuery",
                "variableName": "hasQuery"
              },
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "DirectoryAllEmployees_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "DirectoryAllEmployeesRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "condition": "hasQuery",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": "LAST_NAME"
                      },
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "concreteType": "companyUsersConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "companyUsersEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "condition": "hasQuery",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v7/*: any*/)
                ],
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "searchUsers",
                "plural": true,
                "selections": (v10/*: any*/),
                "storageKey": null
              }
            ]
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "770db5d17e8582c0572174904ab7b607",
    "id": null,
    "metadata": {},
    "name": "DirectoryAllEmployeesRefetchQuery",
    "operationKind": "query",
    "text": "query DirectoryAllEmployeesRefetchQuery(\n  $query: String!\n  $hasQuery: Boolean!\n  $first: Int!\n  $after: String!\n  $status: UserStatusEnum!\n) {\n  viewer {\n    ...DirectoryAllEmployees_viewer_29Swb8\n    id\n  }\n}\n\nfragment DirectoryAllEmployees_viewer_29Swb8 on Viewer {\n  company {\n    users(orderBy: LAST_NAME, query: $query, first: $first, after: $after, status: $status) @skip(if: $hasQuery) {\n      pageInfo {\n        hasNextPage\n      }\n      edges {\n        cursor\n        node {\n          entityId\n          ...PersonCell_user\n          id\n        }\n      }\n    }\n    id\n  }\n  searchUsers(query: $query) @include(if: $hasQuery) {\n    entityId\n    ...PersonCell_user\n    id\n  }\n}\n\nfragment PersonCell_user on User {\n  name\n  email\n  entityId\n  avatarUrl(shape: SQUARE)\n}\n"
  }
};
})();

(node as any).hash = "b716bf31511c6c4638bb567943a1a6f0";

export default node;

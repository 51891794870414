import { StyleSheet } from 'react-native';

import { Colors, fontTypes } from '../../utils/constants';

export default StyleSheet.create({
  badgeContainer: {
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 40,
    height: 40,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 8,
    paddingLeft: 27,
  },
  cardStyle: {
    height: 160,
    borderRadius: 8,
    padding: 0,
  },
  cardWrapperStyle: {
    flex: 1,
  },
  textStyle: {
    color: Colors.darkestgray,
  },
  titleStyle: {
    ...fontTypes.title,
    alignSelf: 'flex-start',
  },
});

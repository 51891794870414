import type { Animated } from 'react-native';
import { headerScrollDistance } from './dimensions';

/**
 * All these interpolations could be done on a single pass...
 * Probably having a single structure for input/output ranges and then
 * filling all the "cues" for each element, according to what/when it happen
 **/

const wrapperTranslate = (headerHeight: number, scrollViewScrollY: Animated.AnimatedValue) => {
  const outputRange: number[] = [0, -headerScrollDistance(headerHeight)];
  return scrollViewScrollY.interpolate({
    inputRange: [0, headerScrollDistance(headerHeight)],
    outputRange,
    extrapolate: 'clamp',
  });
};

const headerTranslate = (headerHeight: number, scrollViewScrollY: Animated.AnimatedValue) => {
  const outputRange: number[] = [0, headerScrollDistance(headerHeight)];
  return scrollViewScrollY.interpolate({
    inputRange: [0, headerScrollDistance(headerHeight)],
    outputRange,
    extrapolate: 'clamp',
  });
};

const titleOpacity = (headerHeight: number, scrollViewScrollY: Animated.AnimatedValue) => {
  const outputRange: number[] = [0, 0, 1];
  return scrollViewScrollY.interpolate({
    inputRange: [
      0,
      headerScrollDistance(headerHeight) / 10,
      headerScrollDistance(headerHeight) / 2,
    ],
    outputRange,
    extrapolate: 'clamp',
  });
};

const goalTitleOpacity = (headerHeight: number, scrollViewScrollY: Animated.AnimatedValue) => {
  const outputRange: number[] = [1, 1, 0];
  return scrollViewScrollY.interpolate({
    inputRange: [
      0,
      headerScrollDistance(headerHeight) / 10,
      headerScrollDistance(headerHeight) / 4,
    ],
    outputRange,
    extrapolate: 'clamp',
  });
};

const goalExcerptOpacity = (headerHeight: number, scrollViewScrollY: Animated.AnimatedValue) => {
  const outputRange: number[] = [1, 1, 0];
  return scrollViewScrollY.interpolate({
    inputRange: [0, headerScrollDistance(headerHeight) / 5, headerScrollDistance(headerHeight)],
    outputRange,
    extrapolate: 'clamp',
  });
};

const headerOpacity = (headerHeight: number, scrollViewScrollY: Animated.AnimatedValue) => {
  const outputRange: number[] = [0, 0, 1];
  return scrollViewScrollY.interpolate({
    inputRange: [-1, headerScrollDistance(headerHeight) / 2, headerScrollDistance(headerHeight)],
    outputRange,
    extrapolate: 'clamp',
  });
};

export const interpolations = {
  wrapperTranslate,
  headerTranslate,
  titleOpacity,
  goalTitleOpacity,
  goalExcerptOpacity,
  headerOpacity,
};
